import { LabelValue, YupSchema } from "app/types";
import { IShopifyTriggers, ShopiFyWorkFlowForm } from "app/types/integration/shopify";
import * as yup from "yup";
import { WebhookConditions } from "../Integration/integrationUtils";

const optionSchema = (msg?: string) => {
    return yup
        .object()
        .shape<YupSchema<LabelValue>>({
            label: yup.string().trim().required(msg),
            value: yup.string().trim().required(msg),
        })
        .nullable();
};
const webhookConditions = yup.object().shape<YupSchema<WebhookConditions>>({
    fact: yup.string().optional(),
    operator: optionSchema("Operator is Required").required("Operator is Required"),
    path: optionSchema("Comparator is Required").required("Comparator is Required"),
    value: yup.string().when(["path", "operator"], {
        is: (path: LabelValue, operator: LabelValue) =>
            !(path?.value === "financial_status" || path?.value === "$.financial_status") &&
            !(path?.value === "shipment_status" || path?.value === "$.shipment_status") &&
            !(operator?.value === "isNotEmpty" || operator?.value === "isEmpty"),
        then: yup.string().required("Value is Required"),
        otherwise: yup.string().optional(),
    }),
    selectValue: yup.object().when(["path", "operator"], {
        is: (path: LabelValue, operator: LabelValue) =>
            !(path?.value === "financial_status" || path?.value === "$.financial_status") &&
            !(path?.value === "shipment_status" || path?.value === "$.shipment_status") &&
            !(operator?.value === "isNotEmpty" || operator?.value === "isEmpty"),
        then: optionSchema("Value is Required").required("Value is Required"),
        otherwise: yup.object().optional(),
    }),
    meta: yup.object().optional(),
});

const operationSchema = yup.array().of(webhookConditions).nullable();

export const shopifyCustomWorkFlowSchema = (isCustom: boolean): yup.AnyObjectSchema => {
    return yup.object().shape<YupSchema<Omit<ShopiFyWorkFlowForm, "actions">>>({
        status: yup.string().optional(),
        filter: isCustom ? optionSchema("Value is Required").required("Value is Required") : yup.mixed().optional(),
        customStatus: isCustom
            ? optionSchema("Value is Required").required("Value is Required")
            : yup.mixed().optional(),
        workflowConditions: yup
            .mixed()
            .when("filter", {
                is: null,
                then: yup.mixed().strip(),
                otherwise: operationSchema,
            })
            .nullable(),
    });
};

export const shopifyWorkFlowList = [
    {
        topic: "orders/create",
        title: "Order Create",
        description: "Send automated order confirmation and tracking updates through WhatsApp",
        icon: "",
        tags: ["Shopify", "Order Created"],
    },
    {
        topic: "orders/fulfilled",
        title: "Order Fulfilled",
        description: "Keep your customers posted on the order fulfillment messages through WhatsApp",
        icon: "",
        tags: ["Shopify", "Order Fulfilled"],
    },
    {
        topic: "fulfillments/update",
        title: "Order Delivered",
        description:
            "Send customers Order Delivery notifications on WhatsApp and simultaneously update a corresponding contact field.",
        icon: "",
        tags: ["Shopify", "Order Delivered"],
    },
    {
        topic: "fulfillments/update",
        title: "Order Out for delivery",
        description:
            "Keep your customers posted about their order status through WhatsApp notifications and simultaneously update a corresponding contact field.",
        icon: "",
        tags: ["Shopify", "Order Out for delivery"],
    },
    {
        topic: "orders/cancelled",
        title: "Order Cancelled",
        description:
            "Send customers Order cancelled notifications on WhatsApp and simultaneously update a corresponding contact field.",
        icon: "",
        tags: ["Shopify", "Order Cancelled"],
    },
    {
        topic: "checkouts/create",
        title: "Cart Abandoned",
        description: "Recover your abandoned cart by sending automated and customized WhatsApp Notifications",
        icon: "",
        tags: ["Shopify", "Cart Abandoned"],
    },
    {
        topic: "refunds/create",
        title: "Refund Create",
        description:
            "Send customers refund notifications on WhatsApp and simultaneously update a corresponding contact field.",
        icon: "",
        tags: ["Shopify", "Refund Created"],
    },
];
