import { Link, Button, ButtonProps } from "@chakra-ui/react";
import { callFnsInSequence } from "app/utils/common";
import React from "react";
import ConnectButton from "app/components/Dialog360/ConnectButton";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { useRedirectToDialog360Page } from "app/hooks/useRedirectToDialog360Page";
import { useDialog360ConnectButton } from "app/screens/Channels/Whatsapp/Dialog360IntegratedOnboarding/hooks/useDialog360ConnectButton";

interface Connect360ButtonOnboardingProps extends Omit<ButtonProps, "as"> {
    buttonText: string;
    isOnboarding: boolean;
    dialog360ClientId?: string;
}
const Connect360ButtonOnboarding: React.FC<Connect360ButtonOnboardingProps> = (props) => {
    const { buttonText, isOnboarding, onClick, ...buttonProps } = props;

    const { getDialog360ButtonProps } = useDialog360ConnectButton({ from: isOnboarding ? "onboarding" : "channels" });
    const {
        dialog360ClientId,
        redirectToOnboardingDialog360Page,
        redirectToDialog360Page,
    } = useRedirectToDialog360Page();

    const dialog360Events = React.useCallback(() => {
        trackEvent({ event: "ONBOARD_360_POPUP" });
        trackEvent({ event: "ONBOARD_ACTIVATE_FREE_TRIAL" });
    }, []);

    const connectButtonProps = React.useMemo((): Partial<ButtonProps> => {
        if (dialog360ClientId) {
            return {
                onClick: (event) => {
                    onClick?.(event);
                    dialog360Events();
                    if (isOnboarding) {
                        redirectToOnboardingDialog360Page();
                        return;
                    }
                    redirectToDialog360Page();
                },
            };
        }
        return {
            as: ConnectButton,
            ...getDialog360ButtonProps({
                label: buttonText,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                callback: () => {},
                onClick: onClick ? callFnsInSequence(dialog360Events, onClick) : dialog360Events,
            }),
        };
    }, [
        buttonText,
        dialog360ClientId,
        dialog360Events,
        getDialog360ButtonProps,
        isOnboarding,
        onClick,
        redirectToDialog360Page,
        redirectToOnboardingDialog360Page,
    ]);

    if (!dialog360ClientId) {
        return (
            <Link href="#dialog-360-step-1">
                <Button
                    w={{ base: "full", sm: "unset", lg: "unset" }}
                    {...connectButtonProps}
                    {...buttonProps}
                    py={{ base: 5, sm: "unset", lg: "unset" }}
                    size="sm"
                    data-tracking-event="ONBOARD_360_POPUP,ONBOARD_ACTIVATE_FREE_TRIAL"
                />
            </Link>
        );
    }
    return (
        <Button
            w={{ base: "full", sm: "unset", lg: "unset" }}
            {...connectButtonProps}
            {...buttonProps}
            py={{ base: 5, sm: "unset", lg: "unset" }}
            size="sm"
            data-tracking-event="ONBOARD_360_POPUP,ONBOARD_ACTIVATE_FREE_TRIAL"
        >
            {buttonText}
        </Button>
    );
};

export default Connect360ButtonOnboarding;
