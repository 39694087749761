import React from "react";
import StateManager, { OptionTypeBase, ValueContainerProps } from "react-select";
import { Props as AsyncSelectProps } from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import FloatingLabelValueContainer from "./FloatingLabelValueContainer";
import { UseReactSelectStylesCustomizedProps, useReactSelectStylesCustomized } from "./useReactSelectStylesCustomized";

export type AsyncCreatableSelectStyledProps<T extends OptionTypeBase, isMulti extends boolean = false> =
    AsyncSelectProps<T, isMulti> & UseReactSelectStylesCustomizedProps;

// eslint-disable-next-line react/display-name
const AsyncCreatableSelectStyled = React.forwardRef(
    <T extends OptionTypeBase, isMulti extends boolean = false>(
        props: AsyncCreatableSelectStyledProps<T, isMulti>,
        ref?: React.LegacyRef<AsyncCreatableSelect<T, isMulti>>
    ) => {
        const { size, fontSize, menuBgColor, containerBgColor, menuHeight, styles = {}, ...selectProps } = props;

        const customStyles = useReactSelectStylesCustomized<isMulti>({
            ...selectProps,
            size,
            fontSize,
            menuBgColor,
            containerBgColor,
            menuHeight,
        });

        const reactSelectComponent: AsyncCreatableSelectStyledProps<T>["component"] = React.useMemo(() => {
            if (selectProps?.isFloatingLabel) {
                return {
                    ValueContainer: FloatingLabelValueContainer as unknown as React.FC<ValueContainerProps<T, isMulti>>,
                };
            }
        }, [selectProps?.isFloatingLabel]);

        return (
            <AsyncCreatableSelect<T, isMulti>
                ref={ref}
                styles={{ ...customStyles, ...styles }}
                components={reactSelectComponent}
                {...selectProps}
            />
        );
    }
);

export default AsyncCreatableSelectStyled as <T extends OptionTypeBase, isMulti extends boolean = false>(
    props: AsyncCreatableSelectStyledProps<T, isMulti> & { ref?: React.LegacyRef<StateManager<T>> }
) => JSX.Element;
