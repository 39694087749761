import { LabelValue, WATemplateValues, YupSchema } from "app/types";
import { PromptAction } from "app/types/bot";
import { TriggerConditionProperties } from "app/types/integration/woocommerce";
import PaymentCaptured from "assets/patment_captured.svg";
import * as yup from "yup";
import { financialStatusValues } from "../Shopify/CreateWorkFlow";
import { BodyFormValues } from "./MappingIntegrationTemplateCommon";

export const statusOptions = [
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Processing",
        value: "processing",
    },
    {
        label: "On-Hold",
        value: "on-hold",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Cancelled",
        value: "cancelled",
    },
    {
        label: "Refunded",
        value: "refunded",
    },
    {
        label: "Failed",
        value: "failed",
    },
    {
        label: "Trash",
        value: "trash",
    },
];

export const sanitizeBodyFormValues = (data: BodyFormValues): WATemplateValues => {
    const { bodyValues: bv, buttonValues: btnValues, headerValues } = data;
    const bodyValues = Object.fromEntries(Object.entries(bv ?? {}).filter(([_, v]) => v != null));
    const isEmpty = Object.values(headerValues ?? {}).every((x) => x === null || x === "");
    const buttonValues = btnValues?.filter((v) => v.parameters[v.parameters.type]);

    const templateValues = {
        bodyValues,
        headerValues: isEmpty ? {} : headerValues,
        buttonValues,
    };
    return templateValues;
};

export interface WebhookConditions {
    fact: string;
    operator: LabelValue;
    value?: string;
    selectValue?: LabelValue;
    path: {
        label: string;
        value: string;
        type?: string;
    };
    meta?: {
        pathName: string;
        operatorName: string;
    };
}

export type Conditions = "all" | "any";

export type CreateConditions = {
    [event in Conditions]: TriggerConditionProperties[];
};

export interface WorkflowConditions {
    condition: CreateConditions;
    actions?: PromptAction[];
}

export interface CreateWebHookFlowConditions {
    status?: string;
    filter?: LabelValue;
    workflowConditions?: WebhookConditions[];
    actions?: PromptAction[];
}

export const filterOptions: LabelValue[] = [
    {
        label: "ALL",
        value: "all",
    },
    {
        label: "ANY",
        value: "any",
    },
];

export const conditionalOperator: LabelValue[] = [
    { label: "Is Empty", value: "isEmpty" },
    { label: "Is Not Empty", value: "isNotEmpty" },
    { label: "Equal", value: "softEqual" },
    { label: "Not equal", value: "softNotEqual" },
];

const optionSchema = (msg?: string) => {
    return yup
        .object()
        .shape<YupSchema<LabelValue>>({
            label: yup.string().trim().required(msg),
            value: yup.string().trim().required(msg),
        })
        .nullable();
};

const webhookConditions = yup.object().shape<YupSchema<WebhookConditions>>({
    fact: yup.string().optional(),
    operator: optionSchema("Operator is Required").required("Operator is Required"),
    path: optionSchema("Comparator is Required").required("Comparator is Required"),
    value: yup.string().when(["path", "operator"], {
        is: (path: LabelValue, operator: LabelValue) =>
            !(path?.value === "status" || path?.value === "$.status") &&
            !(operator?.value === "isNotEmpty" || operator?.value === "isEmpty"),
        then: yup.string().required("Value is Required"),
        otherwise: yup.string().optional(),
    }),
    selectValue: yup.object().when(["path", "operator"], {
        is: (path: LabelValue, operator: LabelValue) =>
            (path?.value === "status" || path?.value === "$.status") &&
            !(operator?.value === "isNotEmpty" || operator?.value === "isEmpty"),
        then: optionSchema("Value is Required").required("Value is Required"),
        otherwise: yup.object().optional(),
    }),
    meta: yup.object().optional(),
});

const operationSchema = yup.array().of(webhookConditions).nullable();

export const conditionsSchema = (isNew: boolean): yup.AnyObjectSchema => {
    return yup.object().shape<YupSchema<Omit<CreateWebHookFlowConditions, "actions">>>({
        status: isNew ? yup.string().required("value is Required") : yup.string().optional(),
        filter: isNew ? optionSchema("Value is Required").required("Value is Required") : yup.object().optional(),
        workflowConditions: yup
            .mixed()
            .when("filter", {
                is: null,
                then: yup.mixed().strip(),
                otherwise: operationSchema,
            })
            .nullable(),
    });
};

const getFilterValues = (value: Conditions): LabelValue => {
    if (value === "all") {
        return { label: "ALL", value: "all" };
    } else if (value === "any") {
        return {
            label: "ANY",
            value: "any",
        };
    }

    return { label: "Select", value: "" };
};

export const comparatorOptions: LabelValue[] = [
    {
        label: "Status",
        value: "status",
    },
    {
        label: "Payment method",
        value: "payment_method",
    },
    {
        label: "Total Price",
        value: "total",
    },
];

const integrationVariant = ["woocommerce", "shopify", "genericwebhook"] as const;
export type IntegrationTypes = (typeof integrationVariant)[number];

const getProperConditionValue = (type: IntegrationTypes, data: WebhookConditions) => {
    if (
        type === "shopify" &&
        (data.path?.value?.includes("financial_status") || data.path?.value?.includes("shipment_status"))
    ) {
        return (data?.selectValue as unknown as LabelValue)?.value;
    }
    if (type === "woocommerce" && data.path?.value?.includes("status")) {
        return (data?.selectValue as unknown as LabelValue)?.value;
    }
    return data?.value;
};

const deSanitizeValues = (type: IntegrationTypes, data: TriggerConditionProperties) => {
    if (type === "shopify" && data?.meta?.pathName === "Financial Status") {
        return financialStatusValues.find((v) => v.value === data?.value);
    }

    if (type === "woocommerce" && data?.meta?.pathName === "Status") {
        return statusOptions.find((c) => c.value === data?.value);
    }

    return data?.value;
};

const getLabelValue = (type: IntegrationTypes, data: WebhookConditions) => {
    if (type === "shopify" && data?.path?.value?.includes("financial_status")) {
        return data?.selectValue?.label;
    }

    if (type === "woocommerce" && data?.path?.value?.includes("status")) {
        return data?.selectValue?.label;
    }
};

export const sanitizeConditionsFormData = (
    data: CreateWebHookFlowConditions,
    integrationType: IntegrationTypes
): WorkflowConditions => {
    const { workflowConditions, filter, actions } = data;
    const actionsInput = actions?.map((a) => {
        if (!a.actionValues?.operation) {
            return {
                actionItem: a.actionItem,
                actionValues: { ...a.actionValues, operation: (a.actionItem.operations as any)?.[0] },
            };
        }
        return a;
    });

    const validConditions: TriggerConditionProperties[] = (workflowConditions ?? [])?.map((v) => {
        const conditionValue = getProperConditionValue(integrationType, v);
        const labelValue = getLabelValue(integrationType, v);

        return {
            fact: integrationType,
            operator: v.operator.value,
            path: v.path.value.includes("$") ? v.path.value : `$.${v.path.value}`,
            value: conditionValue ?? "",
            meta: {
                pathName: v.path.label,
                operatorName: v.operator.label,
                valueLabel: labelValue as string,
            },
        };
    });

    return {
        condition: {
            [filter?.value as Conditions]: validConditions,
        } as CreateConditions,
        actions: actionsInput,
    };
};

export const pathValue = new Map<IntegrationTypes, string>([
    ["shopify", "Financial Status"],
    ["woocommerce", "Status"],
]);

export const deSanitizeConditionsData = (
    data: WorkflowConditions["condition"],
    integrationType: IntegrationTypes
): CreateWebHookFlowConditions => {
    const filterValue = Object.keys(data ?? {})[0];
    const filter = getFilterValues(filterValue as Conditions);

    const conditionsRules: TriggerConditionProperties[] =
        data?.[filter?.value as keyof WorkflowConditions["condition"]] ?? [];

    const workflowConditions: WebhookConditions[] = conditionsRules?.map((c) => {
        const conditionValues = deSanitizeValues(integrationType, c);
        const pathName = pathValue.get(integrationType);
        return {
            fact: integrationType,
            operator: {
                label: c.meta?.operatorName as string,
                value: c.operator,
            },
            path: {
                label: c.meta?.pathName as string,
                value: c.path,
            },
            value: c.meta?.pathName != pathName ? c.value : undefined,
            selectValue: c.meta?.pathName === pathName ? conditionValues : undefined,
        };
    });

    return {
        filter,
        workflowConditions,
    };
};

export interface ZohoBooksWorkFlowListProps {
    title: string;
    icon: string;
    description: string;
    topic: string;
}

export const zohoBooksWorkFlowList: ZohoBooksWorkFlowListProps[] = [
    {
        title: "Send Invoice Notification",
        topic: "send_invoice_notification",
        icon: PaymentCaptured,
        description: "Send Invoice notification to your customers through your official WhatsApp Number",
    },
    {
        title: "Invoice Payment Success",
        topic: "invoice_payment_success",
        icon: PaymentCaptured,
        description: "Invoice Payment Success",
    },
    {
        title: "Invoice Reminder 3 days Before Due Date",
        topic: "invoice_reminder_3_days_before",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 3 Days Before through your official WhatsApp Number",
    },
    {
        title: "Invoice Reminder 1 day Before Due Date",
        topic: "invoice_reminder_1_days_before",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 1 Days Before through your official WhatsApp Number",
    },
    {
        title: "Invoice Reminder 1 day After Due Date",
        topic: "invoice_reminder_1_days_after",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 1 Days After through your official WhatsApp Number",
    },
    {
        title: "Invoice Reminder 3 days After Due Date",
        topic: "invoice_reminder_3_days_after",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 3 Days After through your official WhatsApp Number",
    },
    {
        title: "Invoice Reminder 6 days After Due Date",
        topic: "invoice_reminder_6_days_after",
        icon: PaymentCaptured,
        description:
            "Send Invoice Reminder notification to your customers 6 Days After through your official WhatsApp Number",
    },
    {
        title: " Send Estimate",
        topic: "send_estimate",
        icon: PaymentCaptured,
        description: "Send Estimate  notification to your customers  through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 3 days Before Expiry Date",
        topic: "estimate_reminder_3_days_before",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 3 Days Before through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 1 day Before Expiry Date",
        topic: "estimate_reminder_1_day_before",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 1 Day Before through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 1 day After Expiry Date",
        topic: "estimate_reminder_1_day_after",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 1 Day After through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 3 days After Expiry Date",
        topic: "estimate_reminder_3_days_after",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 3 Days After through your official WhatsApp Number",
    },
    {
        title: "Estimate Reminder 6 days After Expiry Date",
        topic: "estimate_reminder_6_days_after",
        icon: PaymentCaptured,
        description:
            "Send Estimate Reminder notification to your customers 6 Days After through your official WhatsApp Number",
    },
];
