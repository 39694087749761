import { Button, useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useConnectEntityImportSocket } from "app/screens/ImportContact/pages/ImportContactHistory/ImportContactHistoryTable";
import { useListenImportUpdates } from "./useListenImportUpdates";
import React from "react";

export const useListenImportCompletion = () => {
    const toast = useToast();

    useConnectEntityImportSocket();
    useListenImportUpdates({
        onGettingUpdates: (data) => {
            if (data.step === "completed") {
                toast({
                    title: (
                        <Button as={Link} color="white" variant="link" to="/contacts/import/advanced">
                            Contact import successful
                        </Button>
                    ),
                    description: `${data.totalCount} contacts imported`,
                    status: "success",
                });
            }
        },
    });
};
