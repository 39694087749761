import { HStack, VStack, Text, Image, Skeleton, Box } from "@chakra-ui/react";
import React from "react";
import FBLogo from "assets/onboarding/fb-logo.svg";

const Step2Gif = "https://assets.gallabox.com/web/step-2.gif";

const Step2: React.FC = () => {
    return (
        <VStack
            bg={{ base: "transparent", sm: "white", lg: "white" }}
            boxShadow={{ base: "none", sm: "none", lg: "base" }}
            w={{ base: "full", sm: "container.md", lg: "container.md" }}
            rounded="md"
            align="start"
            spacing={3}
            px={{ base: "unset", sm: 10, lg: 10 }}
            py={5}
        >
            <HStack>
                <Image src={FBLogo} fallback={<Skeleton w="36px" h="36px" />} />
                <Text color="gray.800" fontWeight="semibold" fontSize={{ base: "md", sm: "md", lg: "lg" }}>
                    Step 2: Connect 360 Dialog to your Facebook account.
                </Text>
            </HStack>
            <VStack w={{ base: "100%", sm: "94%", lg: "94%" }} alignSelf={{ base: "unset", sm: "end", lg: "end" }}>
                <Text color="gray.600" fontSize="sm">
                    {`Connect 360 Dialog with the Facebook account which is connect to your Facebook business manager.This can be done by clicking on "continue with Facebook" -> Continue as "Account Name"`}
                </Text>
                <Box h={{ base: "298px", sm: "320px", lg: "320px" }} w="full" bg="#BCDEFB" rounded="lg">
                    <Image
                        src={Step2Gif}
                        objectFit="cover"
                        w="full"
                        h="full"
                        maxH="xs"
                        rounded="lg"
                        fallback={<Skeleton w="full" h="full" />}
                    />
                </Box>
            </VStack>
        </VStack>
    );
};

export default Step2;
