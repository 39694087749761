import { HStack, VStack, Text, Badge, Link, StackProps } from "@chakra-ui/react";
import React from "react";
import UserAvatar, { CustomAvatarProps } from "app/components/CustomAvatar";
import { getValidHref } from "app/utils/common";

type ValueType = "text" | "link" | "email";
interface ProfileCardProps extends Omit<CustomAvatarProps, "colorKey"> {
    colorKey?: string;
    value1?: string;
    value2?: string;
    badgeContent?: string;
    value2Type?: ValueType;
    containerProps?: StackProps;
}

const ProfileCard: React.FC<ProfileCardProps> = (props) => {
    const { colorKey, value1, value2, badgeContent, value2Type, containerProps, ...avatarProps } = props;

    const getTextStyle = React.useCallback((value: string, type: ValueType | undefined) => {
        if (type === "link") {
            return (
                <Link
                    href={getValidHref(value)}
                    fontSize="xs"
                    color="gray.500"
                    maxW="full"
                    noOfLines={1}
                    title={value}
                    target="_blank"
                    rel="noopener noreferrer"
                    isExternal={true}
                >
                    {value}
                </Link>
            );
        }
        return (
            <Text fontSize="xs" color="gray.500" maxW="full" noOfLines={1} title={value}>
                {value}
            </Text>
        );
    }, []);

    return (
        <HStack w="full" justify="start" {...containerProps}>
            <UserAvatar size="sm" colorKey={colorKey || "unknown"} {...avatarProps} />
            {(value1 || value2) && (
                <VStack spacing={0} align="start" justify="center" maxW="78%">
                    {value1 && (
                        <HStack w="full" justify="start">
                            <Text
                                fontSize="sm"
                                fontWeight="medium"
                                color="gray.700"
                                maxW="full"
                                noOfLines={1}
                                title={value1}
                            >
                                {value1}
                            </Text>
                            {badgeContent && (
                                <Badge
                                    variant="outline"
                                    colorScheme="gray"
                                    fontSize="0.625rem"
                                    size="xs"
                                    fontWeight="normal"
                                    textTransform="capitalize"
                                >
                                    {badgeContent}
                                </Badge>
                            )}
                        </HStack>
                    )}
                    {value2 && getTextStyle(value2, value2Type)}
                </VStack>
            )}
        </HStack>
    );
};

export default React.memo(ProfileCard);
