import {
    Box,
    Button,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Popover,
    PopoverArrow,
    PopoverContent,
    PopoverTrigger,
    Tag,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import AlertDialog from "app/components/AlertDialog/AlertDialogNoTrigger";
import UpgradePlanButton from "app/components/Entitlement/UpgradePlanButton";
import ExternalLinkIcon from "app/components/ExternalLinkIcon";
import InfoLabel from "app/components/InfoLabel";
import { useRestrictFeature } from "app/hooks/useRestrictFeature";
import { INTEGRATIONS_CATEGORY_MAPPING, IntegrationType } from "app/types/integration";
import React from "react";
import { HiFingerPrint, HiOutlineDotsVertical, HiOutlineTrash } from "react-icons/hi";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { integrationListV2 } from "app/utils/integration";
import { useAccountId } from "app/utils/react-helpers";
import { useAlertUpgradeEvent } from "app/fetchHooks/pricing";
import LearnMoreLink from "app/components/LearnMoreLink";
import { LuExternalLink } from "react-icons/lu";

export interface IntegrationConnectStripChildrenProps {
    onPopoverClose: () => void;
}

type IntegrationConnectStripChildren =
    | React.ReactNode
    | ((props: IntegrationConnectStripChildrenProps) => React.ReactNode);

interface IntegrationConnectStripProps {
    name: IntegrationType;
    integrationName: string;
    integrationLogo: string;
    integrationDescription: string;
    children: IntegrationConnectStripChildren;
    integrationId?: string;
    lastUpdatedDate?: string;
    onIntegrationDelete?: () => void;
    handleReAuth?: (integrationId: string) => void;
}

const IntegrationConnectStrip: React.FC<IntegrationConnectStripProps> = (props) => {
    const {
        name,
        integrationId,
        integrationName,
        integrationLogo,
        integrationDescription,
        lastUpdatedDate,
        onIntegrationDelete,
        handleReAuth,
        children,
    } = props;
    const { onOpen: onPopoverOpen, onClose: onPopoverClose, isOpen: isPopoverOpen } = useDisclosure();
    const {
        onOpen: onDeleteIntegrationAlertOpen,
        onClose: onDeleteIntegrationAlertClose,
        isOpen: isDeleteIntegrationAlertOpen,
    } = useDisclosure();
    const firstFieldRef = React.useRef<HTMLDivElement>(null);
    const accountId = useAccountId();

    const { mutate } = useAlertUpgradeEvent({ accountId });

    React.useEffect(() => {
        return () => onPopoverClose();
    }, []);

    const { data: integrationEntitlement } = useRestrictFeature(INTEGRATIONS_CATEGORY_MAPPING[name], "restrict");

    const { restrictPage } = integrationEntitlement ?? {};

    const integrationHelpDoc = integrationListV2.find((int) => int.name === name)?.helpURL;

    const integrationPrivacyPolicyDoc = integrationListV2.find((int) => int.name === name)?.privacyPolicyURL;

    return (
        <Box p={0} m={0} w="full">
            {restrictPage && !integrationId && (
                <InfoLabel
                    content="This integration is not available in your current plan. Please upgrade your plan"
                    containerStyle={{
                        bg: "yellow.100",
                        borderRadius: "4px",
                        mb: 2,
                        px: 2,
                        w: "full",
                        alignItems: "flex-start",
                        border: "1px solid",
                        borderColor: "yellow.200",
                    }}
                    contentStyle={{ textAlign: "left", color: "black.800" }}
                    iconProps={{ color: "black.800" }}
                />
            )}

            <HStack backgroundColor="gray.100" p={5} borderRadius="6px" w="full">
                <Box borderRadius="13px" p={3} bgColor="gray.100">
                    <Image w={20} h={20} src={integrationLogo} />
                </Box>
                <VStack alignItems="flex-start" spacing={2} width="full">
                    <HStack>
                        <Text textAlign="left" fontSize="lg" fontWeight="bold" color="gray.900">
                            {`${integrationName} Integration`}
                        </Text>
                    </HStack>
                    <HStack width="full" justifyContent="space-between" alignItems="flex-start">
                        <Text
                            maxWidth="2xl"
                            textAlign="left"
                            color="gray.500"
                            fontFamily="inter"
                            fontSize="sm"
                            fontWeight="normal"
                        >
                            {integrationDescription}
                        </Text>

                        {restrictPage && !integrationId ? (
                            <UpgradePlanButton
                                onClick={() => {
                                    trackEvent({
                                        event: "Upgrade",
                                        properties: { module: "integrations", integrationName },
                                    });

                                    mutate({ action: "upgrade", module: "integrations", sub_module: integrationName });
                                }}
                            />
                        ) : integrationId ? (
                            <>
                                <HStack width="full" justifyContent="flex-end" fontWeight="bold" fontSize="sm">
                                    <Text color="gray.500">Last Updated :</Text>
                                    <Text>{lastUpdatedDate}</Text>
                                    <Tag borderRadius="4px" bgColor="green.100" color="green.600">
                                        Connected
                                    </Tag>
                                    <Menu>
                                        <MenuButton
                                            as={IconButton}
                                            aria-label="Options"
                                            icon={<HiOutlineDotsVertical />}
                                        />
                                        <MenuList p="0" bg="white">
                                            <MenuList p="0" bg="white">
                                                {onIntegrationDelete && (
                                                    <MenuItem
                                                        onClick={onDeleteIntegrationAlertOpen}
                                                        icon={<HiOutlineTrash />}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                )}
                                                {handleReAuth && (
                                                    <MenuItem
                                                        icon={<HiFingerPrint />}
                                                        onClick={() => handleReAuth(integrationId)}
                                                    >
                                                        Re Authenticate
                                                    </MenuItem>
                                                )}
                                            </MenuList>
                                        </MenuList>
                                    </Menu>
                                </HStack>
                                <AlertDialog
                                    title={`Delete ${integrationName} Integration`}
                                    body={<Text>{`Do you want to delete ${integrationName} Integration ?`}</Text>}
                                    onOk={onIntegrationDelete}
                                    primaryButtonText="Yes"
                                    secondaryButtonText="No"
                                    primaryButtonProps={{ colorScheme: "red" }}
                                    isOpen={isDeleteIntegrationAlertOpen}
                                    onClose={onDeleteIntegrationAlertClose}
                                />
                            </>
                        ) : (
                            <Popover
                                isOpen={isPopoverOpen}
                                initialFocusRef={firstFieldRef}
                                onOpen={onPopoverOpen}
                                onClose={onPopoverClose}
                                placement="bottom-start"
                                closeOnBlur={false}
                            >
                                <PopoverTrigger>
                                    <Button colorScheme="brand" borderRadius="6px" isDisabled={Boolean(integrationId)}>
                                        Connect
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent p={3}>
                                    <PopoverArrow />
                                    {typeof children === "function" ? children({ onPopoverClose }) : children}
                                </PopoverContent>
                            </Popover>
                        )}
                    </HStack>

                    {integrationHelpDoc && (
                        <LearnMoreLink
                            module="integrations"
                            titleName={`${integrationName} Integration Header`}
                            buttonType="hyperlink"
                            colorScheme="brand"
                            fontWeight="semibold"
                            link={integrationHelpDoc}
                            rightIcon={<Icon as={LuExternalLink} />}
                        >
                            Help Docs
                        </LearnMoreLink>
                    )}
                    {integrationPrivacyPolicyDoc && (
                        <LearnMoreLink
                            module="integrations"
                            titleName={`${integrationName} Integration Header`}
                            buttonType="hyperlink"
                            colorScheme="brand"
                            fontWeight="semibold"
                            link={integrationPrivacyPolicyDoc}
                            rightIcon={<Icon as={LuExternalLink} />}
                        >
                            Privacy policy
                        </LearnMoreLink>
                    )}
                </VStack>
            </HStack>
        </Box>
    );
};

export default IntegrationConnectStrip;
