import { ArrowBackIcon } from "@chakra-ui/icons";
import {
    Button,
    ButtonGroup,
    HStack,
    Icon,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Tag,
    TagCloseButton,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import {
    useCreateStripeWorkflow,
    useGetWorkFlow,
    useIntegrationFieldsList,
    useUpdateStripeWorkflow,
} from "app/fetchHooks/integration";
import { UsePostCreateTemplate } from "app/fetchHooks/message-gallery-template";
import { useWATemplateMessage } from "app/fetchHooks/whatsapp-template";
import { WATemplate } from "app/types";
import { PromptAction } from "app/types/bot";
import { Integration } from "app/types/integration";
import { StripeEventType } from "app/types/integration/stripe";
import { useQueryParam } from "app/utils/history";
import { useAccountId } from "app/utils/react-helpers";
import PaymentCaptured from "assets/patment_captured.svg";
import PaymentFailed from "assets/payment_failed.svg";
import React from "react";
import { useForm } from "react-hook-form";
import { AiOutlineEdit } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { WATemplateValues } from "../../../../types/message/whatsapp-template-message";
import ActionInputComponent from "../ActionInput";
import { sanitizeBodyFormValues } from "../integrationUtils";
import { BodyFormValues } from "../MappingIntegrationTemplate";
import MappingIntegrationCommonTemplate from "../MappingIntegrationTemplateCommon";
import SelectTemplateTabs from "../SelectTemplateTab";
import { WorkFlowSwitch } from "../WorkFlowSwitch";

interface StripeWorkFlowProps {
    data: Integration | undefined;
}

interface StripeWorkFlows {
    topic: StripeEventType;
    title: string;
    description: string;
    icon: string;
}

interface StripeFormData {
    actions?: PromptAction[];
}

const formValues: StripeFormData = {
    actions: [],
};

export const stripeWorkFlowEvents: Record<StripeEventType, string> = {
    "payment_intent-succeeded": "Payment Success",
    "payment_intent-payment_failed": "Payment Failed",
    "invoice-finalized": "Invoice Finalized",
};

export const FieldsModuleMapping: Record<StripeEventType, string> = {
    "payment_intent-succeeded": "Payment Intent",
    "payment_intent-payment_failed": "Payment Intent",
    "invoice-finalized": "Invoice",
};

const stripeWorkflows: StripeWorkFlows[] = [
    {
        topic: "payment_intent-succeeded",

        title: "Payment Intent Success",

        description: "Send Payment status notification to your customers through your official WhatsApp Number",
        icon: PaymentCaptured,
    },

    {
        topic: "payment_intent-payment_failed",

        title: "Payment Intent Failed",

        description: "If payment is failed, send an automated notification to your customers and nudge them to retry",

        icon: PaymentFailed,
    },

    {
        topic: "invoice-finalized",

        title: "Invoice Finalized",

        description: "Send an automated notification to your customers, once the created invoice is finalized",

        icon: PaymentCaptured,
    },
];

const StripeWorkFlow: React.FC<StripeWorkFlowProps> = (props) => {
    const { data } = props;
    const [showWorkFlow, setShowWorkFlow] = React.useState<boolean>(false);
    const [pickTemplate, setPickTemplate] = React.useState<WATemplate | null>(null);
    const [templateValues, setTemplateValues] = React.useState<WATemplateValues | null>(null);
    const integrationId = data?.id ?? "";
    const channelId = data?.stripe?.channelId ?? "";
    const history = useHistory();
    const accountId = useAccountId();
    const query = useQueryParam();
    const triggerKey = query.get("trigger") as StripeEventType;
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOpen: isTemplateOpen, onClose: onTemplateClose, onOpen: onTemplateOpen } = useDisclosure();

    const { data: workFlowData } = useGetWorkFlow({ accountId, integrationId });

    const currentWorkFlow = workFlowData?.find((wf) => wf.stripe?.event === triggerKey);
    const previousValues = currentWorkFlow?.templateValues;
    const templateId = currentWorkFlow?.templateId ?? "";

    const { mutate: createWorkflow, isLoading: createLoading } = useCreateStripeWorkflow({
        accountId,
        integrationId,
        onSuccess: () => handleBackWorkFlow(),
    });

    const { mutate: updateWorkflow, isLoading: updateLoading } = useUpdateStripeWorkflow({
        accountId,
        integrationId,
        workflowId: currentWorkFlow?.id ?? "",
        onSuccess: () => handleBackWorkFlow(),
    });

    const bodyResponseValues: BodyFormValues = React.useMemo(() => {
        return {
            bodyValues: previousValues?.bodyValues ?? {},
            buttonValues: previousValues?.buttonValues ?? [],
            headerValues: previousValues?.headerValues ?? {},
        };
    }, [previousValues]);

    const workflowDescription = stripeWorkflows?.find((c) => c.topic === triggerKey);

    const defaultValues: StripeFormData = React.useMemo(() => {
        let response: StripeFormData;
        if (!!currentWorkFlow) {
            response = {
                actions: currentWorkFlow?.workflowActions,
            };
        } else {
            response = {
                actions: formValues?.actions,
            };
        }
        return response;
    }, [currentWorkFlow]);

    const { control, setValue, handleSubmit, reset } = useForm<StripeFormData>({ defaultValues });

    const defaultPreviousValues: WATemplateValues = React.useMemo(() => {
        return {
            bodyValues: templateValues?.bodyValues,
            buttonValues: templateValues?.buttonValues,
            headerValues: templateValues?.headerValues,
        };
    }, [templateValues]);

    const { data: templateData } = useWATemplateMessage({ accountId, templateId });

    const isEnableUpdateWorkFlowButton = Object.keys(templateValues ?? {}).length > 0;

    const { mutateAsync: createTemplate, isLoading: postLoading } = UsePostCreateTemplate({
        accountId,
        showSuccessMessage: false,
    });

    const { data: fieldData } = useIntegrationFieldsList({
        accountId,
        app: "Stripe",
        enabled: showWorkFlow,
        module: FieldsModuleMapping[triggerKey],
    });

    const variables = fieldData?.pages?.flat() ?? [];

    React.useEffect(() => {
        if (templateData && bodyResponseValues) {
            reset(defaultValues);
            setTemplateValues(bodyResponseValues);
            setPickTemplate(templateData);
        }
    }, [bodyResponseValues, templateData]);

    const handleWorkFlow = (data: StripeEventType) => {
        history.push({
            pathname: history.location.pathname,
            search: `?trigger=${data}`,
        });
        setShowWorkFlow((prev) => !prev);
    };

    const handleBackWorkFlow = () => {
        setPickTemplate(null);
        setTemplateValues(null);
        history.push({ pathname: history.location.pathname });
    };

    const handleTemplate = (data: WATemplate) => {
        setPickTemplate(data);
        onClose();
        onTemplateOpen();
    };

    const handleCreateTemplate = async (id: string) => {
        try {
            const { template } = await createTemplate({
                accountId,
                channelId: data?.stripe?.channelId as string,
                waTemplateGalleryId: id,
            });
            setPickTemplate(template);
            onClose();
            onTemplateOpen();
        } catch (e) {}
    };

    const handleVariables = async (data: BodyFormValues) => {
        const templateValues = await sanitizeBodyFormValues(data);
        setTemplateValues(templateValues);
        onTemplateClose();
    };

    const handleCreateWorkFlow = (data: StripeFormData) => {
        const templateId = pickTemplate?.id ?? "";
        const { actions } = data;
        const actionsInput =
            actions?.map((a) => {
                if (!a.actionValues?.operation) {
                    return {
                        actionItem: a.actionItem,
                        actionValues: { ...a.actionValues, operation: (a.actionItem.operations as any)?.[0] },
                    };
                }
                return a;
            }) ?? [];
        createWorkflow({
            integrationId,
            accountId,
            isActive: true,
            templateId,
            templateValues: templateValues as WATemplateValues,
            stripe: {
                event: triggerKey,
            },
            type: "stripe",
            workflowActions: actionsInput,
        });
    };

    const handleUpdateWorkFlow = (data: StripeFormData) => {
        const templateId = pickTemplate?.id ?? "";
        const { actions } = data;
        const actionsInput =
            actions?.map((a) => {
                if (!a.actionValues?.operation) {
                    return {
                        actionItem: a.actionItem,
                        actionValues: { ...a.actionValues, operation: (a.actionItem.operations as any)?.[0] },
                    };
                }
                return a;
            }) ?? [];
        updateWorkflow({
            templateId,
            templateValues: templateValues as WATemplateValues,
            workflowActions: actionsInput,
            stripe: {
                event: triggerKey,
            },
        });
    };

    const handleReset = () => {
        if (templateData && bodyResponseValues) {
            reset(defaultValues);
            setTemplateValues(bodyResponseValues);
            setPickTemplate(templateData);
        } else {
            setPickTemplate(null);
            setTemplateValues(null);
        }
    };

    const handleTemplateClose = () => {
        setPickTemplate(null);
        setTemplateValues(null);
    };

    React.useEffect(() => {
        if (triggerKey) {
            setShowWorkFlow(true);
        } else {
            setShowWorkFlow(false);
        }
    }, [triggerKey]);

    if (showWorkFlow) {
        return (
            <>
                <VStack
                    as="form"
                    onSubmit={handleSubmit(currentWorkFlow ? handleUpdateWorkFlow : handleCreateWorkFlow)}
                    w="full"
                    height="full"
                    spacing={5}
                    alignItems="flex-start"
                >
                    <HStack w="full">
                        <IconButton
                            icon={<ArrowBackIcon />}
                            aria-label="backButton"
                            onClick={handleBackWorkFlow}
                            size="sm"
                        />
                        <HStack>
                            <Image src={workflowDescription?.icon} />
                            <VStack align="flex-start" spacing={0}>
                                <Text fontSize="16px" color="black" fontWeight="semibold">
                                    {workflowDescription?.title}
                                </Text>
                            </VStack>
                        </HStack>
                        <Spacer />
                    </HStack>
                    <HStack width="full">
                        <VStack alignItems="flex-start" flex="0.3">
                            <Text fontWeight="medium" fontSize="md" color="gray.700">
                                Send Message
                            </Text>
                            <Text fontSize="xs" fontWeight="normal" color="red.500">
                                (Required)
                            </Text>
                        </VStack>
                        <VStack alignItems="flex-start" flex="0.5">
                            {isEnableUpdateWorkFlowButton ? (
                                <HStack w="full">
                                    <Tag bgColor="gray.200">
                                        <Text
                                            maxWidth="20ch"
                                            isTruncated
                                            fontWeight="medium"
                                            fontSize="13px"
                                            color="gray.800"
                                        >
                                            {pickTemplate?.name}
                                        </Text>
                                        <TagCloseButton onClick={handleTemplateClose} />
                                    </Tag>
                                    <IconButton
                                        aria-label="edit-template"
                                        onClick={onTemplateOpen}
                                        bgColor="transparent"
                                        icon={<Icon as={AiOutlineEdit} boxSize={4} />}
                                    />
                                </HStack>
                            ) : (
                                <Button size="sm" fontSize="sm" variant="outline" onClick={onOpen}>
                                    Pick Template
                                </Button>
                            )}
                        </VStack>
                    </HStack>
                    <HStack width="full">
                        <ActionInputComponent control={control} setValue={setValue} />
                    </HStack>
                    <HStack width="full" alignItems="center" justifyContent="center">
                        <ButtonGroup>
                            <Button borderRadius="6px" size="sm" variant="outline" onClick={handleReset}>
                                Cancel
                            </Button>
                            <Button
                                borderRadius="6px"
                                isDisabled={!isEnableUpdateWorkFlowButton}
                                size="sm"
                                isLoading={createLoading || updateLoading}
                                colorScheme="blue"
                                type="submit"
                            >
                                {currentWorkFlow ? "Update WorkFlow" : "Create WorkFlow"}
                            </Button>
                        </ButtonGroup>
                    </HStack>
                </VStack>
                <Modal size="full" isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>
                            <ModalCloseButton />
                        </ModalHeader>
                        <ModalBody width="full" overflow="hidden">
                            <SelectTemplateTabs
                                CreateTemplateFromGallery={handleCreateTemplate}
                                PickTemplate={handleTemplate}
                                channelId={channelId}
                                galleryTags={[]}
                            />
                        </ModalBody>
                    </ModalContent>
                </Modal>
                <Modal size="3xl" isOpen={isTemplateOpen} onClose={onTemplateClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton />
                        <ModalBody>
                            <MappingIntegrationCommonTemplate
                                handleVariables={handleVariables}
                                templateId={pickTemplate?.id ?? ""}
                                variables={variables}
                                isLoading={createLoading || updateLoading}
                                previousValue={isEnableUpdateWorkFlowButton ? defaultPreviousValues : {}}
                                closeModal={onTemplateClose}
                            />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        );
    }

    return (
        <VStack spacing="8">
            {stripeWorkflows.map((item) => {
                const eachWorkFlow = workFlowData?.find((wf) => wf.stripe?.event === item.topic);
                return (
                    <VStack
                        key={item.topic}
                        align="flex-start"
                        spacing={8}
                        w="full"
                        border="1px"
                        borderColor="gray.200"
                        borderRadius="xl"
                        p="5"
                    >
                        <HStack justify="space-between" w="full">
                            <HStack>
                                <Image src={item.icon} />
                                <VStack align="flex-start" spacing={0}>
                                    <Text fontSize="16px" color="black" fontWeight="semibold">
                                        {item.title}
                                    </Text>
                                    <Text fontSize="15px" color="gray.500">
                                        {item.description}
                                    </Text>
                                </VStack>
                            </HStack>
                            <WorkFlowSwitch
                                isActive={Boolean(eachWorkFlow?.isActive)}
                                onActive={(isActive) => {
                                    updateWorkflow({
                                        isActive,
                                        stripe: {
                                            event: item.topic,
                                        },
                                        currentWorkflowId: eachWorkFlow?.id,
                                    });
                                }}
                                onClick={() => handleWorkFlow(item.topic)}
                                showSwitch={eachWorkFlow?.templateId}
                                key={item.topic}
                            />
                        </HStack>
                    </VStack>
                );
            })}
        </VStack>
    );
};

export default StripeWorkFlow;
