import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    VStack,
    Button,
    HStack,
    Text,
    Image,
    Skeleton,
    Box,
} from "@chakra-ui/react";
import { supportDemoURL } from "app/contants";
import { useGetInstantMeetUrl } from "app/fetchHooks/account";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { boldText, windowOpenNewTab } from "app/utils/common";
import { useAccount, useAccountId, useUser } from "app/utils/react-helpers";
import React from "react";

interface GetAssistanceModalProps {
    isOpen: boolean;
    onClose: () => void;
}
const GetAssistanceModal: React.FC<GetAssistanceModalProps> = (props) => {
    const accountId = useAccountId();
    const account = useAccount();
    const user = useUser();

    const { isLoading, mutate: fetchInstantUrl } = useGetInstantMeetUrl({
        accountId,
        businessName: account?.name ?? "UNKNOWN",
        email: user.email,
        phone: account?.mobile ?? "UNKNOWN",
        name: user.name ?? "Guest",
        onSuccess: (data) => {
            if (!data.meetUrl) return;
            windowOpenNewTab(data.meetUrl);
        },
    });
    return (
        <Modal {...props} size="2xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textAlign="center" fontSize="xl" fontWeight="semibold" color="gray.800">
                    Get Expert Assistance To Connect Your Number
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={5} pt={0}>
                    <VStack w="full" spacing={8}>
                        <Text textAlign="center" color="gray.700" size="sm">
                            Let our expert assist you virtually to connect your number. It usually takes less than
                            {boldText(" 15 minutes")} for us to help you out.
                        </Text>
                        <Box w="350px" h="209px">
                            <Image
                                w="full"
                                h="full"
                                src="https://assets.gallabox.com/web/get-onboarding-instant-assistance.svg"
                                fallback={<Skeleton w="full" h="full" />}
                            />
                        </Box>
                        <HStack w="full" justify="center">
                            <Button
                                variant="outline"
                                w="56"
                                size="sm"
                                data-tracking-event="ONBOARD_NEED_HELP_LATER"
                                onClick={() => {
                                    trackEvent({ event: "ONBOARD_NEED_HELP_LATER" });
                                    windowOpenNewTab(supportDemoURL);
                                }}
                            >
                                Schedule For Later
                            </Button>
                            <Button
                                colorScheme="brand"
                                w="56"
                                size="sm"
                                isLoading={isLoading}
                                onClick={() => {
                                    trackEvent({ event: "ONBAORD_NEED_HELP_NOW" });
                                    fetchInstantUrl();
                                }}
                                data-tracking-event="ONBAORD_NEED_HELP_NOW"
                            >
                                <span> Get Expert Assistance Now!</span>
                            </Button>
                        </HStack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default GetAssistanceModal;
