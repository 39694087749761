import { HStack, Icon, Image, Skeleton, Spacer, Text, VStack } from "@chakra-ui/react";
import { useAccount } from "app/utils/react-helpers";
import React, { PropsWithChildren } from "react";
import { AiFillCamera } from "react-icons/ai";
import { FaLaugh } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { ImAttachment } from "react-icons/im";

import { ArrowBackIcon } from "@chakra-ui/icons";
import GreenTick from "assets/green-tick.svg";
import MicIcon from "assets/Group 3.png";
import WaPattern from "assets/wa-pattern.png";
import WaLogo from "assets/whatsappLogo.svg";
import GBTooltip from "../GBTooltip";

const WhatsAppUIPreviewWrap: React.FC<PropsWithChildren> = ({ children }) => {
    const account = useAccount();
    const { logoUrl, name: accountName } = account ?? {};

    return (
        <VStack
            alignItems="flex-start"
            border="1px solid"
            borderColor="gray.100"
            borderRadius="24px"
            w="full"
            maxW="300px"
            height="480px"
            overflow="hidden"
            spacing={0}
            bgImage={WaPattern}
            backgroundRepeat="no-repeat"
        >
            <HStack h={"50px"} textAlign="left" bg={"#008069"} p={4} borderRadius={"23px 23px 0 0"} w="full">
                <ArrowBackIcon color="white" />
                <Image boxSize="24px" borderRadius="full" objectFit="cover" src={logoUrl ?? WaLogo} />
                <HStack flex={1} width="full" align="center" overflow="hidden" justify="flex-start">
                    <GBTooltip label={accountName}>
                        <Text fontWeight="medium" maxW="full" fontSize="medium" color="white" noOfLines={1}>
                            {accountName}
                        </Text>
                    </GBTooltip>
                    <Image boxSize={3} src={GreenTick} />
                </HStack>
                <Icon size="8" as={FiMoreVertical} color="white" />
            </HStack>

            <VStack
                py={4}
                pl={5}
                w="full"
                flex={1}
                overflowY="scroll"
                position="relative"
                alignItems="flex-start"
                maxH="400px"
            >
                {children}
            </VStack>
            <HStack p={2} w="full">
                <HStack px="8px" w="full" flex={1} bgColor="white" height="45px" borderRadius="full">
                    <Icon as={FaLaugh} color="gray.400" boxSize="6" />
                    <Text fontSize="md" color="gray.400" fontWeight="medium">
                        Message
                    </Text>
                    <Spacer />
                    <Icon as={ImAttachment} color="gray.400" boxSize="4" />
                    <Icon as={AiFillCamera} color="gray.400" boxSize="5" />
                </HStack>
                <Image fallback={<Skeleton height="30px" />} src={MicIcon} />
            </HStack>
        </VStack>
    );
};

export default WhatsAppUIPreviewWrap;
