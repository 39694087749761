import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";
import { useAccount, useUser } from "app/utils/react-helpers";
import React from "react";
import { ProductFruits } from "react-product-fruits";

const isOFC = (countryISO: string) => {
    //India, Saudi Arabia, United Arab Emirates
    const countriesNotIncludedInOFC = ["IND", "SAU", "ARE"];
    return !countriesNotIncludedInOFC.includes(countryISO);
};

const GBProductTour = () => {
    const user = useUser();
    const account = useAccount();
    const { isFreeTrial, isSandboxChannel, isNewAccount, isSubscribed } = useAccountMasterHook();
    
    if (!account || !user || Object.keys(user).length < 1 || isSubscribed || isNewAccount) return null;

    const userInfo = {
        username: user.id,
        email: user.email,
        firstname: user.name,
        props: {
            ...(account as unknown as Record<string, any>),
            isSandbox: isSandboxChannel,
            isTrial: isFreeTrial,
            isSubscribed: isSubscribed,
            isOFC: account.countryIso2 ? isOFC(account.countryIso2?.iso) : false,
        },
    };
    return <ProductFruits workspaceCode="ZNhsvDkOX61hzxe5" language="en" user={userInfo} />;
};

export default GBProductTour;
