import { Box, VStack, Text, HStack, Button, Modal, ModalContent, ModalOverlay, ModalBody } from "@chakra-ui/react";
import Lottie from "lottie-react";
import React from "react";
import ReadyForMappingLottie from "assets/import-contact/ready-for-mapping.json";
import { useImportedContactRefresh } from "app/fetchHooks/entityImport";
import { useAccountId } from "app/utils/react-helpers";
import { ImportContact } from "app/types/entity";
import { useListenImportUpdates } from "../hooks/useListenImportUpdates";

interface ReadyForMappingModalProps {
    isOpen: boolean;
    onClose: () => void;
    importId: string;
    onReadyForMapping?: (importContact: ImportContact) => void;
    onFailure?: (importContact: ImportContact) => void;
}

const ReadyForMappingModal: React.FC<ReadyForMappingModalProps> = (props) => {
    const { isOpen, onClose, importId, onReadyForMapping, onFailure } = props;
    const accountId = useAccountId();
    const [showCheckStatus, setCheckStatus] = React.useState(false);

    const [parsedContacts, setParsedContacts] = React.useState<number>(0);

    React.useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (isOpen) {
            timeout = setTimeout(() => {
                setCheckStatus(true);
            }, 10000);
        }
        return () => clearTimeout(timeout);
    }, [isOpen]);

    const { mutate: refresh, isLoading } = useImportedContactRefresh({
        accountId,
        onSuccess: (importContact) => {
            const { status } = importContact;
            if (status === "READY FOR MAPPING") {
                onReadyForMapping?.(importContact);
            } else if (
                (status === "FAILED" || status === "PARSING ERROR" || status === "VALIDATION ERROR") &&
                importContact
            ) {
                onFailure?.(importContact);
            }
        },
    });
    const onRefresh = () => {
        refresh(importId);
    };

    useListenImportUpdates({
        onGettingUpdates: (data) => {
            if (data.id !== importId) return;
            if (data.step === "parsing" && data.currentCount) {
                setParsedContacts(data.currentCount);
            }
        },
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody as={VStack} w="full" spacing={2} p={6}>
                    <Box boxSize="128px">
                        <Lottie animationData={ReadyForMappingLottie} loop />
                    </Box>
                    {parsedContacts && (
                        <Text fontWeight="medium" color="green.600">
                            {parsedContacts} contact{parsedContacts > 1 ? "s" : ""} processed
                        </Text>
                    )}
                    <VStack spacing={0.5}>
                        <Text fontSize="sm" color="gray.500">
                            Getting your contacts ready for mapping
                        </Text>
                        {showCheckStatus && (
                            <HStack>
                                <Text fontSize="sm" color="gray.400">
                                    Taking too long?
                                </Text>
                                <Button
                                    variant="link"
                                    colorScheme="blue"
                                    size="sm"
                                    fontWeight="normal"
                                    isLoading={isLoading}
                                    onClick={onRefresh}
                                >
                                    Check status
                                </Button>
                            </HStack>
                        )}
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ReadyForMappingModal;
