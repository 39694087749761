import { useToast } from "@chakra-ui/react";
import { QueryKey, TQueryKey } from "app/types";
import { Tag } from "app/types/tag";
import { deleteJSON, fetcher, mapQueryParams, postJSON } from "app/utils/fetchUtils";
import {
    MutationFunction,
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";

export interface TagProps {
    accountId?: string;
}
const PAGE_SIZE = 20;
const fetchTag: QueryFunction<Tag[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/account/${accountId}/tags`);
};

export const useTag = (props: TagProps): UseQueryResult<Tag[], Error> => {
    const { accountId } = props;
    return useQuery([QueryKey.TagList, { accountId }], fetchTag);
};

export interface UseTagProps {
    accountId: string;
    showSuccessMessage?: boolean;
    onSuccess?: (accountId: Tag) => void;
}

type UsePostTag<InputType> = UseMutationResult<Tag, Error, InputType>;

export const useCreateTag = <InputType extends Partial<Tag>>(props: UseTagProps): UsePostTag<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.TagList, { accountId }];

    const updateTag = (Tag: InputType) => {
        return postJSON<InputType>(`api/account/${accountId}/tags`, Tag);
    };

    const mutationResult = useMutation<Tag, Error, InputType>(updateTag, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (Tag) => {
            if (showSuccessMessage) {
                toast({
                    title: "Tag Added",
                    status: "success",
                    description: "Tag Added successfully!",
                });
            }
            onSuccess?.(Tag);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};
type UseDeleteTag = UseMutationResult<Tag, Error, { tagId: string }>;

export const useDeleteTag = (accountId: string, onSuccess?: () => void): UseDeleteTag => {
    const queryClient = useQueryClient();
    const toast = useToast({ isClosable: true, duration: 2000 });

    const queryKey: string | unknown[] = [QueryKey.TagList, { accountId }];

    const deleteTagPost: MutationFunction<Tag, { tagId: string }> = ({ tagId }) => {
        return deleteJSON<Tag>(`/api/account/${accountId}/tags/${tagId}`);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<Tag, Error, { tagId: string }>(deleteTagPost, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedField, context) => {
            queryClient.setQueryData(queryKey, context);
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while deleting Tag",
            });
        },
        onSuccess: () => {
            toast({
                title: "Tag  Deleted",
                status: "success",
                description: "Tag  deleted successfully!",
            });
            onSuccess?.();
        },
        onMutate: async ({ tagId }) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousTag = queryClient.getQueryData<Tag[]>(queryKey);

            // Optionally return a context containing data to use when for example rolling back
            return previousTag ?? [[]];
        },

        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface TagListProps<TData> extends UseInfiniteQueryOptions<TData[], Error, TData[], TData[], TQueryKey> {
    accountId: string;
    search?: string;
    pageLimit?: number;
}
export const useTagList = (props: TagListProps<Tag>): UseInfiniteQueryResult<Tag[], Error> => {
    const { accountId, search, pageLimit, ...options } = props;
    const limit = pageLimit ?? PAGE_SIZE;
    const fetchTagList: QueryFunction<Tag[], TQueryKey> = ({ pageParam, queryKey }) => {
        const [, { accountId, search }] = queryKey;

        const queryParams = mapQueryParams({
            page: pageParam ?? 1,
            limit: PAGE_SIZE,
            name: search,
        });

        return fetcher(`/api/account/${accountId}/tags?${queryParams}`);
    };

    const infiniteQueryResult = useInfiniteQuery<Tag[], Error, Tag[], TQueryKey>(
        [QueryKey.TagList, { accountId, search }],
        fetchTagList,
        {
            ...options,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === limit ? pages.length + 1 : undefined;
            },
        }
    );

    return infiniteQueryResult;
};
