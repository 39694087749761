import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { StylesObject, WATemplate } from "app/types";
import React from "react";
import PickFromExistingTemplates from "./PickFromExistingTemplate";
import PickFromGallery from "./PickFromGallery";

const tabStyles: StylesObject = {
    tabList: {
        borderBottom: "1px solid",
        borderColor: "gray.100",
    },
    tabSelected: {
        color: "blue.500",
        fontSize: "md",
        fontWeight: "medium",
        borderBottom: "2px solid",
        borderColor: "brand.500",
    },
    tabPanel: {
        pb: 4,
        pt: 4,
        height: "full",
        width: "full",
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
    },
};

interface SelectTemplateTabsProps {
    galleryTags: string[];
    PickTemplate: (data: WATemplate) => void;
    channelId: string;
    CreateTemplateFromGallery: (id: string) => Promise<void>;
}

const SelectTemplateTabs: React.FC<SelectTemplateTabsProps> = (props) => {
    const { CreateTemplateFromGallery, PickTemplate, channelId, galleryTags } = props;
    return (
        <Tabs isLazy w="full" lazyBehavior="keepMounted">
            <TabList sx={tabStyles.tabList}>
                <Tab _selected={tabStyles.tabSelected} color="gray.600" fontSize="md">
                    Pick From Gallery
                </Tab>

                <Tab _selected={tabStyles.tabSelected} color="gray.600" fontSize="md">
                    Pick From Existing Templates
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel p={0} sx={tabStyles.tabPanel}>
                    <PickFromGallery handleSoftTemplateCreation={CreateTemplateFromGallery} tags={galleryTags} />
                </TabPanel>
                <TabPanel p={0} sx={tabStyles.tabPanel}>
                    <PickFromExistingTemplates handleTemplate={PickTemplate} channelId={channelId} />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default SelectTemplateTabs;
