import { RootState } from "app/modules";
import React from "react";
import { useSelector } from "react-redux";
import AlertDialog from "../AlertDialog/AlertDialogNoTrigger";
import { ALERT_HEADER, GENERIC_DESC, OLDER_VERSION_ALERT_HEADER, OLDER_VERSION_DESC } from "./constant";
import ReloadAlertDesc from "./ReloadAlertDesc";
import ReloadAlertFooter from "./ReloadAlertFooter";

const ReloadAlert: React.FC = () => {
    const environment = useSelector((state: RootState) => state.environment);
    const isOlderVersion = environment.localFEVersion !== environment.serverFEVersion;

    return (
        <AlertDialog
            isOpen={true}
            body={<ReloadAlertDesc content={isOlderVersion ? OLDER_VERSION_DESC : GENERIC_DESC} />}
            title={isOlderVersion ? OLDER_VERSION_ALERT_HEADER : ALERT_HEADER}
            footerProps={{ justifyContent: "center" }}
            customFooter={<ReloadAlertFooter />}
        />
    );
};

export default ReloadAlert;
