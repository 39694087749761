import { createContext } from "react";
import { TableContextType } from "./type";

const dummyFns = () => {
    return;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TableContext = createContext<TableContextType<any>>({
    data: [],
    setData: dummyFns,
    columns: [],
    setColumns: dummyFns,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    table: {} as TableContextType<any>["table"],
    setPageCount: dummyFns,
    rows: [],
    isSomeColumnFreezed: false,
    virtualizeConfig: { isVirtual: false, paddingBottom: 0, paddingTop: 0, setIsVirtual: dummyFns },
    tableContainerRef: { current: null },
    frozenColumnPos: { left: {}, right: {} },
    setFrozenColumnPos: dummyFns,
    reCalculateFrozenColumnPositions: dummyFns,
    frozenColumnConfig: {
        frozenColumnRef: {},
        setFrozenColumnRef: dummyFns,
        leftFrozenColumWidth: {},
        rightFrozenColumWidth: {},
        setLeftFrozenColumWidth: dummyFns,
        setRightFrozenColumWidth: dummyFns,
    },
    showShadowForLeftLastFrozenColumn: false,
    showShadowOnRightFirstFrozenColumn: false,
    getFrozenColumnShadowStyle: () => {
        return {};
    },
    setFrozenColumnConfig: dummyFns,
    tableContainerScrollXConfig: undefined,
    setTableContainerScrollXConfig: dummyFns,
});

export default TableContext;
