import {
    Box,
    FormHelperText as ChakraFormHelperText,
    FormLabel as ChakraFormLabel,
    FormLabelProps as ChakraFormLabelProps,
    TextProps as ChakraHelpTextProps,
    Kbd,
    Text,
    BoxProps,
} from "@chakra-ui/react";
import React, { FC, Fragment } from "react";

const getBrowserOperatingSystem = () => {
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
        return "android";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return "ios";
    } else if (/Windows/i.test(userAgent)) {
        return "windows";
    } else if (/Mac/i.test(userAgent)) {
        return "mac";
    } else if (/Linux/i.test(userAgent)) {
        return "linux";
    } else {
        return "unknown";
    }
};

export const FormLabel: React.FC<ChakraFormLabelProps> = (props) => {
    return <ChakraFormLabel fontSize="xs" color="gray.700" textTransform="uppercase" fontWeight="bold" {...props} />;
};

export const FormHelperText: React.FC<ChakraHelpTextProps> = (props) => {
    return <ChakraFormHelperText fontSize="xs" fontStyle="italic" {...props} />;
};

export const EmojiShortcutKeys: FC = () => {
    const platform = getBrowserOperatingSystem();

    let keys: string[] = [];

    if (platform === "windows") {
        keys = ["⊞ Win", "."];
    }
    if (platform === "mac") {
        keys = ["CTRL", "⌘", "Space"];
    }
    if (platform === "linux") {
        keys = ["CTRL", "."];
    }

    if (keys.length < 1) return null;

    return (
        <Text as="span" fontSize="xs">
            <Text as="span" fontStyle="italic">
                {"To add "}
            </Text>
            <Text as="span">{" 🙂 "}</Text>
            <Text as="span" fontStyle="italic">
                {"press "}
            </Text>
            {keys.map((k, i) => (
                <Fragment key={k}>
                    <Kbd>{k}</Kbd>
                    {i < keys.length - 1 && <span> + </span>}
                </Fragment>
            ))}
        </Text>
    );
};

interface JSONFieldPreviewProps extends BoxProps {
    value?: Record<string, unknown>;
}

export const JSONFieldPreview: React.FC<JSONFieldPreviewProps> = (props) => {
    const { value, ...boxProps } = props;
    return (
        <Box
            w="full"
            maxH="300px"
            overflow="auto"
            whiteSpace="pre"
            border="1px solid"
            rounded="md"
            borderColor="gray.200"
            px={4}
            py={2}
            color={value ? "initial" : "gray.300"}
            {...boxProps}
        >
            {value
                ? JSON.stringify(value, null, 2)
                : JSON.stringify({ name: "{{contact.name}}", email: "{{contact.email}}" }, null, 2)}
        </Box>
    );
};
