import { Box } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

const styles = {
    container: {
        fontFamily: "Inter",
        fontStyle: "normal",
        // pl: "28px",
        background: "#FFFFFF",
        border: "1px solid #E4E4E4",
        boxShadow: "0px 9px 10px rgba(0, 0, 0, 0.05)",
        overflow: "auto",
    },
    heading: {
        fontSize: "xs",
        fontWeight: 500,
        letterSpacing: "0.1em",
        textTransform: "uppercase",
        opacity: 0.6,
        p: "24px 0px 0px",
    },
    subHeading: {
        fontSize: "sm",
        fontWeight: 400,
        lineHeight: "14px",
        p: "0px 14px 0px",
    },
    active: {
        color: "#0075FF",
    },
    content: {
        background: "#FFFFFF",
        border: "1px solid #E4E4E4",
        boxShadow: "0px 9px 10px rgba(0, 0, 0, 0.05)",
        overflow: "auto",
    },
};
const AccountsMenu: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <Box height="full" w="full" maxW="full" sx={styles.container}>
            {children}
        </Box>
    );
};

export default AccountsMenu;
