const styles = {
    box: {
        p: "60px 80px 80px 60px",
        paddingX: { base: "16px", sm: "16px", lg: "50px" },
        width: { base: "350px", sm: "350px", lg: "450px" },
        border: "1px solid #E4E4E4",
        boxShadow: "0px 9px 10px rgba(0, 0, 0, 0.05)",
        fontFamily: "Inter",
        backgroundColor: "#FFF",
    },
    heading: {
        fontSize: { base: "lg", sm: "lg", lg: "xl" },
        fontWeight: 600,
        py: 2,
    },
    subheading: {
        fontSize: "sm",
        fontWeight: 400,
        color: "#666666",
    },
    button: {
        fontSize: "sm",
        fontWeight: 500,
        color: "#FFFFFF",
        bg: "#0083FF",
        borderRadius: "md",
        height: "32px",
    },
};
export default styles;

export const styles2 = {
    box: {
        width: "700px",
        border: "1px solid #E4E4E4",
        boxShadow: "0px 9px 10px rgba(0, 0, 0, 0.05)",
        fontFamily: "Inter",
        backgroundColor: "#FFF",
    },
};
