import React from "react";
import GBBrandName from "assets/gb-brand-name.svg";
import { HStack, Skeleton, Icon, Image } from "@chakra-ui/react";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { isMacOS } from "app/utils/common";
import { GB_ICON_LOGO } from "app/contants";
interface NavigationHeaderProps {
    isCollapsed: boolean;
    onToggle: () => void;
}
const NavigationHeader: React.FC<NavigationHeaderProps> = (props) => {
    const { isCollapsed, onToggle } = props;

    const onNavChange = React.useCallback(() => {
        trackEvent({
            event: isCollapsed ? "LNAV_OPEN" : "LNAV_COLLAPSE",
        });
        onToggle();
    }, [isCollapsed, onToggle]);

    return (
        <HStack w="full" bg="white" spacing={2} alignItems="center" p={4}>
            <Image w="full" boxSize={6} src={GB_ICON_LOGO} fallback={<Skeleton boxSize={6} />} />
            {!isCollapsed && (
                <Image src={GBBrandName} w="85" h="25px" fallback={<Skeleton w="85px" h="25px" mt="1" />} />
            )}

            <Icon
                as={isCollapsed ? RiArrowRightSLine : RiArrowLeftSLine}
                pos="absolute"
                boxSize={5}
                color="blue.500"
                transition="left 0.2s"
                left={isCollapsed ? "40px" : "180px"}
                bg="gray.100"
                boxShadow="base"
                _hover={{ bg: "blue.500", color: "white" }}
                onClick={onNavChange}
                cursor="pointer"
                rounded="sm"
                title={isMacOS ? "⌘ + \\" : "CTRL + \\"}
                data-cy="navigation-shrink-expand-btn"
            />
        </HStack>
    );
};

export default React.memo(NavigationHeader);
