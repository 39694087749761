import React from "react";

const EnvironmentAlert: React.FC<{ icon: React.ReactNode; title: React.ReactNode; desc: React.ReactNode }> = ({
    icon,
    title,
    desc,
}) => {
    const [show, setShow] = React.useState(true);
    const handleClose = () => {
        setShow(false);
    };
    return show ? (
        <>
            <div
                className="no-inter"
                style={{
                    padding: "15px",
                    position: "fixed",
                    top: 0,
                    margin: "0 auto",
                    left: 0,
                    right: 0,
                    backgroundColor: "#c5e6ff",
                    color: "#727272",
                    fontSize: "14px",
                    zIndex: 100,
                    textAlign: "center",
                }}
            >
                {icon}
                <p
                    style={{
                        display: "inline",
                        paddingLeft: "5px",
                    }}
                >
                    <span
                        style={{
                            fontSize: "15px",
                            fontWeight: 600,
                            color: "#3e4041",
                        }}
                    >
                        {title}
                    </span>{" "}
                    {desc}
                </p>
                <span
                    onClick={handleClose}
                    className="close"
                    style={{
                        fontSize: "18px",
                        padding: "0px 8px",
                        cursor: "pointer",
                        verticalAlign: "text-bottom",
                        float: "right",
                    }}
                >
                    ×
                </span>
            </div>
            <div style={{ paddingTop: 34 }}>&nbsp;</div>
        </>
    ) : null;
};

export default EnvironmentAlert;
