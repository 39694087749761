/* eslint-disable react/display-name */
import { Box, BoxProps, Checkbox, Flex, FlexProps, Table } from "@chakra-ui/react";
import {
    ColumnOrderState,
    getCoreRowModel,
    RowSelectionState,
    useReactTable,
    Table as TableType,
} from "@tanstack/react-table";
import { OnChangeFn, PaginationState, RowData, TableOptions } from "@tanstack/table-core";
import { findChildByDisplayName, getIsTwoArrayOfObjectIdentical } from "app/utils/common";
import { debounce } from "app/utils/debounce";
import { usePrevious } from "app/utils/react-helpers";
import React, { HTMLProps, PropsWithChildren, UIEventHandler, useEffect } from "react";
import { v4 as uuid } from "uuid";
import { useFreezeColumnConfig } from "./hooks/useFreezeColumnConfig";
import { useGetRowAndConfig } from "./hooks/useGetRowAndConfig";
import TableContext from "./TableContext/context";
import { GBColumnDef, TableContextType } from "./TableContext/type";
import { tableStyles } from "./TableStyles";
import { getGridTemplateColumn, sanitizeColumnData, splitMinMax } from "./utils";
import isEqual from "lodash/isEqual";

export function IndeterminateCheckbox({
    indeterminate,
    checked,
    onChange,
    dataCy,
}: { indeterminate?: boolean; dataCy?: string } & HTMLProps<HTMLInputElement>): JSX.Element {
    const ref = React.useRef<HTMLInputElement>(null);
    return (
        <Checkbox
            isChecked={checked}
            isIndeterminate={indeterminate}
            type="checkbox"
            ref={ref}
            onChange={onChange}
            onClick={(e) => e.stopPropagation()}
            borderColor="gray.400"
            size="md"
            data-cy={dataCy}
        />
    );
}

interface WithId {
    id: string;
}
export interface TableRef<T extends RowData> extends TableType<T> {
    allSelectedRowData: T[];
}

export interface SelectionColumnConfig<T extends RowData>
    extends Partial<Pick<GBColumnDef<T>, "isDraggable" | "enableColumnFreezing" | "freezeTo">> {
    enableSelection: boolean;
    selectionIndex?: number;
    onRowSelectionChange?: (selectedRows: T[]) => void;
    dataCyPrefix?: string;
}

export interface TanstackTableProps<T extends RowData>
    extends Pick<TableContextType<T>, "data" | "columns">,
        Pick<TableOptions<T>, "enableColumnResizing"> {
    selectionColumnConfig?: SelectionColumnConfig<T>;
    containerWidth?: number;
    tableRef?: React.RefObject<TableRef<T>>;
    containerProps?: FlexProps;
}

const TanstackTable = <T extends RowData = RowData>(props: PropsWithChildren<TanstackTableProps<T>>): JSX.Element => {
    const {
        data: originalData,
        selectionColumnConfig,
        enableColumnResizing = false,
        containerWidth,
        tableRef,
        containerProps,
    } = props;

    const { columns: sanitizedColumns, isSomeColumnFreezed } = React.useMemo(() => {
        return sanitizeColumnData<T>(props.columns, selectionColumnConfig);
    }, [props.columns, selectionColumnConfig]);
    const [data, setData] = React.useState<T[]>(originalData);
    const [columns, setColumns] = React.useState<GBColumnDef<T>[]>(sanitizedColumns);
    const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
    const [pageCount, setPageCount] = React.useState<number>(-1);
    const [isVirtual, setIsVirtual] = React.useState<boolean>(false);
    const tableContainerRef = React.useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement | null>;
    const tableElementRef = React.useRef<HTMLTableElement>(null) as React.MutableRefObject<HTMLTableElement | null>;

    const allSelectedData = React.useRef<T[]>([]);
    const prevData = usePrevious(props.data);
    // const prevColumns = usePrevious(props.columns);

    React.useEffect(() => {
        // if (prevColumns && getIsTwoArrayOfObjectIdentical<GBColumnDef<T>>(prevColumns, props.columns)) return;
        setColumns(sanitizedColumns);
    }, [sanitizedColumns]);

    React.useEffect(() => {
        if (prevData && getIsTwoArrayOfObjectIdentical(prevData as object[], props.data as object[])) return;
        setData(originalData);
    }, [originalData, prevData, props.data]);

    const setSanitizedColumns = React.useCallback((updatedColumns: GBColumnDef<T>[]) => {
        const { columns: sanitizedColumns } = sanitizeColumnData<T>(updatedColumns);
        setColumns(sanitizedColumns);
    }, []);

    const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });

    const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>(
        sanitizedColumns.map((column) => column.id as string) //must start out with populated columnOrder so we can splice
    );

    React.useEffect(() => {
        setColumnOrder(sanitizedColumns.map((column) => column.id as string));
    }, [sanitizedColumns]);

    const onPaginationChange: OnChangeFn<PaginationState> = React.useCallback((pagination) => {
        //call onPageChange props
        setPagination(pagination);
    }, []);

    const size = React.useMemo(() => {
        if (!Number(containerWidth)) return 150;
        const defaultSize = (Number(containerWidth) - 30) / columns.length;
        if (defaultSize > 150) return defaultSize;
        return 150;
    }, [columns.length, containerWidth]);

    const onRowSelectionChange: OnChangeFn<RowSelectionState> = React.useCallback(
        (selectionChange) => {
            setRowSelection(selectionChange);

            if (!selectionColumnConfig?.onRowSelectionChange) return;

            const old = allSelectedData.current;
            const selectedContactIds = old.reduce((acc, curr) => {
                if (!(curr as WithId)?.id) return acc;
                return { ...acc, [(curr as WithId)?.id]: true };
            }, {});
            const oldSelectedContactIds = Object.keys(selectedContactIds);
            const updatedSelected =
                typeof selectionChange === "function" ? selectionChange(selectedContactIds) : selectionChange;
            const updatedSelectedContactIds = Object.keys(updatedSelected);

            const diff = oldSelectedContactIds
                .filter((x) => !updatedSelectedContactIds.includes(x))
                .concat(updatedSelectedContactIds.filter((x) => !oldSelectedContactIds.includes(x)));
            if (oldSelectedContactIds.length < updatedSelectedContactIds.length) {
                const newData = data.filter((d) => diff.includes((d as WithId)?.id));
                allSelectedData.current = [...old, ...newData];
            } else {
                allSelectedData.current = old.filter((d) => !diff.includes((d as WithId)?.id));
            }

            selectionColumnConfig.onRowSelectionChange(allSelectedData.current);
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectionColumnConfig?.onRowSelectionChange, data]
    );

    const tableOptions: TableOptions<T> = React.useMemo(() => {
        return {
            data,
            columns,
            columnResizeMode: "onChange",
            enableColumnResizing,
            enableRowSelection: selectionColumnConfig?.enableSelection,
            onRowSelectionChange,
            onColumnOrderChange: setColumnOrder,
            getCoreRowModel: getCoreRowModel(),
            onPaginationChange,
            state: {
                rowSelection,
                columnVisibility: { 1: false },
                pagination: {
                    pageIndex,
                    pageSize,
                },
                columnOrder,
            },
            defaultColumn: {
                size,
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getRowId: (originalRow) => (originalRow as any)?.id ?? uuid(),
            manualPagination: true,
            pageCount,
        };
    }, [
        columnOrder,
        columns,
        data,
        enableColumnResizing,
        onPaginationChange,
        onRowSelectionChange,
        pageCount,
        pageIndex,
        pageSize,
        rowSelection,
        selectionColumnConfig?.enableSelection,
        size,
    ]);

    const table = useReactTable<T>(tableOptions);

    const gridTemplateColumnsRef = React.useRef(getGridTemplateColumn(columns, table.getAllColumns()));

    React.useEffect(() => {
        if (!tableElementRef.current) return;
        tableElementRef.current.style.gridTemplateColumns = gridTemplateColumnsRef.current;
    }, [tableElementRef.current, gridTemplateColumnsRef.current]);

    React.useEffect(() => {
        if (!tableElementRef.current) return;
        const newGridTemplateColumns = getGridTemplateColumn(columns, table.getAllColumns());

        tableElementRef.current.style.gridTemplateColumns = newGridTemplateColumns;
        gridTemplateColumnsRef.current = newGridTemplateColumns;
    }, [columns]);

    React.useLayoutEffect(() => {
        const resizingIndex = table.getAllColumns()?.findIndex((column) => column.getIsResizing());
        if (resizingIndex < 0 || !tableElementRef.current) return;

        const prev = gridTemplateColumnsRef.current;
        if (!prev) return;
        const inputString = prev;

        const parts = inputString.split(/\s(?![^()]*\))/).filter(Boolean);

        const newGridTemplateColumns = parts
            .map((prevWidth, index) => {
                if (resizingIndex === index) {
                    const size = table.getAllColumns()?.[index].getSize();
                    return `minmax(${size}px, ${splitMinMax(prevWidth)[1]})`;
                }
                return prevWidth;
            })
            .join(" ");

        tableElementRef.current.style.gridTemplateColumns = newGridTemplateColumns;
        gridTemplateColumnsRef.current = newGridTemplateColumns;
    });

    const freezeColumnAllConfig = useFreezeColumnConfig({ isSomeColumnFreezed, columns });

    const state = table.getState();
    const columnSizingInfo = React.useMemo(() => state.columnSizingInfo, [state.columnSizingInfo]);
    const reCalculateFrozenColumnPositions = React.useMemo(
        () => freezeColumnAllConfig.reCalculateFrozenColumnPositions,
        [freezeColumnAllConfig.reCalculateFrozenColumnPositions]
    );

    React.useEffect(() => {
        if (columnSizingInfo.isResizingColumn && isSomeColumnFreezed) return;
        const timeout = setTimeout(reCalculateFrozenColumnPositions, 1000);
        return () => clearTimeout(timeout);
    }, [columnSizingInfo, isSomeColumnFreezed, reCalculateFrozenColumnPositions]);

    const { paddingBottom, paddingTop, rows } = useGetRowAndConfig({
        isVirtualRows: isVirtual,
        tableContainerRef,
        table,
    });

    React.useImperativeHandle(tableRef, () => {
        return { allSelectedRowData: allSelectedData.current, ...table };
    });

    const { targetComps, otherComps } = React.useMemo(
        () => findChildByDisplayName(props.children, ["TablePaginationFooter", "TableInfiniteLoader"]) ?? null,
        [props.children]
    );
    const { targetComps: TableInfiniteLoaderComp } = React.useMemo(
        () => findChildByDisplayName(targetComps, ["TableInfiniteLoader"]) ?? null,
        [targetComps]
    );
    const { targetComps: TablePaginationFooterComp } = React.useMemo(
        () => findChildByDisplayName(targetComps, ["TablePaginationFooter"]) ?? null,
        [targetComps]
    );

    const providerValue: TableContextType<T> = React.useMemo(() => {
        return {
            data,
            setData,
            columns,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setColumns: setSanitizedColumns as any,
            table,
            setPageCount,
            rows,
            tableContainerRef,
            isSomeColumnFreezed,
            ...freezeColumnAllConfig,
            virtualizeConfig: {
                isVirtual,
                setIsVirtual,
                paddingTop,
                paddingBottom,
            },
        };
    }, [
        columns,
        data,
        freezeColumnAllConfig,
        isSomeColumnFreezed,
        isVirtual,
        paddingBottom,
        paddingTop,
        rows,
        setSanitizedColumns,
        table,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onContainerScroll: UIEventHandler<HTMLDivElement> = React.useCallback(
        debounce((e) => {
            if (!isSomeColumnFreezed) return;
            const { scrollLeft, scrollWidth, clientWidth } = e.target as HTMLDivElement;

            const isNothingChanged = isEqual(freezeColumnAllConfig.tableContainerScrollXConfig, {
                scrollLeft,
                scrollWidth,
                clientWidth,
            });
            if (isNothingChanged) return;

            freezeColumnAllConfig.setTableContainerScrollXConfig({ scrollLeft, scrollWidth, clientWidth });
        }, 200),
        [isSomeColumnFreezed]
    );

    return (
        <TableContext.Provider value={{ ...providerValue } as TableContextType<T>}>
            <Flex
                direction="column"
                maxH="full"
                w="full"
                h="full"
                maxW="full"
                justify="start"
                zIndex="base"
                pos="relative"
                boxShadow="base"
                borderTopRadius="md"
                {...containerProps}
            >
                <Box
                    w="full"
                    h="full"
                    maxW="full"
                    overflow="auto"
                    bg="white"
                    ref={(element) => {
                        if (!element || tableContainerRef.current) return;
                        tableContainerRef.current = element;

                        if (!isSomeColumnFreezed || freezeColumnAllConfig.tableContainerScrollXConfig) return;
                        const { scrollLeft, scrollWidth, clientWidth } = element as HTMLDivElement;

                        const isNothingChanged = isEqual(freezeColumnAllConfig.tableContainerScrollXConfig, {
                            scrollLeft,
                            scrollWidth,
                            clientWidth,
                        });
                        if (isNothingChanged) return;

                        freezeColumnAllConfig.setTableContainerScrollXConfig({ scrollLeft, scrollWidth, clientWidth });
                    }}
                    onScroll={onContainerScroll}
                    borderTopLeftRadius="md"
                    borderTopRightRadius="md"
                >
                    <Table
                        overflow="visible"
                        sx={tableStyles.table}
                        minW="full"
                        display="grid"
                        // minH="calc(100% - 50px)"
                        w={enableColumnResizing ? table.getCenterTotalSize() : "full"}
                        gridTemplateRows="32px repeat(auto-fill, minmax(40px, auto))"
                        gridTemplateColumns={gridTemplateColumnsRef.current}
                        ref={tableElementRef}
                        bg="white"
                    >
                        {otherComps}
                    </Table>
                    {TableInfiniteLoaderComp}
                </Box>
                {TablePaginationFooterComp}
            </Flex>
        </TableContext.Provider>
    );
};

interface TanstackTableContainerProps<T extends RowData = RowData>
    extends Omit<TanstackTableProps<T>, "containerWidth" | "containerProps"> {
    containerProps?: BoxProps;
    innerContainerProps?: TanstackTableProps<T>["containerProps"];
}

const TanstackTableContainer = <T extends RowData = RowData>(
    props: PropsWithChildren<TanstackTableContainerProps<T>>
): JSX.Element => {
    const { containerProps, innerContainerProps, ...otherProps } = props;
    const [containerWidth, setContainerWidth] = React.useState(0);
    return (
        <Box
            w="full"
            h="full"
            {...containerProps}
            ref={(element) => {
                if (containerWidth || !element) return;
                const elmInnerWidth = Number(element?.getBoundingClientRect().width.toFixed(2));
                if (!elmInnerWidth) return;
                setContainerWidth(elmInnerWidth);
            }}
        >
            {containerWidth && (
                <TanstackTable {...otherProps} containerProps={innerContainerProps} containerWidth={containerWidth} />
            )}
        </Box>
    );
};
export default TanstackTableContainer;
