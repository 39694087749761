import { HStack, Text, StackProps } from "@chakra-ui/react";
import InfoTooltip, { InfoTooltipProps } from "app/components/InfoTooltip";
import React from "react";

interface TableHeaderTextProps extends StackProps {
    header: string;
    description?: InfoTooltipProps["text"];
}

const TableHeaderText: React.FC<TableHeaderTextProps> = (props) => {
    const { header, description, ...containerProps } = props;
    return (
        <HStack spacing={0.5} align="center" {...containerProps}>
            <Text color="gray.80" fontSize="xs" fontWeight="medium" maxW="full" isTruncated title={header}>
                {header}
            </Text>
            {description && (
                <InfoTooltip text={description} containerProps={{ boxSize: 3 }} infoIconProps={{ color: "gray.600" }} />
            )}
        </HStack>
    );
};

export default React.memo(TableHeaderText);
