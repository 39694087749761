import { HStack, VStack, Text, Image, Skeleton, Box } from "@chakra-ui/react";
import React from "react";
import Dialog360Logo from "assets/onboarding/dialog360-logo.svg";
import Step4Img from "assets/onboarding/dialog-360/step-4.svg";

const Step4: React.FC = () => {
    return (
        <VStack
            bg={{ base: "transparent", sm: "white", lg: "white" }}
            boxShadow={{ base: "none", sm: "none", lg: "base" }}
            w={{ base: "full", sm: "container.md", lg: "container.md" }}
            px={{ base: "unset", sm: 10, lg: 10 }}
            align="start"
            rounded="md"
            spacing={3}
            py={5}
        >
            <HStack w="full">
                <Image src={Dialog360Logo} fallback={<Skeleton w="36px" h="36px" />} />
                <Text color="gray.800" fontWeight="semibold" fontSize={{ base: "md", sm: "md", lg: "lg" }}>
                    Step 4: Wait for 3-5 mins, your account is getting created.
                </Text>
            </HStack>
            <VStack
                w={{ base: "100%", sm: "94%", lg: "94%" }}
                alignSelf={{ base: "unset", sm: "end", lg: "end" }}
                align="start"
            >
                <Text color="gray.600" fontSize="sm">
                    Note: If you are stuck at this step, please initiate WABA creation again.
                </Text>
                <Box w="full" h={{ base: "298px", sm: "320px", lg: "320px" }} bg="#BCDEFB" rounded="lg">
                    <Image
                        src={Step4Img}
                        objectFit="cover"
                        w="full"
                        h="full"
                        maxH="xs"
                        rounded="lg"
                        fallback={<Skeleton w="full" h="full" />}
                    />
                </Box>
            </VStack>
        </VStack>
    );
};

export default Step4;
