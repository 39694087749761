import { ColorProps } from "@chakra-ui/react";
import { getMustacheVariables } from "app/screens/Account/WATemplatesV2/utilities/utils";
import { Status } from "app/screens/Broadcast/types";
import { WATemplateMessageButtonValue } from "app/types";
import { WaScreenProps, WATemplate, WATemplateStatus } from "app/types/message/whatsapp-template";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const colorCode = (status: WATemplateStatus) => {
    switch (status) {
        case "approved":
            return "green.100";
        case "pending":
            return "yellow.100";
        case "submitted":
            return "blue.100";
        case "rejected":
            return "red.100";
        default:
            return "gray.200";
    }
};

export const statusColor = (status?: Status): ColorProps["color"] => {
    switch (status) {
        case "COMPLETED":
            return "green.100";
        case "PENDING":
            return "yellow.100";
        case "ACTIVE":
            return "blue.100";
        case "FAILED":
            return "red.100";
        default:
            return "gray.200";
    }
};

export const statusFontColor = (status?: Status): ColorProps["color"] => {
    switch (status) {
        case "COMPLETED":
            return "green.600";
        case "PENDING":
            return "yellow.600";
        case "ACTIVE":
            return "blue.600";
        case "FAILED":
            return "red.600";
        default:
            return "gray.600";
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fontColor = (status: WATemplateStatus) => {
    switch (status) {
        case "approved":
            return "green.500";
        case "pending":
            return "yellow.500";
        case "submitted":
            return "blue.500";
        case "rejected":
            return "red.500";
        default:
            return "gray.500";
    }
};

export const getTemplateButtonValues = (
    template: WATemplate | undefined
): WATemplateMessageButtonValue[] | undefined => {
    const buttonComponent = template?.components.find((f) => f.type === "BUTTONS");
    const buttons = buttonComponent?.buttons
        ?.map((m, i) => {
            if (m.type === "URL" && m.url) {
                const variables = getMustacheVariables(m.url);
                const hasVariables = variables.length > 0;

                if (!hasVariables) return null;
                return {
                    ...m,
                    index: i,
                    sub_type: "url",
                    parameters: { type: "text", text: "" },
                };
            }

            if (m.type === "QUICK_REPLY") {
                return {
                    index: i,
                    sub_type: "quick_reply",
                    parameters: { type: "payload", payload: "" },
                };
            }
            if (m.type === "FLOW") {
                return {
                    index: i,
                    sub_type: "flow",
                    parameters: { type: "action", action: { flow_token: "" } },
                };
            }
            if (m.type === "COPY_CODE") {
                return {
                    index: i,
                    sub_type: "COPY_CODE",
                    parameters: { type: "coupon_code", coupon_code: "" },
                };
            }
        })
        .filter(Boolean) as WATemplateMessageButtonValue[];

    if (!buttons || buttons.length < 1) return undefined;
    return buttons;
};

export const getHeaders = (template: WaScreenProps): string[] => {
    return template.components.reduce((acc: string[], curr) => {
        if (curr.format === "IMAGE" || curr.format === "VIDEO") {
            acc.push("MEDIA");
        }
        if (curr.type === "BODY") {
            acc.push("BODY TEXT");
        }

        if (curr.type === "BUTTONS") {
            acc.push("BUTTON");
        }
        return acc;
    }, []);
};
