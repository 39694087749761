import {
    HStack,
    Popover,
    PopoverContent,
    PopoverTrigger,
    VStack,
    Text,
    Icon,
    Button,
    Box,
    Image,
    Link,
} from "@chakra-ui/react";
import React from "react";
import helpBgImg from "assets/onboarding/help-bg-pattern.png";
import helpQRCode from "assets/onboarding/help-qr-code.png";
import { RiWhatsappFill } from "react-icons/ri";
import { IoCloseCircle } from "react-icons/io5";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { useGBMediaQuery } from "app/hooks/useGBMediaQuery";
import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";

const salesWAUrl = "https://wame.pro/1h82fr";
const OnboardingHelp: React.FC = () => {
    const { isMobile } = useGBMediaQuery();
    const { isOFC } = useAccountMasterHook();

    if (isOFC) return null;

    if (isMobile) {
        return (
            <Button
                colorScheme="whatsapp"
                bg="green.600"
                size="sm"
                rounded="md"
                leftIcon={<Icon as={RiWhatsappFill} fontSize="20px" />}
                as={Link}
                target="_blank"
                href={salesWAUrl}
                data-tracking-event="ONBOARD_CONNECT_HELP_CHAT"
                onClick={() => {
                    trackEvent({ event: "ONBOARD_CONNECT_HELP_CHAT" });
                }}
            >
                Help
            </Button>
        );
    }

    return (
        <Popover placement="top-end">
            {({ onClose }) => {
                return (
                    <>
                        <PopoverTrigger>
                            <Button
                                colorScheme="whatsapp"
                                bg="green.600"
                                size="sm"
                                rounded="md"
                                leftIcon={<Icon as={RiWhatsappFill} fontSize="20px" />}
                                data-tracking-event="ONBOARD_CONNECT_HELP_CHAT"
                                onClick={() => {
                                    trackEvent({ event: "ONBOARD_CONNECT_HELP_CHAT" });
                                }}
                            >
                                Help
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent w="fit-content">
                            <Box w="270px" rounded="lg" boxShadow="base" h="450px" pos="relative">
                                <Box
                                    pos="absolute"
                                    top="0"
                                    backgroundImage={helpBgImg}
                                    backgroundPosition="center"
                                    backgroundAttachment="fixed"
                                    w="full"
                                    h="30%"
                                    borderTopRadius="lg"
                                    bgColor="blue.400"
                                    opacity="1"
                                    p={5}
                                >
                                    <HStack w="full" mb="5" align="start" spacing={0}>
                                        <Text color="white" fontSize="lg" fontWeight="bold">
                                            Looking for help to connect your number?
                                        </Text>
                                        <Icon
                                            as={IoCloseCircle}
                                            color="gray.300"
                                            onClick={onClose}
                                            cursor="pointer"
                                            fontSize="2xl"
                                        />
                                    </HStack>
                                    <VStack
                                        py={5}
                                        bg="white"
                                        zIndex="10"
                                        mx="auto"
                                        boxShadow="base"
                                        rounded="lg"
                                        spacing={2}
                                    >
                                        <Text color="gray.500" fontSize="xs">
                                            Scan this QR for WhatsApp Support
                                        </Text>
                                        <Image src={helpQRCode} boxSize="52" />
                                        <Text color="gray.500" fontSize="xs">
                                            -- OR --
                                        </Text>
                                        <Button
                                            colorScheme="whatsapp"
                                            bg="green.600"
                                            size="sm"
                                            as={Link}
                                            target="_blank"
                                            href={salesWAUrl}
                                            leftIcon={<Icon as={RiWhatsappFill} fontSize="20px" />}
                                        >
                                            WhatsApp Web
                                        </Button>
                                    </VStack>
                                </Box>
                            </Box>
                        </PopoverContent>
                    </>
                );
            }}
        </Popover>
    );
};

export default OnboardingHelp;
