import { DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, useToast } from "@chakra-ui/react";
import { updateFEVersion } from "app/modules/environment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

interface NewVersionAlertProps {
    url?: string;
    tryDelay?: number;
    forceDelay?: number;
}

const NewVersionAlert: React.FC<NewVersionAlertProps> = ({
    url = "/",
    tryDelay = 15 * 60 * 1000, // 15 minutes
    forceDelay = 24 * 60 * 60 * 1000, // 1 day
}) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const toastId = "NewVersionAlert";
    const reloadApp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        window.location.reload();
        e.stopPropagation();
    };

    useEffect(() => {
        const intervalId = setInterval(
            () => {
                const gitHashMeta = window.document.head.querySelector("meta[name='git-hash']");
                const currentHash = gitHashMeta?.getAttribute("content");
                const fetchUrl = `${url}${url.indexOf("?") > 0 ? "&" : "?"}rnd=${new Date().getTime()}`;
                fetch(fetchUrl, {
                    method: "get",
                    headers: { "Content-Type": "text/html" },
                })
                    .then((response) => {
                        const { status, statusText } = response;
                        if (status >= 200 && status < 300) return response;
                        throw new Error(statusText);
                    })
                    .then((response) => {
                        response.text().then((htmlText) => {
                            const rgx = /<meta[^n]*name="git-hash"[^c]*content="([^"]+)"[\s]*\/>/gim;
                            const match = rgx.exec(htmlText);
                            if (match && match[1] && window?.document?.head && currentHash) {
                                const serverHash = match[1];
                                dispatch(updateFEVersion(currentHash, serverHash));

                                if (currentHash !== serverHash) {
                                    if (!toast.isActive(toastId)) {
                                        toast({
                                            id: toastId,
                                            title: "Update Available!",
                                            variant: "subtle",
                                            description: (
                                                <Box>
                                                    A new version of Gallabox is available.
                                                    <Button
                                                        rightIcon={<DownloadIcon />}
                                                        onClick={reloadApp}
                                                        colorScheme="blue"
                                                        variant="ghost"
                                                        size="sm"
                                                    >
                                                        Click to reload
                                                    </Button>
                                                </Box>
                                            ),
                                            position: "top-right",
                                            duration: 60000,
                                            isClosable: true,
                                        });
                                    }
                                }
                            }
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            tryDelay,
            forceDelay
        );
        return () => clearInterval(intervalId);
    }, [url, tryDelay, forceDelay]);

    return null;
};

export default NewVersionAlert;
