import { RowData } from "@tanstack/react-table";
import React from "react";
import { FreezeTo, FrozenColumnPositionType, FrozenColumnWidth, GBColumnDef } from "../TableContext/type";
import { getLeftFrozenColumnShadowStyle, getRightFrozenColumnShadowStyle } from "../utils";

export interface SetFreezeColumns<T extends RowData> {
    enableColumnFreezing?: boolean;
    freezeTo: GBColumnDef<T>["freezeTo"];
    elementWidth: number;
    replaceExistingValue: boolean;
    currentIndex: number;
}

type TableContainerScrollXConfigType = Pick<HTMLDivElement, "scrollWidth" | "scrollLeft" | "clientWidth"> | undefined;

type FrozenColumnRefKey = `${number}_${FreezeTo}`;

type FrozenColumnRefType = Record<FrozenColumnRefKey, HTMLTableHeaderCellElement>;
export interface UseFreezeColumnConfigResult<T extends RowData> {
    reCalculateFrozenColumnPositions: () => void;
    setFrozenColumnConfig: (props: SetFreezeColumns<T>) => void;
    getFrozenColumnShadowStyle: (
        frozenTo: FreezeTo,
        currentIndex: number,
        isCellBorderVisible?: boolean
    ) => ReturnType<typeof getLeftFrozenColumnShadowStyle>;
    showShadowOnRightFirstFrozenColumn: boolean;
    showShadowForLeftLastFrozenColumn: boolean;
    tableContainerScrollXConfig: TableContainerScrollXConfigType;
    setTableContainerScrollXConfig: React.Dispatch<React.SetStateAction<TableContainerScrollXConfigType | undefined>>;
    frozenColumnPos: FrozenColumnPositionType;
    setFrozenColumnPos: React.Dispatch<React.SetStateAction<FrozenColumnPositionType>>;
    frozenColumnConfig: {
        frozenColumnRef: FrozenColumnRefType;
        setFrozenColumnRef: React.Dispatch<React.SetStateAction<FrozenColumnRefType>>;
        leftFrozenColumWidth: FrozenColumnWidth;
        setLeftFrozenColumWidth: React.Dispatch<React.SetStateAction<FrozenColumnWidth>>;
        rightFrozenColumWidth: FrozenColumnWidth;
        setRightFrozenColumWidth: React.Dispatch<React.SetStateAction<FrozenColumnWidth>>;
    };
}

interface UseFreezeColumnConfigProps<T extends RowData> {
    isSomeColumnFreezed?: boolean;
    columns: GBColumnDef<T>[];
}
export const useFreezeColumnConfig = <T extends RowData = RowData>(
    props: UseFreezeColumnConfigProps<T>
): UseFreezeColumnConfigResult<T> => {
    const { isSomeColumnFreezed, columns } = props;
    const [frozenColumnRef, setFrozenColumnRef] = React.useState<FrozenColumnRefType>({});

    const [leftFrozenColumWidth, setLeftFrozenColumWidth] = React.useState<FrozenColumnWidth>({});
    const [rightFrozenColumWidth, setRightFrozenColumWidth] = React.useState<FrozenColumnWidth>({});
    const [tableContainerScrollXConfig, setTableContainerScrollXConfig] =
        React.useState<TableContainerScrollXConfigType>();

    const [frozenColumnPos, setFrozenColumnPos] = React.useState<FrozenColumnPositionType>({
        left: {},
        right: {},
    });

    const leftFrozenColumWidthRef = React.useRef<FrozenColumnWidth>(leftFrozenColumWidth);
    const rightFrozenColumWidthRef = React.useRef<FrozenColumnWidth>(rightFrozenColumWidth);

    React.useLayoutEffect(() => {
        leftFrozenColumWidthRef.current = leftFrozenColumWidth;
    }, [leftFrozenColumWidth]);
    React.useLayoutEffect(() => {
        rightFrozenColumWidthRef.current = rightFrozenColumWidth;
    }, [rightFrozenColumWidth]);

    React.useLayoutEffect(() => {
        setFrozenColumnRef({});
        setLeftFrozenColumWidth({});
        setRightFrozenColumWidth({});
        setFrozenColumnPos({ left: {}, right: {} });
    }, [columns]);

    React.useLayoutEffect(() => {
        //To calculate position of each column to left freeze
        if (Object.keys(leftFrozenColumWidth).length < 1) return;

        const leftFrozenColumnPos: Record<string, number> = { 0: 0 };
        Object.keys(leftFrozenColumWidth)
            .sort()
            .forEach((key) => {
                if (leftFrozenColumWidth[Number(key) + 1]) {
                    leftFrozenColumnPos[Number(key) + 1] = leftFrozenColumnPos[key] + leftFrozenColumWidth[key];
                }
            });
        setFrozenColumnPos((old) => {
            return { ...old, left: leftFrozenColumnPos };
        });
    }, [leftFrozenColumWidth]);

    React.useLayoutEffect(() => {
        //To calculate position of each column to right freeze
        if (Object.keys(rightFrozenColumWidth).length < 1) return;
        const rightFrozenColumnPos: Record<string, number> = { [columns.length - 1]: 0 };
        Object.keys(rightFrozenColumWidth)
            .sort()
            .reverse()
            .forEach((key) => {
                if (rightFrozenColumWidth[Number(key) - 1]) {
                    rightFrozenColumnPos[Number(key) - 1] = rightFrozenColumnPos[key] + rightFrozenColumWidth[key];
                }
            });
        setFrozenColumnPos((old) => {
            return { ...old, right: rightFrozenColumnPos };
        });
    }, [columns.length, rightFrozenColumWidth]);

    const { showShadowForLeftLastFrozenColumn, showShadowOnRightFirstFrozenColumn } = React.useMemo(() => {
        if (!isSomeColumnFreezed || !tableContainerScrollXConfig)
            return { showShadowForLeftLastFrozenColumn: false, showShadowOnRightFirstFrozenColumn: false };

        const { scrollLeft, scrollWidth, clientWidth } = tableContainerScrollXConfig;
        const showShadowForLeftLastFrozenColumn = scrollLeft > 0;
        const showShadowOnRightFirstFrozenColumn = Math.round(scrollLeft) + clientWidth !== scrollWidth;

        return { showShadowForLeftLastFrozenColumn, showShadowOnRightFirstFrozenColumn };
    }, [isSomeColumnFreezed, tableContainerScrollXConfig]);

    const setFrozenColumnConfig = React.useCallback((props: SetFreezeColumns<T>) => {
        const { enableColumnFreezing } = props;
        if (!enableColumnFreezing) return;

        const { replaceExistingValue, elementWidth, freezeTo, currentIndex: index } = props;

        const modifyLeftValue = leftFrozenColumWidthRef.current[index] != null ? replaceExistingValue : true;
        const modifyRightValue = rightFrozenColumWidthRef.current[index] != null ? replaceExistingValue : true;

        if ((!freezeTo || freezeTo === "left") && modifyLeftValue) {
            setLeftFrozenColumWidth((old) => {
                return { ...old, [index]: elementWidth };
            });
        } else if (freezeTo === "right" && modifyRightValue) {
            setRightFrozenColumWidth((old) => {
                return { ...old, [index]: elementWidth };
            });
        }
    }, []);

    const getFrozenColumnShadowStyle = React.useCallback(
        (
            frozenTo: FreezeTo,
            currentIndex: number,
            isCellBorderVisible: boolean = false
        ): ReturnType<typeof getLeftFrozenColumnShadowStyle> => {
            if (frozenTo === "left") {
                const isLastFrozenColumnFromLeft = Boolean(
                    frozenColumnPos?.left?.[currentIndex] != null && !frozenColumnPos?.left?.[currentIndex + 1]
                );

                return getLeftFrozenColumnShadowStyle({
                    isLast: isLastFrozenColumnFromLeft,
                    isShadowVisible: showShadowForLeftLastFrozenColumn,
                    isCellBorderVisible,
                });
            }

            const isFirstFrozenColumnFromLeft = Boolean(
                frozenColumnPos?.right?.[currentIndex] != null && !frozenColumnPos?.right?.[currentIndex - 1]
            );

            return getRightFrozenColumnShadowStyle({
                isFirst: isFirstFrozenColumnFromLeft,
                isShadowVisible: showShadowOnRightFirstFrozenColumn,
                isCellBorderVisible,
            });
        },
        [frozenColumnPos, showShadowOnRightFirstFrozenColumn, showShadowForLeftLastFrozenColumn]
    );

    const reCalculateFrozenColumnPositions = React.useCallback(() => {
        const keys = Object.keys(frozenColumnRef) as FrozenColumnRefKey[];

        if (keys.length < 1) return;

        keys.forEach((key) => {
            const [index, freezeTo] = key.split("_") as [number, FreezeTo];
            const element = frozenColumnRef[key];
            setFrozenColumnConfig({
                currentIndex: Number(index),
                elementWidth: Number(element.getBoundingClientRect().width.toFixed(2)),
                freezeTo,
                replaceExistingValue: true,
                enableColumnFreezing: true,
            });
        });
    }, [frozenColumnRef, setFrozenColumnConfig]);

    React.useLayoutEffect(() => {
        const timeout = setTimeout(() => reCalculateFrozenColumnPositions(), 1000);
        return () => clearTimeout(timeout);
    }, [frozenColumnRef, reCalculateFrozenColumnPositions]);

    const returningData = React.useMemo(() => {
        return {
            reCalculateFrozenColumnPositions,
            setFrozenColumnConfig,
            getFrozenColumnShadowStyle,
            tableContainerScrollXConfig,
            setTableContainerScrollXConfig,
            frozenColumnPos,
            setFrozenColumnPos,
            showShadowForLeftLastFrozenColumn,
            showShadowOnRightFirstFrozenColumn,
            frozenColumnConfig: {
                frozenColumnRef,
                setFrozenColumnRef,
                leftFrozenColumWidth,
                setLeftFrozenColumWidth,
                rightFrozenColumWidth,
                setRightFrozenColumWidth,
            },
        };
    }, [
        frozenColumnPos,
        frozenColumnRef,
        getFrozenColumnShadowStyle,
        leftFrozenColumWidth,
        reCalculateFrozenColumnPositions,
        rightFrozenColumWidth,
        setFrozenColumnConfig,
        showShadowForLeftLastFrozenColumn,
        showShadowOnRightFirstFrozenColumn,
        tableContainerScrollXConfig,
    ]);

    return returningData;
};
