import { useDisclosure, useEventListener } from "@chakra-ui/react";
import { AbilityContext } from "app/config/abilities/can";
import { useAccountId } from "app/utils/react-helpers";
import React from "react";
import { PopUpNavigator } from "../PopUpNavigator/PopUpNavigator";
import { checkAndTogglePopUpNavigation, getNavigationItemsByRolesAndPermissions } from "../PopUpNavigator/utils";

const QuickSearch: React.FC = () => {
    const ability = React.useContext(AbilityContext);
    const accountId = useAccountId();
    const { isOpen: popUpNavigatorIsOpen, onOpen: openPopUpNavigator, onClose: closePopUpNavigator } = useDisclosure();

    function handler(e: KeyboardEvent) {
        checkAndTogglePopUpNavigation(e, openPopUpNavigator, closePopUpNavigator, popUpNavigatorIsOpen);
    }

    useEventListener("keydown", handler);

    const navigationMenuItems = React.useMemo(() => {
        if (!popUpNavigatorIsOpen) return [];
        return getNavigationItemsByRolesAndPermissions(ability, accountId);
    }, [ability, accountId, popUpNavigatorIsOpen]);

    if (!popUpNavigatorIsOpen) return null;

    return <PopUpNavigator isOpen={popUpNavigatorIsOpen} onClose={closePopUpNavigator} options={navigationMenuItems} />;
};

export default QuickSearch;
