import { Box, Button, HStack, Image, ImageProps, Skeleton, Spacer, Stack, Text, VStack } from "@chakra-ui/react";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { callFnsInSequence, isJSXElement } from "app/utils/common";
import React from "react";

interface QACompProps {
    imgSrc: ImageProps["src"];
    title: string | JSX.Element;
    helperText?: string | JSX.Element;
    primaryBtnText: string;
    primaryBtnOnClick: () => void;
    primaryBtnEvent: string;
    secondaryBtnText: string;
    secondaryBtnOnClick: () => void;
    secondaryBtnEvent: string;
}
const QAComp: React.FC<QACompProps> = (props) => {
    const titleComp = React.useMemo(() => {
        if (isJSXElement(props.title)) return props.title;
        return (
            <Text
                color="gray.800"
                fontSize={{ base: "sm", sm: "lg", lg: "lg" }}
                fontWeight="semibold"
                textAlign={{ base: "center", sm: "left", lg: "left" }}
            >
                {props.title}
            </Text>
        );
    }, [props.title]);
    const helperTextComp = React.useMemo(() => {
        if (isJSXElement(props.helperText)) return props.helperText;
        return (
            <Text
                color="gray.600"
                fontSize={{ base: "xs", sm: "md", lg: "md" }}
                textAlign={{ base: "center", sm: "left", lg: "left" }}
            >
                {props.helperText}
            </Text>
        );
    }, [props.helperText]);

    return (
        <Stack
            direction={{ base: "column", sm: "row", lg: "row" }}
            w="full"
            h={{ base: "full", sm: "226px", lg: "226px" }}
            spacing={5}
            align={{ base: "center", sm: "center" }}
        >
            <Box
                w={{ base: "full", sm: "226px", lg: "226px" }}
                h={{ base: "172px", sm: "226px", lg: "226px" }}
                flexShrink={0}
            >
                <Image w="full" h="full" src={props.imgSrc} fallback={<Skeleton w="full" h="full" />} />
            </Box>
            <Spacer display={{ base: "block", sm: "none", lg: "none" }} />

            <VStack
                w="full"
                h="full"
                align="start"
                justify="space-between"
                pt={5}
                pb={{ base: 0, sm: 5, lg: 5 }}
                flex={1}
                spacing={{ base: 10, sm: "unset", lg: "unset" }}
            >
                <VStack w="full" align={{ base: "center", sm: "start", lg: "start" }}>
                    {titleComp}
                    {helperTextComp}
                </VStack>
                <HStack w="full">
                    <Button
                        size="sm"
                        w="full"
                        py={{ base: 5, sm: "unset", lg: "unset" }}
                        bg="white"
                        variant="outline"
                        data-tracking-event={props.secondaryBtnEvent}
                        onClick={callFnsInSequence(props.secondaryBtnOnClick, () =>
                            trackEvent({ event: props.secondaryBtnEvent })
                        )}
                        data-event={props.secondaryBtnEvent}
                    >
                        {props.secondaryBtnText}
                    </Button>
                    <Button
                        size="sm"
                        py={{ base: 5, sm: "unset", lg: "unset" }}
                        w="full"
                        bg="white"
                        variant="outline"
                        data-tracking-event={props.primaryBtnEvent}
                        onClick={callFnsInSequence(props.primaryBtnOnClick, () =>
                            trackEvent({ event: props.primaryBtnEvent })
                        )}
                        data-event={props.primaryBtnEvent}
                    >
                        {props.primaryBtnText}
                    </Button>
                </HStack>
            </VStack>
        </Stack>
    );
};

export default QAComp;
