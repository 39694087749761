import { Box, HStack, Icon, Td, TableCellProps as ChakraTableCellProps } from "@chakra-ui/react";
import { Cell, flexRender, RowData } from "@tanstack/react-table";
import { StylesObject } from "app/types";
import { isEqual } from "lodash";
import React, { MouseEventHandler } from "react";
import { DraggableProvidedDragHandleProps, DraggableProvidedDraggableProps } from "react-beautiful-dnd";
import { GrDrag } from "react-icons/gr";
import LoadingState from "../LoadingState";
import { GBColumnDef } from "./TableContext/type";
import { getStickyProps } from "./utils";

export interface RowReorderDetails {
    index?: number;
    id?: string;
    isReordering?: boolean;
}

export interface TableCellProps<T extends RowData> extends RowReorderDetails, ChakraTableCellProps {
    cell: Cell<T, unknown>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: string | boolean | number | any;
    cellId: string;
    cellWidth: number;
    isDraggable?: boolean;
    dragHandleProps?: DraggableProvidedDragHandleProps | undefined | null;
    draggableStyle?: DraggableProvidedDraggableProps["style"];
    freezeLeftPosition?: number;
    freezeRightPosition?: number;
}

const defaultStyle: StylesObject = {
    td: {
        // wordBreak: "break-all",
        m: 0,
        p: 2,
        _last: { borderRight: 0 },
        color: "#333333",
        fontWeight: "400",
    },
};

// eslint-disable-next-line react/display-name
const TableCell = React.forwardRef(
    <T extends RowData = RowData>(props: TableCellProps<T>, ref: React.Ref<HTMLTableDataCellElement>): JSX.Element => {
        const {
            cell,
            isDraggable,
            dragHandleProps,
            draggableStyle,
            isReordering,
            cellWidth,
            freezeLeftPosition,
            freezeRightPosition,
            sx,
            cellId,
            ...tableCellProps
        } = props;

        const freezeLeftProps = getStickyProps("left", freezeLeftPosition);
        const freezeRightProps = getStickyProps("right", freezeRightPosition);

        const { isUserInteractiveCell, enableColumnFreezing } = cell.column.columnDef as GBColumnDef<T>;

        const onTableDataClick = React.useCallback<NonNullable<ChakraTableCellProps["onClick"]>>(
            (e) => {
                if (isUserInteractiveCell) e.stopPropagation();
            },
            [isUserInteractiveCell]
        );

        const tdStyle = React.useMemo(() => {
            if (!sx) return defaultStyle.td;
            return {
                ...defaultStyle.td,
                ...sx,
            };
        }, [sx]);

        const onCellClick: MouseEventHandler<HTMLDivElement> = React.useCallback(
            (e) => {
                if (isUserInteractiveCell) e.stopPropagation();
            },
            [isUserInteractiveCell]
        );

        return (
            <Td
                sx={tdStyle}
                bg="white"
                key={cell.id}
                ref={ref}
                onClick={onTableDataClick}
                {...freezeRightProps}
                {...freezeLeftProps}
                {...tableCellProps}
                w="full"
                display="flex"
            >
                <HStack spacing={3} w="full">
                    {isDraggable && (
                        <Box
                            display={isReordering ? "block" : "none"}
                            boxSize={4}
                            position="absolute"
                            left={0}
                            _groupHover={{ display: "block" }}
                            {...dragHandleProps}
                            {...draggableStyle}
                        >
                            {isReordering ? <LoadingState spinnerSize={14} /> : <Icon as={GrDrag} w="full" h="full" />}
                        </Box>
                    )}
                    <Box onClick={onCellClick} w="full">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Box>
                </HStack>
            </Td>
        );
    }
);

const TableCellMemo = React.memo(TableCell, (previous, current) => {
    return isEqual(previous, current);
}) as <T extends RowData>(props: TableCellProps<T> & { ref?: React.Ref<HTMLTableDataCellElement> }) => JSX.Element;

export default TableCellMemo;
