import { FormControl, FormControlProps, FormErrorMessage, FormLabel, FormLabelProps } from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { OptionTypeBase, Props as ReactSelectInputProps } from "react-select";
import ReactSelectStyled from "./ReactSelectStyled";

interface ControlledReactSelectStyledProps extends ReactSelectInputProps<OptionTypeBase> {
    error?: string;
    name: string;
    control: ReturnType<typeof useForm>["control"];
    width?: string;
    label?: string;
    labelProps?: Partial<FormLabelProps>;
    formControlProps?: Partial<FormControlProps>;
    defaultSelectValue?: string;
}

const ControlledReactSelectStyled: React.FC<ControlledReactSelectStyledProps> = (props) => {
    const {
        error,
        control,
        name,
        defaultSelectValue,
        width,
        label,
        labelProps = {},
        formControlProps = {},
        ...otherProps
    } = props;
    return (
        <FormControl id={name} isInvalid={Boolean(error)} {...formControlProps}>
            <FormLabel fontSize="sm" color={"gray.500"} mb={0} {...labelProps}>
                {label}
            </FormLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultSelectValue}
                render={(props) => (
                    <ReactSelectStyled
                        width={width}
                        {...otherProps}
                        {...props}
                        onChange={(e) => {
                            props.onChange(e);
                            otherProps?.onChange?.(e, { action: "set-value" });
                        }}
                    />
                )}
            />
            {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
        </FormControl>
    );
};

export default ControlledReactSelectStyled;
