import { Row, RowData, Table } from "@tanstack/react-table";
import { useVirtual } from "react-virtual";
import React from "react";
interface UseGetRowAndConfigProps<T extends RowData> {
    isVirtualRows: boolean;
    table: Table<T>;
    tableContainerRef: React.RefObject<HTMLDivElement>;
}

interface UseGetRowAndConfigResult<T extends RowData> {
    paddingTop: number;
    paddingBottom: number;
    rows: Row<T>[];
}
export const useGetRowAndConfig = <T extends RowData = RowData>(
    props: UseGetRowAndConfigProps<T>
): UseGetRowAndConfigResult<T> => {
    const { table, tableContainerRef, isVirtualRows } = props;
    const { rows } = table.getRowModel();

    const rowVirtualizer = useVirtual({
        parentRef: isVirtualRows ? tableContainerRef : { current: null },
        size: rows.length,
        overscan: 10,
    });

    const result = React.useMemo(() => {
        if (!isVirtualRows) return { paddingBottom: 0, paddingTop: 0, rows };
        const { virtualItems, totalSize } = rowVirtualizer;
        const paddingTop = virtualItems.length > 0 ? virtualItems?.[0]?.start || 0 : 0;
        const paddingBottom =
            virtualItems.length > 0 ? totalSize - (virtualItems?.[virtualItems.length - 1]?.end || 0) : 0;

        const virtualRows = virtualItems.map((virtualRow) => rows[virtualRow.index] as Row<T>);

        return { rows: virtualRows, paddingBottom, paddingTop };
    }, [isVirtualRows, rowVirtualizer, rows]);

    return result;
};
