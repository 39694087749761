import { useCheckAbility } from "app/hooks/useCheckAbility";
import React from "react";
import { NavigationAccordionItemProps } from "../components/NavigationAccordionItem";

type UseGetAccessibleNavigationProps = Omit<NavigationAccordionItemProps, "isCollapsible">;
type UseGetAccessibleNavigationType = Partial<UseGetAccessibleNavigationProps>;

export const useGetAccessibleNavigation = (props: UseGetAccessibleNavigationProps): UseGetAccessibleNavigationType => {
    const { subNav, mainNav } = props;
    const isSubNav = subNav?.length ?? false;
    const { to: mainNavTo, href: mainNavHref, ...restMainNav } = mainNav;
    const mainNavigationProps = isSubNav ? restMainNav : mainNav;

    const { checkAbility } = useCheckAbility();

    const accessibleMainNav = React.useMemo(() => {
        const { action, subject, hide } = mainNavigationProps;
        if (hide) return undefined;
        if (isSubNav || !action || !subject) return mainNavigationProps;

        return checkAbility(action, subject) ? mainNavigationProps : undefined;
    }, [checkAbility, isSubNav, mainNavigationProps]);

    const accessibleSubNav = React.useMemo(() => {
        return subNav?.filter((nav) => {
            if (nav.type === "divider") return true;
            if (nav.hide) return false;
            if (nav.subject && nav.action) {
                return checkAbility(nav.action, nav.subject);
            }
            return true;
        });
    }, [checkAbility, subNav]);

    return { mainNav: accessibleMainNav, subNav: accessibleSubNav };
};
