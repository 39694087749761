import { Link, VStack, Text, Icon, keyframes } from "@chakra-ui/react";
import React from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

const downwardMotion = keyframes`
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(2px);
  }
  40% {
    transform: translateY(3px);
  }
  60% {
    transform: translateY(2px);
  }
  80% {
    transform: translateY(1px);
  }
  100%{
    transform:translateY(0px);
  }
  `;

const Dialog360Steps: React.FC = () => {
    return (
        <VStack
            w="full"
            spacing={{ base: 2, sm: 10, lg: 10 }}
            px={{ base: 5, sm: "unset", lg: "unset" }}
            // pb={{ base: 10, sm: "unset", lg: "unset" }}
        >
            <VStack w="full">
                <Text fontSize="2xl" fontWeight="semibold">
                    How it works
                </Text>
                <Link href="#dialog-360-step-1" bg="blue.500" color="white" rounded="full" boxSize="25px">
                    <Icon
                        as={RiArrowDownSLine}
                        fontSize="25px"
                        animation={`${downwardMotion} 1s linear infinite`}
                        zIndex={0}
                        w="full"
                        h="full"
                    />
                </Link>
            </VStack>
            <Step1 />
            <Step2 />
            <Step3 />
            <Step4 />
            <Step5 />
        </VStack>
    );
};

export default Dialog360Steps;
