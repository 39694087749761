import { Button, Icon, Text } from "@chakra-ui/react";
import AlertBanner from "app/components/AlertBanner";
import { useArrayPagination } from "app/hooks/useArrayPagination";
import { ExtractArrayType } from "app/types/type-utils";
import { callFnsInSequence, removeElementFromArray } from "app/utils/common";
import React, { useEffect } from "react";
import { RiArrowLeftSLine } from "react-icons/ri";
import { AlertBannerProps } from ".";
export interface AlertBannerPropsWithVisibility extends AlertBannerProps {
    isClosable?: boolean;
    isShow: boolean;
}

interface MultiAlertProps {
    alerts: AlertBannerPropsWithVisibility[];
    setAlerts: (alerts: AlertBannerPropsWithVisibility[]) => void;
}

const MultiAlert: React.FC<MultiAlertProps> = (props) => {
    const { alerts, setAlerts } = props;

    const timeIntervalRef = React.useRef<NodeJS.Timeout>();
    const { currentData, currentIndex, setCurrentIndex, goToNext, goToPrevious } = useArrayPagination<
        ExtractArrayType<MultiAlertProps["alerts"]>
    >({
        type: "loop",
        data: alerts,
    });

    const currentAlert = currentData as AlertBannerPropsWithVisibility;

    const alertHover = React.useRef<boolean>(false);
    const startScrollInterval = React.useCallback(() => {
        timeIntervalRef.current = setInterval(() => {
            goToNext();
        }, 1000 * 10);
    }, [goToNext]);

    const clearScrollInterval = React.useCallback(() => {
        if (timeIntervalRef.current) {
            clearInterval(timeIntervalRef.current);
        }
    }, []);

    useEffect(() => {
        if (!alertHover.current) startScrollInterval();
        return clearScrollInterval;
    }, [clearScrollInterval, startScrollInterval]);

    const onDismiss = React.useCallback(() => {
        const newArr = removeElementFromArray(alerts, currentIndex);
        const nextIndex = currentIndex + 1;
        const prevIndex = currentIndex - 1;

        if (currentIndex <= newArr.length - 1) {
            setCurrentIndex(currentIndex);
        } else if (nextIndex <= newArr.length - 1) {
            setCurrentIndex(nextIndex);
        } else if (prevIndex >= 0) {
            setCurrentIndex(prevIndex);
        }
        setAlerts(newArr);
    }, [alerts, currentIndex, setAlerts]);

    const countComp = React.useMemo(() => {
        if (alerts.length <= 1) return null;
        return (
            <Button
                size="xs"
                colorScheme="yellow"
                bg="transparent"
                _hover={{ bg: "yellow.200" }}
                onClick={goToNext}
                data-cy="top-banner-alert-next-button"
            >
                Next
            </Button>
        );
    }, [alerts.length, goToNext]);

    const contentLeftComp = React.useMemo(() => {
        if (alerts.length <= 1) return null;
        return (
            <Text fontSize="xs" color="gray.700">
                ({currentIndex + 1}/{alerts.length} notifications)
            </Text>
        );
    }, [alerts.length, currentIndex]);

    const backButtonComp = React.useMemo(() => {
        if (alerts.length >= 1 && currentIndex >= 1) {
            return (
                <Icon
                    as={RiArrowLeftSLine}
                    onClick={goToPrevious}
                    boxSize={5}
                    color="gray.500"
                    cursor="pointer"
                    rounded="sm"
                />
            );
        }
        return null;
    }, [alerts.length, currentIndex, goToPrevious]);

    if (alerts.length < 1) return null;
    return (
        <AlertBanner
            {...currentAlert}
            onClose={
                currentAlert?.onClose || currentAlert?.isClosable
                    ? callFnsInSequence(currentAlert?.onClose, onDismiss)
                    : undefined
            }
            backButtonComp={backButtonComp}
            secondaryComponent={countComp}
            contentLeftComp={contentLeftComp}
            containerProps={{
                onMouseLeave: () => {
                    alertHover.current = false;
                    startScrollInterval();
                },
                onMouseEnter: () => {
                    alertHover.current = true;
                    clearScrollInterval();
                },
                borderBottomColor: "yellow.200",
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
            }}
        />
    );
};

export default MultiAlert;
