import React from "react";
import AlertDialog, { AlertDialogProps } from "app/components/AlertDialog/AlertDialogNoTrigger";

type ExitAlertProps = Omit<AlertDialogProps, "title" | "body" | "primaryButtonText" | "secondaryButtonText"> & {
    bodyText?: string | JSX.Element;
};

const ExitAlert: React.FC<ExitAlertProps> = (props) => {
    return (
        <AlertDialog
            title="Exit Import"
            body={props.bodyText ?? "Are you sure? If you exit, you will lose all the changes you made."}
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
            primaryButtonProps={{ colorScheme: "brand" }}
            secondaryButtonProps={{ variant: "ghost" }}
            {...props}
        />
    );
};

export default ExitAlert;
