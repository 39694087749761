import { Tr, TableRowProps as ChakraTableRowProps, TableCellProps as ChakraTableCellProps } from "@chakra-ui/react";
import { ColumnOrderState, Row, RowData, RowSelectionState } from "@tanstack/react-table";
import { cloneDeep, isEqual } from "lodash";
import React from "react";
import TableCell, { RowReorderDetails, TableCellProps } from "./TableCell";
import { FrozenColumnPositionType, GBColumnDef, TableContextType } from "./TableContext/type";

const styles: Record<string, ChakraTableCellProps> = {
    trSelectable: {
        _groupHover: { bgColor: "gray.50" },
        bgColor: "white",
        borderBottomWidth: "1px",
    },
    trBorder: {
        bgColor: "white",
        borderBottomWidth: "1px",
    },
    td: {
        wordBreak: "break-all",
        m: 0,
        p: 2,
        position: "relative",
        _last: { borderRight: 0 },
        color: "#333333",
        fontWeight: "400",
    },
};

export interface TableRowOriginalProps<T extends RowData>
    extends ChakraTableRowProps,
        Partial<Pick<TableCellProps<T>, "isDraggable" | "dragHandleProps" | "draggableStyle">>,
        RowReorderDetails,
        Pick<TableContextType<T>, "getFrozenColumnShadowStyle"> {
    row: Row<T>;
    rowSelection: RowSelectionState;
    columnOrder: ColumnOrderState;
    isDragging?: boolean;
    isDropped?: boolean;
    frozenColumnPos?: FrozenColumnPositionType;
    onRowClick?: (row: T, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
    enableRowDivider?: boolean;
    isSomeColumnFreezed?: boolean;
}

// eslint-disable-next-line react/display-name
const TableRowOriginal = React.forwardRef(
    <T extends RowData = RowData>(props: TableRowOriginalProps<T>, ref: React.Ref<HTMLElement>): JSX.Element => {
        const {
            row,
            isDraggable,
            dragHandleProps,
            draggableStyle,
            isReordering,
            frozenColumnPos,
            getFrozenColumnShadowStyle,
            onRowClick,
            enableRowDivider = false,
            zIndex,
            rowSelection,
            columnOrder,
            ...rest
        } = props;

        const enableDivider = Boolean(enableRowDivider || onRowClick);

        return (
            <Tr
                {...rest}
                ref={ref}
                onClick={(e) => {
                    e.stopPropagation();
                    onRowClick?.(row.original, e);
                }}
                role="group"
                _hover={{ cursor: onRowClick ? "pointer" : undefined }}
                display="contents"
            >
                {row.getVisibleCells().map((cell, index) => {
                    const cellValue = cloneDeep(cell.getValue());
                    const { isUserInteractiveCell } = cell.column.columnDef as GBColumnDef<T>;

                    return (
                        <TableCell<T>
                            cell={cell}
                            value={cell.column.id === "select" ? rowSelection[row.id] : cellValue}
                            cellId={cell.id}
                            key={cell.id}
                            isDraggable={isDraggable && index == 0}
                            dragHandleProps={dragHandleProps}
                            freezeLeftPosition={frozenColumnPos?.left?.[index]}
                            freezeRightPosition={frozenColumnPos?.right?.[index]}
                            draggableStyle={draggableStyle}
                            isReordering={isReordering}
                            cellWidth={cell.column.getSize()}
                            {...getFrozenColumnShadowStyle("left", index, enableDivider)}
                            {...getFrozenColumnShadowStyle("right", index, enableDivider)}
                            {...(enableDivider ? styles.trSelectable : styles.trBorder)}
                            zIndex={isUserInteractiveCell ? zIndex : undefined}
                        />
                    );
                })}
            </Tr>
        );
    }
);

const TableRow = React.memo(TableRowOriginal, (previous, current) => {
    return isEqual(previous, current);
}) as <T extends RowData>(props: TableRowOriginalProps<T> & { ref?: React.Ref<HTMLTableRowElement> }) => JSX.Element;

export default TableRowOriginal as <T extends RowData>(
    props: TableRowOriginalProps<T> & { ref?: React.Ref<HTMLTableRowElement> }
) => JSX.Element;
