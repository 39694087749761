import { Flex, StackProps, TableColumnHeaderProps, Th } from "@chakra-ui/react";
import { flexRender, Header, RowData } from "@tanstack/react-table";
import React from "react";
import { GBColumnDef } from "./TableContext/type";
import { getStickyProps } from "./utils";
import isEqual from "lodash/isEqual";
export interface TableHeaderCellProps<T extends RowData> extends TableColumnHeaderProps {
    header: Header<T, unknown>;
    isSticky?: boolean;
    freezeLeftPosition?: number;
    freezeRightPosition?: number;
}
// eslint-disable-next-line react/display-name
const TableHeaderCell = React.forwardRef(
    <T extends RowData = RowData>(props: TableHeaderCellProps<T>, ref: React.LegacyRef<HTMLTableHeaderCellElement>) => {
        const { header, children, freezeLeftPosition, freezeRightPosition, isSticky, ...thProps } = props;

        const freezeLeftProps = React.useMemo(
            () => getStickyProps("left", freezeLeftPosition, "banner"),
            [freezeLeftPosition]
        );
        const freezeRightProps = React.useMemo(
            () => getStickyProps("right", freezeRightPosition, "banner"),
            [freezeRightPosition]
        );

        const { freezeTo, enableColumnFreezing } = header.column.columnDef as GBColumnDef<T>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const freezeColumnDataAttribute: Record<string, any> = {
            ["data-freezeTo"]: enableColumnFreezing ? (!freezeTo ? "left" : "right") : "",
            ["data-enableColumnFreezing"]: enableColumnFreezing,
            ["data-columnIndex"]: header.index,
        };
        const thSx = React.useMemo<TableColumnHeaderProps>(() => {
            return { zIndex: "base", ...freezeRightProps, ...freezeLeftProps };
        }, [freezeLeftProps, freezeRightProps]);
        return (
            <Th
                ref={ref}
                colSpan={header.colSpan}
                key={header.index}
                {...freezeColumnDataAttribute}
                {...thProps}
                {...thSx}
                zIndex={enableColumnFreezing ? 2 : 1}
                pos="sticky"
                top={0}
                display="flex"
                w="full"
            >
                <Flex w="full">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {children}
                </Flex>
            </Th>
        );
    }
);

export default React.memo(TableHeaderCell, (previous, current) => {
    return isEqual(previous, current);
}) as <T extends RowData = RowData>(
    props: TableHeaderCellProps<T> & { ref?: React.LegacyRef<HTMLTableHeaderCellElement> }
) => JSX.Element;
