import { Center } from "@chakra-ui/react";
import React from "react";
import LoadingState from "./components/LoadingState";

interface SuspenseConfig {
    isFullScreen?: boolean;
}

const defaultConfig: Partial<SuspenseConfig> = {
    isFullScreen: true,
};

const defaultLoading = (config?: SuspenseConfig) => (
    <Center w="full" maxW="full" h={config?.isFullScreen ? "100vh" : "full"} bg="white" maxH="full">
        <LoadingState title="Loading..." spinnerSize={30} />
    </Center>
);

const withSuspense = <P extends React.JSX.IntrinsicAttributes>(
    WrappedComponent: React.FC<P>,
    FallbackComponent: React.ReactNode = null,
    config: SuspenseConfig = defaultConfig
): React.FC<P> => {
    const Comp: React.FC<P> = (props) => {
        if (!FallbackComponent) FallbackComponent = defaultLoading(config); // by default
        if (!WrappedComponent) return null;
        return (
            <React.Suspense fallback={FallbackComponent}>
                <WrappedComponent {...props} />
            </React.Suspense>
        );
    };
    Comp.displayName = `withSuspense(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;
    return Comp;
};

export default withSuspense;
