import { useAccount } from "app/utils/react-helpers";
import { useHistory } from "react-router-dom";
import React from "react";
interface UseRedirectDialog360PageReturnType {
    dialog360ClientId: string | undefined;
    redirectToDialog360Page: () => void;
    redirectToOnboardingDialog360Page: () => void;
}
export const useRedirectToDialog360Page = (): UseRedirectDialog360PageReturnType => {
    const account = useAccount();
    const history = useHistory();

    const dialog360ClientId = account?.channelProvider?.dialog360?.clientId;

    const redirectToDialog360Page = React.useCallback(() => {
        history.push("/channels/whatsapp/dialog360");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const redirectToOnboardingDialog360Page = React.useCallback(() => {
        history.push("/channels/whatsapp/dialog360/new");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { redirectToDialog360Page, redirectToOnboardingDialog360Page, dialog360ClientId };
};
