import { ArrowBackIcon } from "@chakra-ui/icons";
import { IconButton, IconButtonProps } from "@chakra-ui/react";
import React from "react";
type GoBackIconProps = Omit<IconButtonProps, "icon" | "aria-label">;

const GoBackIcon: React.FC<GoBackIconProps> = (props) => {
    return (
        <IconButton
            icon={<ArrowBackIcon />}
            aria-label="Go Back"
            variant="ghost"
            colorScheme="gray"
            size="sm"
            fontSize="14px"
            color="gray.700"
            fontWeight="400"
            {...props}
        />
    );
};

export default GoBackIcon;
