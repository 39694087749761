import {
    Icon,
    Menu,
    MenuButton,
    IconButton,
    MenuList,
    MenuItem,
    useDisclosure,
    CSSObject,
    Portal,
    useToast,
} from "@chakra-ui/react";
import { useEntityImportReportDownload, usePatchImportContact } from "app/fetchHooks/entityImport";
import { useGetEntityImportVersion } from "app/screens/ImportContact/hooks/useGetEntityImportVersion";
import { ImportContact } from "app/types/entity";
import { useAccountId } from "app/utils/react-helpers";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { failedImport, readyImport, openPreviewImport, pendingImport, successImport } from "../../utils";
import { windowOpenNewTab } from "app/utils/common";

const menuItemStyle: CSSObject = {
    color: "gray.500",
    fontSize: "sm",
    py: 1,
    rounded: "md",
    _hover: { color: "gray.800" },
};

interface ImportContactHistoryMenuProps {
    importData: ImportContact;
    rowIndex?: number;
    dataCy?: string;
}

const ImportContactHistoryMenu: React.FC<ImportContactHistoryMenuProps> = (props) => {
    const { importData, rowIndex, dataCy } = props;
    const { status } = importData;

    const accountId = useAccountId();
    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const entityVersion = useGetEntityImportVersion();

    const { mutate: patchImportContact, isLoading: isUpdating } = usePatchImportContact({
        accountId,
        version: entityVersion,
    });

    const toast = useToast({ isClosable: true });

    const { mutate: createEntityImportReportDownload } = useEntityImportReportDownload({
        accountId,
        entityImportId: importData?.id,
        onError() {
            toast({
                title: "File Download Failed",
                status: "error",
                description: "Something happened while downloading the file!",
            });
        },
        onSuccess({ url }) {
            if (url) windowOpenNewTab(url);
        },
    });

    const handleMapping = (selectedData: ImportContact) => {
        const { id, status } = selectedData;
        if (readyImport.includes(status) || openPreviewImport.includes(status)) {
            history.push(`/contacts/import/advanced/${id}/preview`);
            return;
        }
    };

    const startDownload = (reportType: string) => {
        createEntityImportReportDownload(reportType);
    };

    const isCancellable = ![...pendingImport, ...failedImport, ...successImport].includes(status);
    return (
        <Menu isLazy size="sm" isOpen={isOpen} onClose={onClose}>
            <MenuButton
                as={IconButton}
                isLoading={isUpdating}
                variant="ghost"
                size="sm"
                icon={<Icon as={BsThreeDotsVertical} />}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    onOpen();
                }}
                data-cy={`${dataCy}-item-${rowIndex}-action-menu-button`}
            ></MenuButton>
            {isOpen && (
                <Portal>
                    <MenuList
                        rounded="md"
                        px={2}
                        zIndex="3"
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                        data-cy={`${dataCy}-item-${rowIndex}-action-menu-list`}
                    >
                        {importData?.hasErrorReport ? (
                            <MenuItem
                                sx={menuItemStyle}
                                data-cy={`${dataCy}-item-${rowIndex}-error-report-link`}
                                onClick={() => startDownload("error-report")}
                            >
                                Error report
                            </MenuItem>
                        ) : null}
                        {isCancellable && (
                            <MenuItem
                                sx={menuItemStyle}
                                as={RouterLink}
                                to={`/contacts/import/${importData.id}`}
                                data-cy={`${dataCy}-item-${rowIndex}-cancel-import-link`}
                            >
                                View Config
                            </MenuItem>
                        )}
                        {importData?.hasUploadedFile && (
                            <MenuItem
                                sx={menuItemStyle}
                                data-cy={`${dataCy}-item-${rowIndex}-download-original-file-link`}
                                onClick={() => startDownload("original-file")}
                            >
                                Download original file
                            </MenuItem>
                        )}

                        {isCancellable && (
                            <MenuItem
                                sx={menuItemStyle}
                                onClick={() => patchImportContact({ id: importData.id, status: "CANCELLED" })}
                                data-cy={`${dataCy}-item-${rowIndex}-cancel-import-link`}
                            >
                                Cancel import
                            </MenuItem>
                        )}

                        {importData?.hasCompletedReport && (
                            <MenuItem
                                sx={menuItemStyle}
                                data-cy={`${dataCy}-item-${rowIndex}-download-report-link`}
                                onClick={() => startDownload("completed-report")}
                            >
                                Download Report
                            </MenuItem>
                        )}
                        {readyImport.includes(status) ? (
                            <MenuItem
                                sx={menuItemStyle}
                                onClick={() => handleMapping(importData)}
                                data-cy={`${dataCy}-item-${rowIndex}-map-import-link`}
                            >
                                Map import
                            </MenuItem>
                        ) : null}
                        {openPreviewImport.includes(status) ? (
                            <MenuItem
                                sx={menuItemStyle}
                                onClick={() => handleMapping(importData)}
                                data-cy={`${dataCy}-item-${rowIndex}-view-mapping-link`}
                            >
                                View Mapping
                            </MenuItem>
                        ) : null}
                    </MenuList>
                </Portal>
            )}
        </Menu>
    );
};

export default ImportContactHistoryMenu;
