import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Select, { components, Props as ReactSelectProps } from "react-select";
import { CustomOption } from "./CustomOption";
import { defaultValue } from "./PopUpNavigator";
import { usePopNavigationSelectStyle } from "./styles";
import { Option } from "./types";
import { trackEvent } from "app/hooks/productAnalytics/tracker";

interface IPopUpNavigationSelect extends ReactSelectProps<Option> {
    onClose: () => void;
    placeholderValue?: string;
}

export const PopUpNavigationSelect: React.FC<IPopUpNavigationSelect> = (props) => {
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onClose, placeholderValue, onChange: oC, ...otherProps } = props;
    const [search, setSearch] = useState("");
    const selectStyles = usePopNavigationSelectStyle();

    const onChange: ReactSelectProps<Option>["onChange"] = (item) => {
        if (!item) return;
        history.push((item as Option).navigationLink);
        trackEvent({
            event: "CTRL Search",
            properties: { search_string: search, module_name: (item as Option).label },
        });
        onClose();
    };

    return (
        <Select
            {...otherProps}
            placeholder={placeholderValue ?? defaultValue["placeholderValue"]}
            styles={selectStyles}
            // cacheOptions
            // defaultOptions
            menuIsOpen
            onChange={onChange}
            onInputChange={setSearch}
            components={{
                Option: CustomOption as typeof components.Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
            }}
        />
    );
};
