import { VStack, HStack, Kbd, Text } from "@chakra-ui/react";
import { useAPIMe } from "app/fetchHooks/user-availability";
import React from "react";
import { checkOS } from "app/utils/common";
import ProfilePopover from "../ProfilePopover";

interface NavigationFooterProps {
    isCollapsed: boolean;
}
const NavigationFooter: React.FC<NavigationFooterProps> = (props) => {
    const { isCollapsed } = props;
    const { data: user, isFetched } = useAPIMe();
    const isMacOs = checkOS().includes("mac");

    return (
        <VStack w="full" align="start" spacing={0}>
            {!isCollapsed && (
                <HStack color="gray.600" spacing="1" px="4" pb="2" align="baseline">
                    <Text color="gray.400" fontWeight="light" fontSize="0.75rem">
                        Quick Search
                    </Text>
                    <span>
                        <Kbd fontWeight="normal" fontSize="0.75rem">
                            {isMacOs ? "⌘" : "Ctrl"}
                        </Kbd>
                        {" + "}
                        <Kbd fontWeight="normal" fontSize="0.75rem">
                            K
                        </Kbd>
                    </span>
                </HStack>
            )}
            {user && isFetched ? <ProfilePopover user={user} isCollapsed={isCollapsed} /> : null}
        </VStack>
    );
};

export default NavigationFooter;
