import {
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    HStack,
    Icon,
    Input,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    Text,
    VStack,
} from "@chakra-ui/react";
import AsyncSelectStyled from "app/components/CustomizedReactSelect/AsyncSelectStyled";
import ReactSelectStyled from "app/components/CustomizedReactSelect/ReactSelectStyled";
import LoadingState from "app/components/LoadingState";
import { useGetActions } from "app/fetchHooks/action";
import { getMustacheVariablesIsAvailable } from "app/screens/Account/WATemplatesV2/utilities/utils";
import {
    Action,
    ActionWithConfig,
    Fact,
    FactType,
    FactValues,
    FieldFactValueType,
    FieldLookUpObject,
    Operation,
} from "app/types/action";
import { PromptAction, ResponseType } from "app/types/bot";
import { LabelValue, UnknownObject } from "app/types/common";
import { AsyncSelectErrorHandler, getJSON } from "app/utils/fetchUtils";
import { mustacheRender } from "app/utils/react-helpers";
import dayjs from "dayjs";
import get from "lodash/get";
import React, { FC, useCallback } from "react";
import { MdAdd, MdClose } from "react-icons/md";
import { useId } from "react-id-generator";
import ReactSelect from "react-select";
import { FormLabel } from "./Components";

const responseTypeFactTypeMap = new Map<ResponseType, FactType[]>([
    ["text", ["text", "number", "select", "lookup", "boolean"]],
    ["number", ["text", "number", "select", "lookup", "boolean"]],
    ["datetime", ["date"]],
    ["email", ["text", "number", "select", "lookup", "boolean"]],
    ["phone", ["text", "number", "select", "lookup", "boolean"]],
    ["url", ["text", "number", "select", "lookup", "boolean"]],
    ["file", []],
    ["location", []],
]);

const isValidFactType = (factType: FactType, responseType: ResponseType) => {
    const factTypeList = responseTypeFactTypeMap.get(responseType);
    if (!factTypeList) return false;
    return factTypeList.includes(factType);
};

interface PromptActionsProps {
    accountId: string;
    values: PromptAction[];
    handleFieldValueChange: <T = string>(elementId: string, path: string, value: T) => void;
    handleFieldRemove: (id: string, field: string) => void;
    elementId: string;
    responseType?: ResponseType;
    disable?: boolean;
}

interface PromptActionValue {
    isValueFromResponse: boolean;
    actionIndex: number;
}

const PromptActions: FC<PromptActionsProps> = (props) => {
    const {
        accountId,
        values: promptActions,
        handleFieldValueChange,
        handleFieldRemove,
        elementId: id,
        responseType,
        disable = false,
    } = props;

    const [promptActionValues, setPromptActionValues] = React.useState<PromptActionValue[]>([]);

    const {
        data: rawActions,
        isLoading,
        isError,
    } = useGetActions<ActionWithConfig>({
        accountId,
        options: { requestModule: "BOT_PROMPT", modules: ["conversation", "contact", "company"] },
    });

    const actions = React.useMemo(() => {
        if (!responseType) return rawActions;
        return rawActions?.filter((action) => {
            if (action.fact.module === "Conversation") return true;
            return isValidFactType(action.fact?.type, responseType);
        });
    }, [rawActions, responseType]);

    React.useEffect(() => {
        setPromptActionValues(
            promptActions.map((action, index) => {
                return {
                    isValueFromResponse: action.actionItem?.hasValue ? action.actionValues?.values == null : false,
                    actionIndex: index,
                };
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!responseType) return;
        const newActions = promptActions.filter((a) => {
            if (!a.actionItem?.fact) return true;
            if (a.actionItem.fact.module === "Conversation") return true;
            return isValidFactType(a.actionItem.fact?.type, responseType);
        });

        if (newActions.length < 1) {
            handleFieldRemove(id, "actions");
        } else {
            handleFieldValueChange<PromptAction[]>(id, "actions", newActions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseType]);

    const idList = useId(promptActions.length, "action");

    if (isLoading) {
        return (
            <Grid w="full" placeItems="center">
                <LoadingState title="Loading..." spinnerSize={20} />
            </Grid>
        );
    }

    if (isError || !actions) {
        return (
            <HStack w="full">
                <Text fontSize="sm" color="gray.600">
                    Error loading actions! Please refresh the page or try after sometime
                </Text>
            </HStack>
        );
    }

    return (
        <VStack align="flex-start" w="full">
            {promptActions.map((promptAction, index) => {
                const currentActionItem = actions.find((a) => a.name === promptAction.actionItem?.name);
                const allowDynamicResponse =
                    (currentActionItem?.config?.bot?.allowDynamicResponse ?? false) && !!responseType;

                const fact: Fact | undefined =
                    currentActionItem?.fact ?? (promptAction.actionItem?.fact as Fact | undefined);

                return (
                    <VStack
                        align="flex-start"
                        w="full"
                        key={idList[index]}
                        border="1px solid"
                        borderColor="gray.100"
                        rounded="md"
                        p={4}
                        position="relative"
                    >
                        <FormControl id="actionType" isRequired position="relative">
                            <FormLabel>Action Type</FormLabel>
                            <ReactSelect<Action>
                                placeholder="Select an Action"
                                options={actions}
                                value={currentActionItem}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                isOptionDisabled={(option) => {
                                    return promptActions.some((pa, i) => {
                                        const { module: prevModule, property: prevProperty } =
                                            pa.actionItem?.fact ?? {};

                                        const { module: currentModule, property: currentProperty } = option?.fact ?? {};

                                        const noPrev = !prevModule || !prevProperty;
                                        const noCurrent = !currentModule || !currentProperty;

                                        if (noPrev || noCurrent || i === index) return false;

                                        const isSameModule = prevModule === currentModule;
                                        const isSameProperty = prevProperty === currentProperty;

                                        return isSameModule && isSameProperty;
                                    });
                                }}
                                onChange={(option) => {
                                    const action = option as Action;
                                    handleFieldValueChange<Action>(id, `actions[${index}].actionItem`, action);
                                    if (action.fact?.type === "boolean") {
                                        handleFieldValueChange<FactValues>(id, `actions[${index}].actionValues`, {
                                            // default value for boolean/switch field
                                            values: false,
                                        });
                                    } else if (action.operations?.length === 1) {
                                        handleFieldValueChange<FactValues>(id, `actions[${index}].actionValues`, {
                                            operation: action.operations[0],
                                        });
                                    } else {
                                        handleFieldRemove(id, `actions[${index}].actionValues`);
                                    }
                                }}
                                isDisabled={disable}
                                data-cy={`action-select-${idList[index]}`}
                            />
                            {/* workaround for required validation */}
                            <Input
                                tabIndex={-1}
                                autoComplete="off"
                                value={currentActionItem != null ? JSON.stringify(currentActionItem) : ""}
                                required
                                position="absolute"
                                h={0}
                                opacity={0}
                                isDisabled={disable}
                                data-cy={`action-input-${idList[index]}`}
                            />
                            {fact?.module === "Company" ? (
                                <FormHelperText fontSize="xs">
                                    Company data will be updated only if the contact has a company
                                </FormHelperText>
                            ) : null}
                        </FormControl>

                        {currentActionItem?.hasValue ? (
                            <>
                                {allowDynamicResponse ? (
                                    <Checkbox
                                        id="getResponseFromBot"
                                        isChecked={promptActionValues[index]?.isValueFromResponse}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            setPromptActionValues((prev) => {
                                                const actionExists = prev.some((p) => p.actionIndex === index);
                                                if (actionExists) {
                                                    return prev.map((p) => {
                                                        if (p.actionIndex === index)
                                                            return { ...p, isValueFromResponse: isChecked };
                                                        return p;
                                                    });
                                                }
                                                return [
                                                    ...prev,
                                                    { actionIndex: index, isValueFromResponse: isChecked },
                                                ];
                                            });
                                            if (isChecked) {
                                                handleFieldRemove(id, `actions[${index}].actionValues.values`);
                                            }
                                        }}
                                        isDisabled={disable}
                                        data-cy={`action-checkbox-${idList[index]}`}
                                    >
                                        <Text fontSize="sm">Get response from bot</Text>
                                    </Checkbox>
                                ) : null}

                                {(!allowDynamicResponse || !promptActionValues[index]?.isValueFromResponse) &&
                                fact != null ? (
                                    <FormControl id={fact.property} isRequired position="relative" isDisabled={disable}>
                                        <FormLabel>{fact.label}</FormLabel>
                                        <FieldByFactType
                                            value={promptAction.actionValues?.values as FieldFactValueType}
                                            onChange={(v) => {
                                                handleFieldValueChange(id, `actions[${index}].actionValues.values`, v);
                                            }}
                                            disable={disable}
                                            {...fact}
                                        />
                                    </FormControl>
                                ) : null}
                            </>
                        ) : null}

                        {currentActionItem?.operations != null &&
                        currentActionItem?.operations.length > 0 &&
                        fact != null ? (
                            <FormControl id={`${fact.property}-operation`} isRequired isDisabled={disable}>
                                <FormLabel>Operation</FormLabel>
                                <RadioGroup
                                    onChange={(v) =>
                                        handleFieldValueChange(id, `actions[${index}].actionValues.operation`, v)
                                    }
                                    value={promptAction.actionValues?.operation as Operation}
                                    isDisabled={disable}
                                >
                                    <Stack direction="row">
                                        {currentActionItem.operations.map((o) => (
                                            <Radio
                                                key={`${fact.property}-${o}`}
                                                value={o}
                                                size="sm"
                                                isDisabled={disable}
                                                data-cy={`action-radio-${idList[index]}`}
                                            >
                                                {o}
                                            </Radio>
                                        ))}
                                    </Stack>
                                </RadioGroup>
                            </FormControl>
                        ) : null}

                        <Button
                            size="xs"
                            variant="link"
                            colorScheme="red"
                            fontWeight="normal"
                            onClick={() => {
                                const prevActions = promptActions ?? [];
                                const newActions = prevActions.filter((_, i) => i !== index);
                                if (newActions.length < 1) handleFieldRemove(id, "actions");
                                else handleFieldValueChange<PromptAction[]>(id, "actions", newActions);
                            }}
                            position="absolute"
                            top={0}
                            right={2}
                            leftIcon={<Icon as={MdClose} />}
                            isDisabled={disable}
                            data-cy={`action-remove-${idList[index]}`}
                        >
                            Remove action
                        </Button>
                    </VStack>
                );
            })}
            <Button
                size="sm"
                variant="outline"
                onClick={() => {
                    const prevActions = promptActions ?? [];
                    const newAction: PromptAction = {} as PromptAction;
                    const newActions = [...prevActions, newAction].filter(Boolean);
                    handleFieldValueChange<PromptAction[]>(id, "actions", newActions);
                    setPromptActionValues((prev) => [...prev, { actionIndex: prev.length, isValueFromResponse: true }]);
                }}
                leftIcon={<Icon as={MdAdd} />}
                isDisabled={disable}
                data-cy="action-add"
            >
                {promptActions.length > 0 ? "Add another action" : "Add action"}
            </Button>
        </VStack>
    );
};

export default PromptActions;

interface FieldByFactTypeProps extends Fact {
    value?: FieldFactValueType | null;
    onChange?: (value: FieldFactValueType | null) => void;
    disable?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mustacheValues?: Record<string, any>;
}

export const FieldByFactType: FC<FieldByFactTypeProps> = (props) => {
    const { value, onChange, disable = false, mustacheValues, ...fact } = props;
    const { label, type: factType, property: factProperty, options = [] } = fact;

    const [isLoading, setLoading] = React.useState(!!fact.lookup);
    const [defaultOptions, setDefaultOptions] = React.useState<FieldLookUpObject[]>([]);

    const [isPreLookupLoading, setPreLookupLoading] = React.useState(!!fact.preLookup);
    const [preLookupDefaultOptions, setPreLookupDefaultOptions] = React.useState<FieldLookUpObject[]>([]);
    const [preLookupValue, setPreLookupValue] = React.useState<FieldLookUpObject | null>(() => {
        if (!fact.preLookup || !(value as FieldLookUpObject | undefined | null)?.preLookupValue) return null;
        return {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: (value as FieldLookUpObject).preLookupValue!,
            name: (value as FieldLookUpObject).preLookupName || `Unknown ${fact.preLookup.label}`,
        };
    });

    React.useEffect(() => {
        if (!fact.preLookup) return;
        if (preLookupValue?.id !== (value as FieldLookUpObject)?.preLookupValue) onChange?.(null);
        setLoading(true);
        loadOptions("")
            .then((o) => {
                setDefaultOptions(o);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preLookupValue]);

    React.useEffect(() => {
        if (!fact.lookup || (fact.preLookup != null && preLookupValue == null)) {
            return;
        }
        setLoading(true);
        loadOptions("")
            .then((o) => {
                setDefaultOptions(o);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fact.lookup]);

    React.useEffect(() => {
        if (!fact.preLookup) return;
        setPreLookupLoading(true);
        preLookupLoadOptions("")
            .then((o) => {
                setPreLookupDefaultOptions(o);
            })
            .finally(() => {
                setPreLookupLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fact.preLookup]);

    const loadOptions = useCallback(
        async (inputValue: string): Promise<FieldLookUpObject[]> => {
            if (factType !== "lookup" || !fact.lookup) return [];
            if (fact.preLookup != null && preLookupValue == null) return [];

            const { url: lookupUrl, searchQueryKey, valuePath, labelPath } = fact.lookup;
            let url = [`/api${lookupUrl}`, `${searchQueryKey}=${inputValue}`].join(lookupUrl.includes("?") ? "&" : "?");
            if (fact.preLookup) {
                if (fact.lookup.queryParams) {
                    const queryParam = `${fact.preLookup.name}=${(preLookupValue as unknown as Record<string, string> | undefined)?.[fact.preLookup.valuePath] || ""}`;
                    url = [url, queryParam].join("&");
                }
            }

            const isMustacheAvailable =
                getMustacheVariablesIsAvailable(url) && mustacheValues && Object.keys(mustacheValues).length > 0;

            if (isMustacheAvailable) {
                url = mustacheRender(url, mustacheValues);
            }

            const lookupResponse = (await getJSON(url).catch(AsyncSelectErrorHandler)) as UnknownObject[];
            return lookupResponse
                .map((l): FieldLookUpObject => {
                    const { [valuePath]: value } = l;
                    return { name: get(l, labelPath.split(".")) as string, id: value as string };
                })
                .filter((v: FieldLookUpObject) => Boolean(v.id));
        },
        [fact.lookup, fact.preLookup, factType, preLookupValue, mustacheValues]
    );

    const preLookupLoadOptions = useCallback(
        async (inputValue: string): Promise<FieldLookUpObject[]> => {
            if (!fact.preLookup) return [];

            const { url: lookupUrl, searchQueryKey, valuePath, labelPath } = fact.preLookup;
            const url = [`/api${lookupUrl}`, `${searchQueryKey}=${inputValue}`].join(
                lookupUrl.includes("?") ? "&" : "?"
            );

            const lookupResponse = (await getJSON(url).catch(AsyncSelectErrorHandler)) as UnknownObject[];
            return lookupResponse
                .map((l): FieldLookUpObject => {
                    const { [valuePath]: value } = l;
                    return { name: get(l, labelPath.split(".")) as string, id: value as string };
                })
                .filter((v: FieldLookUpObject) => Boolean(v.id));
        },
        [fact.preLookup]
    );

    const isWebsite = factProperty.toLowerCase() === "website";
    const isEmail = factProperty.toLowerCase() === "email";
    const isNumber = factType === "number";

    switch (factType) {
        case "text":
        case "number":
            const textValue = value as string | number;
            const hasVariable = typeof textValue === "string" ? /{{\s*[\w\-.]+\s*}}/g.test(textValue) : false;
            const inputType = isWebsite
                ? "url"
                : isNumber && !hasVariable
                  ? "number"
                  : isEmail && !hasVariable
                    ? "email"
                    : "text";
            return (
                <Input
                    type={inputType}
                    required
                    value={textValue}
                    onChange={(v) => onChange?.(v.target.value)}
                    isDisabled={disable}
                    data-cy={`fact-${label}-input`}
                />
            );
        case "boolean":
            const booleanValue = value as boolean;
            return (
                <Switch
                    size="md"
                    isChecked={booleanValue}
                    onChange={(v) => onChange?.(v.target.checked)}
                    isDisabled={disable}
                    data-cy={`fact-${label}-switch`}
                />
            );
        case "file":
            break;
        case "date":
            const dateValue = dayjs(value as string | Date).format("YYYY-MM-DD");
            return (
                <Input
                    required
                    value={dateValue}
                    onChange={(v) => onChange?.(new Date(v.target.value))}
                    type={factType}
                    isDisabled={disable}
                    data-cy={`fact-${label}-date`}
                />
            );
        case "select":
            const selectValue = value as string;
            return (
                <>
                    <ReactSelectStyled<LabelValue>
                        placeholder={`Select ${label ?? ""}`}
                        value={{ label: selectValue, value: selectValue }}
                        onChange={(option) => onChange?.((option as LabelValue).value)}
                        options={options.map((o) => ({ label: o, value: o }))}
                        isDisabled={disable}
                        data-cy={`fact-${label}-select`}
                        menuHeight="200px"
                    />
                    {/* workaround for required validation */}
                    <Input
                        tabIndex={-1}
                        autoComplete="off"
                        value={selectValue}
                        required
                        position="absolute"
                        h={0}
                        opacity={0}
                        isDisabled={disable}
                        data-cy={`fact-${label}-input`}
                    />
                </>
            );
        case "lookup":
            const lookupValue = value as FieldLookUpObject | null | undefined;
            return (
                <VStack w="full" alignItems="flex-start">
                    {fact.preLookup ? (
                        <>
                            <AsyncSelectStyled
                                defaultOptions={preLookupDefaultOptions}
                                loadOptions={preLookupLoadOptions}
                                isLoading={isPreLookupLoading}
                                value={preLookupValue}
                                getOptionValue={(o) => (o as FieldLookUpObject)?.id}
                                getOptionLabel={(o) => (o as FieldLookUpObject)?.name}
                                onChange={(v) => setPreLookupValue?.(v as FieldLookUpObject)}
                                isDisabled={disable}
                                data-cy={`fact-preLookup-${fact.preLookup.label}-select`}
                                placeholder={`Select ${fact.preLookup.label || ""}`.trim()}
                                menuHeight="200px"
                            />
                            {/* workaround for required validation */}
                            <Input
                                tabIndex={-1}
                                autoComplete="off"
                                value={preLookupValue ? JSON.stringify(preLookupValue) : ""}
                                required
                                position="absolute"
                                h={0}
                                opacity={0}
                                isDisabled={disable}
                                data-cy={`fact-preLookup-${fact.preLookup.label}-input`}
                            />
                        </>
                    ) : null}
                    {fact.preLookup != null && preLookupValue == null ? null : (
                        <>
                            <AsyncSelectStyled
                                defaultOptions={defaultOptions}
                                loadOptions={loadOptions}
                                isLoading={isLoading}
                                value={lookupValue}
                                getOptionValue={(o) => (o as FieldLookUpObject)?.id}
                                getOptionLabel={(o) => (o as FieldLookUpObject)?.name}
                                onChange={(v) => {
                                    if (fact.preLookup != null && v != null) {
                                        v.preLookupName = preLookupValue?.name;
                                        v.preLookupValue = preLookupValue?.id;
                                    }
                                    onChange?.(v as FieldLookUpObject);
                                }}
                                isDisabled={disable}
                                data-cy={`fact-${label}-select`}
                                placeholder={`Select ${label || ""}`.trim()}
                                menuHeight="200px"
                            />
                            {/* workaround for required validation */}
                            <Input
                                tabIndex={-1}
                                autoComplete="off"
                                value={lookupValue ? JSON.stringify(lookupValue) : ""}
                                required
                                position="absolute"
                                h={0}
                                opacity={0}
                                isDisabled={disable}
                                data-cy={`fact-${label}-input`}
                            />
                        </>
                    )}
                </VStack>
            );
        default:
            break;
    }

    return null;
};
