import { HStack, CircularProgress, Text, Box, CircularProgressProps } from "@chakra-ui/react";
import React from "react";

interface GBCircularProgressbarProps extends CircularProgressProps {
    total: number;
    currentValue: number;
}

const GBCircularProgressbar: React.FC<GBCircularProgressbarProps> = (props) => {
    const { total, currentValue, ...circularProgressProps } = props;
    const percentage = total > 0 ? (currentValue / total) * 100 : 0;
    return (
        <HStack width="full">
            <CircularProgress size="30px" value={percentage} color="blue.500" {...circularProgressProps} />
            <Box>
                <Text color="gray.800" fontSize="2xs" maxW="8ch" isTruncated title={currentValue.toString()}>
                    {currentValue}
                </Text>
                <Text color="gray.500" fontSize="2xs" noOfLines={1}>{`${Math.round(percentage)}%`}</Text>
            </Box>
        </HStack>
    );
};

export default GBCircularProgressbar;
