import Cookies from "js-cookie";
import { isNumber } from "lodash";
import React from "react";

interface UseNudgeProps {
    timeOutConfig: {
        delay: number;
        timeOutCallback: () => void;
    };
    option: "everyOnce" | number;
    outOffWindowCallback: () => void;
    enabled?: boolean;
}

const setNudgeCookie = (option: number) => {
    const expires = new Date(new Date().getTime() + 60 * option * 60 * 1000);
    Cookies.set("exportNudge", "nudged", {
        expires,
    });
};

// delay in seconds
export const useNudge = (props: UseNudgeProps): ((option: number) => void) => {
    const {
        outOffWindowCallback,
        timeOutConfig: { delay, timeOutCallback },
        option,
        enabled = true,
    } = props;

    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
    const savedTimeOutCallback = React.useRef(timeOutCallback);
    const savedOutOfWindowCallback = React.useRef(outOffWindowCallback);
    const isOutOfWidowCalled = React.useRef<boolean>(false);

    React.useEffect(() => {
        savedTimeOutCallback.current = timeOutCallback;
    }, [timeOutCallback]);

    const nudgeCookieKey = Cookies.get("exportNudge");
    React.useEffect(() => {
        if (!enabled) return;
        const tick = () => {
            savedTimeOutCallback.current();
            if (isNumber(option) && !nudgeCookieKey) setNudgeCookie(option);
        };
        if (typeof delay === "number" && (option === "everyOnce" || !nudgeCookieKey)) {
            timeoutRef.current = setTimeout(tick, delay * 1000);
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            timeoutRef.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    React.useEffect(() => {
        savedOutOfWindowCallback.current = outOffWindowCallback;
    }, [outOffWindowCallback]);

    React.useEffect(() => {
        if (!enabled) return;
        if (!isOutOfWidowCalled.current) {
            const updateMousePosition = (e: MouseEvent) => {
                e = e ? e : (window.event as MouseEvent);
                const from = e.relatedTarget || (e as any).toElement;
                if (!from || from.nodeName == "HTML") {
                    if (isNumber(option) && !nudgeCookieKey) setNudgeCookie(option);
                    if (option === "everyOnce" || !nudgeCookieKey) {
                        isOutOfWidowCalled.current = true;
                        savedOutOfWindowCallback.current?.();
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        savedOutOfWindowCallback.current = () => {};
                    }
                }
            };
            window.addEventListener("mouseout", updateMousePosition, false);
            return () => {
                window.removeEventListener("mouseout", updateMousePosition, false);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOutOfWidowCalled.current]);

    return setNudgeCookie;
};
