import { useRestrictFeature } from "app/hooks/useRestrictFeature";

interface AccountOverUsageReturnType {
    isAccountOverUsed: boolean;
}

export const useAccountOverUsage = (): AccountOverUsageReturnType => {
    const {
        data: { overUsage: isAccountOverUsed },
    } = useRestrictFeature("accounts_users");

    return { isAccountOverUsed };
};
