import { VStack, Text } from "@chakra-ui/react";
import React from "react";

interface ReloadAlertDescProps {
    content: string;
}
const ReloadAlertDesc: React.FC<ReloadAlertDescProps> = (props) => {
    const { content } = props;
    return (
        <VStack align={"flex-start"}>
            <Text>{content}</Text>
        </VStack>
    );
};

export default ReloadAlertDesc;
