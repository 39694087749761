import { AccountEntitlementContext } from "app/AccountEntitlementContext/context";
import { UpdateAccountEntitlementUsage } from "app/AccountEntitlementContext/type";
import { SAccountEntitlement } from "app/types";
import { useAccountId } from "app/utils/react-helpers";
import { socketEmit, SocketEvents, useSocket } from "app/utils/use-socketio";
import { useContext, useEffect } from "react";

export const useListenAccountEntitlement = (): void => {
    const { updateEntitlement, setAccountEntitlement } = useContext(AccountEntitlementContext);
    const accountId = useAccountId();
    const { socket } = useSocket(SocketEvents.UserAccountConnect, (value) =>
        console.log("socket::accountEntitlement::connected")
    );

    useSocket(SocketEvents.UpdateAccountUsage, (updatedUsageData: UpdateAccountEntitlementUsage) => {
        if (!updatedUsageData) return;
        updateEntitlement(updatedUsageData, "usage");
    });
    useSocket(SocketEvents.UpdateAccountAddons, (updatedUsageData: UpdateAccountEntitlementUsage) => {
        if (!updatedUsageData) return;
        updateEntitlement(updatedUsageData, "addOns");
    });

    useSocket(SocketEvents.UpdateAccountEntitlement, (accountEntitlement: SAccountEntitlement) => {
        if (!accountEntitlement) return;
        setAccountEntitlement(accountEntitlement);
    });

    useEffect(() => {
        socketEmit(socket, SocketEvents.UserAccountConnect, accountId);
    }, [accountId, socket]);
};
