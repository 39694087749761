import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Alert,
    AlertDescription,
    AlertIcon,
    Box,
    Button,
    Flex,
    HStack,
    Text,
    VStack,
} from "@chakra-ui/react";
import GallaboxLoadingState from "app/components/GBLoadingState";
import GBTooltip from "app/components/GBTooltip";
import IntegrationInputWithVariable from "app/components/IntegrationExpressionInput.tsx/IntegrationInputwithVariable";
import WhatsAppUIPreviewWrap from "app/components/TemplateMsgPreview/WhatsAppUIPreviewWrap";
import { useWATemplateMessage } from "app/fetchHooks/whatsapp-template";
import { HeaderValues, WAITemplateMessage } from "app/types";
import { IntegrationField } from "app/types/integration/integrationField";
import { useAccountId } from "app/utils/react-helpers";
import { getTemplateButtonValues } from "app/utils/wa-templates";
import { startCase } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import WATemplatePreview from "../WATemplatesV2/component/WATemplatePreview";

export interface WrapperIntegrationDetails {
    recipientName: IntegrationField[];
    recipientNumber: IntegrationField[];
    bodyValues: IntegrationField[];
    headerValues: IntegrationField[];
    buttonValues: IntegrationField[];
}

interface MappingIntegrationTemplateCommonProps {
    templateId: string;
    variables: WrapperIntegrationDetails | IntegrationField[];
    handleVariables: (data: BodyFormValues) => Promise<void>;
    previousValue?: BodyFormValues;
    isRecipientDetailsEnabled?: boolean;
    isLoading: boolean;
    closeModal: () => void;
}

export interface BodyFormValues
    extends Partial<Pick<WAITemplateMessage, "bodyValues" | "headerValues" | "buttonValues">> {
    recipientNumber?: string;
    recipientName?: string;
}

const MappingIntegrationCommonTemplate: React.FC<MappingIntegrationTemplateCommonProps> = (props) => {
    const { templateId, variables, handleVariables, isLoading, previousValue, isRecipientDetailsEnabled, closeModal } =
        props;
    const accountId = useAccountId();
    const { data: template, isLoading: isLoadingTemplate } = useWATemplateMessage({
        accountId,
        templateId,
    });

    const isVariableArray = Array.isArray(variables);

    const isDottedVariableAvailable =
        (template?.variables?.filter((v) => v.includes(".") || v.includes("$") || v.includes(","))?.length ?? 0) > 0;

    const {
        bodyValues: PBodyValues,
        buttonValues: PButtonValues,
        headerValues: PHeaderValues,
        recipientName,
        recipientNumber,
    } = previousValue ?? {};

    const headerValues: HeaderValues | undefined = React.useMemo(() => {
        const headerComponent = template?.components.find((m) => m.type === "HEADER");
        if (!headerComponent || headerComponent.format === "TEXT") return {};
        return { mediaUrl: "", mediaName: "" };
    }, [template?.components]);

    const buttonValues = React.useMemo(() => {
        return getTemplateButtonValues(template);
    }, [template]);
    const urlButton = React.useMemo(() => {
        if (!buttonValues) return;
        return buttonValues.filter((b) => b.sub_type === "url");
    }, [buttonValues]);

    const couponButton = React.useMemo(() => {
        if (!buttonValues) return;
        return buttonValues.filter((b) => b.sub_type === "COPY_CODE");
    }, [buttonValues]);

    const btnValues = React.useMemo(() => {
        if (!buttonValues) return [];
        return buttonValues.filter((b) => b.sub_type === "quick_reply");
    }, [buttonValues]);

    const isAdvancedOptionOpen = Object.keys(headerValues).length || (btnValues && btnValues.length > 0);

    const defaultValues: BodyFormValues = React.useMemo(() => {
        const bt = buttonValues?.map((b) => PButtonValues?.find((a) => a.index === b.index) ?? b);
        return {
            recipientName,
            recipientNumber,
            bodyValues: PBodyValues ?? {},
            headerValues: PHeaderValues ?? headerValues,
            buttonValues: bt,
        };
    }, [PBodyValues, PButtonValues, PHeaderValues, buttonValues, headerValues, recipientName, recipientNumber]);

    const { control, handleSubmit, reset } = useForm<BodyFormValues>({
        defaultValues,
        shouldUnregister: false,
    });

    React.useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    if (isLoadingTemplate)
        return (
            <VStack w="full" height="full" alignItems="center" justifyContent="center">
                <GallaboxLoadingState title="Loading..." spinnerSize={30} />
            </VStack>
        );

    return (
        <VStack as="form" onSubmit={handleSubmit(handleVariables)} w="full" height="full" maxWidth="800px" mx="auto">
            {isDottedVariableAvailable ? (
                <Alert alignItems="flex-start" status="error" bgColor="red.100" borderRadius="6px">
                    <AlertIcon />
                    <AlertDescription>
                        Template variable name cannot support special characters like dollar, quotes and period ($,', .)
                        Please create different template with valid variable names.
                    </AlertDescription>
                </Alert>
            ) : null}
            <HStack w="full" spacing="30px" height="full" maxHeight="full" alignItems="baseline" maxWidth="full">
                <Box w="full" height="full">
                    <Box py="10px">
                        <GBTooltip label={template?.name}>
                            <Text maxWidth="30ch" isTruncated fontSize="16px" fontWeight="medium">
                                {template?.name}
                            </Text>
                        </GBTooltip>
                    </Box>
                    <WhatsAppUIPreviewWrap>
                        <WATemplatePreview
                            components={template?.components ?? []}
                            language={template?.language}
                            showBodyVariable={true}
                        />
                    </WhatsAppUIPreviewWrap>
                </Box>
                <VStack maxHeight="full" w="full" height="full">
                    {isRecipientDetailsEnabled ? (
                        <VStack w="full">
                            <IntegrationInputWithVariable
                                name="recipientName"
                                label="Recipient Name"
                                placeholder="Enter your value"
                                control={control}
                                required
                                formHelperText="This is used as name while creating new contact in Gallabox."
                                IntegrationFieldVariables={isVariableArray ? variables : variables.recipientName}
                                isLoading={isLoading}
                            />
                            <IntegrationInputWithVariable
                                name="recipientNumber"
                                label="Recipient Phone"
                                placeholder="Enter your value"
                                control={control}
                                formHelperText="Messages will be sent to this number. Valid formats are +919009090090 or 9009090090"
                                required
                                IntegrationFieldVariables={isVariableArray ? variables : variables.recipientNumber}
                                isLoading={isLoading}
                            />
                        </VStack>
                    ) : null}
                    <Text
                        hidden={template?.variables?.length === 0}
                        w="full"
                        fontSize="16px"
                        fontWeight="medium"
                        textAlign="left"
                    >
                        Body Variables
                    </Text>
                    {template?.variables?.map((v, idx) => {
                        return (
                            <>
                                <IntegrationInputWithVariable
                                    name={`bodyValues.${v}`}
                                    key={idx}
                                    label={v}
                                    placeholder="Enter your value"
                                    required
                                    control={control}
                                    IntegrationFieldVariables={isVariableArray ? variables : variables.bodyValues}
                                    isLoading={isLoading}
                                />
                            </>
                        );
                    })}
                    {urlButton?.length || couponButton?.length ? (
                        <Text w="full" fontSize="16px" fontWeight="medium" textAlign="left">
                            Footer Variables
                        </Text>
                    ) : null}
                    {urlButton?.map((urlBtn, index) => {
                        const btnIndex = buttonValues?.findIndex((a) => a.index === urlBtn.index) ?? 0;
                        return (
                            <IntegrationInputWithVariable
                                name={`buttonValues[${btnIndex}].parameters.text`}
                                label="URL"
                                placeholder="Enter your value"
                                control={control}
                                required
                                IntegrationFieldVariables={isVariableArray ? variables : variables.buttonValues}
                                isLoading={isLoading}
                                key={btnIndex}
                                leftAddon={(urlBtn as any).url}
                            />
                        );
                    })}

                    {couponButton?.map((cpnBtn) => {
                        const btnIndex = buttonValues?.findIndex((a) => a.index === cpnBtn.index) ?? 0;
                        return (
                            <IntegrationInputWithVariable
                                name={`buttonValues[${btnIndex}].parameters.coupon_code`}
                                label="Offer Code"
                                placeholder="GET50OFF"
                                control={control}
                                required
                                IntegrationFieldVariables={isVariableArray ? variables : variables.buttonValues}
                                isLoading={isLoading}
                                key={btnIndex}
                            />
                        );
                    })}

                    {isAdvancedOptionOpen ? (
                        <Accordion allowToggle w="full">
                            <AccordionItem border="none">
                                <AccordionButton
                                    _hover={{ bg: "transparent", color: "brand.500" }}
                                    px={0}
                                    data-cy="integration-template-advanced-options-accordion"
                                >
                                    <Box textAlign="left" fontSize="xs">
                                        Advanced Options
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel px={0} pb={4}>
                                    {btnValues && btnValues.length > 0 && (
                                        <Flex wrap="wrap" gridGap={1}>
                                            {btnValues.map((t, idx) => {
                                                const isFlow = t.sub_type === "flow";
                                                const name = isFlow
                                                    ? `buttonValues[${t?.index}].parameters.action.flow_token`
                                                    : `buttonValues[${t?.index}].parameters.payload`;
                                                const btnLabel = isFlow
                                                    ? `Flow token for Button ${idx + 1}`
                                                    : `Payload for Button ${idx + 1})`;
                                                return (
                                                    <IntegrationInputWithVariable
                                                        name={name}
                                                        key={idx}
                                                        label={btnLabel}
                                                        placeholder="Enter your value"
                                                        control={control}
                                                        IntegrationFieldVariables={
                                                            isVariableArray ? variables : variables.buttonValues
                                                        }
                                                        isLoading={isLoading}
                                                    />
                                                );
                                            })}
                                        </Flex>
                                    )}
                                    {Object.keys(headerValues)?.map((m, idx) => {
                                        return (
                                            <IntegrationInputWithVariable
                                                name={`headerValues.${m}`}
                                                key={idx}
                                                label={startCase(m)}
                                                placeholder="Enter your value"
                                                control={control}
                                                IntegrationFieldVariables={
                                                    isVariableArray ? variables : variables.headerValues
                                                }
                                                isLoading={isLoading}
                                            />
                                        );
                                    })}
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    ) : null}
                </VStack>
            </HStack>
            <HStack w="full" justifyContent="flex-end">
                <Button size="sm" colorScheme="gray" variant="outline" onClick={closeModal}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    size="sm"
                    isDisabled={isDottedVariableAvailable}
                    isLoading={isLoading}
                    colorScheme="brand"
                >
                    <span>Done</span>
                </Button>
            </HStack>
        </VStack>
    );
};

export default MappingIntegrationCommonTemplate;
