import { useToast } from "@chakra-ui/react";
import { CalendlyConnectForm } from "app/screens/Account/Integration/Calendly/CalendlyConnectForm";
import { WorkflowConditions } from "app/screens/Account/Integration/integrationUtils";
import { BodyFormValues } from "app/screens/Account/Integration/MappingIntegrationTemplate";
import { ZohoCRMConnectForm } from "app/screens/Account/Integration/ZohoCRMSignals/ZohoCRMConnect";
import { WAITemplateMessage, WATemplateValues } from "app/types";
import { Action, FactValues } from "app/types/action";
import { PromptAction } from "app/types/bot";
import { QueryKey, TQueryKey } from "app/types/common";
import {
    CalendlyWorkflow,
    GenericWebhookWorkFlow,
    Integration,
    ShiprocketStatusNameType,
    ShipwayStatusCode,
    Workflow,
} from "app/types/integration";
import {
    ConnectAbandonCartIntegrationInput,
    IWCAbandonedCartIntegration,
    WCAbandonedCartWorkflowType,
} from "app/types/integration/abandoncart";
import { CalendlyEventList } from "app/types/integration/calendly";
import { CashFreeConnect, CashFreeEventType } from "app/types/integration/cashfree";
import {
    ConnectCleverTap,
    ConnectCleverTapIntegrationInput,
    UpdateCleverTapConfigInput,
} from "app/types/integration/cleverTap";
import { IntegrationField } from "app/types/integration/integrationField";
import { IntegrationLog } from "app/types/integration/integrationLog";
import {
    ConnectLeadSquared,
    ConnectLeadSquaredIntegrationInput,
    UpdateLsqConfigInput,
} from "app/types/integration/leadsquared";
import { IRazorPayIntegration, RazorpayEventType } from "app/types/integration/razorPay";
import {
    ConnectShopFloIntegrationInput,
    ShopfloIntegration,
    ShopfloWorkFlow,
    UpdateShopfloConfigInput,
} from "app/types/integration/shopFlo";
import { ConnectStripe, ConnectStripeIntegrationInput, UpdateStripeConfigInput } from "app/types/integration/stripe";
import { IWCCartFlowIntegration, WCCartFlowWorkflowType } from "app/types/integration/woocommerceCartFlow";
import { ZohoBooksCreateWorkFlowInputData } from "app/types/integration/zohoBooks";
import { deleteJSON, fetcher, mapQueryParams, patchJSON, postJSON, putJSON } from "app/utils/fetchUtils";
import {
    MutationFunction,
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryOptions,
    UseQueryResult,
} from "react-query";
import { useHistory } from "react-router-dom";

interface ShopifyPayment {
    confirmationUrl: string;
}
interface Oauth2ResultData extends ShopifyPayment {
    integration?: Integration;
    uri?: string;
    id?: string;
}

export interface SessionParams {
    readonly id: string;
    shop: string;
    state: string;
    isOnline: boolean;
    scope?: string;
    expires?: Date;
    accessToken?: string;
}
interface UseInitiateShopifyAuthProps {
    accountId: string;
    onSuccess?: (res: Oauth2ResultData) => void;
    onError?: () => void;
}
type UsePostOauth2Credential<InputType> = UseMutationResult<Oauth2ResultData, Error, InputType>;

export const useInitiateShopifyAuth = (props: UseInitiateShopifyAuthProps): UsePostOauth2Credential<SessionParams> => {
    const { accountId, onError, onSuccess } = props;
    const toast = useToast();
    const history = useHistory();

    const fetchOAuth2URI = (data: SessionParams) => {
        return postJSON(`/api/accounts/${accountId}/integrations/shopify/create`, data);
    };

    const mutationResult = useMutation<Oauth2ResultData, Error, SessionParams>(fetchOAuth2URI, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            onError?.();
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
            history.replace(`/integration/shopify`);
        },

        onSuccess: (i) => {
            onSuccess?.(i);
        },
    });
    return mutationResult;
};

interface UseUpdateIntegrationProps {
    accountId?: string;
    integrationId: string;
    onSuccess?: (field: Integration) => void;
    showSuccessMessage?: boolean;
}

interface UpdateIntegrationInput {
    integrationName?: string;
    brandName?: string;
    channelId: string;
}

interface UpdateIntegrationInputWithShopUrl extends UpdateIntegrationInput {
    publicShopUrl: string;
    shopUrl?: string;
}

type UsePatchIntegration<InputType> = UseMutationResult<Integration, Error, InputType>;

export interface UseUpdateIntegration {
    woocommerce?: UpdateIntegrationInputWithShopUrl;
    razorpay?: UpdateIntegrationInput;
    fbleads?: UpdateIntegrationInput;
    genericWebhook?: UpdateIntegrationInput;
    shiprocket?: UpdateIntegrationInput;
    cashfree?: UpdateIntegrationInput;
    woocommerce_cartflow?: UpdateIntegrationInput;
    shipway?: UpdateIntegrationInput;
    woocommerce_abandoned_cart?: UpdateIntegrationInput;
    zoho_books?: UpdateIntegrationInput;
    calendly?: UpdateIntegrationInput;
}

export const useUpdateIntegration = <InputType extends UseUpdateIntegration>(
    props: UseUpdateIntegrationProps
): UsePatchIntegration<InputType> => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const integrationUpdate = (data: InputType): Promise<Integration> => {
        return patchJSON<Integration>(`/api/accounts/${accountId}/integrations/${integrationId}`, {
            ...data,
        } as unknown as any);
    };

    const mutationResult = useMutation<Integration, Error, InputType>(integrationUpdate, {
        // If the mutation fails, use the context returned from onMutate to roll back

        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (field) => {
            if (showSuccessMessage) {
                toast({
                    title: "Integration Updated",
                    status: "success",
                });
            }
            onSuccess?.(field);
        },

        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface IntegrationListProps
    extends UseInfiniteQueryOptions<Integration[], Error, Integration[], Integration[], TQueryKey> {
    accountId: string;
    search?: string;
}

export const useIntegrationList = (props: IntegrationListProps): UseInfiniteQueryResult<Integration[], Error> => {
    const { accountId, search, ...options } = props;

    const PAGE_SIZE = 50;

    const infiniteQueryResult = useInfiniteQuery<Integration[], Error, Integration[], TQueryKey>(
        [QueryKey.IntegrationFieldsList, { accountId, search }],
        ({ pageParam, queryKey }) => {
            const [, { accountId, providerName }] = queryKey;
            const queryParams = mapQueryParams({
                page: pageParam ?? 1,
                limit: PAGE_SIZE,
                providerName,
            });

            return fetcher(`/api/accounts/${accountId}/integrations?${queryParams}`);
        },
        {
            ...options,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === PAGE_SIZE ? pages.length + 1 : undefined;
            },
        }
    );

    return infiniteQueryResult;
};

const IntegrationFieldType = [
    "Shopify",
    "WooCommerce",
    "Razorpay",
    "FbLeads",
    "genericWebhook",
    "Shiprocket",
    "Cashfree",
    "WoocommerceCartflow",
    "Shipway",
    "WoocommerceAbandonedCart",
    "ZohoBooks",
    "Calendly",
    "Stripe",
    "Shopflo",
] as const;

export type IntegrationFieldApp = (typeof IntegrationFieldType)[number];

interface IntegrationFieldsListProps
    extends UseInfiniteQueryOptions<IntegrationField[], Error, IntegrationField[], IntegrationField[], TQueryKey> {
    accountId: string;
    search?: string;
    app?: IntegrationFieldApp;
    integrationId?: string;
    pageId?: string;
    formId?: string;
    module?: string;
    enabled?: boolean;
}

export const useIntegrationFieldsList = (
    props: IntegrationFieldsListProps
): UseInfiniteQueryResult<IntegrationField[], Error> => {
    const { accountId, search, app, integrationId, pageId, formId, module, enabled = true, ...options } = props;

    const PAGE_SIZE = 20;

    const infiniteQueryResult = useInfiniteQuery<IntegrationField[], Error, IntegrationField[], TQueryKey>(
        [QueryKey.IntegrationFieldsList, { accountId, search, app, integrationId, pageId, formId, module }],
        ({ pageParam, queryKey }) => {
            const [, { accountId, app, module, pageId, formId }] = queryKey;
            const queryParams = mapQueryParams({
                page: pageParam ?? 1,
                limit: PAGE_SIZE,
                app,
                integrationId,
                pageId,
                formId,
                module,
            });

            return fetcher(`/api/accounts/${accountId}/integration-fields?${queryParams}`);
        },
        {
            ...options,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === PAGE_SIZE ? pages.length + 1 : undefined;
            },
            enabled,
        }
    );

    return infiniteQueryResult;
};

interface UsePostCreateIntegrationFieldProps {
    accountId: string;
    onSuccess?: (field: IntegrationField) => void;
    showSuccessMessage?: boolean;
}

type UsePostIntegrationField<InputType> = UseMutationResult<IntegrationField, Error, InputType>;

export const usePostIntegrationField = <InputType extends Omit<IntegrationField, "id" | "app"> & { id?: string }>(
    props: UsePostCreateIntegrationFieldProps
): UsePostIntegrationField<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.IntegrationFieldsList, { accountId }];

    const integrationField = (data: InputType) => {
        if (data.id)
            return patchJSON<InputType>(`/api/accounts/${accountId}/integration-fields/${data.id}`, { ...data });
        return postJSON<InputType>(`/api/accounts/${accountId}/integration-fields`, { ...data });
    };

    const mutationResult = useMutation<IntegrationField, Error, InputType>(integrationField, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (field, { id }) => {
            if (showSuccessMessage) {
                toast({
                    title: `Integration Field ${id ? "Edited" : "Added"}`,
                    status: "success",
                });
            }
            onSuccess?.(field);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface IntegrationFieldProps {
    accountId: string;
    integrationFieldId?: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}

export const fetchIntegrationField: QueryFunction<IntegrationField, TQueryKey> = ({ queryKey }) => {
    const [, { accountId, integrationFieldId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/integration-fields/${integrationFieldId}`);
};

export const useGetIntegrationField = (props: IntegrationFieldProps): UseQueryResult<IntegrationField, Error> => {
    const { accountId, integrationFieldId } = props;

    return useQuery<IntegrationField, Error, IntegrationField, TQueryKey>(
        [QueryKey.IntegrationField, { accountId, integrationFieldId }],
        fetchIntegrationField,
        { enabled: Boolean(accountId) && Boolean(integrationFieldId) }
    );
};

interface IntegrationProps {
    accountId: string;
    integrationId?: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}

export const fetchIntegration: QueryFunction<Integration, TQueryKey> = ({ queryKey }) => {
    const [, { accountId, integrationId }] = queryKey;

    return fetcher(`/api/accounts/${accountId}/integrations/${integrationId}`);
};

export const useGetIntegration = (props: IntegrationProps): UseQueryResult<Integration, Error> => {
    const { accountId, integrationId } = props;

    return useQuery<Integration, Error, Integration, TQueryKey>(
        [QueryKey.Integration, { accountId, integrationId }],
        fetchIntegration,
        {
            enabled: Boolean(accountId) && Boolean(integrationId),
        }
    );
};

type UseCheckPaymentReturn = UseMutationResult<ShopifyPayment, Error, void, unknown>;

export const useCheckPaymentShopify = (props: IntegrationProps): UseCheckPaymentReturn => {
    const { accountId, integrationId, onSuccess } = props;
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];
    const queryClient = useQueryClient();

    const checkPayment = () => {
        return fetcher(
            `/api/accounts/${accountId}/integrations/shopify/${integrationId}/checkPayment?processPayment=true`
        );
    };

    const mutationResult = useMutation<ShopifyPayment, Error>(checkPayment, {
        onSuccess: (res) => {
            if (res?.confirmationUrl) {
                window.open(res.confirmationUrl, "_self");
                return;
            }
            queryClient.invalidateQueries(queryKey);
        },
    });
    return mutationResult;
};

export const fetchWorkFlow: QueryFunction<Workflow[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId, integrationId }] = queryKey;

    return fetcher(`/api/accounts/${accountId}/integrations/${integrationId}/workflow`);
};

export const useGetWorkFlow = (props: IntegrationProps): UseQueryResult<Workflow[], Error> => {
    const { accountId, integrationId } = props;

    return useQuery<Workflow[], Error, Workflow[], TQueryKey>(
        [QueryKey.IntegrationWorkFlow, { accountId, integrationId }],
        fetchWorkFlow,
        {
            enabled: Boolean(accountId) && Boolean(integrationId),
        }
    );
};

interface RegisterQueryContext {
    previousIntegration?: Integration;
}

interface ShopifyRegisterProps {
    topic: string;
    name: string;
    templateId?: string;
    isCustom?: boolean;
    workflowId?: string;
    sendAfter?: number;
    condition?: WorkflowConditions["condition"];
    isActive?: boolean;
    templateValues?: BodyFormValues;
    workflowActions?: PromptAction[];
}

interface ShopifyRegisterFlow {
    accountId: string;
    integrationId: string;
    workflowId?: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
    isCustom: boolean;
    isNewWorkFlow: boolean;
}

type UseUpdateTemplateResult<InputType> = UseMutationResult<Integration, Error, InputType, RegisterQueryContext>;

export const useShopifyRegister = <InputType extends Record<string, any> = ShopifyRegisterProps>(
    props: ShopifyRegisterFlow
): UseUpdateTemplateResult<InputType> => {
    const {
        accountId,
        integrationId,
        workflowId,
        showSuccessMessage = true,
        isCustom,
        isNewWorkFlow,
        onSuccess,
    } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const registerTemplate = (templateDetails: InputType) => {
        if (isNewWorkFlow) {
            const queryParams = mapQueryParams({
                isCustom,
            });

            return postJSON<Integration>(
                `/api/accounts/${accountId}/integrations/shopify/${integrationId}/workflow?${queryParams}`,
                templateDetails as any
            );
        }
        const queryParams = mapQueryParams({
            isCustom: templateDetails?.isCustom,
        });
        const modifiedWorkflowId = workflowId ?? templateDetails?.workflowId;

        return patchJSON<Integration>(
            `/api/accounts/${accountId}/integrations/shopify/${integrationId}/workflow/${modifiedWorkflowId}?${queryParams}`,
            templateDetails
        );
    };

    const mutationResult = useMutation<Integration, Error, InputType, RegisterQueryContext>(registerTemplate, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedChannel, context) => {
            toast({
                title: "Something went wrong!",
                status: "error",
                description: err.message,
            });
            if (context?.previousIntegration) {
                queryClient.setQueryData<Integration>(queryKey, context.previousIntegration);
            }
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: `${isNewWorkFlow ? "Create" : "Update"} workflow!`,
                    status: "success",
                    description: `${isNewWorkFlow ? "Create" : "Update"} workflow successfully!`,
                });
            }
            onSuccess?.();
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

// Woocommerce Integration

interface UseInitiateWooCommerceAuthProps {
    accountId: string;
    onSuccess?: (res: Oauth2ResultData) => void;
}
interface OauthWooCommercePostData {
    shopUrl: string;
}

type UsePostWooCommerceOauth2Credential<InputType> = UseMutationResult<Oauth2ResultData, Error, InputType>;

export const useInitiateWooCommerceAuth = (
    props: UseInitiateWooCommerceAuthProps
): UsePostWooCommerceOauth2Credential<OauthWooCommercePostData> => {
    const { accountId, onSuccess } = props;
    const toast = useToast();

    const fetchOAuth2URI = (data: OauthWooCommercePostData) => {
        return postJSON(`/api/accounts/${accountId}/integrations/woocommerce/connect`, data);
    };

    const mutationResult = useMutation<Oauth2ResultData, Error, OauthWooCommercePostData>(fetchOAuth2URI, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
    });
    return mutationResult;
};

//register WooCommerce

interface registerWooCommerce {
    triggerKey: string;
    customId?: string;
    templateId?: string;
    isActive?: boolean;
    sendAfter?: number;
    status?: string;
    condition?: WorkflowConditions["condition"];
    templateValues?: Partial<Pick<WAITemplateMessage, "bodyValues" | "headerValues" | "buttonValues">>;
    workflowActions?: PromptAction[];
}

interface useRegisterWooCommerceProps {
    accountId: string;
    integrationId: string;
    showSuccessMessage?: boolean;
    onSuccess?: () => void;
}
type UsePatchWooCommerceIntegration<InputType> = UseMutationResult<Integration, Error, InputType>;

export const useRegisterWooCommerce = <InputType extends registerWooCommerce>(
    props: useRegisterWooCommerceProps
): UsePatchWooCommerceIntegration<InputType> => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const WooCommerceRegister = (data: InputType): Promise<Integration> => {
        return patchJSON<Integration>(`/api/accounts/${accountId}/integrations/woocommerce/${integrationId}/register`, {
            ...data,
        } as unknown as any);
    };

    const mutationResult = useMutation<Integration, Error, InputType>(WooCommerceRegister, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: "WooCommerce Integration Updated",
                    status: "success",
                });
            }
            onSuccess?.();
        },
        onMutate: async (updatedTemplate) => {
            await queryClient.cancelQueries(queryKey);

            const previousIntegration = queryClient.getQueryData<Integration>(queryKey);

            if (!previousIntegration || !previousIntegration.woocommerce) return {};

            const updatedTriggers = previousIntegration.woocommerce?.triggerDetails?.map((v) => {
                if (v.triggerKey === updatedTemplate.triggerKey && v.customId === updatedTemplate.customId) {
                    return { ...v, ...updatedTemplate };
                }
                return v;
            });

            const updated: Integration = {
                ...previousIntegration,
                woocommerce: { ...previousIntegration.woocommerce, triggerDetails: updatedTriggers },
            };
            queryClient.setQueryData<Integration>(queryKey, updated);

            return previousIntegration;
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

//Get list of Logs

interface IntegrationLogProps {
    accountId?: string;
    integrationId?: string;
}

export const useGetIntegrationLogs = (props: IntegrationLogProps): UseInfiniteQueryResult<IntegrationLog[], Error> => {
    const { accountId, integrationId, ...options } = props;

    const PAGE_SIZE = 20;

    const infiniteQueryResult = useInfiniteQuery<IntegrationLog[], Error, IntegrationLog[], TQueryKey>(
        [QueryKey.IntegrationLogList, { accountId, integrationId }],
        ({ pageParam, queryKey }) => {
            const [, { accountId, providerName }] = queryKey;
            const queryParams = mapQueryParams({
                page: pageParam ?? 1,
                limit: PAGE_SIZE,
                providerName,
            });

            return fetcher(`/api/accounts/${accountId}/integrations/${integrationId}/logs?${queryParams}`);
        },
        {
            ...options,
            enabled: Boolean(accountId && integrationId),
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === PAGE_SIZE ? pages.length + 1 : undefined;
            },
        }
    );

    return infiniteQueryResult;
};

//use initiate FbLeads auth

interface UseInitiateFbleadsAuthProps {
    accountId: string;
    onSuccess?: (res: Oauth2ResultData) => void;
}

interface OauthFbleadsPostData {
    name: string;
}

type UsePostFbleadsOauth2Credential<InputType> = UseMutationResult<Oauth2ResultData, Error, InputType>;

export const useInitiateFbleadsAuth = (
    props: UseInitiateFbleadsAuthProps
): UsePostFbleadsOauth2Credential<OauthFbleadsPostData> => {
    const { accountId, onSuccess } = props;
    const toast = useToast();

    const fetchOAuth2URI = (data: OauthFbleadsPostData) => {
        return postJSON(`/api/accounts/${accountId}/integrations/fbleads/connect`, data);
    };

    const mutationResult = useMutation<Oauth2ResultData, Error, OauthFbleadsPostData>(fetchOAuth2URI, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
    });
    return mutationResult;
};

//use initiate FbLeads Re Auth

interface UseInitiateFbleadsAuthProps {
    accountId: string;
    onSuccess?: (res: Oauth2ResultData) => void;
}

interface ReAuthFbleadsPostData {
    integrationId: string;
}

type UsePostFbleadsReAuthCredential<InputType> = UseMutationResult<Oauth2ResultData, Error, InputType>;

export const useInitiateFbleadsReAuth = (
    props: UseInitiateFbleadsAuthProps
): UsePostFbleadsReAuthCredential<ReAuthFbleadsPostData> => {
    const { accountId, onSuccess } = props;
    const toast = useToast();

    const fetchOAuth2URI = (data: ReAuthFbleadsPostData) => {
        return postJSON(`/api/accounts/${accountId}/integrations/fbleads/${data.integrationId}/reconnect`, {});
    };

    const mutationResult = useMutation<Oauth2ResultData, Error, ReAuthFbleadsPostData>(fetchOAuth2URI, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
    });
    return mutationResult;
};

interface FbOptionProps {
    accountId: string;
    integrationId: string;
    resource: "page" | "form" | "field";
    pageId?: string;
    formId?: string;
}

export const useGetFbOptions = <T>(props: FbOptionProps): UseInfiniteQueryResult<T, Error> => {
    const { accountId, integrationId, resource, pageId, formId } = props;

    const infiniteQueryResult = useInfiniteQuery<T, Error, T, TQueryKey>(
        [QueryKey.FbLeadsOption, { accountId, integrationId, resource, pageId, formId }],
        ({ queryKey }) => {
            const [, { accountId, integrationId, resource, pageId, formId }] = queryKey;
            const queryParams = mapQueryParams({ resource, pageId, formId });
            return fetcher(`/api/accounts/${accountId}/integrations/fbleads/${integrationId}/options?${queryParams}`);
        }
    );

    return infiniteQueryResult;
};

//fbleads-register
interface useRegisterFbLeads {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
    showSuccessMessage?: boolean;
}

export interface RegisterFbLeads {
    pageLabel?: string;
    pageId?: string;
    formLabel?: string;
    formId?: string;
    isActive?: boolean;
    workflowId?: string;
    templateId?: string;
    recipientNumber?: string;
    recipientName?: string;
    templateValues?: Partial<Pick<WAITemplateMessage, "bodyValues" | "headerValues" | "buttonValues">>;
}

export const fbleadsActions = ["SendWATemplate", "RunAction"] as const;
type FbleadsActions = (typeof fbleadsActions)[number];

interface FbleadsSendWATemplateAction {
    templateId: string;
    templateValues?: WATemplateValues;
    recipientName?: string;
    recipientNumber?: string;
    bodyValues?: Record<string, string>;
}

export interface FbleadsRunAction {
    actionItem: Action;
    actionValues?: FactValues;
}

export interface IFBLeadsAction {
    type: FbleadsActions;
    SendWATemplate?: FbleadsSendWATemplateAction;
    RunAction?: FbleadsRunAction;
}

export interface IFBLeadsRegisterWebhookData {
    workflowId?: string;
    isActive?: boolean;
    pageId?: string;
    pageLabel?: string;
    formId?: string;
    formLabel?: string;
    actions?: IFBLeadsAction[];
}

type UsePatchFbLeadsIntegration<InputType> = UseMutationResult<Integration, Error, InputType>;

export const useRegisterFbLeads = <InputType extends Record<string, any> = IFBLeadsRegisterWebhookData>(
    props: useRegisterFbLeads
): UsePatchFbLeadsIntegration<InputType> => {
    const { accountId, onSuccess, integrationId, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const FbLeadsRegister = (data: InputType): Promise<Integration> => {
        return patchJSON<Integration>(`/api/accounts/${accountId}/integrations/fbleads/${integrationId}/register`, {
            ...data,
        } as unknown as any);
    };

    const mutationResult = useMutation<Integration, Error, InputType>(FbLeadsRegister, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: "FaceBook Leads Workflow Created",
                    status: "success",
                });
            }
            onSuccess?.();
        },

        onMutate: async (updateWorkflow: RegisterFbLeads) => {
            await queryClient.cancelQueries(queryKey);
            const previousWorkflow = queryClient.getQueryData<Integration>(queryKey);

            if (!previousWorkflow || !previousWorkflow.fbleads) return {};

            const updatedWorkflow = previousWorkflow.fbleads.workflows?.map((workflow) => {
                if (workflow.id === updateWorkflow.workflowId) {
                    return {
                        ...workflow,
                        ...(updateWorkflow.workflowId ? { isActive: updateWorkflow.isActive } : {}),
                        ...(updateWorkflow.pageId ? { pageId: updateWorkflow.pageId } : {}),
                        ...(updateWorkflow.formId ? { formId: updateWorkflow.formId } : {}),
                        ...(updateWorkflow.templateId
                            ? {
                                  actions: workflow.actions.map((v) =>
                                      v.type === "SendWATemplate"
                                          ? {
                                                ...v,
                                                SendWATemplate: { templateId: updateWorkflow.templateId as string },
                                            }
                                          : v
                                  ),
                              }
                            : {}),
                    };
                }
                return workflow;
            });

            const updated: Integration = {
                ...previousWorkflow,
                fbleads: {
                    ...previousWorkflow.fbleads,
                    workflows: updatedWorkflow,
                },
            };
            queryClient.setQueryData<Integration>(queryKey, updated);

            return previousWorkflow;
        },

        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

//use create webengage Integration

interface UseCreateWebEngageProps {
    accountId: string;
    onSuccess?: (res: Integration) => void;
}

interface WebEngagePostData {
    name: string;
}

type UsePostWebEngage = UseMutationResult<Integration, Error, WebEngagePostData>;

export const useCreateWebEngage = (props: UseCreateWebEngageProps): UsePostWebEngage => {
    const { accountId, onSuccess } = props;
    const toast = useToast();

    const createWebEngage = (data: WebEngagePostData) => {
        return postJSON(`/api/accounts/${accountId}/integrations/webengage/connect`, data);
    };

    const mutationResult = useMutation<Integration, Error, WebEngagePostData>(createWebEngage, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
    });
    return mutationResult;
};

//create moengage Integration
interface MoEngagePostData {
    brandName: string;
}

interface UseCreateMoEngageProps extends UseMutationOptions<Integration, Error, MoEngagePostData> {
    accountId: string;
    onSuccess?: (res: Integration) => void;
}

type UsePostMoEngage = UseMutationResult<Integration, Error, MoEngagePostData>;

export const useCreateMoEngage = (props: UseCreateMoEngageProps): UsePostMoEngage => {
    const { accountId, onSuccess } = props;
    const toast = useToast();

    const createMoEngage = (data: MoEngagePostData) => {
        return postJSON(`/api/accounts/${accountId}/integrations/moengage`, data);
    };

    const mutationResult = useMutation<Integration, Error, MoEngagePostData>(createMoEngage, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
    });
    return mutationResult;
};

//RazorPay
interface UseRazorPayConnectProps {
    accountId: string;
    onSuccess?: (res: IRazorPayIntegration) => void;
}
interface RazorPayConnectPostData {
    brandName: string;
}

type UsePostRazorPayRegister<InputType> = UseMutationResult<IRazorPayIntegration, Error, InputType>;

export const useRazorPayConnect = (
    props: UseRazorPayConnectProps
): UsePostRazorPayRegister<RazorPayConnectPostData> => {
    const { accountId, onSuccess } = props;
    const toast = useToast();

    const fetchRazorPay = (data: RazorPayConnectPostData) => {
        return postJSON(`/api/accounts/${accountId}/integrations/razorpay`, data);
    };

    const mutationResult = useMutation<IRazorPayIntegration, Error, RazorPayConnectPostData>(fetchRazorPay, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
    });
    return mutationResult;
};

//register & update RazorPay

interface registerRazorPay {
    triggerKey: RazorpayEventType;
    templateId?: string;
    isActive?: boolean;
    templateValues?: WATemplateValues;
}

interface useRegisterRazorPayProps {
    accountId: string;
    integrationId: string;
    showSuccessMessage?: boolean;
    onSuccess?: () => void;
}
type UsePatchRazorPayIntegration<InputType> = UseMutationResult<Integration, Error, InputType>;

export const useRegisterRazorPay = <InputType extends registerRazorPay>(
    props: useRegisterRazorPayProps
): UsePatchRazorPayIntegration<InputType> => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const RazorPayRegister = (data: InputType): Promise<Integration> => {
        return patchJSON<Integration>(`/api/accounts/${accountId}/integrations/razorpay/${integrationId}`, {
            ...data,
        } as unknown as any);
    };

    const mutationResult = useMutation<Integration, Error, InputType>(RazorPayRegister, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            if (showSuccessMessage) {
                toast({
                    title: "RazorPay Integration Updated",
                    status: "success",
                });
            }
            onSuccess?.();
        },
        onMutate: async (data) => {
            const previousWorkflow = queryClient.getQueryData<Integration>(queryKey);
            const updated = {
                ...previousWorkflow,
                razorpay: {
                    ...previousWorkflow?.razorpay,
                    triggers: {
                        ...previousWorkflow?.razorpay?.triggers,
                        [data.triggerKey]: { ...data },
                    },
                },
            };
            await queryClient.setQueryData(queryKey, updated);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

//register & update Woocommerce abandoncart

interface registerWCCartFlow {
    triggerKey: WCCartFlowWorkflowType;
    templateId?: string;
    isActive?: boolean;
    templateValues?: WATemplateValues;
    workflowActions?: PromptAction[];
}

interface registerWCabandonCart {
    triggerKey: WCAbandonedCartWorkflowType;
    templateId?: string;
    isActive?: boolean;
    templateValues?: WATemplateValues;
}

interface useRegisterWCCartFlowProps {
    accountId: string;
    integrationId: string;
    showSuccessMessage?: boolean;
    onSuccess?: () => void;
}

interface useRegisterWCabandonCartProps {
    accountId: string;
    integrationId: string;
    showSuccessMessage?: boolean;
    onSuccess?: () => void;
}
type UsePatchWCCartFlowIntegration<InputType> = UseMutationResult<Integration, Error, InputType>;

export const useRegisterWCCartFlow = <InputType extends registerWCCartFlow>(
    props: useRegisterWCCartFlowProps
): UsePatchWCCartFlowIntegration<InputType> => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const WCCartFlowRegister: MutationFunction<Integration, InputType> = (data: InputType) => {
        return patchJSON<Integration>(`/api/accounts/${accountId}/integrations/woocommerce-cartflow/${integrationId}`, {
            ...data,
        } as unknown as any);
    };

    const mutationResult = useMutation<Integration, Error, InputType>(WCCartFlowRegister, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (data) => {
            if (showSuccessMessage) {
                toast({
                    title: "Abandon Cart Integration Updated",
                    status: "success",
                });
            }
            const previousWorkflow = queryClient.getQueryData<Integration>(queryKey);
            const updated = {
                ...previousWorkflow,
                woocommerce_cartflow: {
                    ...data?.woocommerce_cartflow,
                },
            };
            queryClient.setQueryData(queryKey, updated);
            onSuccess?.();
        },
    });

    return mutationResult;
};

type UsePatchWCabandonCartIntegration<InputType> = UseMutationResult<Integration, Error, InputType>;

export const useRegisterWCabandonCart = <InputType extends registerWCabandonCart>(
    props: useRegisterWCabandonCartProps
): UsePatchWCabandonCartIntegration<InputType> => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const WCabandonCartRegister: MutationFunction<Integration, InputType> = (data: InputType) => {
        return patchJSON<InputType, Integration>(
            `/api/accounts/${accountId}/integrations/woocommerce-abandoned-cart/${integrationId}`,
            {
                ...data,
            }
        );
    };

    const mutationResult = useMutation<Integration, Error, InputType>(WCabandonCartRegister, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (data) => {
            if (showSuccessMessage) {
                toast({
                    title: "Abandon Cart Integration Updated",
                    status: "success",
                });
            }
            const previousWorkflow = queryClient.getQueryData<Integration>(queryKey);
            const updated = {
                ...previousWorkflow,
                woocommerce_abandoned_cart: {
                    ...data?.woocommerce_abandoned_cart,
                },
            };
            queryClient.setQueryData(queryKey, updated);
            onSuccess?.();
        },
    });

    return mutationResult;
};

//delete Integration

type UseDeleteTag = UseMutationResult<boolean, Error, void, unknown>;

interface DeleteIntegrationProps {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

export const useDeleteIntegration = (props: DeleteIntegrationProps): UseDeleteTag => {
    const { accountId, integrationId, onSuccess } = props;
    const queryClient = useQueryClient();
    const toast = useToast({ isClosable: true, duration: 2000 });

    const queryKey: string | unknown[] = [QueryKey.IntegrationFieldsList, { accountId }];

    const deleteIntegration: MutationFunction<boolean> = () => {
        return deleteJSON<boolean>(`/api/accounts/${accountId}/integrations/${integrationId}`);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<boolean, Error>(deleteIntegration, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedField, context) => {
            queryClient.setQueryData(queryKey, context);
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while deleting Integration",
            });
        },
        onSuccess: () => {
            toast({
                title: "Integration  Deleted",
                status: "success",
                description: "Integration deleted successfully!",
            });
            onSuccess?.();
        },
        onMutate: async () => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousTag = queryClient.getQueryData<boolean>(queryKey);

            // Optionally return a context containing data to use when for example rolling back
            return previousTag ?? [[]];
        },

        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface UseInitiateHubspoteAuthProps {
    accountId: string;
    onSuccess?: (res: Oauth2ResultData) => void;
}
interface OauthHubspotPostData {
    name: string;
}

type UsePostHubspotOauth2Credential<InputType> = UseMutationResult<Oauth2ResultData, Error, InputType>;

export const useInitiateHubspotAuth = (
    props: UseInitiateHubspoteAuthProps
): UsePostHubspotOauth2Credential<OauthHubspotPostData> => {
    const { accountId, onSuccess } = props;
    const toast = useToast();

    const fetchOAuth2URI = (data: OauthHubspotPostData) => {
        return postJSON(`/api/accounts/${accountId}/integrations/hubspot/connect`, data);
    };

    const mutationResult = useMutation<Oauth2ResultData, Error, OauthHubspotPostData>(fetchOAuth2URI, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
    });
    return mutationResult;
};

//initiate RazorPay OAuth

interface RazorPayOAuthProps extends UseMutationOptions<string, Error, RazorPayOauthInput> {
    accountId: string;
    onSuccess?: () => void;
}

interface RazorPayOauthInput {
    brandName: string;
}

type RazorPayOauthResult = UseMutationResult<string, Error, RazorPayOauthInput>;

export const useRazorPayOAuth = (props: RazorPayOAuthProps): RazorPayOauthResult => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const fetchRazorPayOAuth = (data: RazorPayOauthInput) => {
        return postJSON(`/api/accounts/${accountId}/integrations/razorpay/connect`, data);
    };

    const result = useMutation<string, Error, RazorPayOauthInput>(fetchRazorPayOAuth, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
        ...options,
    });

    return result;
};

// Revoke RazorPay OAuth

type UseRevokeRazorPay = UseMutationResult<boolean, Error, void, unknown>;

interface UseRevokeAuthProps {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

export const useRevokeAuth = (props: UseRevokeAuthProps): UseRevokeRazorPay => {
    const { accountId, integrationId, onSuccess } = props;

    const queryClient = useQueryClient();
    const toast = useToast({ isClosable: true, duration: 2000 });

    const queryKey: string | unknown[] = [QueryKey.IntegrationFieldsList, { accountId, integrationId }];

    const deleteIntegration: MutationFunction<boolean> = () => {
        return deleteJSON<boolean>(`/api/accounts/${accountId}/integrations/razorpay/${integrationId}/revoke`);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const mutationResult = useMutation<boolean, Error>(deleteIntegration, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _updatedField, context) => {
            queryClient.setQueryData(queryKey, context);
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while deleting Integration",
            });
        },
        onSuccess: () => {
            toast({
                title: "Integration  Deleted",
                status: "success",
                description: "Integration deleted successfully!",
            });
            onSuccess?.();
        },
        onMutate: async () => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            // Snapshot the previous value
            const previousTag = queryClient.getQueryData<boolean>(queryKey);

            // Optionally return a context containing data to use when for example rolling back
            return previousTag ?? [[]];
        },

        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

//connect leadsquared integration

interface ConnectLeadSquaredIntegrationProps
    extends UseMutationOptions<ConnectLeadSquared, Error, ConnectLeadSquaredIntegrationInput> {
    accountId: string;
    onSuccess?: (data: ConnectLeadSquared) => void;
}

type ConnectLeadSquaredIntegrationResponse = UseMutationResult<
    ConnectLeadSquared,
    Error,
    ConnectLeadSquaredIntegrationInput
>;

export const useConnectLeadSquaredIntegration = (
    props: ConnectLeadSquaredIntegrationProps
): ConnectLeadSquaredIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectLsq: MutationFunction<ConnectLeadSquared, ConnectLeadSquaredIntegrationInput> = (
        data: ConnectLeadSquaredIntegrationInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/leadsquared`, data);
    };

    const mutationResult = useMutation<ConnectLeadSquared, Error, ConnectLeadSquaredIntegrationInput>(connectLsq, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while Connecting Integration",
            });
        },
        onSuccess: (data) => {
            toast({
                title: "Success",
                status: "success",
                description: "Integration Created",
            });
            onSuccess?.(data);
        },
        ...options,
    });

    return mutationResult;
};

//connect clever integration

interface ConnectCleverTapIntegrationProps
    extends UseMutationOptions<ConnectCleverTap, Error, ConnectCleverTapIntegrationInput> {
    accountId: string;
    onSuccess?: (data: ConnectCleverTap) => void;
}

type ConnectCleverTapIntegrationResponse = UseMutationResult<ConnectCleverTap, Error, ConnectCleverTapIntegrationInput>;

export const useConnectCleverTapIntegration = (
    props: ConnectCleverTapIntegrationProps
): ConnectCleverTapIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectCleverTap: MutationFunction<ConnectCleverTap, ConnectCleverTapIntegrationInput> = (
        data: ConnectCleverTapIntegrationInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/clevertap`, data);
    };

    const mutationResult = useMutation<ConnectCleverTap, Error, ConnectCleverTapIntegrationInput>(connectCleverTap, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while Connecting Integration",
            });
        },
        onSuccess: (data) => {
            toast({
                title: "Success",
                status: "success",
                description: "Integration Created",
            });
            onSuccess?.(data);
        },
        ...options,
    });

    return mutationResult;
};

//connect ship-rocket integration
interface ConnectShipRocketInput {
    brandName: string;
    email: string;
    password: string;
}

interface UseConnectShipRocket extends UseMutationOptions<Integration, Error, ConnectShipRocketInput> {
    accountId: string;
}

export type UseShipRocketResult = UseMutationResult<Integration, Error, ConnectShipRocketInput>;

export const useConnectShipRocket = (props: UseConnectShipRocket): UseShipRocketResult => {
    const { accountId, ...options } = props;
    const toast = useToast();

    const connectShipRocket = (data: ConnectShipRocketInput) => {
        return postJSON(`/api/accounts/${accountId}/integrations/shiprocket`, { ...data });
    };

    const mutationResult = useMutation<Integration, Error, ConnectShipRocketInput>(connectShipRocket, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            toast({
                title: "Success",
                status: "success",
                description: "ShipRocket Integration Created successfully",
            });
        },
        ...options,
    });

    return mutationResult;
};

//connect stripe integration

interface ConnectStripeIntegrationProps
    extends UseMutationOptions<ConnectStripe, Error, ConnectStripeIntegrationInput> {
    accountId: string;
    onSuccess?: (data: ConnectStripe) => void;
}

type ConnectStripeIntegrationResponse = UseMutationResult<ConnectStripe, Error, ConnectStripeIntegrationInput>;

export const useConnectStripeIntegration = (props: ConnectStripeIntegrationProps): ConnectStripeIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectStripe: MutationFunction<ConnectStripe, ConnectStripeIntegrationInput> = (
        data: ConnectStripeIntegrationInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/stripe/create`, data);
    };

    const mutationResult = useMutation<ConnectStripe, Error, ConnectStripeIntegrationInput>(connectStripe, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while Connecting Integration",
            });
        },
        onSuccess: (data) => {
            toast({
                title: "Success",
                status: "success",
                description: "Integration Created",
            });
            onSuccess?.(data);
        },
        ...options,
    });

    return mutationResult;
};

//update LSQ configuration

interface UpdateLsqConfigurationProps extends UseMutationOptions<Integration, Error, UpdateLsqConfigInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}
//update trigger

interface RegisterShipRocket {
    triggerKey: ShiprocketStatusNameType;
    templateId?: string;
    isActive?: boolean;
    templateValues?: WATemplateValues;
    workflowActions?: PromptAction[];
}

interface UpdateShipRocketWorkFlowProps extends UseMutationOptions<Integration, Error, RegisterShipRocket> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

//update triggers

type UpdateLsqResponse = UseMutationResult<Integration, Error, UpdateLsqConfigInput>;

export const useUpdateLsqConfiguration = (props: UpdateLsqConfigurationProps): UpdateLsqResponse => {
    const { integrationId, accountId, onSuccess, ...options } = props;
    const queryClient = useQueryClient();
    const toast = useToast();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateLsqConfig: MutationFunction<Integration, UpdateLsqConfigInput> = (data: UpdateLsqConfigInput) => {
        return patchJSON(
            `api/accounts/${accountId}/integrations/leadsquared/${integrationId}?populatePaths=channel`,
            data
        );
    };

    const response = useMutation<Integration, Error, UpdateLsqConfigInput>(updateLsqConfig, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while updating  Integration",
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData<Integration>(queryKey, data);
            toast({
                title: "Integration  Updated",
                status: "success",
                description: "Integration Updated successfully!",
            });
            onSuccess?.();
        },

        ...options,
    });

    return response;
};

///api/accounts/{{accountId}}/integrations/leadsquared/63c8de0410393bb6cec75030

interface UseDeleteLsqIntegrationProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
}

export const useDeleteLsqIntegration = (props: UseDeleteLsqIntegrationProps) => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();

    const deleteLsqIntegration: MutationFunction<Integration, void> = () => {
        return deleteJSON(`/api/accounts/${accountId}/integrations/leadsquared/${integrationId}`);
    };

    const deleteResult = useMutation<Integration, Error, void>(deleteLsqIntegration, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message,
            });
        },

        ...rest,
    });
    return deleteResult;
};

//update LSQ configuration

interface UpdateCleverTapConfigurationProps extends UseMutationOptions<Integration, Error, UpdateCleverTapConfigInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type UpdateCleverTapResponse = UseMutationResult<Integration, Error, UpdateCleverTapConfigInput>;

export const useUpdateCleverTapConfiguration = (props: UpdateCleverTapConfigurationProps): UpdateCleverTapResponse => {
    const { integrationId, accountId, onSuccess, ...options } = props;
    const queryClient = useQueryClient();
    const toast = useToast();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateCleverTapConfig: MutationFunction<Integration, UpdateCleverTapConfigInput> = (
        data: UpdateCleverTapConfigInput
    ) => {
        return patchJSON(
            `api/accounts/${accountId}/integrations/${integrationId}/clevertap?populatePaths=channel`,
            data
        );
    };

    const response = useMutation<Integration, Error, UpdateCleverTapConfigInput>(updateCleverTapConfig, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while updating  Integration",
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData<Integration>(queryKey, data);
            toast({
                title: "Integration  Updated",
                status: "success",
                description: "Integration Updated successfully!",
            });
            onSuccess?.();
        },

        ...options,
    });

    return response;
};

interface UseDeleteCleverTapIntegrationProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
}

export const useDeleteCleverTapIntegration = (props: UseDeleteCleverTapIntegrationProps) => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();

    const deleteCleverTapIntegration: MutationFunction<Integration, void> = () => {
        return deleteJSON(`/api/accounts/${accountId}/integrations/${integrationId}/clevertap`);
    };

    const deleteResult = useMutation<Integration, Error, void>(deleteCleverTapIntegration, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                title: "Deleted Successfully",
                description: "CleverTap integration Deleted Successfully",
            });
        },
        ...rest,
    });
    return deleteResult;
};

//update Stripe configuration

interface UpdateStripeConfigurationProps extends UseMutationOptions<Integration, Error, UpdateStripeConfigInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type UpdateStripeResponse = UseMutationResult<Integration, Error, UpdateStripeConfigInput>;

export const useUpdateStripeConfiguration = (props: UpdateStripeConfigurationProps): UpdateStripeResponse => {
    const { integrationId, accountId, onSuccess, ...options } = props;
    const queryClient = useQueryClient();
    const toast = useToast();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateStripeConfig: MutationFunction<Integration, UpdateStripeConfigInput> = (
        data: UpdateStripeConfigInput
    ) => {
        return patchJSON(`api/accounts/${accountId}/integrations/${integrationId}/stripe?populatePaths=channel`, data);
    };

    const response = useMutation<Integration, Error, UpdateStripeConfigInput>(updateStripeConfig, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while updating  Integration",
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData<Integration>(queryKey, data);
            toast({
                title: "Integration  Updated",
                status: "success",
                description: "Integration Updated successfully!",
            });
            onSuccess?.();
        },

        ...options,
    });

    return response;
};

interface UseDeleteStripeIntegrationProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
}

export const useDeleteStripeIntegration = (props: UseDeleteStripeIntegrationProps) => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();

    const deleteStripeIntegration: MutationFunction<Integration, void> = () => {
        return deleteJSON(`/api/accounts/${accountId}/integrations/${integrationId}/stripe`);
    };

    const deleteResult = useMutation<Integration, Error, void>(deleteStripeIntegration, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                title: "Deleted Successfully",
                description: "Stripe integration Deleted Successfully",
            });
        },
        ...rest,
    });
    return deleteResult;
};

//Update WebEngage Configuration

interface UseUpdateWebEngageProps extends UseMutationOptions<Integration, Error, UpdateWebEngageInput> {
    accountId: string;
    integrationId: string;
}

// re-capture response webhook integration

type ReCaptureResponse = UseMutationResult<Integration, Error, void>;

interface UseRecaptureResponse extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
}

export interface UpdateWebEngageInput {
    name: string;
    statusUrl: string;
}

type UpdateWebEngageIntegration = UseMutationResult<Integration, Error, UpdateWebEngageInput>;

export const useUpdateWebEngage = (props: UseUpdateWebEngageProps): UpdateWebEngageIntegration => {
    const { accountId, integrationId } = props;
    const toast = useToast();

    const updateWebEngage: MutationFunction<Integration, UpdateWebEngageInput> = (data: UpdateWebEngageInput) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/webengage/${integrationId}`, data);
    };

    const mutationResult = useMutation<Integration, Error, UpdateWebEngageInput>(updateWebEngage, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error?.message,
            });
        },
        onSuccess: () => {
            toast({
                title: "Configuration  Updated",
                status: "success",
                description: "Configuration Updated successfully!",
            });
        },
    });

    return mutationResult;
};

//Update MoEngage Configuration

interface UseUpdateMoEngageProps extends UseMutationOptions<Integration, Error, UpdateMoEngageInput> {
    accountId: string;
    integrationId: string;
}
export interface UpdateMoEngageInput {
    brandName: string;
    statusUrl: string;
    channelId?: string;
}

type UpdateMoEngageIntegration = UseMutationResult<Integration, Error, UpdateMoEngageInput>;

export const useUpdateMoEngage = (props: UseUpdateMoEngageProps): UpdateMoEngageIntegration => {
    const { accountId, integrationId } = props;
    const toast = useToast();
    const queryClient = useQueryClient();

    const updateMoEngage: MutationFunction<Integration, UpdateMoEngageInput> = (data: UpdateMoEngageInput) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/${integrationId}/moengage`, data);
    };

    const mutationResult = useMutation<Integration, Error, UpdateMoEngageInput>(updateMoEngage, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error?.message,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries([QueryKey.Integration, { accountId, integrationId }]);
            toast({
                title: "Configuration  Updated",
                status: "success",
                description: "Configuration Updated successfully!",
            });
        },
    });

    return mutationResult;
};

export const useRecaptureResponse = (props: UseRecaptureResponse): ReCaptureResponse => {
    const { accountId, integrationId, ...options } = props;
    const toast = useToast();

    const reCaptureResponse: MutationFunction<Integration, void> = () => {
        return postJSON(
            `/api/accounts/${accountId}/integrations/${integrationId}/genericWebhook/recaptureResponse`,
            {}
        );
    };

    const reCaptureResponseResult = useMutation<Integration, Error, void>(reCaptureResponse, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        ...options,
    });

    return reCaptureResponseResult;
};
//Delete Generic Webhook Workflow

interface UseDeleteWorkFlowProps {
    accountId: string;
    integrationId: string;
    workflowId: string;
}

type DeleteWorkFlow = UseMutationResult<Integration, Error, void>;

export const useDeleteWorkFlow = (props: UseDeleteWorkFlowProps): DeleteWorkFlow => {
    const { accountId, integrationId, workflowId } = props;

    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const deleteWorkFlow: MutationFunction<Integration, void> = () => {
        return deleteJSON(
            `api/accounts/${accountId}/integrations/${integrationId}/genericWebhook/workflow/${workflowId}/delete`
        );
    };

    const mutationResult = useMutation<Integration, Error, void>(deleteWorkFlow, {
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },

        onSuccess: () => {
            toast({
                title: "WorkFlow Deleted",
                status: "success",
                description: "WorkFlow Deleted successfully!",
            });
        },

        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

type CreateWorkFlow = UseMutationResult<Integration, Error, GenericWebhookWorkFlow>;

interface CreateWebHookWorkFlowProps extends UseMutationOptions<Integration, Error, GenericWebhookWorkFlow> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

export const useCreateWebHookWorkFlow = (props: CreateWebHookWorkFlowProps): CreateWorkFlow => {
    const { accountId, integrationId, onSuccess, ...options } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey = [QueryKey.Integration, { accountId, integrationId }];

    const createWorkflow: MutationFunction<Integration, GenericWebhookWorkFlow> = (data: GenericWebhookWorkFlow) => {
        return postJSON(`/api/accounts/${accountId}/integrations/${integrationId}/genericWebhook/workflow`, {
            ...data,
        });
    };

    const response = useMutation<Integration, Error, GenericWebhookWorkFlow>(createWorkflow, {
        onError: (err) => {
            toast({
                status: "error",
                title: "WorkFlow Creation Failed",
                description: err.message ?? "WorkFlow Creation Failed",
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData<Integration>(queryKey, data);
            toast({
                status: "success",
                title: "WorkFlow Created",
                description: "WorkFlow Created",
            });
            onSuccess?.();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
        ...options,
    });

    return response;
};

type UpdateWebHookWorkFlow = UseMutationResult<Integration, Error, GenericWebhookWorkFlow>;

interface UpdateWebHookWorkFlowProps extends UseMutationOptions<Integration, Error, GenericWebhookWorkFlow> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

export const useUpdateWebHookWorkFlow = (props: UpdateWebHookWorkFlowProps): UpdateWebHookWorkFlow => {
    const { accountId, integrationId, onSuccess, ...options } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey = [QueryKey.Integration, { accountId, integrationId }];

    const updateWorkFlow: MutationFunction<Integration, GenericWebhookWorkFlow> = (data: GenericWebhookWorkFlow) => {
        return patchJSON(
            `/api/accounts/${accountId}/integrations/${integrationId}/genericWebhook/workflow/${data?.id}`,
            data
        );
    };

    const response = useMutation<Integration, Error, GenericWebhookWorkFlow>(updateWorkFlow, {
        onError: (err) => {
            toast({
                status: "error",
                title: "WorkFlow Update Failed",
                description: err.message ?? "WorkFlow Update Failed",
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData<Integration>(queryKey, data);
            toast({
                status: "success",
                title: "WorkFlow Updated",
                description: "WorkFlow Updated",
            });
            onSuccess?.();
        },

        ...options,
    });

    return response;
};

interface CreateGenericWebHookInput {
    brandName: string;
}

type CreateGenericWebHook = UseMutationResult<Integration, Error, CreateGenericWebHookInput>;

interface CreateGenericWebhookProps extends UseMutationOptions<Integration, Error, CreateGenericWebHookInput> {
    accountId: string;
    onSuccess?: (data: Integration) => void;
}

export const useCreateGenericWebhook = (props: CreateGenericWebhookProps): CreateGenericWebHook => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const createWebHook = (data: CreateGenericWebHookInput) => {
        return postJSON(`/api/accounts/${accountId}/integrations/genericWebhook`, { ...data });
    };

    const response = useMutation<Integration, Error, CreateGenericWebHookInput>(createWebHook, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Create  Integration Failed",
                description: err?.message ?? "Create  Integration Failed",
            });
        },
        onSuccess: (data) => {
            toast({
                status: "success",
                title: "Integration Created",
                description: "Integration Created",
            });
            onSuccess?.(data);
        },

        ...options,
    });

    return response;
};

type UpdateShipRocketTrigger = UseMutationResult<Integration, Error, RegisterShipRocket>;

export const useUpdateShipRocketWorkFlow = (props: UpdateShipRocketWorkFlowProps): UpdateShipRocketTrigger => {
    const { accountId, integrationId, onSuccess, ...options } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateShipRocket: MutationFunction<Integration, RegisterShipRocket> = (data: RegisterShipRocket) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/shiprocket/${integrationId}`, { ...data });
    };

    const mutationResult = useMutation<Integration, Error, RegisterShipRocket>(updateShipRocket, {
        onError: (error) => {
            toast({ title: "Error", status: "error", description: error?.message });
        },
        onSuccess: () => {
            onSuccess?.();
            toast({ title: "Success", status: "success", description: "ShipRocket Updated Successfully" });
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },

        ...options,
    });

    return mutationResult;
};

//delete Cartflow Integration

type UseDeleteCartflowMutationOptions = UseMutationOptions<boolean, Error, void>;
type UseDeleteCartflowMutationResult = UseMutationResult<boolean, Error, void>;

//delete ShipRocket Integration

type UseDeleteShipRocketMutationResult = UseMutationResult<boolean, Error, void>;
type UseDeleteShipRocketMutationOptions = UseMutationOptions<boolean, Error, void>;

interface UseConnectCashFreeIntegrationInput {
    brandName: string;
}

interface UseConnectCashFreeProps
    extends UseMutationOptions<CashFreeConnect, Error, UseConnectCashFreeIntegrationInput> {
    accountId: string;
    onSuccess?: (data: CashFreeConnect) => void;
}

type ConnectCashFreeIntegrationResponse = UseMutationResult<CashFreeConnect, Error, UseConnectCashFreeIntegrationInput>;

export const useConnectCashFreeIntegration = (props: UseConnectCashFreeProps): ConnectCashFreeIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectCashfree: MutationFunction<CashFreeConnect, UseConnectCashFreeIntegrationInput> = (
        data: UseConnectCashFreeIntegrationInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/cashfree`, data);
    };
    const responseResult = useMutation<CashFreeConnect, Error, UseConnectCashFreeIntegrationInput>(connectCashfree, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message ?? "Error while Connecting Integration",
            });
        },
        onSuccess: (data) => {
            toast({
                title: "Integration  Connected",
                status: "success",
                description: "Integration Connected successfully!",
            });
            onSuccess?.(data);
        },
        ...options,
    });

    return responseResult;
};

//update LSQ configuration

interface UpdateTriggersInput {
    templateId: string;
    templateValues?: WATemplateValues;
    triggerKey: CashFreeEventType;
    isActive: boolean;
}

interface UseUpdateCashFreeTriggers extends UseMutationOptions<Integration, Error, UpdateTriggersInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

interface DeleteShipRocketProps extends UseDeleteShipRocketMutationOptions {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

export const useDeleteShipRocket = (props: DeleteShipRocketProps): UseDeleteShipRocketMutationResult => {
    const { accountId, integrationId, onSuccess, ...options } = props;
    const toast = useToast();

    const deleteShipRocketIntegration: MutationFunction<boolean, void> = () => {
        return deleteJSON(`api/accounts/${accountId}/integrations/shiprocket/${integrationId}`);
    };

    const mutationResult = useMutation<boolean, Error, void>(deleteShipRocketIntegration, {
        onSuccess: () => {
            onSuccess?.();
            toast({ title: "Success", status: "success", description: "ShipRocket Deleted Successfully" });
        },
        onError: () => {
            toast({ title: "Error", status: "error", description: "Error" });
        },
        ...options,
    });

    return mutationResult;
};

interface DeleteCartflowProps extends UseDeleteCartflowMutationOptions {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

export const useDeleteCartflow = (props: DeleteCartflowProps): UseDeleteCartflowMutationResult => {
    const { accountId, integrationId, onSuccess, ...options } = props;
    const toast = useToast();

    const deleteCartflowIntegration: MutationFunction<boolean, void> = () => {
        return deleteJSON(`api/accounts/${accountId}/integrations/woocommerce-cartflow/${integrationId}`);
    };

    const mutationResult = useMutation<boolean, Error, void>(deleteCartflowIntegration, {
        onSuccess: () => {
            onSuccess?.();
            toast({
                title: "Success",
                status: "success",
                description: "Woocommerce Cartflow integration Deleted Successfully",
            });
        },
        onError: () => {
            toast({ title: "Error", status: "error", description: "Error" });
        },
        ...options,
    });

    return mutationResult;
};

type UpdateCashFreeTriggersResponse = UseMutationResult<Integration, Error, UpdateTriggersInput>;

export const useUpdateCashFreeTriggers = (props: UseUpdateCashFreeTriggers): UpdateCashFreeTriggersResponse => {
    const { accountId, integrationId, onSuccess, ...rest } = props;
    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateCashFreeTriggers: MutationFunction<Integration, UpdateTriggersInput> = (data: UpdateTriggersInput) => {
        return putJSON(`/api/accounts/${accountId}/integrations/cashfree/${integrationId}`, data);
    };

    const responseUpdatedTriggers = useMutation<Integration, Error, UpdateTriggersInput>(updateCashFreeTriggers, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            toast({
                title: "Integration Registered successfully",
                status: "success",
                description: "Integration Registered successfully!",
            });
            onSuccess?.();
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
        ...rest,
    });

    return responseUpdatedTriggers;
};

//connect WC cartflow integration

interface ConnectCartFlowInput {
    brandName: string;
    shopUrl: string;
}

interface ConnectCartFlowResponse {
    uri: string;
}
interface ConnectCartFlowIntegrationProps
    extends UseMutationOptions<ConnectCartFlowResponse, Error, ConnectCartFlowInput> {
    accountId: string;
    onSuccess?: (data: ConnectCartFlowResponse) => void;
}

type ConnectCartFlowIntegrationResponse = UseMutationResult<ConnectCartFlowResponse, Error, ConnectCartFlowInput>;

export const useConnectWCCartFlowIntegration = (
    props: ConnectCartFlowIntegrationProps
): ConnectCartFlowIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectWCCartFlow: MutationFunction<ConnectCartFlowResponse, ConnectCartFlowInput> = (
        data: ConnectCartFlowInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/woocommerce-cartflow/connect`, data);
    };

    const mutationResult = useMutation<ConnectCartFlowResponse, Error, ConnectCartFlowInput>(connectWCCartFlow, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while Connecting Integration",
            });
        },
        onSuccess: (data) => {
            onSuccess?.(data);
        },
        ...options,
    });

    return mutationResult;
};

//create WC cartflow integration

interface CreateCartFlowInput {
    brandName: string;
    credentialId: string;
}

interface CreateCartFlowIntegrationProps
    extends UseMutationOptions<IWCCartFlowIntegration, Error, CreateCartFlowInput> {
    accountId: string;
    onSuccess?: (data: IWCCartFlowIntegration) => void;
}

type CreateCartFlowIntegrationResponse = UseMutationResult<IWCCartFlowIntegration, Error, CreateCartFlowInput>;

export const useCreateWCCartFlowIntegration = (
    props: CreateCartFlowIntegrationProps
): CreateCartFlowIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectWCCartFlow: MutationFunction<IWCCartFlowIntegration, CreateCartFlowInput> = (
        data: CreateCartFlowInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/woocommerce-cartflow/create`, data);
    };

    const mutationResult = useMutation<IWCCartFlowIntegration, Error, CreateCartFlowInput>(connectWCCartFlow, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while Connecting Integration",
            });
        },
        onSuccess: (data) => {
            onSuccess?.(data);
        },
        ...options,
    });

    return mutationResult;
};
//connect abandoned cart integration

interface ConnectAbandonCartIntegrationProps
    extends UseMutationOptions<IWCAbandonedCartIntegration, Error, ConnectAbandonCartIntegrationInput> {
    accountId: string;
    onSuccess?: (data: IWCAbandonedCartIntegration) => void;
}

type ConnectAbandonCartIntegrationResponse = UseMutationResult<
    IWCAbandonedCartIntegration,
    Error,
    ConnectAbandonCartIntegrationInput
>;

export const useConnectAbandonCartIntegration = (
    props: ConnectAbandonCartIntegrationProps
): ConnectAbandonCartIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectLsq: MutationFunction<IWCAbandonedCartIntegration, ConnectAbandonCartIntegrationInput> = (
        data: ConnectAbandonCartIntegrationInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/woocommerce-abandoned-cart`, data);
    };

    const mutationResult = useMutation<IWCAbandonedCartIntegration, Error, ConnectAbandonCartIntegrationInput>(
        connectLsq,
        {
            onError: (err) => {
                toast({
                    title: "Error",
                    status: "error",
                    description: err.message ?? "Error while Connecting Integration",
                });
            },
            onSuccess: (data) => {
                toast({
                    title: "Integration  Connected",
                    status: "success",
                    description: "Integration Connected successfully!",
                });
                onSuccess?.(data);
            },
            ...options,
        }
    );

    return mutationResult;
};

//Shipway Integration

export interface UseConnectShipwayProps extends UseMutationOptions<Integration, Error, ShipwayData> {
    accountId: string;
}

type UseShipwayConnectResult = UseMutationResult<Integration, Error, ShipwayData>;

export interface ShipwayData {
    brandName: string;
    userName: string;
    password: string;
}

export const useConnectShipway = (props: UseConnectShipwayProps): UseShipwayConnectResult => {
    const { accountId, ...options } = props;
    const toast = useToast();

    const connectShipway: MutationFunction<Integration, ShipwayData> = (data: ShipwayData) => {
        return postJSON(
            `api/accounts/${accountId}/integrations/shipway
        `,
            data
        );
    };

    const result = useMutation<Integration, Error, ShipwayData>(connectShipway, {
        ...options,
    });

    return result;
};

//Connect Calendly

interface UseConnectCalendlyProps extends UseMutationOptions<string, Error, CalendlyConnectForm> {
    accountId: string;
    onSuccess?: (data: string) => void;
}

type CalendlyResponse = UseMutationResult<string, Error, CalendlyConnectForm>;

export const useConnectCalendly = (props: UseConnectCalendlyProps): CalendlyResponse => {
    const { accountId, onSuccess, ...rest } = props;
    const toast = useToast();

    const connectCalendly: MutationFunction<string, CalendlyConnectForm> = (data: CalendlyConnectForm) => {
        return postJSON(`/api/accounts/${accountId}/integrations/calendly/connect`, data);
    };

    const connectionResult = useMutation<string, Error, CalendlyConnectForm>(connectCalendly, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess,
        ...rest,
    });

    return connectionResult;
};

//create workFlow Calendly

export interface UseCreateCalendlyWorkFlowProps extends UseMutationOptions<Integration, Error, CalendlyWorkFlowInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: (data: Integration) => void;
}

export interface CalendlyWorkFlowInput
    extends Omit<
        CalendlyWorkflow,
        "id" | "templateValues" | "events" | "eventTypeUri" | "duration" | "remind" | "useCase" | "recipient"
    > {
    templateValues: Pick<WAITemplateMessage, "bodyValues" | "headerValues" | "buttonValues">;
    type: string;
    calendly?: Pick<CalendlyWorkflow, "events" | "eventTypeUri" | "duration" | "remind" | "useCase" | "recipient">;
}

type CalendlyWorkFlowResponse = UseMutationResult<Integration, Error, CalendlyWorkFlowInput>;

export const useCreateCalendlyWorkFlow = (props: UseCreateCalendlyWorkFlowProps): CalendlyWorkFlowResponse => {
    const { accountId, integrationId, onSuccess, ...rest } = props;
    const toast = useToast();
    const queryKey = [QueryKey.Integration, { accountId, integrationId }];
    const queryClient = useQueryClient();
    const createWorkflow: MutationFunction<Integration, CalendlyWorkFlowInput> = (data: CalendlyWorkFlowInput) => {
        return postJSON(`/api/accounts/${accountId}/integrations/${integrationId}/calendly/workflow`, {
            ...data,
            accountId,
            integrationId,
        });
    };

    const response = useMutation<Integration, Error, CalendlyWorkFlowInput>(createWorkflow, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData<Integration>(queryKey, data);
            toast({
                title: "Workflow Created successfully",
                status: "success",
                description: "Successfully Created",
            });
            onSuccess?.(data);
        },
        ...rest,
    });

    return response;
};

export interface UseUpdateCalendlyWorkFlowProps
    extends UseMutationOptions<Integration, Error, Partial<CalendlyWorkflow>> {
    accountId: string;
    integrationId: string;
}

type UpdateCalendlyWorkFlowResponse = UseMutationResult<Integration, Error, Partial<CalendlyWorkflow>>;

export const useUpdateCalendlyWorkFlow = (props: UseUpdateCalendlyWorkFlowProps): UpdateCalendlyWorkFlowResponse => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();
    const queryKey = [QueryKey.Integration, { accountId, integrationId }];
    const queryClient = useQueryClient();

    const updateCalendlyWorkFlow: MutationFunction<Integration, Partial<CalendlyWorkflow>> = (
        data: Partial<CalendlyWorkflow>
    ) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/${integrationId}/calendly/workflow/${data.id}`, data);
    };

    const mutationResult = useMutation<Integration, Error, Partial<CalendlyWorkflow>>(updateCalendlyWorkFlow, {
        onSuccess: () => {
            toast({
                title: "Workflow Updated successfully",
                status: "success",
                description: "Successfully Updated",
            });
        },
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
        ...rest,
    });
    return mutationResult;
};

//Delete Shipway Integration

interface DeleteShipwayProps extends UseDeleteShipRocketMutationOptions {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

export const useDeleteShipway = (props: DeleteShipwayProps): UseDeleteShipRocketMutationResult => {
    const { accountId, integrationId, onSuccess, ...options } = props;
    const toast = useToast();

    const deleteShipwayIntegration: MutationFunction<boolean, void> = () => {
        return deleteJSON(`api/accounts/${accountId}/integrations/shipway/${integrationId}`);
    };

    const mutationResult = useMutation<boolean, Error, void>(deleteShipwayIntegration, {
        onSuccess: () => {
            onSuccess?.();
            toast({ title: "Success", status: "success", description: "Shipway Deleted Successfully" });
        },
        onError: () => {
            toast({ title: "Error", status: "error", description: "Error" });
        },
        ...options,
    });

    return mutationResult;
};

//update Shipway Integration

interface UpdateShipwayInput {
    triggerKey: ShipwayStatusCode;
    templateId: string;
    isActive: boolean;
    templateValues?: WATemplateValues;
}

interface UpdateShipwayIntegrationProps extends UseMutationOptions<Integration, Error, UpdateShipwayInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type UpdateShipwayIntegrationResult = UseMutationResult<Integration, Error, UpdateShipwayInput>;

export const useUpdateShipwayIntegration = (props: UpdateShipwayIntegrationProps): UpdateShipwayIntegrationResult => {
    const { accountId, integrationId, onSuccess, ...options } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateShipwayIntegration: MutationFunction<Integration, UpdateShipwayInput> = (data: UpdateShipwayInput) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/shipway/${integrationId}`, data);
    };

    const result = useMutation<Integration, Error, UpdateShipwayInput>(updateShipwayIntegration, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            onSuccess?.();
            toast({ title: "Success", status: "success", description: "Shipway Updated Successfully" });
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },

        ...options,
    });

    return result;
};

interface UseConnectZohoCRMSignal extends UseMutationOptions<string, Error, ZohoCRMConnectForm> {
    accountId: string;
}

type UseConnectZohoCRMSignalResult = UseMutationResult<string, Error, ZohoCRMConnectForm>;

export const useConnectZohoCRMSignal = (props: UseConnectZohoCRMSignal): UseConnectZohoCRMSignalResult => {
    const { accountId, ...rest } = props;
    const toast = useToast();

    const connectSignals: MutationFunction<string, ZohoCRMConnectForm> = (data: ZohoCRMConnectForm) => {
        return postJSON(`/api/accounts/${accountId}/integrations/zoho-crm/connect`, data);
    };

    const connectionResult = useMutation<string, Error, ZohoCRMConnectForm>(connectSignals, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message,
            });
        },
        ...rest,
    });

    return connectionResult;
};
interface UseConnectZohoBooksProps extends UseMutationOptions<string, Error, void> {
    accountId: string;
}

export const useConnectZohoBooks = (props: UseConnectZohoBooksProps) => {
    const { accountId, ...rest } = props;
    const toast = useToast();

    const connectZohoBooks: MutationFunction<string, void> = () => {
        return postJSON(`/api/accounts/${accountId}/integrations/zoho-books/connect`, {});
    };

    const connectionResult = useMutation<string, Error, void>(connectZohoBooks, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message,
            });
        },
        ...rest,
    });

    return connectionResult;
};

export interface UseUpdateZohoCRMSignalsProps extends UseMutationOptions<Integration, Error, ZohoCRMUpdateData> {
    accountId: string;
    integrationId: string;
}
interface UseCreateZohoBooksWorkFlowProps
    extends UseMutationOptions<Integration, Error, Omit<ZohoBooksCreateWorkFlowInputData, "name">> {
    accountId: string;
    integrationId: string;
}
//get Calendly Events

interface UseCalendlyEventsProps {
    accountId: string;
    integrationId: string;
}

export interface ZohoCRMUpdateData {
    integrationName: string;
    channelIds?: string[];
    enableSignals: boolean;
}

type UpdateCRMResult = UseMutationResult<Integration, Error, ZohoCRMUpdateData>;

export const useUpdateZohoCRMSignals = (props: UseUpdateZohoCRMSignalsProps): UpdateCRMResult => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateSignal: MutationFunction<Integration, ZohoCRMUpdateData> = (data: ZohoCRMUpdateData) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/zoho-crm/${integrationId}`, data);
    };

    const updateResult = useMutation<Integration, Error, ZohoCRMUpdateData>(updateSignal, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
        onSuccess: () => {
            toast({
                title: "Integration Updated",
                status: "success",
            });
        },
        ...rest,
    });

    return updateResult;
};
export const useCreateZohoBooksWorkFlow = (props: UseCreateZohoBooksWorkFlowProps) => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const createWorkFlow: MutationFunction<Integration, Omit<ZohoBooksCreateWorkFlowInputData, "name">> = (
        data: Omit<ZohoBooksCreateWorkFlowInputData, "name">
    ) => {
        return postJSON(`/api/accounts/${accountId}/integrations/${integrationId}/zoho-books/workflow`, data);
    };

    const createResult = useMutation<Integration, Error, Omit<ZohoBooksCreateWorkFlowInputData, "name">>(
        createWorkFlow,
        {
            onError: (err) => {
                toast({
                    title: "Error",
                    status: "error",
                    description: err.message,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries(queryKey);
            },
            ...rest,
        }
    );

    return createResult;
};

interface UseUpdateZohoBooksWorkFlowProps
    extends UseMutationOptions<Integration, Error, Partial<ZohoBooksCreateWorkFlowInputData>> {
    accountId: string;
    integrationId: string;
}

export const useUpdateZohoBooksWorkFlow = (props: UseUpdateZohoBooksWorkFlowProps) => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateZohoBooksWorkflow: MutationFunction<Integration, Partial<ZohoBooksCreateWorkFlowInputData>> = (
        data: Partial<ZohoBooksCreateWorkFlowInputData>
    ) => {
        return patchJSON(
            `/api/accounts/${accountId}/integrations/${integrationId}/zoho-books/workflow/${data.id}`,
            data
        );
    };

    const updateResult = useMutation<Integration, Error, Partial<ZohoBooksCreateWorkFlowInputData>>(
        updateZohoBooksWorkflow,
        {
            onError: (err) => {
                toast({
                    title: "Error",
                    status: "error",
                    description: err.message,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries(queryKey);
            },
            ...rest,
        }
    );

    return updateResult;
};

interface UseDeleteZohoBooksWorkflowProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
}

export const useDeleteZohoBooksWorkflow = (props: UseDeleteZohoBooksWorkflowProps) => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();

    const deleteWorkFlow: MutationFunction<Integration, void> = () => {
        return deleteJSON(`/api/accounts/${accountId}/integrations/${integrationId}/zoho-books/workflow/delete`);
    };

    const deleteResult = useMutation<Integration, Error, void>(deleteWorkFlow, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message,
            });
        },
    });

    return deleteResult;
};

interface UseDeleteZohoCRMSignalsProps extends UseMutationOptions<boolean, Error, void> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

export const useDeleteZohoCRMSignals = (props: UseDeleteZohoCRMSignalsProps) => {
    const { accountId, integrationId, onSuccess, ...rest } = props;
    const toast = useToast();

    const deleteSignals: MutationFunction<boolean, void> = () => {
        return deleteJSON(`/api/accounts/${accountId}/integrations/zoho-crm/${integrationId}`);
    };

    const deleteResult = useMutation<boolean, Error, void>(deleteSignals, {
        onSuccess: () => {
            toast({
                title: "Successfully Deleted",
                status: "success",
                description: "Integration Deleted Successfully",
            });
            onSuccess?.();
        },
        ...rest,
    });

    return deleteResult;
};
export const useGetCalendlyEvents = (props: UseCalendlyEventsProps) => {
    const { accountId, integrationId } = props;

    const fetchCalendlyEvents: QueryFunction<CalendlyEventList> = () => {
        return fetcher(`/api/accounts/${accountId}/integrations/${integrationId}/calendly/events
        `);
    };

    return useQuery<CalendlyEventList, Error>(
        [QueryKey.CalendlyEventsList, { accountId, integrationId }],
        fetchCalendlyEvents,
        {
            enabled: Boolean(accountId) && Boolean(integrationId),
        }
    );
};

//delete Workflow

interface useDeleteCalendlyWorkFlowProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
    workflowId: string;
}

export const useDeleteCalendlyWorkFlow = (
    props: useDeleteCalendlyWorkFlowProps
): UseMutationResult<Integration, Error, void> => {
    const { accountId, integrationId, workflowId } = props;
    const toast = useToast();
    const queryKey = [QueryKey.Integration, { accountId, integrationId }];
    const queryClient = useQueryClient();

    const deleteWorkFlow: MutationFunction<Integration, void> = () => {
        return deleteJSON(
            `/api/accounts/${accountId}/integrations/${integrationId}/calendly/workflow/${workflowId}/delete`
        );
    };

    const res = useMutation<Integration, Error, void>(deleteWorkFlow, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message,
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData(queryKey, data);
            toast({ title: "Workflow deleted successfully", status: "success", description: "Successfully Deleted" });
        },
    });

    return res;
};

interface UpdateWCAbandonCartIntegrationInput {
    integrationName: string;
    channelId: string;
}

type UsePatchWCAbandonCartIntegration<InputType> = UseMutationResult<Integration, Error, InputType>;

export const useUpdatWCAbandonCarteIntegration = <InputType extends UpdateWCAbandonCartIntegrationInput>(
    props: UseUpdateIntegrationProps
): UsePatchWCAbandonCartIntegration<InputType> => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const integrationUpdate = (data: InputType): Promise<Integration> => {
        return patchJSON<Integration>(
            `/api/accounts/${accountId}/integrations/woocommerce-abandoned-cart/${integrationId}/config`,
            {
                ...data,
            } as unknown as any
        );
    };

    const mutationResult = useMutation<Integration, Error, InputType>(integrationUpdate, {
        // If the mutation fails, use the context returned from onMutate to roll back

        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (field) => {
            if (showSuccessMessage) {
                toast({
                    title: "Integration Updated",
                    status: "success",
                });
                queryClient.invalidateQueries(queryKey);
            }
            onSuccess?.(field);
        },
    });

    return mutationResult;
};

interface UseUpdateZohoBooksConfigurationProps
    extends UseMutationOptions<Integration, Error, UpdateZohoBooksConfigData> {
    accountId: string;
    integrationId: string;
}

interface UpdateZohoBooksConfigData {
    channelId?: string;
}

export const useUpdateZohoBooksConfiguration = (props: UseUpdateZohoBooksConfigurationProps) => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateConfig: MutationFunction<Integration, UpdateZohoBooksConfigData> = (
        data: UpdateZohoBooksConfigData
    ) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/zoho-books/${integrationId}`, data);
    };

    const updatedConfig = useMutation<Integration, Error, UpdateZohoBooksConfigData>(updateConfig, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message,
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },

        ...rest,
    });
    return updatedConfig;
};
//delete Calendly Integration

interface UseDeleteCalendlyIntegrationProps extends UseMutationOptions<boolean, Error, void> {
    accountId: string;
    integrationId: string;
    onComplete?: () => void;
}

type DeleteIntegrationResult = UseMutationResult<boolean, Error, void>;

export const useDeleteCalendlyIntegration = (props: UseDeleteCalendlyIntegrationProps): DeleteIntegrationResult => {
    const { accountId, integrationId, onComplete, ...rest } = props;
    const toast = useToast();

    const deleteIntegration: MutationFunction<boolean, void> = () => {
        return deleteJSON(`/api/accounts/${accountId}/integrations/${integrationId}/calendly`);
    };

    const deleteResult = useMutation<boolean, Error, void>(deleteIntegration, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message,
            });
        },
        onSuccess: () => {
            toast({
                title: "Integration deleted successfully",
                status: "success",
                description: "Successfully Deleted",
            });
            onComplete?.();
        },
        ...rest,
    });

    return deleteResult;
};

interface UseGetCalendlyVariablesProps extends UseQueryOptions<IntegrationField[], Error> {
    accountId: string;
    integrationId: string;
    eventUri: string;
    isPhone?: boolean;
}

export const useGetCalendlyVariables = (props: UseGetCalendlyVariablesProps) => {
    const { accountId, integrationId, eventUri, isPhone, ...options } = props;
    const queryKey = [QueryKey.CalendlyIntegrationVariablesList, { accountId, integrationId, eventUri, isPhone }];

    const fetchIntegrationField = () => {
        const queryParams = mapQueryParams({ eventUri, isPhone });
        return fetcher(`/api/accounts/${accountId}/integrations/${integrationId}/calendly/variable?${queryParams}`);
    };

    const variablesList = useQuery<IntegrationField[], Error>(queryKey, fetchIntegrationField, { ...options });
    return variablesList;
};

interface UseUpdateCalendlyProps extends UseMutationOptions<Integration, Error, UpdateIntegrationInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: (field: Integration) => void;
    showSuccessMessage?: boolean;
}
type UpdateCalendlyIntegration = UseMutationResult<Integration, Error, UpdateIntegrationInput>;

export const useUpdateCalendlyIntegration = (props: UseUpdateCalendlyProps): UpdateCalendlyIntegration => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateCalendly: MutationFunction<Integration, UpdateIntegrationInput> = (data: UpdateIntegrationInput) => {
        return patchJSON(`/api/accounts/${accountId}/integrations/${integrationId}/calendly`, data);
    };

    const mutationResult = useMutation<Integration, Error, UpdateIntegrationInput>(updateCalendly, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (field) => {
            if (showSuccessMessage) {
                toast({
                    title: "Integration Updated",
                    status: "success",
                });
            }
            onSuccess?.(field);
        },

        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};
interface CreateShopifyInput {
    shop: string;
}

interface CreateShopifyIntegrationResult {
    location: string;
}

type CreateShopifyIntegration = UseMutationResult<CreateShopifyIntegrationResult, Error, CreateShopifyInput>;

interface CreateShopifyProps extends UseMutationOptions<CreateShopifyIntegrationResult, Error, CreateShopifyInput> {
    accountId: string;
    onSuccess?: (data: CreateShopifyIntegrationResult) => void;
}

type ShopifyReAuthenticateIntegration = UseMutationResult<Integration, Error, void>;

interface ShopifyReAuthenticateProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
    onSuccess?: (data: Integration) => void;
}

export const useShopifyReAuthenticate = (props: ShopifyReAuthenticateProps): ShopifyReAuthenticateIntegration => {
    const { accountId, integrationId, onSuccess, ...options } = props;
    const toast = useToast();

    const shopifyReAuthenticate = () => {
        return fetcher(`/api/accounts/${accountId}/integrations/shopify/${integrationId}/reconnect`);
    };

    const response = useMutation<Integration, Error, void>(shopifyReAuthenticate, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Integration Re-Authenticate Failed",
                description: err?.message ?? "Integration Re-Authenticate Failed",
            });
        },
        onSuccess: (data) => {
            toast({
                status: "success",
                title: "Integration Re-Authenticated",
            });
            onSuccess?.(data);
        },

        ...options,
    });

    return response;
};

export const useShopifyIntegration = <InputType extends UpdateIntegrationInputWithShopUrl>(
    props: UseUpdateIntegrationProps
): UsePatchIntegration<InputType> => {
    const { accountId, integrationId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const integrationUpdate = (data: InputType): Promise<Integration> => {
        return patchJSON<Integration>(`/api/accounts/${accountId}/integrations/shopify/${integrationId}/config`, {
            ...data,
        } as unknown as any);
    };

    const mutationResult = useMutation<Integration, Error, InputType>(integrationUpdate, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (field) => {
            if (showSuccessMessage) {
                toast({
                    title: "Integration Updated",
                    status: "success",
                });
            }
            onSuccess?.(field);
        },

        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

type CreateWorkFlowInput = Omit<Workflow, "id" | "recipient">;

interface UseCreateStripeWorkflow extends UseMutationOptions<Workflow[], Error, CreateWorkFlowInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type CreateStripeWorkflowResponse = UseMutationResult<Workflow[], Error, CreateWorkFlowInput>;

export const useCreateStripeWorkflow = (props: UseCreateStripeWorkflow): CreateStripeWorkflowResponse => {
    const { accountId, integrationId, onSuccess, ...rest } = props;
    const toast = useToast();

    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.IntegrationWorkFlow, { accountId, integrationId }];

    const createStripeWorkflow: MutationFunction<Workflow[], CreateWorkFlowInput> = (data: CreateWorkFlowInput) => {
        return postJSON(`/api/accounts/${accountId}/integrations/${integrationId}/stripe/workflow`, data);
    };

    const responseCreateWorkflow = useMutation<Workflow[], Error, CreateWorkFlowInput>(createStripeWorkflow, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                description: "Workflow created successfully!",
            });
            queryClient.invalidateQueries(queryKey);
            onSuccess?.();
        },

        ...rest,
    });

    return responseCreateWorkflow;
};

type UpdateStripeWorkFlowInput = Partial<
    Pick<Workflow, "isActive" | "templateId" | "templateValues" | "workflowActions" | "stripe">
> & {
    currentWorkflowId?: string;
};

interface UseUpdateStripeWorkflow extends UseMutationOptions<Workflow[], Error, UpdateStripeWorkFlowInput> {
    accountId: string;
    integrationId: string;
    workflowId: string;
    onSuccess?: () => void;
}

type UpdateStripeWorkflowResponse = UseMutationResult<Workflow[], Error, UpdateStripeWorkFlowInput>;

export const useUpdateStripeWorkflow = (props: UseUpdateStripeWorkflow): UpdateStripeWorkflowResponse => {
    const { accountId, integrationId, workflowId, onSuccess, ...rest } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.IntegrationWorkFlow, { accountId, integrationId }];

    const createStripeWorkflow: MutationFunction<Workflow[], UpdateStripeWorkFlowInput> = (
        data: UpdateStripeWorkFlowInput
    ) => {
        const { currentWorkflowId, ...rest } = data;
        return patchJSON(
            `/api/accounts/${accountId}/integrations/${integrationId}/stripe/workflow/${
                currentWorkflowId || workflowId
            }`,
            { ...rest }
        );
    };

    const responseUpdateWorkflow = useMutation<Workflow[], Error, UpdateStripeWorkFlowInput>(createStripeWorkflow, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                description: "Workflow update successfully!",
            });
            queryClient.invalidateQueries(queryKey);
            onSuccess?.();
        },

        ...rest,
    });

    return responseUpdateWorkflow;
};

//connect shopflo integration

interface ConnectShopFloIntegrationProps
    extends UseMutationOptions<ShopfloIntegration, Error, ConnectShopFloIntegrationInput> {
    accountId: string;
    onSuccess?: (data: ShopfloIntegration) => void;
}

type ConnectShopFloIntegrationResponse = UseMutationResult<ShopfloIntegration, Error, ConnectShopFloIntegrationInput>;

export const useConnectShopFloIntegration = (
    props: ConnectShopFloIntegrationProps
): ConnectShopFloIntegrationResponse => {
    const { accountId, onSuccess, ...options } = props;
    const toast = useToast();

    const connectShopFlo: MutationFunction<ShopfloIntegration, ConnectShopFloIntegrationInput> = (
        data: ConnectShopFloIntegrationInput
    ) => {
        return postJSON(`api/accounts/${accountId}/integrations/shopflo`, data);
    };

    const mutationResult = useMutation<ShopfloIntegration, Error, ConnectShopFloIntegrationInput>(connectShopFlo, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while Connecting Integration",
            });
        },
        onSuccess: (data) => {
            toast({
                title: "Success",
                status: "success",
                description: "Integration Created",
            });
            onSuccess?.(data);
        },
        ...options,
    });

    return mutationResult;
};

interface UseDeleteShopfloIntegrationProps extends UseMutationOptions<Integration, Error, void> {
    accountId: string;
    integrationId: string;
}

export const useDeleteShopfloIntegration = (props: UseDeleteShopfloIntegrationProps) => {
    const { accountId, integrationId, ...rest } = props;
    const toast = useToast();

    const deleteShopfloIntegration: MutationFunction<Integration, void> = () => {
        return deleteJSON(`/api/accounts/${accountId}/integrations/shopflo/${integrationId}`);
    };

    const deleteResult = useMutation<Integration, Error, void>(deleteShopfloIntegration, {
        onError: (err) => {
            toast({
                status: "error",
                title: "Error",
                description: err.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                title: "Deleted Successfully",
                description: "Shopflo integration Deleted Successfully",
            });
        },
        ...rest,
    });
    return deleteResult;
};

//update Shopflo configuration

interface UpdateShopfloConfigurationProps extends UseMutationOptions<Integration, Error, UpdateShopfloConfigInput> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type UpdateShopfloResponse = UseMutationResult<Integration, Error, UpdateShopfloConfigInput>;

export const useUpdateShopfloConfiguration = (props: UpdateShopfloConfigurationProps): UpdateShopfloResponse => {
    const { integrationId, accountId, onSuccess, ...options } = props;
    const queryClient = useQueryClient();
    const toast = useToast();
    const queryKey: string | unknown[] = [QueryKey.Integration, { accountId, integrationId }];

    const updateShopfloConfig: MutationFunction<Integration, UpdateShopfloConfigInput> = (
        data: UpdateShopfloConfigInput
    ) => {
        return patchJSON(
            `api/accounts/${accountId}/integrations/shopflo/${integrationId}/config?populatePaths=channel`,
            data
        );
    };

    const response = useMutation<Integration, Error, UpdateShopfloConfigInput>(updateShopfloConfig, {
        onError: (err) => {
            toast({
                title: "Error",
                status: "error",
                description: err.message ?? "Error while updating  Integration",
            });
        },
        onSuccess: (data) => {
            queryClient.setQueryData<Integration>(queryKey, data);
            toast({
                title: "Integration  Updated",
                status: "success",
                description: "Integration Updated successfully!",
            });
            onSuccess?.();
        },

        ...options,
    });

    return response;
};

interface UseCreateShopfloWorkflow extends UseMutationOptions<Workflow[], Error, ShopfloWorkFlow> {
    accountId: string;
    integrationId: string;
    onSuccess?: () => void;
}

type CreateShopfloWorkflowResponse = UseMutationResult<Workflow[], Error, ShopfloWorkFlow>;

export const useCreateShopfloWorkflow = (props: UseCreateShopfloWorkflow): CreateShopfloWorkflowResponse => {
    const { accountId, integrationId, onSuccess, ...rest } = props;
    const toast = useToast();

    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.IntegrationWorkFlow, { accountId, integrationId }];

    const createShopfloWorkflow: MutationFunction<Workflow[], ShopfloWorkFlow> = (data: ShopfloWorkFlow) => {
        return postJSON(`/api/accounts/${accountId}/integrations/shopflo/${integrationId}/workflow`, data);
    };

    const responseCreateWorkflow = useMutation<Workflow[], Error, ShopfloWorkFlow>(createShopfloWorkflow, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                description: "Workflow created successfully!",
            });
            queryClient.invalidateQueries(queryKey);
            onSuccess?.();
        },

        ...rest,
    });

    return responseCreateWorkflow;
};

type UpdateShopfloWorkFlowInput = Partial<ShopfloWorkFlow>;

interface UseUpdateShopfloWorkflow extends UseMutationOptions<Workflow[], Error, UpdateShopfloWorkFlowInput> {
    accountId: string;
    integrationId: string;
    workflowId: string;
    onSuccess?: () => void;
}

type UpdateShopfloWorkflowResponse = UseMutationResult<Workflow[], Error, UpdateShopfloWorkFlowInput>;

export const useUpdateShopfloWorkflow = (props: UseUpdateShopfloWorkflow): UpdateShopfloWorkflowResponse => {
    const { accountId, integrationId, workflowId, onSuccess, ...rest } = props;
    const toast = useToast();
    const queryClient = useQueryClient();
    const queryKey: string | unknown[] = [QueryKey.IntegrationWorkFlow, { accountId, integrationId }];

    const createShopfloWorkflow: MutationFunction<Workflow[], UpdateShopfloWorkFlowInput> = (
        data: UpdateShopfloWorkFlowInput
    ) => {
        return patchJSON(
            `/api/accounts/${accountId}/integrations/shopflo/${integrationId}/workflow/${workflowId}`,
            data
        );
    };

    const responseUpdateWorkflow = useMutation<Workflow[], Error, UpdateShopfloWorkFlowInput>(createShopfloWorkflow, {
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: () => {
            toast({
                status: "success",
                description: "Workflow update successfully!",
            });
            queryClient.invalidateQueries(queryKey);
            queryClient.invalidateQueries([QueryKey.Integration, { accountId, integrationId }]);
            onSuccess?.();
        },

        ...rest,
    });

    return responseUpdateWorkflow;
};
