import Spinner from "app/components/Spinner";
import { RootState } from "app/modules";
import { handleAuth } from "app/modules/authed";
import queryString from "qs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteProps, useHistory } from "react-router-dom";

const Auth: React.FC = (props: RouteProps) => {
    const { location } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const accessToken = useSelector((state: RootState) => state.authed.accessToken);
    const isLoggedIn = Boolean(accessToken);
    const qs = location && location.search && queryString.parse(location.search, { ignoreQueryPrefix: true });
    const { nexturl, token } = qs || { nexturl: "/", token: "" };

    if (isLoggedIn && typeof nexturl === "string") {
        history.push(nexturl);
    }
    if (token && typeof token === "string" && typeof nexturl === "string") {
        dispatch(handleAuth(token));
        history.push(nexturl);
    }

    return <Spinner />;
};

export default Auth;
