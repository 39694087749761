import { HStack, VStack, Text, Image, Skeleton, Box } from "@chakra-ui/react";
import React from "react";
import Dialog360Logo from "assets/onboarding/dialog360-logo.svg";
import InfoTooltip from "app/components/InfoTooltip";

const Step1Gif = "https://assets.gallabox.com/web/step-1.gif";

const Step1: React.FC = () => {
    return (
        <VStack
            bg={{ base: "transparent", sm: "white", lg: "white" }}
            boxShadow={{ base: "none", sm: "none", lg: "base" }}
            w={{ base: "full", sm: "container.md", lg: "container.md" }}
            rounded="md"
            spacing={3}
            px={{ base: "unset", sm: 10, lg: 10 }}
            py={5}
            id="dialog-360-step-1"
            align="start"
        >
            <HStack w="full" pt={1} spacing={2}>
                <Image src={Dialog360Logo} fallback={<Skeleton w="36px" h="36px" />} />
                <Text color="gray.800" fontWeight="semibold" fontSize={{ base: "md", sm: "md", lg: "lg" }}>
                    Step 1: Sign up to 360 Dialog
                </Text>
            </HStack>
            <VStack w={{ base: "100%", sm: "94%", lg: "94%" }} alignSelf={{ base: "unset", sm: "end", lg: "end" }}>
                <Text color="gray.600" fontSize="sm">
                    The world's number one WhatsApp Business API service provider (BSP) and with their unmatched
                    technology, we ensure your data is secure and communication is seamless.
                </Text>
                <Box
                    w="full"
                    h={{ base: "298px", sm: "320px", lg: "320px" }}
                    alignSelf={{ base: "unset", sm: "end", lg: "end" }}
                    bg="#BCDEFB"
                    rounded="lg"
                >
                    <Image
                        src={Step1Gif}
                        objectFit="contain"
                        w="full"
                        h="full"
                        maxH="xs"
                        fallback={<Skeleton w="full" h="full" />}
                    />
                </Box>
            </VStack>
        </VStack>
    );
};

export default Step1;
