import { HStack, VStack, Text, Image, Skeleton, Box } from "@chakra-ui/react";
import React from "react";
import WhatsappImg from "assets/onboarding/whatsapp-logo.svg";

import Step31Img from "assets/onboarding/dialog-360/step-3-1.svg";
import Step32Img from "assets/onboarding/dialog-360/step-3-2.svg";
import Step33Img from "assets/onboarding/dialog-360/step-3-3.svg";
import Step34Img from "assets/onboarding/dialog-360/step-3-4.svg";

const Step3: React.FC = () => {
    return (
        <VStack
            bg={{ base: "transparent", sm: "white", lg: "white" }}
            boxShadow={{ base: "none", sm: "none", lg: "base" }}
            w={{ base: "full", sm: "container.md", lg: "container.md" }}
            px={{ base: "unset", sm: 10, lg: 10 }}
            align="start"
            spacing={3}
            rounded="md"
            py={5}
        >
            <HStack w="full">
                <Image src={WhatsappImg} w="36px" h="36px" fallback={<Skeleton w="36px" h="36px" />} />
                <Text color="gray.800" fontWeight="semibold" fontSize={{ base: "md", sm: "md", lg: "lg" }}>
                    Step 3: Fill in your business information.
                </Text>
            </HStack>
            <VStack
                w={{ base: "100%", sm: "94%", lg: "94%" }}
                alignSelf={{ base: "unset", sm: "end", lg: "end" }}
                spacing={5}
                align="start"
                pt={2.5}
            >
                <VStack w="full" align="start">
                    <Text color="gray.800" fontSize="md" fontWeight="medium">
                        Fill in your business information.
                    </Text>
                    <Box h={{ base: "298px", sm: "320px", lg: "320px" }} w="full" bg="#BCDEFB" rounded="lg">
                        <Image
                            src={Step31Img}
                            objectFit="cover"
                            w="full"
                            h="full"
                            maxH="xs"
                            rounded="lg"
                            fallback={<Skeleton w="full" h="full" />}
                        />
                    </Box>
                </VStack>
                <VStack w="full" align="start">
                    <Text color="gray.800" fontSize="md" fontWeight="medium">
                        Choose or create your WhatsApp Business Account (WABA).
                    </Text>
                    <Box h={{ base: "298px", sm: "320px", lg: "320px" }} w="full" bg="#BCDEFB" rounded="lg">
                        <Image
                            src={Step32Img}
                            objectFit="cover"
                            w="full"
                            h="full"
                            maxH="xs"
                            rounded="lg"
                            fallback={<Skeleton w="full" h="full" />}
                        />
                    </Box>
                </VStack>
                <VStack w="full" align="start" spacing={1}>
                    <Text color="gray.800" fontSize="md" fontWeight="medium">
                        Create your WhatsApp Business (WABA) profile.
                    </Text>
                    <Text color="gray.600" fontSize="sm">
                        Information provided here will be visible to whatsapp users under your business profile.
                    </Text>
                    <Box h={{ base: "298px", sm: "320px", lg: "320px" }} w="full" bg="#BCDEFB" rounded="lg">
                        <Image
                            src={Step33Img}
                            objectFit="cover"
                            w="full"
                            h="full"
                            maxH="xs"
                            rounded="lg"
                            fallback={<Skeleton w="full" h="full" />}
                        />
                    </Box>
                </VStack>
                <VStack w="full" align="start">
                    <Text color="gray.800" fontSize="md" fontWeight="medium">
                        Add and verify your mobile number.
                    </Text>
                    <Box h={{ base: "298px", sm: "320px", lg: "320px" }} w="full" bg="#BCDEFB" rounded="lg">
                        <Image
                            src={Step34Img}
                            objectFit="cover"
                            w="full"
                            h="full"
                            maxH="xs"
                            rounded="lg"
                            fallback={<Skeleton w="full" h="full" />}
                        />
                    </Box>
                </VStack>
            </VStack>
        </VStack>
    );
};

export default Step3;
