import { Box, Divider, HStack, Spacer, useDisclosure, VStack } from "@chakra-ui/react";
import MultiAlert, { AlertBannerPropsWithVisibility } from "app/components/AlertBanner/MultiAlert";
import { useListenAccountEntitlement } from "app/hooks/useListenAccountEntitlement";
import useLocalStorage from "app/hooks/useLocalStorage";
import { useShowTopAlertBanner } from "app/hooks/useShowTopAlertBanner";
import { RootState } from "app/modules";
import { useListenImportCompletion } from "app/screens/ImportContact/hooks/useListenImportCompletion";
import MobileAppDownloadView from "app/screens/Onboarding/Sandbox/MobileAppDownloadView";
import useCannyWidget from "app/screens/Widget/CannyWidget";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MessageCreditsModal from "./components/MessageCreditsModal";
import NavigationAccordion from "./components/NavigationAccordion";
import NavigationAccordionItem from "./components/NavigationAccordionItem";
import NavigationFooter from "./components/NavigationFooter";
import NavigationHeader from "./components/NavigationHeader";
import QuickSearch from "./components/QuickSearch";
import { useNavigationList } from "./hooks/useNavigationList";
import { useNavigationShortCut } from "./hooks/useNavigationShortCut";
import { appLayoutStyles } from "./utils";

dayjs.extend(utc);
dayjs.extend(timezone);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NavigationLayout: React.FC<{ component: React.ComponentType<any> }> = ({ component: Component, ...rest }) => {
    const [alerts, setAlerts] = useState<AlertBannerPropsWithVisibility[]>([]);

    const [navigationCollapsedState, setNavigationCollapsedState] = useLocalStorage<boolean | undefined>(
        "navigation-collapsed-state",
        undefined,
        {}
    );

    const { isOpen: isCollapsed, onToggle: onCollapseToggle } = useDisclosure({
        defaultIsOpen: typeof navigationCollapsedState === "boolean" && navigationCollapsedState ? true : false,
    });

    const { navigationItems } = useNavigationList();
    const { multiAlertProps } = useShowTopAlertBanner();

    useCannyWidget({ isCollapsed });
    useListenAccountEntitlement();
    useListenImportCompletion();

    useEffect(() => {
        setAlerts(multiAlertProps);
    }, [multiAlertProps]);

    const onToggle = React.useCallback(() => {
        setNavigationCollapsedState(!isCollapsed);
        onCollapseToggle();
    }, [isCollapsed, onCollapseToggle, setNavigationCollapsedState]);

    useNavigationShortCut({ onToggle });

    const setAlertsProp = React.useCallback((alerts: AlertBannerPropsWithVisibility[]) => {
        setAlerts(alerts);
    }, []);

    const collapsibleProps = {
        isCollapsed,
        onToggle,
    };
    return (
        <>
            <VStack sx={appLayoutStyles.container} justify="start" align="start" overflowY="hidden" spacing={0}>
                {multiAlertProps.length && (
                    <Box w="full">
                        <MultiAlert alerts={alerts} setAlerts={setAlertsProp} />
                    </Box>
                )}

                <HStack
                    w="full"
                    h={alerts.length > 0 ? "calc(100% - 32px)" : "full"}
                    minW="full"
                    justify="start"
                    align="initial"
                    overflow="hidden"
                    pos="relative"
                    spacing={0}
                >
                    <VStack
                        sx={appLayoutStyles.header}
                        w={isCollapsed ? "60px" : "200px"}
                        align={isCollapsed ? "center" : "start"}
                        spacing={0}
                        transition="all 0.2s"
                        boxShadow="base"
                        borderRightWidth="1px"
                        borderRightColor="gray.500"
                        borderRightStyle="solid"
                        h="full"
                        zIndex={1}
                        maxH="full"
                        overflow="hidden"
                    >
                        <NavigationHeader {...collapsibleProps} onToggle={onToggle} />
                        <NavigationAccordion {...collapsibleProps}>
                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems["get-started"]} />
                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.conversations} />
                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.contacts} />

                            <Divider alignSelf="center" borderColor="gray.300" />

                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.bots} />
                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.broadcast} />
                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.sequence} />

                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.commerce} />
                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.flows} />

                            <Divider alignSelf="center" borderColor="gray.300" />

                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.integration} />
                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.analytics} />
                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.settings} />

                            <Divider alignSelf="center" borderColor="gray.300" />

                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems.help} />
                            <NavigationAccordionItem {...collapsibleProps} {...navigationItems["whats-new"]} />
                        </NavigationAccordion>
                        <Spacer />
                        <NavigationFooter {...collapsibleProps} />
                    </VStack>
                    <VStack
                        w="full"
                        maxW="full"
                        overflow="auto"
                        maxH="full"
                        flex={1}
                        align="stretch"
                        sx={appLayoutStyles.content}
                    >
                        <Component {...rest} />
                    </VStack>
                </HStack>
            </VStack>
            <MessageCreditsModal />
            <QuickSearch />
            <style>{`
                        .Canny_BadgeContainer .Canny_Badge {
                            position: absolute;
                            top: ${isCollapsed ? "4px" : "10px"};
                            right: ${isCollapsed ? "10px" : "6px"};
                            border-radius: 10px;
                            background-color: red;
                            padding: 4px;
                            border: 1px solid white;
                            background-color: #E53E3E;
                        }
                    `}</style>
        </>
    );
};

const NavigationLayoutWrapper: React.FC<{ component: React.ComponentType<any> }> = (props) => {
    const { isMobile } = useSelector((state: RootState) => state.environment);

    if (isMobile) return <MobileAppDownloadView />;
    return <NavigationLayout {...props} />;
};

export default NavigationLayoutWrapper;
