import React from "react";
import ReactHelmet from "react-helmet";
import { RouteComponentProps } from "react-router-dom";
import { StaticContext } from "react-router";
import SignIn from "./SignIn";
import { urlTracker } from "app/utils/campaign-tracker";

// eslint-disable-next-line @typescript-eslint/ban-types
type SignInProps = RouteComponentProps<{}, StaticContext, { from: { pathname: string } }>;

const SignInContainer: React.FC<SignInProps> = (props) => {
    React.useLayoutEffect(() => {
        urlTracker();
    }, []);

    return (
        <>
            <ReactHelmet>
                <title>Gallabox | Sign Up For Free | Shared Team Inbox | No-Code Chatbot | WhatsApp Broadcast</title>
                <meta
                    name="description"
                    content="Sign up with Gallabox, India's favorite WhatsApp solution provider. Build no-code bots, schedule broadcasts and collaborate with teams. Get started with a 7-day free trial!"
                />
            </ReactHelmet>
            <SignIn {...props} />
        </>
    );
};

export default SignInContainer;
