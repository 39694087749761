let windowObjectReference: Window | null = null;
let previousUrl: string | null = null;

const receiveMessage = (event: MessageEvent<any>, baseUrl: string, windowObjectReference: Window | null) => {
    if (event.data.type !== "DIALOG_360_INTEGRATED_ONBOARDING") return;
    windowObjectReference?.close();
    const { data } = event;
    const params = `${data.params}`;
    window.location.href = `${data.parentWindowRedirectUrl}${params}`;
};

export const openSignInWindow = (url: string, name: string, baseUrl: string) => {
    // remove any existing event listeners
    window.removeEventListener("message", (event) => receiveMessage(event, baseUrl, windowObjectReference));

    // window features
    const strWindowFeatures = "toolbar=no, menubar=no, width=600, height=900, top=100, left=100";
    // const strWindowFeatures = undefined;

    if (windowObjectReference === null || windowObjectReference.closed) {
        /* if the pointer to the window object in memory does not exist
      or if such pointer exists but the window was closed */
        windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
        /* if the resource to load is different,
      then we load it in the already opened secondary window and then
      we bring such window back on top/in front of its parent window. */
        windowObjectReference = window.open(url, name, strWindowFeatures);
        if (windowObjectReference) windowObjectReference.focus();
    } else {
        /* else the window reference must exist and the window
      is not closed; therefore, we can bring it back on top of any other
      window with the focus() method. There would be no need to re-create
      the window or to reload the referenced resource. */
        windowObjectReference.focus();
    }

    // add the listener for receiving a message from the popup
    window.addEventListener("message", (event) => receiveMessage(event, baseUrl, windowObjectReference), false);
    // assign the previous URL
    previousUrl = url;
};
