import { Button, Icon, Link, Text, useDisclosure } from "@chakra-ui/react";
import { AlertBannerPropsWithVisibility } from "app/components/AlertBanner/MultiAlert";
import { supportDemoURL } from "app/contants";
import { useAccountOverUsage } from "app/screens/Account/AccountOverUsage/useAccountOverUsage";
import NewFreeTrialModal from "app/screens/Onboarding/New/NewFreeTrialModal";
import dayjs from "dayjs";
import React from "react";
import { HiInformationCircle } from "react-icons/hi";
import { LuCalendarHeart } from "react-icons/lu";
import { RiAlertFill, RiUser3Line } from "react-icons/ri";
import { useHistory, useLocation } from "react-router-dom";
import { trackEvent } from "./productAnalytics/tracker";
import { useAccountMasterHook } from "./useAccountMasterHook";
import { useRedirectToDialog360Page } from "./useRedirectToDialog360Page";

const SANDBOX_DAYS = 3;
const TRIAL_DAYS = 6;

interface ShowTopAlertBannerProps {
    multiAlertProps: AlertBannerPropsWithVisibility[];
}

const alertInfoIcon = <Icon as={HiInformationCircle} color="gray.500" />;
const alertWarningIcon = <Icon as={RiAlertFill} color="red.500" />;

export const useShowTopAlertBanner = (): ShowTopAlertBannerProps => {
    const history = useHistory();
    const location = useLocation();

    const {
        isSuspended,
        isSandboxChannel,
        isNewAccount,
        isFreeTrial,
        hasOpenInvoice,
        needActivation,
        sandboxExpireDate,
        freeTrialExpireDate,
        isOFC,
        sandboxLifeSpan,
    } = useAccountMasterHook();
    const { isAccountOverUsed } = useAccountOverUsage();
    const { redirectToDialog360Page } = useRedirectToDialog360Page();
    // free trail details

    const freeTrailExpireContent = React.useMemo(() => {
        if (!freeTrialExpireDate) return "";

        const daysLeft = dayjs(freeTrialExpireDate).utc().local().endOf("day").diff(dayjs().startOf("day"), "day");
        if (daysLeft > TRIAL_DAYS) return "";
        if (daysLeft > 0)
            return (
                <Text fontSize="xs" color="gray.500">
                    Your Gallabox free trial with{" "}
                    <Text as="span" fontWeight="bold">
                        GROWTH
                    </Text>{" "}
                    plan will expire in {daysLeft}
                    {daysLeft > 1 ? " days" : " day"}
                </Text>
            );
        if (daysLeft === 0) return "You’re on the Gallabox Pro Plan. Your free trial ends today!";
        if (daysLeft > -3) return "Your Gallabox free trial is expired!";
        return "⚠️ Payment required!";
    }, [freeTrialExpireDate]);

    // sandbox details
    const sandboxAlertContent = React.useMemo(() => {
        if (!sandboxExpireDate) return "";
        const daysLeft = dayjs(sandboxExpireDate).utc().local().endOf("day").diff(dayjs().startOf("day"), "day");
        if (daysLeft > (sandboxLifeSpan ? sandboxLifeSpan + 1 : SANDBOX_DAYS)) return "";
        if (daysLeft > 0)
            return `You’re currently using Gallabox demo account which ends in ${daysLeft} ${
                daysLeft > 1 ? "days" : "day"
            }`;
        if (daysLeft === 0) return "You’re currently using Gallabox demo account which ends today!";
        if (daysLeft > -3) return "Your Gallabox demo account is expired!";
        return "⚠️ Action required!";
    }, [sandboxExpireDate, sandboxLifeSpan]);

    const newFreeTrialModalDisclosure = useDisclosure();

    const onGetYourOwnNumber = () => {
        history.push("/channels/whatsapp/connect-your-number");
        trackEvent({ event: "ACCOUNT Connect Number", properties: { type: "Top strip" } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const multiAlertProps = React.useMemo(() => {
        const alerts: AlertBannerPropsWithVisibility[] = [
            {
                isShow: isFreeTrial,
                content: {
                    text: freeTrailExpireContent,
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "I’m ready to upgrade",
                        buttonProps: {
                            color: "gray.600",
                            fontSize: "xs",
                            fontWeight: "medium",
                            textDecor: "underline",
                            variant: "unstyled",
                            colorScheme: undefined,
                            _hover: {
                                bg: "yellow.200",
                            },
                            onClick: () => {
                                history.push("/plan-and-subscription?tab=1");
                            },
                        },
                    },

                    secondaryButton: {
                        buttonText: "Book a free consultation",
                        buttonProps: {
                            leftIcon: <Icon as={LuCalendarHeart} />,
                            onClick: () => {
                                trackEvent({
                                    event: "GS_BOOK_DEMO_TOP",
                                });
                            },
                            rightIcon: undefined,
                            ml: 4,
                        },
                        href: supportDemoURL,
                    },
                },
            },
            {
                isShow: isSandboxChannel,
                content: {
                    text: sandboxAlertContent,
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "I’m ready to connect my number",
                        buttonProps: {
                            color: "gray.600",
                            fontSize: "xs",
                            fontWeight: "medium",
                            textDecor: "underline",
                            variant: "unstyled",
                            colorScheme: undefined,
                            onClick: onGetYourOwnNumber,
                            _hover: {
                                bg: "yellow.200",
                            },
                        },
                    },
                    ...(!isOFC
                        ? {
                              secondaryButton: {
                                  buttonText: "Book a free consultation",
                                  buttonProps: {
                                      leftIcon: <Icon as={LuCalendarHeart} />,
                                      rightIcon: undefined,
                                      ml: 4,
                                      onClick: () => {
                                          trackEvent({
                                              event: "GS_BOOK_DEMO",
                                              properties: { state: "Success", type: "Top strip" },
                                          });
                                      },
                                  },
                                  href: supportDemoURL,
                              },
                          }
                        : {}),
                    canPermissionCheck: true,
                    additionalCmptRender: (
                        <NewFreeTrialModal
                            isOpen={newFreeTrialModalDisclosure.isOpen}
                            onClose={newFreeTrialModalDisclosure.onClose}
                        />
                    ),
                },
            },
            {
                isShow: isNewAccount,
                content: {
                    text: "Your are almost there, Get started with your own Whatsapp number",
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Connect your number",
                        buttonProps: {
                            onClick: onGetYourOwnNumber,
                        },
                    },
                    secondaryButton: {
                        buttonText: "Connect with our product expert",
                        href: supportDemoURL,
                    },
                    canPermissionCheck: true,
                },
            },
            {
                isShow: needActivation,
                content: {
                    text: "Your channel is yet to be activated",
                },
                leftIcon: alertInfoIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Activate now",
                        buttonProps: {
                            onClick: () => {
                                redirectToDialog360Page();
                            },
                        },
                    },

                    canPermissionCheck: true,
                },
            },
            {
                isShow: isAccountOverUsed,
                content: {
                    text: "Your user limit is exceeded, Please add more users for your Subscription Plan.",
                },
                leftIcon: <Icon as={RiUser3Line} color="gray.800" fontSize="sm" />,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Add Users",
                        buttonProps: {
                            onClick: () => history.push("/plan-and-subscription?tab=1"),
                        },
                    },

                    canPermissionCheck: true,
                },
            },
            {
                isShow: hasOpenInvoice,
                content: {
                    text: "You have an unpaid invoice",
                },
                leftIcon: alertWarningIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Pay now",
                        buttonProps: {
                            onClick: () => history.push("/plan-and-subscription#invoice"),
                        },
                    },
                },
            },
            {
                isShow: isSuspended,
                content: {
                    text: "Your Account is Suspended",
                },
                leftIcon: alertWarningIcon,
                ctaButton: {
                    primaryButton: {
                        buttonText: "Pay now",
                        buttonProps: {
                            onClick: () => history.push("/plan-and-subscription#invoice"),
                        },
                    },
                },
            },
            {
                isShow: true,
                content: {
                    text: (
                        <Text as="span">
                            <Text textColor="gray.900" fontWeight="bold" as="span">
                                Effective August 1, 2024:{" "}
                            </Text>
                            Utility conversion rates have been reduced. Check the updated pricing{" "}
                            <Button
                                size="slim"
                                as={Link}
                                variant="link"
                                color="blue.500"
                                textDecor="underline"
                                target="_blank"
                                href="https://docs.gallabox.com/pricing-and-billing-modules/conversation-pricing#business-initiated-conversation-pricing"
                                isExternal
                            >
                                here
                            </Button>
                        </Text>
                    ),
                },
                isClosable: true,
            },
        ];

        return alerts.filter((ob) => ob.isShow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        freeTrailExpireContent,
        hasOpenInvoice,
        history,
        isAccountOverUsed,
        isFreeTrial,
        isNewAccount,
        isSandboxChannel,
        isSuspended,
        needActivation,
        newFreeTrialModalDisclosure.isOpen,
        redirectToDialog360Page,
        sandboxAlertContent,
        isOFC,
    ]);

    return { multiAlertProps };
};
