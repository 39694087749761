import { useEventListener } from "@chakra-ui/react";
import { isMacOS } from "app/utils/common";
import React from "react";
interface UseNavigationShortCutProps {
    onToggle: () => void;
}
export const useNavigationShortCut = (props: UseNavigationShortCutProps) => {
    const handler = React.useCallback(
        (e: KeyboardEvent) => {
            let toggleNavigation = false;

            const isValidKeyPressed = e?.key?.toLowerCase() === "\\";
            if (isMacOS) {
                toggleNavigation = e?.metaKey && isValidKeyPressed;
            } else {
                toggleNavigation = e?.ctrlKey && isValidKeyPressed;
            }

            if (!toggleNavigation) return;
            props.onToggle();
        },
        [props]
    );

    useEventListener("keydown", handler);
};
