import { Box, Button, HStack, Switch } from "@chakra-ui/react";
import LoadingState from "app/components/LoadingState";
import React from "react";
import { HiOutlineChevronRight } from "react-icons/hi";

interface WorkFlowSwitchProps {
    onActive: (v: boolean) => void;
    isActive: boolean;
    onClick: () => void;
    showSwitch?: string;
    loading?: boolean;
    switchLoading?: boolean;
}

export const WorkFlowSwitch: React.FC<WorkFlowSwitchProps> = (props) => {
    const { isActive, onActive, onClick, showSwitch, loading, switchLoading } = props;

    if (showSwitch)
        return (
            <HStack>
                <Box>
                    {switchLoading ? (
                        <LoadingState />
                    ) : (
                        <Switch
                            colorScheme="green"
                            onChange={(e) => {
                                e.stopPropagation();
                                onActive(e.target.checked);
                            }}
                            isChecked={isActive}
                            id="activeCase"
                            position="relative"
                        />
                    )}
                </Box>
                <Button
                    onClick={onClick}
                    _hover={{
                        backgroundColor: "white",
                    }}
                    backgroundColor="white"
                >
                    <HiOutlineChevronRight height={5} width={5} />
                </Button>
            </HStack>
        );
    return (
        <Button
            onClick={onClick}
            size="sm"
            fontSize="sm"
            px="5"
            isLoading={loading}
            variant="outline"
            borderRadius="6px"
        >
            <span>Use</span>
        </Button>
    );
};
