import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { PublicRouteProps } from "app/types";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const PublicRoute: React.FC<PublicRouteProps> = ({ component: Component, componentProps = {}, authed, ...rest }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { computedMatch: { path, url } = {}, name } = rest;

    trackEvent({ event: "ROUTE_CHANGE", properties: { path, url, name } });

    return (
        <Route
            {...rest}
            render={(props) => {
                if (authed.accessToken) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const { from = { pathname: "/" } } = props.location?.state || ({} as any);
                    return <Redirect to={{ pathname: "/home", state: { from } }} />;
                }
                return <Component {...props} {...componentProps} />;
            }}
        />
    );
};

export default PublicRoute;
