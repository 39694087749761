import { Box, Button, FormLabel, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { LabelValueWithIcon } from "app/components/ChannelSelectV2";
import ControlledChannelStyleSelect from "app/components/ChannelSelectV2/ControlledChannelStyleSelect";
import GallaboxLoadingState from "app/components/GBLoadingState";
import { useUpdateCalendlyIntegration } from "app/fetchHooks/integration";
import { WhatsappIcon } from "app/icons";
import { YupSchema } from "app/types";
import { Integration } from "app/types/integration";
import { Input } from "app/utils/formUtils";
import { formatPhoneNumber, useAccountId } from "app/utils/react-helpers";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

interface CalendlyConfigurationProps {
    data: Integration | undefined;
    isLoading: boolean;
}

export interface UpdateCalendlyConfiguration {
    integrationName: string;
    channelSelect: LabelValueWithIcon;
}

const updateConfigurationSchema = yup.object().shape<YupSchema<UpdateCalendlyConfiguration>>({
    integrationName: yup.string().trim().required("Integration Name is Required"),
    channelSelect: yup.object().shape<YupSchema<LabelValueWithIcon>>({
        icon: yup.mixed().optional(),
        label: yup.string().optional(),
        value: yup.string().required("Value is Required "),
        labelDescription: yup.string().optional(),
    }),
});

export const CalendlyConfiguration: FC<CalendlyConfigurationProps> = (props) => {
    const { data, isLoading: integLoading } = props;
    const toast = useToast();
    const accountId = useAccountId();
    const { calendly } = data ?? {};
    const { channelId } = calendly ?? {};
    const history = useHistory();
    const integrationId = data?.id ?? "";

    const defaultValues: UpdateCalendlyConfiguration = React.useMemo(() => {
        return {
            integrationName: calendly?.integrationName ?? "",
            channelSelect: {
                label: calendly?.channel?.whatsapp?.whatsappName ?? "Select Channel",
                value: calendly?.channelId ?? "",
                icon: WhatsappIcon,
                labelDescription: formatPhoneNumber(calendly?.channel?.whatsapp?.whatsappNumber),
            },
        };
    }, [calendly]);

    const { register, handleSubmit, control, errors, reset } = useForm<UpdateCalendlyConfiguration>({
        defaultValues,
        resolver: yupResolver<UpdateCalendlyConfiguration>(updateConfigurationSchema),
    });
    const { mutate, isLoading } = useUpdateCalendlyIntegration({
        accountId,
        integrationId,
        onSuccess: () => {
            toast({
                title: "Configuration  Updated",
                status: "success",
                description: "Configuration Updated successfully!",
            });
            history.push(`/integration/calendly/edit/${integrationId}?tab=2`);
        },
        showSuccessMessage: false,
    });

    React.useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues]);

    const updateConfiguration = (data: UpdateCalendlyConfiguration) => {
        const { integrationName, channelSelect } = data;
        const channelId = channelSelect?.value ?? "";
        mutate({ integrationName, channelId });
    };

    if (integLoading)
        return (
            <VStack w="full" height="full" alignItems="center" justify="center">
                <GallaboxLoadingState title="loading..." spinnerSize={20} />
            </VStack>
        );

    return (
        <VStack spacing={4} as="form" onSubmit={handleSubmit(updateConfiguration)}>
            <HStack w="full">
                <VStack flex="0.3" align="flex-start" spacing={"0"}>
                    <FormLabel>Integration Name</FormLabel>
                    <Text color="gray.500" fontSize="13px" fontWeight="normal">
                        Name of the Integration
                    </Text>
                </VStack>
                <Input
                    size="sm"
                    name="integrationName"
                    error={errors.integrationName?.message}
                    borderRadius="6px"
                    register={register}
                    formControlProps={{ flex: "0.5" }}
                />
            </HStack>
            <HStack w="full">
                <VStack flex="0.3" align="flex-start" spacing="0">
                    <FormLabel>Channel Name</FormLabel>
                </VStack>
                <Box flex="0.2">
                    <ControlledChannelStyleSelect
                        name="channelSelect"
                        control={control}
                        defaultValue={channelId}
                        error={errors?.channelSelect?.value?.message}
                    />
                </Box>
            </HStack>
            <Box py={5}>
                <Button
                    bgColor="blue.500"
                    color="white"
                    _hover={{ bg: "blue.600", boxShadow: "sm" }}
                    type="submit"
                    justifyContent="center"
                    size="sm"
                    isLoading={isLoading}
                    borderEndRadius="6px"
                    alignItems="center"
                >
                    <span>Update Configuration</span>
                </Button>
            </Box>
        </VStack>
    );
};
