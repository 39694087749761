import {
    Button,
    ButtonProps,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputLeftAddon,
    InputProps,
    Popover,
    PopoverTrigger,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { IntegrationField } from "app/types/integration/integrationField";
import React, { ChangeEventHandler, FC, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import InfoTooltip from "../InfoTooltip";
import IntegrationExpressionListMenu from "./IntegrationListMenu";

interface ExpressionInputProps extends Omit<InputProps, "onChange"> {
    onChange: (value: string) => void;
    formatting?: boolean;
    variables?: boolean;
    maxCharLength?: number;
    maxChar?: boolean;
    variableButtonProps?: ButtonProps;
    formatButtonProps?: ButtonProps;
    IntegrationFieldVariables?: Partial<IntegrationField>[];
    isLoading: boolean;
    leftAddon?: string;
}

const ExpressionInput: FC<ExpressionInputProps> = (props) => {
    const {
        formatting = true,
        variables = true,
        maxChar = true,
        onChange,
        value: v,
        variableButtonProps,
        IntegrationFieldVariables,
        isLoading,
        leftAddon,
        ...inputProps
    } = props;

    const inputRef = useRef<HTMLInputElement | null>(null);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const buttonPrefix = (leftAddon ?? "").replace(/\{\{([^{}]*)\}\}/g, "");

    const value = (v ?? inputRef.current?.value ?? "") as string;

    const addVariable = (intField: string): void => {
        const variable = intField;
        const input = inputRef.current;
        const [start, end] = [input?.selectionStart ?? 0, input?.selectionEnd ?? 0];
        const newText = value.slice(0, start) + `{{${variable}}}` + value.slice(end);
        onChange(newText);
        onClose();
        input?.focus();
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange(e.target.value);
    };

    const showToolbar = formatting || variables || maxChar;

    const renderVariableTriggerButton = () => {
        if (!variables) return null;
        const buttonProps: ButtonProps = {
            ...variableButtonProps,
            variant: "outline",
            _hover: { bg: "cyan.500", color: "white" },
            bg: "white",
            color: "cyan.500",
            fontWeight: "medium",
        };
        return (
            <Button size="xs" onClick={onOpen} {...buttonProps}>
                Add variable
            </Button>
        );
    };

    const renderToolbar = () => {
        if (!showToolbar) return null;
        return (
            <>
                <HStack w="full" align="flex-start" justify={variables || formatting ? "flex-start" : "flex-end"}>
                    {(variables || formatting) && (
                        <HStack w="full" flex={1} pt={1}>
                            {renderVariableTriggerButton()}
                        </HStack>
                    )}
                </HStack>
                {variables && (
                    <IntegrationExpressionListMenu
                        IntegrationFieldVariables={IntegrationFieldVariables}
                        onSelect={addVariable}
                        isLoading={isLoading}
                    />
                )}
            </>
        );
    };

    return (
        <VStack align="flex-start" w="full" maxW="full" spacing={0}>
            <Popover
                placement="bottom"
                isLazy
                lazyBehavior="keepMounted"
                returnFocusOnClose={false}
                isOpen={isOpen}
                onClose={onClose}
            >
                <PopoverTrigger>
                    {buttonPrefix ? (
                        <InputGroup size="sm">
                            <InputLeftAddon title={buttonPrefix} maxW="25ch" isTruncated>
                                {buttonPrefix}
                            </InputLeftAddon>
                            <Input
                                {...inputProps}
                                size="sm"
                                borderRadius="6px"
                                value={value}
                                ref={inputRef}
                                onChange={handleChange}
                            />
                        </InputGroup>
                    ) : (
                        <Input
                            {...inputProps}
                            size="sm"
                            borderRadius="6px"
                            value={value}
                            ref={inputRef}
                            onChange={handleChange}
                        />
                    )}
                </PopoverTrigger>
                {renderToolbar()}
            </Popover>
        </VStack>
    );
};

interface ExpressionInputFormFieldProps extends Omit<ExpressionInputProps, "renderMenu" | "onChange"> {
    label?: string;
    name: string;
    defaultValue?: string;
    control: ReturnType<typeof useForm>["control"];
    error?: string;
    required?: boolean;
    "data-cy"?: string;
    IntegrationFieldVariables?: Partial<IntegrationField>[];
    isLoading: boolean;
    formHelperText?: string;
    leftAddon?: string;
}

const IntegrationInputWithVariable: React.FC<ExpressionInputFormFieldProps> = (props) => {
    const {
        name,
        label,
        defaultValue,
        error,
        control,
        "data-cy": dataCy,
        required = false,
        maxChar,
        IntegrationFieldVariables,
        isLoading,
        formHelperText,
        ...inputProps
    } = props;
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required }}
            render={({ value: v, onChange }) => {
                const value = typeof v === "string" ? v : "";
                const hasVariable = /{{\s*[\w\-.]+\s*}}/g.test(value);
                return (
                    <VStack
                        as={FormControl}
                        w="full"
                        isRequired={required}
                        align="flex-start"
                        spacing={1}
                        isInvalid={!!error}
                    >
                        {!!label && (
                            <HStack spacing={0}>
                                <FormLabel mb={0} marginInlineEnd={1} fontWeight="medium" fontSize="sm">
                                    {`${label} `}
                                    {!required && (
                                        <Text as="span" color="gray.500" fontWeight="normal">
                                            {"(optional)"}
                                        </Text>
                                    )}
                                </FormLabel>
                                {formHelperText ? <InfoTooltip text={formHelperText} /> : null}
                            </HStack>
                        )}

                        <ExpressionInput
                            {...inputProps}
                            isRequired={required}
                            data-cy={dataCy}
                            value={value}
                            onChange={onChange}
                            defaultValue={defaultValue}
                            isInvalid={Boolean(error)}
                            errorBorderColor="red.300"
                            bg="white"
                            maxChar={!hasVariable && maxChar}
                            IntegrationFieldVariables={IntegrationFieldVariables}
                            isLoading={isLoading}
                        />
                        <FormErrorMessage>{error}</FormErrorMessage>
                    </VStack>
                );
            }}
        />
    );
};

export default IntegrationInputWithVariable;
