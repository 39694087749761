import { Image, VStack, Text } from "@chakra-ui/react";
import React from "react";
import NoImportContact from "assets/import-contact/no-import-contact.svg";
import NewImportButton from "./NewImportButton";

const ImportContactEmptyPage: React.FC = () => {
    return (
        <VStack w="full" mx="auto" h="95%" justify="center" spacing={4}>
            <Image src={NoImportContact} />
            <VStack spacing={1}>
                <Text color="gray.700" fontSize="lg" fontWeight="bold">
                    No Import Contacts
                </Text>
                <Text fontSize="sm" color="gray.500" textAlign="center">
                    Import your customer base into Gallabox
                    <br />
                    to kick-start the conversations.
                </Text>
            </VStack>
            <NewImportButton />
        </VStack>
    );
};

export default ImportContactEmptyPage;
