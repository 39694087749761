import { COOKIE_DOMAIN_ENV } from "environment";
import captureException from "./captureException";

export interface SourceInformation {
    cu?: string;
    lu?: string;
    ru?: string;
    du?: string;
}
const keyPrefix = "tracker_";
const name = "__utmz";

const GlobalScope = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof global !== "undefined") {
        return global;
    }
})();

const getCookie = (name: string) => {
    try {
        const cookieArray = document.cookie.split(";");
        const cookieName = `${name}=`;

        for (let cookie of cookieArray) {
            cookie = cookie.trim();
            if (cookie.indexOf(cookieName) === 0) return cookie.substring(cookieName.length);
        }
        return null;
    } catch (e) {
        captureException(e);
        return null;
    }
};

const setCookieStorage = (name: string, value: any, expirationDays?: number) => {
    try {
        if (!Object.keys(value).length) {
            return;
        }
        const cookieDomain = COOKIE_DOMAIN_ENV;
        const expireData = new Date(new Date().getTime() + (expirationDays ?? 30) * 60 * 24 * 60 * 1000);

        if (!cookieDomain) {
            console.warn("Cookie domain is not set");
            return;
        }
        document.cookie = `${keyPrefix + name}=${JSON.stringify(
            value
        )};expires=${expireData};domain=${cookieDomain};path=/`;
    } catch (e) {
        captureException(e);
    }
};

export const getUtmAndReferrer = (): SourceInformation => {
    try {
        const attr = JSON.parse(getCookie(keyPrefix + name) ?? "{}");
        const duAttr = JSON.parse(getCookie(keyPrefix + name + "_du") ?? "{}");
        return { ...duAttr, ...attr };
    } catch (error) {
        console.log("getUtmAndReferrer::error::", JSON.stringify(error));
        console.log("getUtmAndReferrer::cookie", getCookie(keyPrefix + name));
        captureException(error);
        return {};
    }
};

const _getLocationPathWithQuery = () => {
    const { pathname, search } = GlobalScope?.location || {};
    return `${pathname ?? ""}${search ?? ""}`;
};

const _getReferrer = () => {
    const ref = document?.referrer ?? "";
    if (ref) {
        try {
            const referrerUrl = new URL(ref);
            const isGallabox =
                referrerUrl.hostname.toLowerCase() === "gallabox.com" ||
                referrerUrl.hostname.toLowerCase() === "app.gallabox.com";
            if (isGallabox) return "";
        } catch (e) {
            console.error(e);
        }
    }
    return ref;
};

export const urlTracker = (): void => {
    const previousValues = getUtmAndReferrer();
    const currentPathName = _getLocationPathWithQuery();
    const referrerUrl = _getReferrer();

    const lu = previousValues?.lu ?? currentPathName;

    const currentAttributes = {
        cu: currentPathName,
        lu,
        ru: previousValues?.ru ?? referrerUrl,
    };
    setCookieStorage(name, currentAttributes, 1);

    if (previousValues?.du) return;

    const discoveryAttributes = {
        du: lu,
    };
    setCookieStorage(name + "_du", discoveryAttributes, 30);
};
