import { Box, Button } from "@chakra-ui/react";
import React from "react";

interface InfiniteLoaderComponentProps {
    onClick: () => void;
    isFetchingNextPage: boolean;
    hasNextPage: boolean;
    targetRef: React.RefObject<HTMLDivElement>;
}

const InfiniteLoaderComponent: React.FC<InfiniteLoaderComponentProps> = (props) => {
    const { onClick, hasNextPage, isFetchingNextPage, targetRef } = props;
    return (
        <React.Fragment>
            <Box h="10px" ref={targetRef} />
            <Button
                onClick={onClick}
                isLoading={isFetchingNextPage}
                isDisabled={!hasNextPage}
                colorScheme="blue"
                size="sm"
                borderRadius="6px"
            >
                <span>{isFetchingNextPage ? "Loading More..." : hasNextPage ? "Load More" : "No more to load"}</span>
            </Button>
        </React.Fragment>
    );
};

export default InfiniteLoaderComponent;
