import React from "react";

interface UseArrayPaginationProps<T extends any = any> {
    type: "loop" | "no-loop";
    data: T[];
    defaultIndex?: number;
    splice?: number;
}

interface UseArrayPaginationResult<T extends any = any> {
    currentIndex: number;
    setCurrentIndex: (index: number) => void;
    noNextPage: boolean;
    noPrevPage: boolean;
    goToNext: () => void;
    goToPrevious: () => void;
    currentData: T | T[];
}
export const useArrayPagination = <T extends any = any>(
    props: UseArrayPaginationProps<T>
): UseArrayPaginationResult<T> => {
    const { type, data, defaultIndex, splice = 1 } = props;

    const modifiedData = React.useMemo(() => {
        if (splice <= 1) return data;
        const newData = [...data];
        const newArr = [];
        while (newData.length) newArr.push(newData.splice(0, splice));
        return newArr;
    }, [splice, data]);

    const defaultIdx = React.useMemo(() => {
        if (typeof defaultIndex !== "number") return 0;
        if (defaultIndex < 0 || defaultIndex > modifiedData.length - 1) return 0;
        return defaultIndex;
    }, []);

    const [currentIndex, setCurrentIndex] = React.useState<number>(defaultIdx);

    const noNextPage = currentIndex >= modifiedData.length - 1;
    const noPrevPage = currentIndex === 0;
    const isLoop = type === "loop";

    const goToNext = React.useCallback(() => {
        if (!isLoop && noNextPage) return;
        if (noNextPage) {
            setCurrentIndex(0);
        } else {
            setCurrentIndex(currentIndex + 1);
        }
    }, [currentIndex, isLoop, noNextPage]);

    const goToPrevious = React.useCallback(() => {
        if (!isLoop && noPrevPage) return;
        if (noPrevPage) {
            setCurrentIndex(modifiedData.length - 1);
        } else {
            setCurrentIndex(currentIndex - 1);
        }
    }, [isLoop, noPrevPage, modifiedData.length, currentIndex]);

    return {
        currentData: modifiedData[currentIndex],
        currentIndex,
        goToNext,
        goToPrevious,
        noNextPage,
        noPrevPage,
        setCurrentIndex,
    };
};
