import React from "react";

const NavContainer = () => {
    return <div />;
};

const Footer = () => {
    return <div />;
};

const DefaultLayout: React.FC<{
    component: React.ComponentType<any>;
    rest?: any[];
}> = ({ component: Component, ...rest }) => (
    <div id="wrap">
        <NavContainer />
        <div className="container">
            <Component {...rest} />
        </div>
        <Footer />
    </div>
);

export default DefaultLayout;
