import GallaboxLoadingState from "app/components/GBLoadingState";
import { CalendlyWorkflow, Integration } from "app/types/integration";
import { useQueryParam } from "app/utils/history";
import groupBy from "lodash/groupBy";
import React, { FC } from "react";
import CalendlyUseCase from "./CalendlyUseCase";
import CalendlyWorkflowLists from "./CalendlyWorkflowLists";
import CalendlyWorkFlows from "./CalendlyWorkFlows";

interface CalendlyWorkFlowWrapperProps {
    data: Integration | undefined;
    isLoading: boolean;
}

const CalendlyWorkFlowWrapper: FC<CalendlyWorkFlowWrapperProps> = ({ data, isLoading }) => {
    const param = useQueryParam();
    const selectedUseCase = param.get("usecase") as CalendlyWorkflow["useCase"];
    const isNew = param.get("state") ?? undefined;
    const workflowId = param.get("workflowId") ?? undefined;
    const groupedWorkflows = groupBy(data?.calendly?.workflows, "calendly.useCase");
    const workFlowsList = groupedWorkflows[selectedUseCase] ?? [];
    const showWorkFlow = workFlowsList?.length === 0 || Boolean(isNew) || Boolean(workflowId);

    if (isLoading) return <GallaboxLoadingState />;

    if (showWorkFlow) return <CalendlyWorkFlows data={data} isLoading={isLoading} />;

    return <CalendlyWorkflowLists data={data} isLoading={isLoading} />;
};

export default CalendlyWorkFlowWrapper;
