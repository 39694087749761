import React from "react";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Text, Flex, Icon, HStack, keyframes, Divider } from "@chakra-ui/react";
import range from "lodash/range";

type StepperSize = "md" | "sm";

interface GBStepperProps {
    noOfSteps: number;
    activeStep: number;
    size?: StepperSize;
    stepName?: string[];
}

const animation = keyframes`
0% {
  transform: scale(0.1);
}
20% {
  transform: scale(0.3);
}
40% {
  transform: scale(0.5);
}
60% {
  transform: scale(0.7);
}
80% {
  transform:scale(0.9);
}
100%{
  transform:scale(1);
}
`;

interface StepperStyleType {
    containerSize: string;
    iconSize: string;
    fontSize: string;
}
const sizeDetails = new Map<StepperSize, StepperStyleType>([
    ["sm", { containerSize: "24px", iconSize: "16px", fontSize: "2xs" }],
    ["md", { containerSize: "32px", iconSize: "20px", fontSize: "md" }],
]);
const GBStepper: React.FC<GBStepperProps> = (props) => {
    const { noOfSteps, activeStep, size = "md", stepName } = props;

    const stepperSize = sizeDetails.get(size);
    const { containerSize, iconSize, fontSize } = stepperSize ?? {
        containerSize: "32px",
        iconSize: "20px",
        fontSize: "md",
    };

    const steps = range(noOfSteps);
    return (
        <HStack w="full">
            {steps.map((step, index) => {
                const isDone = step < activeStep;
                const isActive = step === activeStep;
                const isLastStep = step === steps.length - 1;
                const currentStepName = stepName?.[index];
                return (
                    <>
                        <Flex
                            boxSize={containerSize}
                            flexShrink={0}
                            flexGrow={0}
                            bg={isDone ? "brand.500" : "gray.100"}
                            rounded="full"
                            align="center"
                            justify="center"
                            borderColor={isActive ? "brand.500" : undefined}
                            borderWidth={isActive ? "2px" : undefined}
                            key={step}
                        >
                            {isDone ? (
                                <Flex
                                    boxSize={iconSize}
                                    align="center"
                                    justify="center"
                                    animation={`${animation} 1 0.2s`}
                                >
                                    <Icon as={RiCheckboxCircleFill} w="full" h="full" color="white" />
                                </Flex>
                            ) : (
                                <Text fontSize="sm" color="gray.800">
                                    {step + 1}
                                </Text>
                            )}
                        </Flex>
                        {currentStepName && (
                            <Text whiteSpace="nowrap" fontSize={fontSize} color="gray.700">
                                {currentStepName}
                            </Text>
                        )}
                        {!isLastStep && (
                            <Divider
                                orientation="horizontal"
                                borderTopWidth="1.5px"
                                borderColor={isDone ? "brand.500" : undefined}
                            />
                        )}
                    </>
                );
            })}
        </HStack>
    );
};

export default GBStepper;
