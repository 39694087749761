import { VStack, Button, Text } from "@chakra-ui/react";
import { openSupportTicketWidget } from "app/screens/Widget/utils";
import React from "react";
import { RAISE_A_TICKET, PAGE_HAS_BEEN_FORCE_REFRESHED } from "./constant";

const ReloadAlertFooter = () => {
    const onReload = () => {
        window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, "true");
        window.location.reload();
    };

    return (
        <VStack>
            <Button
                ml={3}
                colorScheme="brand"
                fontSize="sm"
                fontWeight="normal"
                rounded="md"
                size="md"
                onClick={onReload}
            >
                {"Reload"}
            </Button>
            <Text color="gray.400" fontSize="0.75rem" fontWeight="normal" whiteSpace="pre-line" overflowWrap="anywhere">
                {"If you are blocked, please "}
                <Button
                    size="xs"
                    textColor="blue.500"
                    fontWeight="normal"
                    variant="link"
                    onClick={openSupportTicketWidget}
                >
                    {RAISE_A_TICKET}
                </Button>
            </Text>
        </VStack>
    );
};

export default ReloadAlertFooter;
