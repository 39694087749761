import {
    Grid,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    VStack,
    ModalFooter,
    Button,
    Image,
    Text,
} from "@chakra-ui/react";
import { usePrepaidAlert } from "app/screens/PrepaidCredit/PrepaidCredit";
import React from "react";
import NoBalance from "assets/emoji-no-balance.svg";
import lowBalanceImg from "assets/low-balance-credit2.svg";
import { useHistory } from "react-router-dom";
import { useAccountMasterHook } from "app/hooks/useAccountMasterHook";
import { useWaChannels } from "app/utils/react-helpers";

const MessageCreditsModal: React.FC = () => {
    const history = useHistory();

    const { isFreeTrial } = useAccountMasterHook();
    const { lowBalance, negativeBalance, noWallet } = usePrepaidAlert();
    const channels = useWaChannels();

    const isContainsDialog360Provider = channels?.some(
        (c) => c?.whatsapp?.provider === "dialog360" || c?.whatsapp?.provider === "dialog360CloudApi"
    );

    const { isOpen, onClose } = useDisclosure({
        defaultIsOpen: !noWallet && (lowBalance || negativeBalance) && !isFreeTrial && isContainsDialog360Provider,
    });

    const handleCredits = () => {
        history.push("/message-credits");
        onClose();
    };

    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <Grid placeItems="center">
                        <Image h={60} src={negativeBalance ? NoBalance : lowBalanceImg} />
                        <VStack spacing={2}>
                            <Text fontSize="16px" fontWeight="bold" color="#000000">
                                Recharge Message Credits Now!
                            </Text>
                            <Text align="center" color="#718096" fontWeight="normal" fontSize="13px">
                                {negativeBalance
                                    ? "Looks like all your credits have been used. Add credits to your account  to continue messaging"
                                    : "Your message credits are running low. Recharge now and enjoy uninterrupted services."}
                            </Text>
                        </VStack>
                    </Grid>
                </ModalBody>
                <ModalFooter alignItems="center" justifyContent="center">
                    <Button
                        colorScheme="gray"
                        color="gray.600"
                        borderRadius="6px"
                        fontWeight="normal"
                        variant="ghost"
                        mr={3}
                        hidden={negativeBalance}
                        onClick={onClose}
                    >
                        Add later
                    </Button>
                    <Button onClick={handleCredits} fontWeight="normal" borderRadius="6px" colorScheme="blue">
                        Add Credits
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default MessageCreditsModal;
