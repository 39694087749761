import { TableColumnHeaderProps } from "@chakra-ui/react";
import { StylesObject } from "app/types";

export const tableStyles: StylesObject = {
    table: {
        fontSize: "sm",
        fontWeight: "normal",
        borderSpacing: 0,
        borderCollapse: "separate",
        h: "auto",
        display: "grid",
    },
    thead: {
        fontSize: "12px",
        textTransform: "uppercase",
        boxShadow: "base",
        borderWidth: "1px 1px 0px",
        borderColor: "gray.200",
        borderTopRadius: "6px",
        height: "auto",
        overflow: "hidden",
        bg: "blue.50",
    },
    tbody: {
        borderWidth: "0px 1px 0px",
        borderColor: "gray.200",
        boxShadow: "base",
        bgColor: "white",
        position: "relative",
        zIndex: "1",
    },
    tr: {
        _hover: {
            bgColor: "brand.50",
            cursor: "pointer",
        },
    },
    td: {
        m: 0,
        p: 2,
        position: "relative",
        color: "black",
    },
    resizer: {
        right: 0,
        w: 1,
        position: "absolute",
        top: 0,
        zIndex: 1,
        touchAction: "none",
        _hover: {
            bgColor: "gray.400",
            cursor: "col-resize",
        },
        height: "full",
    },
    isResizing: {
        bgColor: "gray.100",
        height: "1048px",
        w: "1px",
    },
};
export const headerStyles: Record<string, TableColumnHeaderProps> = {
    th: {
        ...(tableStyles.td as TableColumnHeaderProps),
        backgroundColor: "gray.100",
        letterSpacing: "0.05rem",
        fontWeight: "400",
        color: "gray.800",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    th_divider: {
        _groupHover: {
            borderRightWidth: "1px",
            borderRightColor: "gray.300",
        },
    },
    td: {
        ...(tableStyles.td as TableColumnHeaderProps),
        backgroundColor: "rgba(222, 239, 255, 0.8)",
        color: "#000000",
        fontWeight: "bold",
        p: "0",
    },
};
