import { trackEvent } from "app/hooks/productAnalytics/tracker";
import React from "react";

export const useNavigationTrackEvent = (props: { isCollapsed: boolean }) => {
    const trackLeftNavEvent = React.useCallback(
        (event: string) => {
            trackEvent({
                event: props.isCollapsed ? "LNAV_COLLAPSE" : "LNAV_OPEN",
            });
            trackEvent({
                event,
            });
        },
        [props.isCollapsed]
    );

    return trackLeftNavEvent;
};
