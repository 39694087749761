import { ValidPropertyType } from "@amplitude/analytics-types";
import { User } from "app/types";

export const sanitizeUser = (user: User): Record<string, ValidPropertyType> => {
    const { id: UserId, name: UserName, accounts = [], email: UserEmail } = user;
    const [acc] = accounts;
    const { accountId: AccountId, account, role } = acc ?? {};
    const { name: RoleName = "" } = role ?? {};
    const {
        name: AccountName = "",
        onboardingState: AccountOnBoardingState = "",
        status: AccountStatus = "",
    } = account ?? {};

    const userLog: Record<string, ValidPropertyType> = {
        UserId,
        UserName,
        UserEmail,
        RoleName,
        AccountId,
        AccountName,
        AccountOnBoardingState,
        AccountStatus,
    };
    return userLog;
};
