import { Icon } from "@chakra-ui/icons";
import React, { FC, useEffect, useState } from "react";
import { GrConnect } from "react-icons/gr";
import EnvironmentAlert from "./EnvironmentAlert";

const AlertNetworkConnection: FC = () => {
    const [isOnline, setOnline] = useState<boolean>(true);

    const handleOffline = React.useCallback(() => {
        setOnline(false);
    }, []);

    const handleOnline = React.useCallback(() => {
        setOnline(true);
    }, []);

    return (
        <>
            {!isOnline && (
                <EnvironmentAlert
                    title="No Internet Connection"
                    desc="Your computer is currently offline. Please check your network settings."
                    icon={<Icon as={GrConnect} title={"No Connection"} />}
                />
            )}
            <ListenNetworkConnection onOnline={handleOnline} onOffline={handleOffline} />
        </>
    );
};

interface ListenNetworkConnectionProps {
    onOnline?: () => void;
    onOffline?: () => void;
}

export const ListenNetworkConnection: React.FC<ListenNetworkConnectionProps> = ({ onOnline, onOffline }) => {
    const handleOnline = () => {
        console.log("I am online...");
        if (onOnline) {
            onOnline();
        }
    };
    const handleOffline = () => {
        console.log("I am offline...");
        if (onOffline) {
            onOffline();
        }
    };
    useEffect(() => {
        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    });
    return null;
};

export default AlertNetworkConnection;
