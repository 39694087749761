import { lazy, ComponentType } from "react";
import { PAGE_HAS_BEEN_FORCE_REFRESHED } from "./constant";
import ReloadAlert from "./ReloadAlert";

const lazyWithRetry = (
    componentImport: () => Promise<{
        default: ComponentType<any>;
    }>
) => {
    return lazy(async () => {
        let pageHasAlreadyBeenForceRefreshed;

        try {
            pageHasAlreadyBeenForceRefreshed = JSON.parse(
                window.localStorage.getItem(PAGE_HAS_BEEN_FORCE_REFRESHED) || "false"
            );
            const component = await componentImport();

            window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, "false");

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                // Assuming that the user is not on the latest version of the application.
                // Let's refresh the page immediately.
                return {
                    default: ReloadAlert,
                };
            }

            // The page has already been reloaded
            // Assuming that user is already using the latest version of the application.
            // Let's let the application crash and raise the error.
            throw error;
        }
    });
};
export default lazyWithRetry;
