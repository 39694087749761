import { Dialog360CallbackObject, Dialog360ConnectButtonProps } from "app/types/dialog360";
import React, { FC, useEffect } from "react";
import { openSignInWindow } from "./openPopup";

const ConnectButton: FC<Dialog360ConnectButtonProps> = (props) => {
    const {
        className,
        partnerId,
        callback,
        requestedNumber,
        label = "Connect 360dialog",
        env = "prod",
        queryParameters,
        onClick,
        ...otherProps
    } = props;
    const baseUrl =
        (env === "local" && "http://0.0.0.0:8082") ||
        (env === "staging" && "https://staging-admin.360dialog.io") ||
        (env === "rc" && "https://rc-admin.360dialog.io") ||
        "https://hub.360dialog.com";

    let permissionUrl = requestedNumber
        ? `${baseUrl}/dashboard/app/${partnerId}/permissions?number=${requestedNumber}`
        : `${baseUrl}/dashboard/app/${partnerId}/permissions`;

    if (queryParameters) {
        const values = Object.values(queryParameters);
        Object.keys(queryParameters).forEach((k, idx) => {
            if (idx === 0 && !requestedNumber) {
                permissionUrl = permissionUrl + `?${k}=${values[idx]}`;
            } else {
                permissionUrl = permissionUrl + `&${k}=${values[idx]}`;
            }
        });
    }

    const getUrlParameter = (params: string, name: string) => {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
        const results = regex.exec(params);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    useEffect(() => {
        const params = window.location.search;
        const client = getUrlParameter(params, "client");
        const channels = getUrlParameter(params, "channels");
        const revokedChannels = getUrlParameter(params, "revoked");

        if (client && channels) {
            const channelsArray = channels.substring(1, channels.length - 1).split(",");

            const callbackObj: Dialog360CallbackObject = {
                client: client,
                channels: channelsArray,
            };

            if (revokedChannels) {
                const revokedChannelsArray = revokedChannels.substring(1, revokedChannels.length - 1).split(",");
                callbackObj["revokedChannels"] = revokedChannelsArray;
            }
            callback(callbackObj);

            // remove search parameters from URL after fetching them
            // window.history.replaceState(null, "", window.location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <button
            className={className ? className : "360dialog-connect-button"}
            onClick={(e) => {
                openSignInWindow(
                    permissionUrl,
                    "connect-360dialog",
                    queryParameters?.redirect_url ?? window.location.origin
                );
                onClick?.(e);
            }}
            {...otherProps}
        >
            {label}
        </button>
    );
};

export default ConnectButton;
