import { HStack, Icon, VStack, Text } from "@chakra-ui/react";
import { ImportContact } from "app/types/entity";
import React from "react";
import { NonUndefined } from "react-hook-form";
import { RiInformationLine } from "react-icons/ri";

type ImportType = NonUndefined<ImportContact["importType"]>;

const infoDetails: Record<ImportType, any> = {
    CREATE: {
        title: "Add new",
        description:
            "Only new contacts will be created, if existing contacts exist in the uploaded file, we will drop those contacts",
    },
    UPSERT: {
        title: "Add new and update existing",
        description: "New contacts will be created and existing will be modified.",
    },
};

interface ImportInfoCompInfoProps {
    type: ImportType;
}

const ImportInfoCompInfo: React.FC<ImportInfoCompInfoProps> = (props) => {
    const { title, description } = infoDetails[props.type];

    return (
        <VStack
            w="full"
            p={3}
            bg="gray.100"
            align="start"
            borderColor="gray.200"
            borderWidth="1px"
            rounded="lg"
            spacing={0.5}
        >
            <HStack w="full">
                <Icon as={RiInformationLine} color="gray.500" fontSize="xl" />
                <Text fontWeight="medium" color="gray.500" fontSize="sm">
                    You chose {`"${title}"`}
                </Text>
            </HStack>
            <Text color="gray.400" fontSize="xs" ml={7}>
                {description}
            </Text>
        </VStack>
    );
};

export default ImportInfoCompInfo;
