import { Action } from "app/types/action";
import { QueryKey, TQueryKey } from "app/types/common";
import { fetcher, mapQueryParams } from "app/utils/fetchUtils";
import { QueryFunction, useQuery, UseQueryOptions, UseQueryResult } from "react-query";

export type ActionModule = "conversation" | "contact" | "company" | "bot" | "sequence";
export type ActionRequestModule = "BOT_PROMPT" | "BOT_FLOW" | "BOT" | "INTEGRATION";

export interface ActionListOptions {
    requestModule: ActionRequestModule;
    modules: ActionModule[];
    includeDateFields?: boolean;
}

export interface UseGetActionsProps<T> extends UseQueryOptions<T[], Error, T[], TQueryKey> {
    accountId: string;
    options?: ActionListOptions;
}

export const useGetActions = <T = Action>(props: UseGetActionsProps<T>): UseQueryResult<T[], Error> => {
    const { accountId, options = {}, ...queryOptions } = props;
    const fetchActions: QueryFunction<T[], TQueryKey> = ({ queryKey }) => {
        const [, { accountId, options }] = queryKey;
        const queryParams = mapQueryParams(options);
        const url = queryParams
            ? `/api/accounts/${accountId}/actions?${queryParams}`
            : `/api/accounts/${accountId}/actions`;
        return fetcher(url);
    };
    return useQuery<T[], Error, T[], TQueryKey>(
        [QueryKey.ActionList, { accountId, options }],
        fetchActions,
        queryOptions
    );
};
