/* eslint-disable react/display-name */
import { Box, HStack, Icon, Progress, Text, VStack } from "@chakra-ui/react";
import GBCircularProgressbar from "app/components/GBCircularProgressbar";
import GBTooltip from "app/components/GBTooltip";
import InfoTooltip from "app/components/InfoTooltip";
import StatusLabel from "app/components/StatusLabel";
import TableHeaderText from "app/gbComponents/Headers/TableHeaderText";
import { QueryKey } from "app/types";
import { ImportContact } from "app/types/entity";
import { formatDateGroup } from "app/utils/date";
import { SocketEvents, useSocket } from "app/utils/use-socketio";
import dayjs from "dayjs";
import React from "react";
import { RiContactsBook2Line } from "react-icons/ri";
import { useQueryClient } from "react-query";
import { importTypeOptions } from "../../../UploadContacts/UploadContactForm";
import { ImportContactHistoryTableColumnType, ImportProgressType } from "../types";
import ImportContactHistoryMenu from "./ImportContactHistoryMenu";

interface UseListenEntityImportUpdatesProps {
    importData?: ImportContact;
}
export const useListenEntityImportUpdates = (props: UseListenEntityImportUpdatesProps = {}) => {
    const { importData } = props;
    const queryClient = useQueryClient();

    const [progressData, setProgressData] = React.useState<ImportProgressType | null>(null);

    useSocket(SocketEvents.EntityImportUpdates, (data: ImportProgressType) => {
        const isImportProcessCompleted = !["parsing", "processing"].includes(data?.step ?? "");
        if (!importData) {
            if (isImportProcessCompleted) {
                queryClient.invalidateQueries([QueryKey.ContactsList]);
            }
            return;
        }

        if (data.id !== importData.id) return;

        if (isImportProcessCompleted && importData.status === "PROCESSING") {
            queryClient.invalidateQueries([QueryKey.ContactImportListPagination]);
            queryClient.invalidateQueries([QueryKey.ContactsList]);
        }
        setProgressData(data);
    });
    return { progressData };
};

interface StatusColumnProps {
    importData: ImportContact;
    rowIndex?: number;
    dataCy?: string;
}
const StatusColumn: React.FC<StatusColumnProps> = (props) => {
    const { importData } = props;

    const value = importData.status;
    const failedReason = importData.failedReason;

    const { progressData } = useListenEntityImportUpdates({ importData });
    if (value === "PARSING" || progressData?.step === "parsing") {
        const { currentCount: currentCountNew } = progressData ?? {};
        const { currentCount: currentCountOld } = importData;
        const currentCount = currentCountNew || currentCountOld || 0;

        return (
            <VStack w="full">
                <Text color="blue.500" fontSize="xs" isTruncated maxW="full">
                    Parsing ({currentCount})
                </Text>
                <Progress size="xs" w="full" hasStripe isIndeterminate rounded="full" bg="blue.100" />
            </VStack>
        );
    }
    if (value === "PROCESSING" || progressData?.step === "processing") {
        const { totalCount, currentCount: currentCountNew } = progressData ?? {};
        const { createdCount, updatedCount, totalRows, currentCount: currentCountOld } = importData;
        const currentCount = currentCountNew || currentCountOld || 0;
        const totalRecordCount = totalCount ?? totalRows ?? 0;
        const processedCount = currentCount ?? (createdCount ?? 0) + (updatedCount ?? 0);
        const progressValue = (processedCount / totalRecordCount) * 100;
        return (
            <VStack w="full">
                {totalRecordCount && (
                    <Text fontSize="xs" color="yellow.500" maxW="full" isTruncated>
                        Processing ({processedCount} / {totalRecordCount})
                    </Text>
                )}
                <Progress
                    value={progressValue ? progressValue : undefined}
                    size="xs"
                    bg="yellow.100"
                    rounded="full"
                    colorScheme="yellow"
                    w="full"
                    hasStripe
                    isIndeterminate={!processedCount}
                />
            </VStack>
        );
    }
    return (
        <GBTooltip label={failedReason}>
            <span>
                <StatusLabel module="contact-import-history" status={value} />
            </span>
        </GBTooltip>
    );
};

export const importContactHistoryTableColumns: ImportContactHistoryTableColumnType[] = [
    {
        header: () => <TableHeaderText w="full" pl={6} header="NAME" />,
        id: "NAME",
        minSize: 200,
        cell: React.memo(({ cell }) => {
            const { original: rowOriginal } = cell.row;
            return (
                <Text pl={6} isTruncated maxW="full" title={rowOriginal.name}>
                    {rowOriginal.name}
                </Text>
            );
        }),
        size: 200,
        enableColumnFreezing: true,
    },
    {
        header: () => <TableHeaderText w="full" header="IMPORTED ON" />,
        id: "IMPORTED ON",
        minSize: 150,
        size: 150,
        cell: React.memo(({ cell }) => {
            const { original: rowOriginal } = cell.row;
            const tooltip = React.useMemo(() => {
                return (
                    <VStack minW="250px" maxW="350px">
                        <HStack w="full">
                            <Text whiteSpace="nowrap">Imported by:</Text>
                            <Text whiteSpace="nowrap">
                                {rowOriginal?.creatorId?.name ? rowOriginal?.creatorId?.name : "-"}
                            </Text>
                        </HStack>
                        <HStack w="full">
                            <Text whiteSpace="nowrap">Imported at:</Text>
                            <Text whiteSpace="nowrap">
                                {rowOriginal.createdAt ? formatDateGroup(rowOriginal.createdAt) : "-"} |{" "}
                                {rowOriginal.createdAt ? dayjs(rowOriginal.createdAt).format("h:mm A") : "-"}
                            </Text>
                        </HStack>
                    </VStack>
                );
            }, [rowOriginal.createdAt, rowOriginal?.creatorId?.name]);

            if (!rowOriginal.createdAt) return <>-</>;
            const formattedDate = rowOriginal.createdAt ? formatDateGroup(rowOriginal.createdAt) : "";
            return (
                <HStack spacing={1} w="full">
                    <Text color="gray.800" whiteSpace="nowrap" maxW="full" isTruncated title={formattedDate}>
                        {formattedDate}
                    </Text>
                    <InfoTooltip
                        text={tooltip}
                        infoIconProps={{ color: "gray.400" }}
                        // containerProps={{ marginBlockEnd: "0.5 !important" }}
                    />
                </HStack>
            );
        }),
    },
    {
        header: () => <TableHeaderText w="full" header="IMPORT TYPE" />,
        id: "IMPORT TYPE",
        minSize: 100,
        cell: React.memo(({ cell }) => {
            const { original: rowOriginal } = cell.row;
            const value = importTypeOptions.find((option) => option.value === rowOriginal?.importType)?.label ?? "-";
            return (
                <Text isTruncated maxW="full" title={value}>
                    {value}
                </Text>
            );
        }),
    },
    {
        header: () => (
            <TableHeaderText
                w="full"
                header="TOTAL CONTACTS"
                description="The total number of contacts we attempted to add or update"
            />
        ),
        id: "TOTAL CONTACTS",
        minSize: 100,
        cell: React.memo(({ cell }) => {
            const { original: rowOriginal } = cell.row;
            const value = JSON.stringify(rowOriginal.totalRows) ?? "-";
            return (
                <HStack spacing={1}>
                    <Icon as={RiContactsBook2Line} color="gray.600" />
                    <Text isTruncated color="gray.700" maxW="full" title={value}>
                        {value}
                    </Text>
                </HStack>
            );
        }),
    },
    {
        header: () => (
            <TableHeaderText
                w="full"
                header="UPDATED CONTACTS"
                description="The contacts that were successfully added or updated"
            />
        ),
        id: "UPDATED CONTACTS",
        minSize: 100,
        cell: React.memo(({ cell }) => {
            const { original: rowOriginal } = cell.row;
            const { progressData } = useListenEntityImportUpdates({ importData: rowOriginal });
            const {
                createdCount: liveCreatedCount,
                updatedCount: liveUpdatedCount,
                totalCount: liveTotalCount,
            } = progressData ?? {};
            const { totalRows, updatedCount, createdCount } = rowOriginal;
            const totalRecords = liveTotalCount || (totalRows ?? 0);
            const updatedRecords = liveUpdatedCount || (updatedCount ?? 0);
            const createdRecords = liveCreatedCount || (createdCount ?? 0);
            if (
                typeof totalRecords !== "number" ||
                typeof updatedRecords !== "number" ||
                typeof createdRecords !== "number"
            )
                return <>-</>;
            return (
                <GBCircularProgressbar
                    total={totalRecords}
                    currentValue={updatedRecords + createdRecords}
                    color="green.500"
                />
            );
        }),
    },
    {
        header: () => (
            <TableHeaderText
                w="full"
                header="ERRORED CONTACTS"
                description="Contacts that couldn't be added or updated for some reason"
            />
        ),
        id: "ERRORED CONTACTS",
        minSize: 100,
        cell: React.memo(({ cell }) => {
            const { original: rowOriginal } = cell.row;
            const { progressData } = useListenEntityImportUpdates({ importData: rowOriginal });
            const { errorCount: liveErrorCount, totalCount: liveTotalCount } = progressData ?? {};
            const { totalRows, erroredCount } = rowOriginal;
            const totalRecords = liveTotalCount || (totalRows ?? 0);
            const erroredRecords = liveErrorCount || (erroredCount ?? 0);

            if (typeof totalRecords !== "number" || typeof erroredRecords !== "number") return <>-</>;
            return <GBCircularProgressbar total={totalRecords} currentValue={erroredRecords} color="red.500" />;
        }),
    },
    {
        header: () => <TableHeaderText w="full" header="STATUS " />,
        id: "STATUS ",
        cell: React.memo(({ cell }) => {
            const { original: rowOriginal } = cell.row;
            return <StatusColumn importData={rowOriginal} />;
        }),
        minSize: 100,
        size: 170,
    },
];

export const importContactHistoryActionColumns: ImportContactHistoryTableColumnType = {
    header: "",
    id: "action-item",
    enableResizing: false,
    isUserInteractiveCell: true,
    freezeTo: "right",
    enableColumnFreezing: true,
    size: 50,
    cell: React.memo(({ cell }) => {
        const { original: rowOriginal, index } = cell.row;
        return <ImportContactHistoryMenu importData={rowOriginal} rowIndex={index} dataCy={`${cell.row.index}-menu`} />;
    }),
};
