import { Button, ButtonProps, Icon, IconButton, IconButtonProps, Link } from "@chakra-ui/react";
import GBTooltip from "app/components/GBTooltip";
import { callFnsInSequence, getValidHref } from "app/utils/common";
import React from "react";
import { MdOutlineOpenInNew } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import { NavigationItem } from "../types";

export type NavigationButtonProps = Omit<NavigationItem, "buttonProps"> &
    Omit<ButtonProps, "onClick" | "icon" | "to" | "type"> & {
        isCollapsed?: boolean;
        showTooltip?: boolean;
        onToggle?: () => void;
    };

const NavigationButton: React.FC<NavigationButtonProps> = (navigationButtonProps) => {
    const {
        title,
        to,
        icon: LeftIcon,
        href,
        onClick,
        isCollapsed = false,
        hide,
        iconProps = {},
        badge,
        eventName,
        action,
        subject,
        showTooltip = true,
        closeNavigationOnItemSelect,
        onToggle,
        ...buttonProps
    } = navigationButtonProps;

    const closeNavigation = React.useCallback(() => {
        if (closeNavigationOnItemSelect && !isCollapsed) onToggle?.();
    }, [closeNavigationOnItemSelect, isCollapsed, onToggle]);

    const sanitizedProps = React.useMemo<Partial<IconButtonProps>>(() => {
        if (onClick) return { onClick: callFnsInSequence(onClick, closeNavigation) };

        if (href) {
            return {
                as: Link,
                href: getValidHref(href),
                target: "_blank",
                rel: "noopener noreferrer",
                isExternal: true,
                justifyContent: "space-between",
                rightIcon: <Icon as={MdOutlineOpenInNew} color="gray.500" fontSize="1rem" />,
            };
        }
        if (to) {
            return { as: RouterLink, to: to, onClick: closeNavigation };
        }
        return {};
    }, [onClick, closeNavigation, href, to]);

    const icon = React.useMemo(() => {
        if (!LeftIcon) return undefined;
        return <Icon as={LeftIcon} fontSize={"1rem"} color="gray.700" size="xs" {...iconProps} />;
    }, [LeftIcon, iconProps]);

    const iconButtonComp = React.useMemo(() => {
        if (!isCollapsed) return null;
        if (!showTooltip)
            return (
                <IconButton
                    aria-label={title}
                    data-cy={`navigation-icon-${title}`}
                    {...sanitizedProps}
                    {...buttonProps}
                    icon={icon}
                />
            );
        return (
            <GBTooltip label={title} placement="right">
                <IconButton
                    aria-label={title}
                    data-cy={`navigation-icon-${title}`}
                    {...sanitizedProps}
                    {...buttonProps}
                    icon={icon}
                />
            </GBTooltip>
        );
    }, [buttonProps, icon, isCollapsed, sanitizedProps, showTooltip, title]);

    if (isCollapsed) {
        return iconButtonComp;
    }

    return (
        <Button _hover={{ bg: "gray.100" }} {...buttonProps} {...sanitizedProps} leftIcon={icon}>
            {title}
        </Button>
    );
};
export default React.memo(NavigationButton);
