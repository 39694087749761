import { Action, Subject } from "app/config/abilities/ability";
import { StylesObject } from "app/types/common";
import webIcon from "assets/webchat.png";
import whatsappIcon from "assets/whatsapp-img.png";
import { IconType } from "react-icons";
import { AiFillDatabase } from "react-icons/ai";
import { BiBot, BiChart, BiPlug } from "react-icons/bi";
import { BsCashCoin, BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { CiViewList } from "react-icons/ci";
import {
    HiOutlineAnnotation,
    HiOutlineChat,
    HiOutlineCreditCard,
    HiOutlineCurrencyDollar,
    HiOutlineOfficeBuilding,
    HiOutlineSaveAs,
    HiOutlineSpeakerphone,
    HiOutlineTag,
    HiOutlineTemplate,
    HiOutlineUserCircle,
    HiOutlineUserGroup,
} from "react-icons/hi";
import { MdGpsFixed, MdNotificationsNone, MdOutlineFacebook, MdOutlineSmsFailed } from "react-icons/md";
import { RiContactsBook2Line } from "react-icons/ri";
import { TbTemplate, TbWebhook } from "react-icons/tb";
import { TiFlowChildren } from "react-icons/ti";
import { Option } from "./PopUpNavigator/types";

type NavLink = { name: string; Icon?: IconType; IconImage?: string; to: string; subject?: Subject; action?: Action };

export const CHANNELS: NavLink[] = [
    { name: "WhatsApp", IconImage: whatsappIcon, to: "/channels/whatsapp" },
    { name: "Web Chat", IconImage: webIcon, to: "/channels/web" },
];

export const CONFIGURATIONS: NavLink[] = [
    { name: "Account Details", Icon: HiOutlineUserCircle, to: "/account" },
    { name: "Users & Roles", Icon: HiOutlineUserGroup, to: "/roles", subject: "UsersAndRoles", action: "read" },
    { name: "Field Collection", Icon: HiOutlineCreditCard, to: "/fields", subject: "Fields" },
    { name: "Tags", Icon: HiOutlineTag, to: "/tags", subject: "Tags" },
    {
        name: "Canned Response",
        Icon: HiOutlineAnnotation,
        to: "/cannedresponse",
        subject: "CannedResponse",
        action: "create",
    },
    {
        name: "Whatsapp Templates",
        Icon: HiOutlineTemplate,
        to: "/whatsapp-template-messagesv2",
        subject: "WhatsappTemplate",
        action: "create",
    },
    { name: "Whatsapp flows", Icon: CiViewList, to: "/whatsapp-flows" },
    { name: "Catalogues", Icon: MdOutlineFacebook, to: "/facebook-catalogs", action: "create", subject: "Catalog" },
    {
        name: "Orders",
        Icon: MdOutlineFacebook,
        to: "/whatsapp-order",
        action: "read",
        subject: "WhatsappOrders",
    },
    { name: "Integration", Icon: BiPlug, to: "/integration", action: "read", subject: "Integration" },
    { name: "Payments", Icon: BsCashCoin, to: "/payments", action: "read", subject: "Payments" },
];

export const USER: NavLink[] = [
    { name: "Profile", Icon: HiOutlineUserCircle, to: "/profile" },
    { name: "API Keys", Icon: HiOutlineSaveAs, to: "/apikey", subject: "APIKey" },
];

export const ACCOUNTS: NavLink[] = [
    { name: "Subscription", Icon: HiOutlineCurrencyDollar, to: "/plan-and-subscription", subject: "Subscription" },
    { name: "Message Credits", Icon: HiOutlineCreditCard, to: "/message-credits", subject: "Wallet" },
    { name: "Message Tracker", Icon: MdGpsFixed, to: "/message-tracking", subject: "MessageTracker" },
    { name: "Webhooks", Icon: TbWebhook, to: "/webhook", subject: "Webhook" },
    { name: "Knowledge Base", Icon: AiFillDatabase, to: "/knowledge-base", subject: "KnowledgeBase" },
];

const channelItems: Option[] = CHANNELS.map((channel) => ({
    IconImage: channel.IconImage,
    label: channel.name,
    value: channel.name,
    navigationLink: channel.to,
    subject: "Channel",
    action: "update",
    groupName: "CHANNELS",
}));

const configItems: Option[] = CONFIGURATIONS.map((config) => ({
    IconImage: config.IconImage,
    Icon: config.Icon,
    label: config.name,
    value: config.name,
    navigationLink: config.to,
    subject: config?.subject ?? "Account",
    action: config?.action ?? "read",
    groupName: "CONFIGURATION",
}));
const userItems: Option[] = USER.map((user) => ({
    Icon: user.Icon,
    label: user.name,
    value: user.name,
    navigationLink: user.to,
    subject: user?.subject ?? "UsersAndRoles",
    action: user?.action ?? "read",
    groupName: "USER",
}));
const accountItems: Option[] = ACCOUNTS.map((acc) => ({
    Icon: acc.Icon,
    label: acc.name,
    value: acc.name,
    navigationLink: acc.to,
    groupName: "ACCOUNT",
    subject: acc?.subject ?? "Account",
    action: acc?.action ?? "read",
}));

const NAVIGATION_GROUP_NAMES = [
    "CONVERSATIONS",
    "CONTACTS",
    "BOTS",
    "BROADCAST",
    "ANALYTICS",
    "CONFIGURATION",
    "USER",
    "ACCOUNT",
    "CHANNELS",
] as const;

export type NavigationGroupName = (typeof NAVIGATION_GROUP_NAMES)[number];

export const navigationMenuItems: Option[] = [
    {
        Icon: HiOutlineChat,
        label: "Conversations",
        value: "Conversations",
        navigationLink: "/conversations",
        subject: "Conversation",
        action: "read",
        groupName: "CONVERSATIONS",
    },
    {
        Icon: RiContactsBook2Line,
        label: "Contacts",
        value: "Contacts",
        navigationLink: "/contacts",
        subject: "Contact",
        action: "read",
        groupName: "CONTACTS",
    },
    {
        Icon: HiOutlineOfficeBuilding,
        label: "Companies",
        value: "Companies",
        navigationLink: "/companies",
        subject: "Contact",
        action: "read",
        groupName: "CONTACTS",
    },

    {
        Icon: BiBot,
        label: "My Bots",
        value: "My Bots",
        navigationLink: "/bots",
        subject: "Bot",
        action: "read",
        groupName: "BOTS",
    },
    {
        Icon: TbTemplate,
        label: "Bot Templates",
        value: "Bot Templates",
        navigationLink: "/bot-templates",
        subject: "Bot",
        action: "read",
        groupName: "BOTS",
    },

    {
        Icon: HiOutlineSpeakerphone,
        label: "Broadcast",
        value: "Broadcast",
        navigationLink: "/broadcast",
        subject: "Conversation",
        action: "read",
        groupName: "BROADCAST",
    },
    {
        Icon: TiFlowChildren,
        label: "Sequence",
        value: "Sequence",
        navigationLink: "/sequence",
        subject: "Conversation",
        action: "read",
        groupName: "BROADCAST",
    },
    {
        Icon: BiChart,
        label: "Dashboard",
        value: "Dashboard",
        navigationLink: "/dashboard",
        subject: "Analytics",
        action: "read",
        groupName: "ANALYTICS",
    },
    {
        Icon: MdOutlineSmsFailed,
        label: "WA Failed Message",
        value: "WA Failed Message",
        navigationLink: "/analytics/report/failed-message",
        subject: "Analytics",
        action: "read",
        groupName: "ANALYTICS",
    },
    {
        Icon: MdNotificationsNone,
        label: "WA Notification Message",
        value: "WA Notification Message",
        navigationLink: "/analytics/report/notification-message",
        subject: "Analytics",
        action: "read",
        groupName: "ANALYTICS",
    },
    {
        Icon: BsFileEarmarkSpreadsheet,
        label: "Conversation Report",
        value: "Conversation Report",
        navigationLink: "/conversation-report",
        subject: "Analytics",
        action: "read",
        groupName: "ANALYTICS",
    },
    ...channelItems,
    ...configItems,
    ...userItems,
    ...accountItems,
];

export const appLayoutStyles: StylesObject = {
    container: {
        // display: "grid",
        height: "100vh",
        maxWidth: "1920px",
        marginX: "auto",
        boxShadow: "0 1px 1px 0 rgba(0,0,0,.06),0 2px 5px 0 rgba(0,0,0,.2)",
    },
    header: {
        borderColor: "#e4e4e4",
        fontFamily: "Inter",
        background: "#ffffff",
        boxShadow: "1px 3px 4px  rgba(0, 0, 0, 0.1)",
    },
    content: {
        backgroundColor: "#F2F2F2",
        zIndex: 0,
    },
    item: { fontWeight: "normal", color: "gray.600", rounded: "md" },
    activeItem: { bg: "blue.700", fontWeight: "medium", color: "white", rounded: "md" },
    hoverItem: { color: "gray.700", bg: "gray.100", rounded: "md" },
    onActiveItem: { color: "gray.700", bg: "gray.50", rounded: "md" },
    separator: {
        width: "6px",
        height: "6px",
        bg: "#3164F6",
        my: "auto",
        borderRadius: "5px",
    },
    search: {
        height: "36px",
        fontSize: "14px",
    },
    searchLeftIcon: {
        fontSize: "0.813rem",
        top: "-2px",
        pointerEvents: "none",
    },
    searchInput: {
        height: "36px",
        borderRadius: "full",
        paddingLeft: "36px",
        fontSize: "0.813rem",
    },
    setting: {
        color: "gray.600",
        fontWeight: "normal",
    },
    linkIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "rgba(0, 0, 0, 0.49)",
        mr: "5px",
    },
    avatar: {
        mr: "24px",
    },
    profileItem: {
        fontSize: "sm",
        color: "gray.600",
        _hover: { backgroundColor: "gray.100" },
    },
    helpItem: {
        color: "gray.700",
        bg: "purple.100",
        fontWeight: "normal",
        _hover: { bg: "purple.200" },
    },
};
export const homeRoutes = ["/account-basic-info", "/sandbox", "/getting-started", "/account-disconnect", "home"];

export const isRouteActive = (route: string): boolean => {
    const { pathname } = window.location;
    return pathname.indexOf(route) === 0;
};

export const checkActiveRoute = (route: string[]): boolean => {
    const routeCheck = route.map((r) => {
        if (isRouteActive(r)) return true;
    });
    return routeCheck.some((r) => r === true);
};
