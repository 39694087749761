import { Button, ButtonProps, Image } from "@chakra-ui/react";
import { trackEvent } from "app/hooks/productAnalytics/tracker";
import { mapQueryParams } from "app/utils/fetchUtils";
import { useQueryParam } from "app/utils/history";
import GoogleIcon from "assets/onboarding/google.svg";
import MicrosoftIcon from "assets/onboarding/Microsoft.svg";
import { SERVER_URL_ENV } from "environment";
import { transWithNameSpace } from "lang/langUtils";
import React from "react";

export const socialLoginType = ["google", "microsoft"] as const;
export type SocialLoginType = (typeof socialLoginType)[number];

type SocialLoginProps = {
    [key in SocialLoginType]: {
        icon: string;
    };
};

const socialLoginProps: SocialLoginProps = {
    google: {
        icon: GoogleIcon,
    },
    microsoft: {
        icon: MicrosoftIcon,
    },
};

interface SocialSignInBtnProps extends ButtonProps {
    btnText?: string;
    provider?: SocialLoginType;
    page?: "login" | "signup";
    dataCy?: string;
    skipWorkEmail?: string | null;
    isSkipWorkEmailByCountry?: boolean;
}

const trans = transWithNameSpace("auth");

const SocialSignInBtn: React.FC<SocialSignInBtnProps> = ({
    btnText = trans("Sign in with Google"),
    provider = "google",
    page = "login",
    dataCy,
    skipWorkEmail,
    isSkipWorkEmailByCountry,
    ...rest
}) => {
    const params = useQueryParam();
    const referralCode = params.get("referralCode");
    const queryParams = mapQueryParams({
        type: page,
        referralCode,
        skipWorkEmail,
        isSkipWorkEmailByCountry,
        version: "v2",
    });
    return (
        <Button
            variant="outline"
            fontSize="sm"
            fontWeight={500}
            width="full"
            color="gray.600"
            height="32px"
            onClick={() => {
                if (dataCy) trackEvent({ event: dataCy });
                window.open(SERVER_URL_ENV + `/auth/${provider}?${queryParams}`, "_self");
            }}
            {...rest}
        >
            <Image src={socialLoginProps[provider].icon} boxSize="4" mr="3" />
            {btnText}
        </Button>
    );
};

export default SocialSignInBtn;
