import React from "react";
import EmptyState, { EmptyStateProps } from "./EmptyState";
import { Grid } from "@chakra-ui/react";

export type EmptyStateCenteredProps = EmptyStateProps;

const EmptyStateCentered: React.FC<EmptyStateCenteredProps> = (props) => {
    return (
        <Grid placeItems="center" bg="transparent" w="full" h="full" maxH="full" maxW="full">
            <EmptyState {...props} />
        </Grid>
    );
};

export default EmptyStateCentered;
