import React from "react";
import TanstackTable, { TanstackTableProps } from "app/components/TanstackTable";
import TableBody from "app/components/TanstackTable/TableBody";
import TableHeader from "app/components/TanstackTable/TableHeader";
import PaginationFooter, { PaginationFooterProps } from "app/components/TanstackTable/TableFooter/PaginationFooter";
import { EmptyStateProps } from "app/components/EmptyState";
import { ImportContact } from "app/types/entity";
import { ImportContactHistoryTableColumnType } from "./types";

export interface ImportContactHistoryTableContentBaseProps {
    data: ImportContact[];
    columns: ImportContactHistoryTableColumnType[];
    isLoading: boolean;
    onRowClick: (val: ImportContact | null) => void;
    isError: boolean;
    tableRef?: TanstackTableProps<ImportContact>["tableRef"];
}

const emptyStateProps: EmptyStateProps = {
    title: "Import contacts",
    subtitle: "You have no imports to view",
};

export type ImportContactHistoryTableContentProps = ImportContactHistoryTableContentBaseProps & PaginationFooterProps;

const ImportContactHistoryTableContent: React.FC<ImportContactHistoryTableContentProps> = (props) => {
    const { data, isLoading, isError, columns, onRowClick, tableRef, ...paginationFooterProps } = props;
    return (
        <TanstackTable<ImportContact> data={data} columns={columns} enableColumnResizing={true} tableRef={tableRef}>
            <TableHeader<ImportContact> />
            <TableBody<ImportContact>
                isLoading={isLoading || ((!data || data.length < 1) && paginationFooterProps.isDataFetching)}
                onRowClick={onRowClick}
                error={isError}
                emptyStateProps={emptyStateProps}
            />
            {data && data.length > 1 && <PaginationFooter {...paginationFooterProps} isSticky />}
        </TanstackTable>
    );
};

export default ImportContactHistoryTableContent;
