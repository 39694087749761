/* eslint-disable @typescript-eslint/no-empty-interface */
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Grid, GridItem, Heading, HStack, Link, Spacer, Text, useToast, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import GBLogoWithBrandName from "app/components/GBLogoWithBrandName";
import Player from "app/components/Player/Player";
import SocialSignInBtn from "app/components/SocialSignInBtn";
import useLocalStorage from "app/hooks/useLocalStorage";
import useSocialSigninErrorHandler from "app/hooks/useSocialSigninErrorHandler";
import { authUser, checkIPRestriction, isIPRestricted, loginUser } from "app/modules/authed";
import * as fetchUtils from "app/utils/fetchUtils";
import { Input } from "app/utils/formUtils";
import { useGBTranslation } from "lang/hooks/useGBTranslation";
import { transWithNameSpace } from "lang/langUtils";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { StaticContext, useHistory } from "react-router";
import { RouteComponentProps, Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import { getmfaToken } from "app/utils/fetchUtils";
import styles from "../styles";
import { TWO_FA_LOCAL_STORAGE_ACCESSOR } from "./TwoFA/pages/TwoFANudge";
//TODO: Add logic for remember me

//TODO: Handle Error

interface SignInFormValues {
    email: string;
    password: string;
}

const defaultValues = {
    email: "",
    password: "",
};

const trans = transWithNameSpace("auth");

const schema = yup.object().shape({
    email: yup.string().trim().required(trans("Email is required")).email(trans("Provide a valid mail id")),
    password: yup.string().trim().required(trans("Password is required")),
});

const G2_URL = "https://www.g2.com/products/gallabox/reviews";

// eslint-disable-next-line @typescript-eslint/ban-types
interface SignInProps extends RouteComponentProps<{}, StaticContext, { from: { pathname: string; search?: string } }> {}

const SignIn: React.FC<SignInProps> = (props) => {
    const {
        from: { pathname, search },
    } = props.location?.state || { from: { pathname: "/home" } };

    const { t } = useGBTranslation(["auth", "common"]);

    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");

    const [isSubmitting, setSubmitting] = React.useState(false);

    const [, setTwoFALocalState] = useLocalStorage(TWO_FA_LOCAL_STORAGE_ACCESSOR, true, { storeInitialValue: true });

    const toast = useToast();

    useSocialSigninErrorHandler();

    React.useEffect(() => {
        setTwoFALocalState(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { register, errors, handleSubmit, watch } = useForm<SignInFormValues>({
        defaultValues,
        resolver: yupResolver<SignInFormValues>(schema),
    });

    const dispatch = useDispatch();
    const history = useHistory();

    const checkIsIPRestricted: Parameters<typeof loginUser>[2] = (err) => {
        if (!err) {
            history.push({ pathname, search });
            return;
        } else if (err && isIPRestricted(err)) {
            history.push("/ip-not-allowed", { ipErrMsg: err.message });
            return;
        }
        setSubmitting(false);
    };

    const onLoginSuccess: Parameters<typeof loginUser>[1] = (data) => {
        const { isTwoFARequired, mfaToken } = data;
        if (isTwoFARequired) {
            if (mfaToken) {
                history.push(`/two-factor-auth?c=${mfaToken}`);
                return;
            }

            toast({
                status: "error",
                title: "Login failed",
                description: "We couldn't able to login you, please try again.",
            });
            setSubmitting(false);
        }
    };

    const onLoginFailure: Parameters<typeof loginUser>[2] = (err) => {
        setSubmitting(false);
        checkIsIPRestricted(err);
    };
    const onSubmit: SubmitHandler<SignInFormValues> = (values: SignInFormValues) => {
        setSubmitting(true);
        dispatch(loginUser(values, onLoginSuccess, onLoginFailure));
    };

    // This handle social login's
    React.useEffect(() => {
        if (token) {
            dispatch(checkIPRestriction(null));
            fetchUtils.setAuthCookie(token);
            dispatch(authUser(token));
        }
    }, [dispatch, history, token]);

    return (
        <Grid
            templateColumns={{ base: "1fr", sm: "1fr", lg: "1.5fr 1fr" }}
            height="100vh"
            width={"full"}
            maxW="1600px"
            mx="auto"
        >
            <GridItem
                paddingLeft="32"
                pr="12"
                minWidth={"lg"}
                w={"full"}
                display={{ base: "none", sm: "none", lg: "flex" }}
                justifyContent="flex-start"
                bg="linear-gradient(270deg, #E5F4FF 35.21%, #FFFFFF 82.25%)"
            >
                <VStack minWidth={"lg"} spacing="5" align="start" pt="8">
                    <GBLogoWithBrandName
                        marginBottom={{ base: "10", sm: "10", lg: "3" }}
                        h={{ base: "10", sm: "10", lg: "8" }}
                    />
                    <Heading fontSize="24px" fontFamily="inter" fontWeight="700" w="2xl" maxH="full">
                        {t("Whats New")}
                    </Heading>
                    <Player
                        muted={false}
                        playing={false}
                        url={"https://www.youtube.com/watch?v=atnFtWLkN-o"}
                        height="250px"
                        width="450px"
                        controls
                        lazy={true}
                        config={{ file: { attributes: { controlsList: "nodownload" } } }}
                    />
                    <VStack spacing="2" align="start">
                        <Text fontSize="lg" fontWeight="600" lineHeight="7">
                            {/* {t("Streamline Your Conversations")} */}
                            {t("login_whatsnew_section_heading")}
                        </Text>
                        <Text fontSize="sm" lineHeight="7" textColor="gray.600">
                            {/* {t("WhatsApp Flows is changing the way businesses")} */}
                            {t("login_whatsnew_section_subheading")}
                        </Text>
                        <Link
                            href="https://docs.gallabox.com/bots/ask-questions/ask-location#ask-location-card"
                            fontSize={"md"}
                            maxW="full"
                            noOfLines={1}
                            target="_blank"
                            rel="noopener noreferrer"
                            isExternal={true}
                            color="blue.500"
                        >
                            {t("Learn More")}
                            <ExternalLinkIcon mx="6px" fontSize="14" />
                        </Link>
                    </VStack>
                </VStack>
            </GridItem>
            <GridItem
                display="flex"
                flexDirection="column"
                alignContent="flex-start"
                flexWrap="nowrap"
                height="100%"
                border="1px solid #E4E4E4"
                boxShadow="0px 9px 10px rgba(0, 0, 0, 0.05)"
                fontFamily="Inter"
                backgroundColor="#FFF"
                maxWidth="100%"
                overflow="hidden"
                paddingX={{ base: "0", sm: "0", lg: "0" }}
                paddingTop={{ base: "10", sm: "10", lg: "10" }}
            >
                <VStack h="90%" justify="center" align="start">
                    <Box paddingX={{ base: "8", sm: "8", lg: "20" }}>
                        <HStack
                            flexDirection="column"
                            justifyContent={{ base: "center", sm: "center", lg: "flex-start" }}
                            alignItems={{ base: "center", sm: "center", lg: "flex-start" }}
                            spacing={1}
                        >
                            <Heading
                                display="flex"
                                flexDirection="row"
                                fontWeight="bold"
                                sx={styles.heading}
                                ml={[0, "0 !important"]}
                            >
                                {t("Login to gallabox")}
                                {/* <Image paddingLeft={"2"} h={{ base: "6", sm: "6", lg: "8" }} src={Hand} /> */}
                            </Heading>
                            <Text align="left" fontFamily="inter" sx={styles.subheading} ml={[0, "0 !important"]}>
                                {t("Don't have an account")}
                                <Link as={RouterLink} to="/signup" color="#3364FA">
                                    {t("Sign Up")}
                                </Link>
                                {/* <a href="https://gallabox.com/whatsapp" style={{ color: "#3364FA" }}>
                        Talk to us
                    </a> */}
                            </Text>
                        </HStack>
                    </Box>
                    <Box
                        as="form"
                        onSubmit={handleSubmit(onSubmit)}
                        w="100%"
                        paddingX={{ base: "10", sm: "10", lg: "20" }}
                        paddingY="4"
                    >
                        <Input
                            label={
                                <Text>
                                    {t("Email Id")} <span style={{ color: "red" }}>*</span>
                                </Text>
                            }
                            name="email"
                            type="text"
                            size="sm"
                            borderRadius="4px"
                            register={register}
                            registerProps={{ required: true }}
                            placeholder={t("Enter your Email ID")}
                            error={errors.email?.message}
                            autoFocus
                        />
                        <Input
                            label={
                                <Text>
                                    {t("Password")} <span style={{ color: "red" }}>*</span>
                                </Text>
                            }
                            name="password"
                            type="password"
                            formControlProps={{
                                paddingTop: "16px",
                            }}
                            size="sm"
                            borderRadius="4px"
                            register={register}
                            registerProps={{ required: true }}
                            placeholder={t("Enter your Password")}
                            error={errors.password?.message}
                            autoComplete="off"
                            showPassword
                        />

                        <Link
                            as={RouterLink}
                            to="/password/reset"
                            fontFamily="inter"
                            fontSize="medium"
                            sx={{ fontSize: "sm", fontWeight: 400, mt: "3", color: "#0083FF" }}
                        >
                            {t("Forgot Password")}
                        </Link>
                        <Button
                            type="submit"
                            colorScheme="brand"
                            sx={{ ...styles.button, mt: "32px", rounded: "md", width: "100%" }}
                            isLoading={isSubmitting}
                        >
                            <span>{t("Login")}</span>
                        </Button>

                        <Text fontSize="xs" textAlign="center" color="gray.500" my={2}>
                            {t("or", { ns: "common" })}
                        </Text>

                        <VStack w="full" spacing={2}>
                            <SocialSignInBtn />

                            <SocialSignInBtn btnText={t("Sign in with Microsoft")} provider="microsoft" />
                        </VStack>
                    </Box>
                </VStack>
                <Spacer />
                <Box px={10} display="flex" paddingBottom={"25"} justifyContent="space-evenly">
                    <Link href="https://gallabox.com/privacy-policy" isExternal fontSize="sm" color="#666666">
                        {t("Privacy Policy")}
                    </Link>
                    <Divider orientation="vertical" height="20px" />
                    <Link href="https://gallabox.com/terms" isExternal fontSize="sm" color="#666666">
                        {t("Term of Use")}
                    </Link>
                    <Divider orientation="vertical" height="20px" />
                    <Link href="https://gallabox.com/contact" isExternal fontSize="sm" color="#666666">
                        {t("Contact Us")}
                    </Link>
                </Box>
            </GridItem>
        </Grid>
    );
};

export default SignIn;
