import LoadingState from "app/components/LoadingState";
import { useTablePagination } from "app/components/TanstackTable/hooks/useTablePagination";
import { useCheckAbility } from "app/hooks/useCheckAbility";
import ImportContactEmptyPage from "app/screens/ImportContact/component/ImportContactEmptyPage";
import { useGetImportContactList } from "app/screens/ImportContact/fetchHooks";
import { ImportContact } from "app/types/entity";
import { useAccountId } from "app/utils/react-helpers";
import { socketEmit, SocketEvents, useSocket } from "app/utils/use-socketio";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { openPreviewImport, readyImport } from "../utils";
import { importContactHistoryActionColumns, importContactHistoryTableColumns } from "./columns";
import ImportContactHistoryTableContent from "./ImportContactHistoryTableContent";
import { ImportContactHistoryTableColumnType } from "./types";

export const useConnectEntityImportSocket = () => {
    const accountId = useAccountId();
    const { socket } = useSocket(SocketEvents.EntityImportConnect, () =>
        console.log("socket::import_contact::connected")
    );
    useEffect(() => {
        socketEmit(socket, SocketEvents.EntityImportConnect, accountId);
    }, [accountId, socket]);
};

const ImportContactHistoryTable: React.FC = () => {
    const accountId = useAccountId();
    const history = useHistory();
    const { canIReadPII } = useCheckAbility();

    const { page, pageSize, onPageChange, onPageSizeChange } = useTablePagination();
    const { data, isLoading, isFetching, isError } = useGetImportContactList({
        accountId,
        page,
        limit: pageSize,
        getCount: true,
    });

    const columns: ImportContactHistoryTableColumnType[] = React.useMemo(() => {
        if (canIReadPII) return [importContactHistoryActionColumns, ...importContactHistoryTableColumns];
        return importContactHistoryTableColumns;
    }, [canIReadPII]);

    const handleMapping = (selectedData: ImportContact) => {
        const { id, status } = selectedData;
        if (readyImport.includes(status) || openPreviewImport.includes(status)) {
            history.push(`/contacts/import/advanced/${id}/preview`);
            return;
        }
    };

    const handleSelect = React.useCallback((data: ImportContact | null) => {
        if (!data) return;
        if (data.status == "READY FOR MAPPING" || openPreviewImport.includes(data.status)) handleMapping(data);
    }, []);

    if (isLoading) return <LoadingState isCentered={true} />;
    if (!(data?.data ?? (data as unknown as ImportContact[]))) {
        return <ImportContactEmptyPage />;
    }
    return (
        <ImportContactHistoryTableContent
            data={data?.data ?? (data as unknown as ImportContact[]) ?? []}
            totalDataCount={data?.count ?? 100}
            isLoading={isLoading}
            isDataFetching={isFetching}
            dataCountLoadType="instant"
            isError={isError}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRowClick={handleSelect}
            columns={columns}
        />
    );
};

export default ImportContactHistoryTable;
