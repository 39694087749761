import { supabase } from "app/database/supabase";
import { WebinarTableType } from "app/database/supabase/supabase.types";
import dayjs from "dayjs";

export const getWebinarBanner = async (): Promise<WebinarTableType[]> => {
    const now = dayjs();
    const formattedNow = now.utc().format("YYYY-MM-DDTHH:mm:ssZ");

    const response = await supabase
        .from<"webinar_banner", WebinarTableType>("webinar_banner")
        .select<"*", WebinarTableType>("*")
        .gt("webinar_registration_link_expire_at", formattedNow)
        .order("webinar_registration_link_expire_at", { ascending: true })
        .limit(1);
    if (response.error) {
        throw new Error(response.error.message);
    }

    return response.data;
};
