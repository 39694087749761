import { useToast } from "@chakra-ui/react";
import { QueryKey, TQueryKey } from "app/types/common";
import { EntityImportAction, EntityImportVersion, ImportContact } from "app/types/entity";
import { fetcher, mapQueryParams, patchJSON, postJSON } from "app/utils/fetchUtils";
import { update } from "app/utils/react-query";
import {
    MutationFunction,
    QueryFunction,
    useInfiniteQuery,
    UseInfiniteQueryOptions,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";
import { UseGetContactProps } from "./contact";

interface ImportedContactListProps
    extends UseInfiniteQueryOptions<ImportContact[], Error, ImportContact[], ImportContact[], TQueryKey> {
    accountId: string;
}

export const useGetImportedContact = (
    props: ImportedContactListProps
): UseInfiniteQueryResult<ImportContact[], Error> => {
    const { accountId, ...options } = props;

    const PAGE_SIZE = 20;

    const infiniteQueryResult = useInfiniteQuery<ImportContact[], Error, ImportContact[], TQueryKey>(
        [QueryKey.ContactImportList, { accountId }],
        ({ pageParam, queryKey }) => {
            const [, { accountId }] = queryKey;
            const queryParams = mapQueryParams({
                page: pageParam ?? 1,
                limit: PAGE_SIZE,
            });

            return fetcher(`/api/accounts/${accountId}/entityImports?${queryParams}`);
        },
        {
            ...options,
            getNextPageParam: (lastPage, pages) => {
                if (!lastPage?.length) return undefined;
                return lastPage.length === PAGE_SIZE ? pages.length + 1 : undefined;
            },
        }
    );

    return infiniteQueryResult;
};

interface UsePostCreateImportProps {
    accountId: string;
    onSuccess?: (contact: ImportContact) => void;
    showSuccessMessage?: boolean;
    importId?: string;
    version?: EntityImportVersion;
}

type UsePostImportContact<InputType> = UseMutationResult<ImportContact, Error, InputType>;

export const usePostCreateImport = <InputType extends Partial<ImportContact>>(
    props: UsePostCreateImportProps
): UsePostImportContact<InputType> => {
    const { accountId, onSuccess, showSuccessMessage = true, version = "V1" } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ContactImportList, { accountId }];

    const importContact = (data: InputType) => {
        return postJSON<InputType>(`/api/accounts/${accountId}/entityImports`, { ...data, version });
    };

    const mutationResult = useMutation<ImportContact, Error, InputType>(importContact, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (contact) => {
            if (showSuccessMessage) {
                toast({
                    title: "Contact Import",
                    status: "success",
                    description: "Contact import initiated!",
                });
            }
            queryClient.invalidateQueries([QueryKey.ContactImportListPagination], { exact: false });

            onSuccess?.(contact);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface UseUpdateImportBaseProps {
    accountId: string;
    showSuccessMessage?: boolean;
}

type UseUpdateImportProps = UseMutationOptions<ImportContact, Error, UpdatableImportContactFields> &
    UseUpdateImportBaseProps;
type UpdatableImportContactFields = Pick<ImportContact, "name" | "tags" | "importType" | "messageOptIn"> & {
    importId: string;
};

type UseUpdateImportResult = UseMutationResult<ImportContact, Error, UpdatableImportContactFields>;

export const useUpdateImport = (props: UseUpdateImportProps): UseUpdateImportResult => {
    const { accountId, onSuccess, showSuccessMessage = true } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ContactImportList, { accountId }];

    const importContact: MutationFunction<ImportContact, UpdatableImportContactFields> = (data) => {
        const { importId, ...updatableData } = data;
        return patchJSON(`/api/accounts/${accountId}/entityImports/${importId}`, updatableData);
    };

    const mutationResult = useMutation<ImportContact, Error, UpdatableImportContactFields>(importContact, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (...args) => {
            if (showSuccessMessage) {
                toast({
                    title: "Contact import updated",
                    status: "success",
                    // description: "Contact import initiated!",
                });
            }
            queryClient.invalidateQueries([QueryKey.ContactImportListPagination], { exact: false });

            onSuccess?.(...args);
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

interface UseImportedContactRefreshProps extends UseMutationOptions<ImportContact, Error, string> {
    accountId: string;
}
export const useImportedContactRefresh = (
    props: UseImportedContactRefreshProps
): UseMutationResult<ImportContact, Error, string> => {
    const { accountId, onSuccess, onError, ...options } = props;
    const toast = useToast();

    const fetchImportedContact: MutationFunction<ImportContact, string> = (importId) => {
        return fetcher(`/api/accounts/${accountId}/entityImports/${importId}`);
    };
    const queryClient = useQueryClient();

    return useMutation<ImportContact, Error, string>(fetchImportedContact, {
        onError: (...args) => {
            const [error] = args;
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
            onError?.(...args);
        },
        onSuccess: (...args) => {
            const [updatedImport, importId] = args;
            const queryKey = [QueryKey.ContactImportList, { accountId }];
            update<ImportContact>(queryClient, queryKey, importId, updatedImport);
            onSuccess?.(...args);
        },
        ...options,
    });
};

const fetchImportedContact: QueryFunction<ImportContact, TQueryKey> = ({ queryKey }) => {
    const [, { accountId, importId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/entityImports/${importId}`);
};

export const useImportedContact = (props: UseGetContactProps): UseQueryResult<ImportContact, Error> => {
    const { accountId, importId } = props;
    const toast = useToast();

    return useQuery<ImportContact, Error, ImportContact, TQueryKey>(
        [QueryKey.ContactImport, { accountId, importId }],
        fetchImportedContact,
        {
            enabled: Boolean(accountId) && Boolean(importId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
        }
    );
};

export const usePatchImportContact = <InputType extends Partial<ImportContact>>(
    props: UsePostCreateImportProps
): UsePostImportContact<InputType> => {
    const { accountId, importId, onSuccess, showSuccessMessage = true, version = "V1" } = props;

    const toast = useToast();
    const queryClient = useQueryClient();

    const queryKey: string | unknown[] = [QueryKey.ContactImportList, { accountId }];

    const updateContactImport = (data: InputType) => {
        return postJSON(`/api/accounts/${accountId}/entityImports/${importId || data.id}/start-or-cancel`, {
            ...data,
            version,
        });
    };

    const mutationResult = useMutation<ImportContact, Error, InputType>(updateContactImport, {
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (error) => {
            toast.closeAll();
            toast({
                title: "Error",
                status: "error",
                description: error.message,
            });
        },
        onSuccess: (contact) => {
            if (showSuccessMessage) {
                toast.closeAll();
                toast({
                    title: "Contact Import",
                    status: "success",
                    description: `Contact import mapping ${
                        contact.status === "CANCELLED" ? "cancelled" : "in process"
                    }!`,
                });
            }

            queryClient.invalidateQueries([QueryKey.ContactImportListPagination], { exact: false });

            onSuccess?.(contact);
        },
        // When mutate is called:
        onMutate: async (updatedContact) => {
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);
            // Snapshot the previous value
            const previousContact = queryClient.getQueryData<ImportContact>(queryKey);

            if (!previousContact) return {};

            const updated = { ...previousContact, ...updatedContact };

            queryClient.setQueryData<ImportContact & InputType>(queryKey, updated);

            // Optionally return a context containing data to use when for example rolling back
            return { previousContact };
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutationResult;
};

const fetchFieldAction: QueryFunction<EntityImportAction[], TQueryKey> = ({ queryKey }) => {
    const [, { accountId }] = queryKey;
    return fetcher(`/api/accounts/${accountId}/entityImports/fields?entity=CONTACT`);
};

export const useGetImportFieldAction = (props: UseGetContactProps): UseQueryResult<EntityImportAction[], Error> => {
    const { accountId } = props;
    const toast = useToast();

    return useQuery<EntityImportAction[], Error, EntityImportAction[], TQueryKey>(
        [QueryKey.ContactImportActions, { accountId }],
        fetchFieldAction,
        {
            enabled: Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
        }
    );
};
interface EntityImportReportDownloadResult {
    url: string;
}

interface UseEntityImportReportDownloadProps
    extends UseMutationOptions<EntityImportReportDownloadResult, Error, string> {
    accountId: string;
    entityImportId: string;
}

export const useEntityImportReportDownload = (
    props: UseEntityImportReportDownloadProps
): UseMutationResult<EntityImportReportDownloadResult, Error, string> => {
    const { accountId, entityImportId, onSuccess, ...options } = props;
    const toast = useToast();

    const addEntityImportReportDownload: MutationFunction<EntityImportReportDownloadResult, string> = (reportType) => {
        return postJSON(`/api/accounts/${accountId}/entityImports/${entityImportId}/report-download`, { reportType });
    };

    return useMutation<EntityImportReportDownloadResult, Error, string>(addEntityImportReportDownload, {
        onError: (error) => {
            toast({
                status: "error",
                title: "Error",
                description: error.message,
            });
        },
        onSuccess: (...args) => {
            onSuccess?.(...args);
        },
        ...options,
    });
};
