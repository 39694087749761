import { Box, Tbody, Td, Tr } from "@chakra-ui/react";
import { RowData } from "@tanstack/react-table";
import LoadingState, { LoadingStateProps } from "app/components/LoadingState";
import { useDebounce } from "app/hooks/useDebounce";
import EmptyStateSvg from "assets/EmptyState/NoDataFound.svg";
import React, { PropsWithChildren, useContext } from "react";
import CenterToScreen from "../CenterToScreen";
import { EmptyStateProps } from "../EmptyState";
import ErrorState from "../ErrorState";
import FailureState from "../FailureState";
import TableContext from "./TableContext/context";
import { TableContextType } from "./TableContext/type";
import TableRow, { TableRowOriginalProps } from "./TableRow";
import { tableStyles } from "./TableStyles";

interface TableBodyProps<T extends RowData> extends Pick<TableRowOriginalProps<T>, "onRowClick" | "enableRowDivider"> {
    isLoading?: boolean;
    error?: boolean | string;
    loaderProps?: Partial<LoadingStateProps>;
    emptyStateProps?: EmptyStateProps;
    emptyStateComp?: JSX.Element;
}

const TableCentered: React.FC<PropsWithChildren<{ colSpan: number }>> = (props) => {
    return (
        <Box minH="400px">
            <Tbody display="contents">
                <Box pos="absolute" w="fit-content" top="50%" left="50%" transform="translate(-50%, -50%)">
                    {props.children}
                </Box>
            </Tbody>
        </Box>
    );
};

const TableBody = <T extends RowData = RowData>(props: TableBodyProps<T>) => {
    const { isLoading, error, loaderProps = {}, emptyStateProps = {}, emptyStateComp, ...tableRowProps } = props;
    const {
        table,
        rows,
        data,
        columns,
        virtualizeConfig: { paddingTop, paddingBottom },
        frozenColumnPos,
        getFrozenColumnShadowStyle,
        isSomeColumnFreezed,
    } = useContext<TableContextType<T>>(TableContext);
    const pageSize = React.useMemo(() => {
        return table.getState().pagination.pageSize;
    }, [table]);

    //Slight delay need to process the data after fetching from API. So that used debounce of zero
    const debouncedLoading = useDebounce(isLoading, 0);

    if (isLoading || debouncedLoading) {
        return (
            <TableCentered colSpan={columns.length}>
                <LoadingState spinnerSize={30} title="Loading..." {...loaderProps} />
            </TableCentered>
        );
    }

    if (error) {
        return (
            <TableCentered colSpan={columns.length}>
                <ErrorState errorTitle={typeof error === "string" ? error : "Error while fetching data"} />
            </TableCentered>
        );
    }

    if (!data || data.length < 1) {
        return (
            <TableCentered colSpan={columns.length}>
                {emptyStateComp ?? (
                    <CenterToScreen isCentered={true}>
                        <FailureState imageProps={{ src: EmptyStateSvg }} title="No data found" {...emptyStateProps} />
                    </CenterToScreen>
                )}
            </TableCentered>
        );
    }

    return (
        <Tbody sx={tableStyles.tbody} h="full" display="contents">
            {paddingTop > 0 ? (
                <Tr display="contents">
                    <Td h={`${paddingTop}px`} gridColumn={`1 / ${columns.length + 1}`} />
                </Tr>
            ) : null}
            {rows.map((row, index, originalRows) => {
                const rowSelection = table.getState().rowSelection;
                const columnOrder = table.getState().columnOrder;
                return (
                    <TableRow<T>
                        key={row.id ?? index}
                        row={row}
                        rowSelection={rowSelection}
                        columnOrder={columnOrder}
                        frozenColumnPos={frozenColumnPos}
                        getFrozenColumnShadowStyle={getFrozenColumnShadowStyle}
                        isSomeColumnFreezed={isSomeColumnFreezed}
                        height="48px"
                        {...tableRowProps}
                    />
                );
            })}

            {paddingBottom > 0 ? (
                <Tr display="contents">
                    <Td h={`${paddingBottom}px`} gridColumn={`1 / ${columns.length + 1}`} />
                </Tr>
            ) : null}
        </Tbody>
    );
};

export default TableBody;
