import { Button, Grid, HStack, Icon, Image, Tag, Text, VStack } from "@chakra-ui/react";
import GallaboxLoadingState from "app/components/GBLoadingState";
import { CalendlyWorkflow, Integration } from "app/types/integration";
import { useQueryParam } from "app/utils/history";
import groupBy from "lodash/groupBy";
import React, { FC } from "react";
import { LuPlus } from "react-icons/lu";
import { useHistory } from "react-router-dom";
import { useCaseList } from "./calendlyUtils";
import CalendlyWorkFlowWrapper from "./CalendlyWorkFlowWrapper";

interface CalendlyUseCaseProps {
    data: Integration | undefined;
    isLoading: boolean;
}

const CalendlyUseCase: FC<CalendlyUseCaseProps> = ({ data, isLoading }) => {
    const history = useHistory();
    const param = useQueryParam();
    const selectedUseCase = param.get("usecase") as CalendlyWorkflow["useCase"];

    const groupedWorkflows = groupBy(data?.calendly?.workflows, "calendly.useCase");
    const handleUseCase = (value: string) => {
        const validUseCase = value.toLowerCase();
        history.push({
            pathname: history.location.pathname,
            search: `tab=2&usecase=${validUseCase}`,
        });
    };

    if (isLoading) return <GallaboxLoadingState />;

    if (selectedUseCase) return <CalendlyWorkFlowWrapper data={data} isLoading={isLoading} />;

    return (
        <Grid w="full" py={4} templateColumns="repeat(auto-fill, minmax(260px, 1fr))" gap={6}>
            {useCaseList.map((v) => {
                const count = groupedWorkflows[v.title.toLowerCase()]?.length;
                return (
                    <VStack
                        cursor="pointer"
                        onClick={() => handleUseCase(v.title)}
                        key={v.title}
                        bgColor="white"
                        p={4}
                        spacing={2}
                        height="full"
                        borderRadius="6px"
                        borderWidth="1px"
                        borderStyle="solid"
                        overflow="hidden"
                        borderColor="gray.200"
                        _hover={{ boxShadow: "md", borderColor: "#068FFF" }}
                        alignItems="flex-start"
                    >
                        <HStack width="full" justifyContent="space-between">
                            <Icon as={v.img} boxSize="5" textColor="gray.600" />
                            {count > 0 ? (
                                <Tag>{`${count} Workflow${count > 1 ? "s" : ""}`}</Tag>
                            ) : (
                                <Button leftIcon={<LuPlus />} size="xs" colorScheme="brand" variant="solid">
                                    Add Workflow
                                </Button>
                            )}
                        </HStack>
                        <Text fontSize="sm" fontWeight="600" color="gray.800">
                            {v.name}
                        </Text>
                        <Text color="gray.400" title={v.description} noOfLines={2} fontSize="xs" fontWeight="normal">
                            {v.description}
                        </Text>
                    </VStack>
                );
            })}
        </Grid>
    );
};

export default CalendlyUseCase;
