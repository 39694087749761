import React from "react";

export interface UseTablePaginationProps {
    initialPage?: number;
    initialPageSize?: number;
}

export interface UseTablePaginationResult {
    page: number;
    pageSize: number;
    onPageChange: (pageIndex: number) => void;
    onPageSizeChange: (pageSize: number) => void;
    resetToInitialPage: () => void;
    goToPreviousPage: () => void;
    goToNextPage: () => void;
}

export const useTablePagination = (props: UseTablePaginationProps = {}): UseTablePaginationResult => {
    const { initialPage = 1, initialPageSize = 20 } = props;
    const [page, setPage] = React.useState<number>(initialPage);
    const [pageSize, setPageSize] = React.useState<number>(initialPageSize);
    const onPageChange = React.useCallback((pageIndex: number) => {
        setPage(pageIndex + 1);
    }, []);
    const onPageSizeChange = React.useCallback((pageSize: number) => {
        setPageSize(pageSize);
    }, []);

    const resetToInitialPage = React.useCallback(() => {
        setPage(1);
    }, []);

    const goToPreviousPage = React.useCallback(() => {
        setPage((page) => {
            if (page <= 1) return page;
            return page - 1;
        });
    }, []);

    const goToNextPage = React.useCallback(() => {
        setPage((page) => page + 1);
    }, []);
    return {
        page,
        pageSize,
        onPageChange,
        onPageSizeChange,
        resetToInitialPage,
        goToPreviousPage,
        goToNextPage,
    };
};
