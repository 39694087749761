import { Button, HStack, Icon, ListItem, Text, UnorderedList, VStack } from "@chakra-ui/react";
import { ImportContact } from "app/types/entity";
import React from "react";
import { RiAlertFill } from "react-icons/ri";

interface ImportErrorInfoProps {
    importData: ImportContact;
    onRetry?: () => void;
}

const ImportErrorInfo: React.FC<ImportErrorInfoProps> = (props) => {
    const { importData, onRetry } = props;
    const isError =
        importData?.status === "PARSING ERROR" ||
        importData?.status === "FAILED" ||
        importData?.status === "VALIDATION ERROR";
    if (!isError) return null;
    return (
        <VStack w="full" spacing={4}>
            <VStack
                w="full"
                align="start"
                p={4}
                borderColor="gray.200"
                borderWidth="1px"
                borderRadius="lg"
                spacing={0.5}
                bg="red.50"
            >
                <HStack w="full" justify="start">
                    <Icon as={RiAlertFill} color="red.500" />
                    <Text color="gray.700" fontWeight="medium">
                        Error
                    </Text>
                </HStack>
                <UnorderedList fontSize="sm" color="gray.500" ml={10}>
                    <ListItem>{importData?.failedReason}</ListItem>
                </UnorderedList>
                {onRetry && (
                    <Button onClick={onRetry} variant="outline" size="sm" bg="white" alignSelf="end">
                        Retry
                    </Button>
                )}
            </VStack>
        </VStack>
    );
};

export default ImportErrorInfo;
