import { Timestamp } from "./common";
import { IContactTag } from "./contact";
import { IField } from "./field";
import { User } from "./user";

export const ENTITY_IMPORT_VERSIONS = ["V1", "V2"] as const;
export type EntityImportVersion = (typeof ENTITY_IMPORT_VERSIONS)[number];
export const ENTITY_IMPORT_TYPE = ["CREATE", "UPSERT"] as const;
export type EntityImportType = (typeof ENTITY_IMPORT_TYPE)[number];

export interface ImportContact extends Timestamp {
    name: string;
    status:
        | "PARSING"
        | "PARSING ERROR"
        | "READY FOR MAPPING"
        | "PROCESSING"
        | "VALIDATION ERROR"
        | "COMPLETED"
        | "FAILED"
        | "CANCELLED";
    importType?: EntityImportType;
    creatorId: User;
    mappings: ContactMapping[];
    continueOnError?: boolean;
    totalRows: number;
    createdCount?: number;
    updatedCount?: number;
    currentCount?: number;
    erroredCount?: number;
    errorReportUrl?: string;
    conmpletedReportUrl?: string;
    errorItems?: { message: string }[];
    id: string;
    failedReason?: string;
    dataRows: any[];
    fileId?: string;
    fileUrl: string;
    entity?: string;
    tags?: IContactTag[];
    version?: EntityImportVersion;
    messageOptIn?: boolean;
    hasUploadedFile?: boolean;
    hasErrorReport?: boolean;
    hasCompletedReport?: boolean;
    uploadedFileName?: string;
}

export const ContactMappingAction = ["Append", "Replace", "Add If Empty", "Ignore", ""] as const;

export type CONTACT_MAPPING_ACTION = (typeof ContactMappingAction)[number];

export interface ContactMapping {
    fieldId?: string;
    name: string;
    action: CONTACT_MAPPING_ACTION;
    format?: string;
}

export interface EntityImportAction {
    field: IField;
    actions: string[];
    formats?: string[];
}
// Currently using entity import version
export const ENTITY_IMPORT_VERSION: EntityImportVersion = "V2";
