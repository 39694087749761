import React from "react";
import QAComp from "./component/QAComp";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { Text, Box, VStack, Heading, StackProps, ButtonProps, Flex, Icon, HStack, Stack } from "@chakra-ui/react";
import { OnboardingQuestionType } from "./type";
import OTPImg from "assets/onboarding/free-trial/otp-img.svg";
import OTPImgMob from "assets/onboarding/free-trial/otp-img-mobile.svg";
import BackupImg from "assets/onboarding/free-trial/backup-img.svg";
import BackupImgMob from "assets/onboarding/free-trial/backup-img-mobile.svg";
import BusinessMngImg from "assets/onboarding/free-trial/fb-business-manager-img.svg";
import BusinessMngImgMob from "assets/onboarding/free-trial/fb-business-manager-img-mobile.svg";
import BackIconButton from "app/components/Button/BackIconButton";
import GBStepper from "app/components/GBStepper";
import { useGBMediaQuery } from "app/hooks/useGBMediaQuery";

interface QandAProps extends ReturnType<typeof useSteps> {
    setAnswer: (qusKey: OnboardingQuestionType, val: boolean) => void;
    onBack: () => void;
    containerProps?: StackProps;
    backButtonProps?: ButtonProps;
}

const QandA: React.FC<QandAProps> = (props) => {
    const { setAnswer, onBack, containerProps = {}, backButtonProps = {}, ...stepProps } = props;
    const { nextStep, activeStep } = stepProps;

    const { isMobile } = useGBMediaQuery();

    const navigateToNextStep = React.useCallback((qusKey: OnboardingQuestionType, val: boolean) => {
        setAnswer(qusKey, val);
        nextStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <VStack w="full" p={{ base: 5, sm: "unset", lg: "unset" }} h="full" spacing={5}>
            <Heading fontSize="lg" whiteSpace="pre-wrap" textAlign="center">
                You are one step away from {isMobile && <br />}
                <Text as="span" color="brand.500">
                    7 days free trial
                </Text>
            </Heading>

            <VStack {...containerProps} flexWrap="nowrap" spacing={10} flex={{ base: 1, sm: "unset", lg: "unset" }}>
                <HStack w="full">
                    <Box
                        flex={1}
                        pos={{ base: "fixed", sm: "unset", lg: "unset" }}
                        top={{ base: "20px", sm: "unset", lg: "unset" }}
                        left={{ base: "20px", sm: "unset", lg: "unset" }}
                    >
                        <BackIconButton onClick={onBack} {...backButtonProps} />
                    </Box>
                    {isMobile && <Box flex={1} />}
                    <Box w="190px">
                        <GBStepper activeStep={activeStep} noOfSteps={3} />
                    </Box>
                    <Box flex={1} />
                </HStack>
                {activeStep === 0 && (
                    <QAComp
                        imgSrc={isMobile ? OTPImgMob : OTPImg}
                        title="Do you have the phone number to connect with WhatsApp Business API handy?"
                        helperText="You will receive the OTP to this number"
                        primaryBtnText="Yes! I have 😃"
                        secondaryBtnText="No, I don't 😥"
                        secondaryBtnEvent="ONBOARD_NOT_HAVE_NUMBER"
                        primaryBtnEvent="ONBOARD_HAVE_NUMBER"
                        primaryBtnOnClick={() => {
                            navigateToNextStep("whatsapp-api", true);
                        }}
                        secondaryBtnOnClick={() => {
                            navigateToNextStep("whatsapp-api", false);
                        }}
                    />
                )}
                {activeStep === 1 && (
                    <QAComp
                        imgSrc={isMobile ? BackupImgMob : BackupImg}
                        title="Confirm that you don’t use any WhatsApp account with this number?"
                        helperText={
                            <Text
                                color="gray.600"
                                fontSize={{ base: "xs", sm: "md", lg: "md" }}
                                textAlign={{ base: "center", sm: "left", lg: "left" }}
                            >
                                If so, then
                                <Box as="span" fontWeight="semibold" color="gray.800">
                                    {" backup and remove "}
                                </Box>
                                WhatsApp application before proceeding.
                            </Text>
                        }
                        primaryBtnText="Yes, I don't use 😃"
                        secondaryBtnText="No, I use 😥"
                        secondaryBtnEvent="ONBOARD_NOT_USING_WHATSAPP"
                        primaryBtnEvent="ONBOARD_USING_WHATSAPP"
                        primaryBtnOnClick={() => {
                            navigateToNextStep("already-linked", true);
                        }}
                        secondaryBtnOnClick={() => {
                            navigateToNextStep("already-linked", false);
                        }}
                    />
                )}
                {activeStep === 2 && (
                    <QAComp
                        imgSrc={isMobile ? BusinessMngImgMob : BusinessMngImg}
                        title="Do you have the Facebook Business Manager access ready?"
                        helperText="You need this to connect your number with us."
                        primaryBtnText="Yes! I have 😃"
                        secondaryBtnText="No, I don't 😥"
                        secondaryBtnEvent="ONBOARD_NOT_HAVE_FB_MANAGER"
                        primaryBtnEvent="ONBOARD_HAVE_FB_MANAGER"
                        primaryBtnOnClick={() => {
                            setAnswer("fb-manager-access", true);
                        }}
                        secondaryBtnOnClick={() => {
                            setAnswer("fb-manager-access", false);
                        }}
                    />
                )}
            </VStack>
        </VStack>
    );
};

export default QandA;
