import { useToast } from "@chakra-ui/react";
import { QueryKey } from "app/types";
import { ImportContact } from "app/types/entity";
import { mapQueryParams, fetcher } from "app/utils/fetchUtils";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

type ImportContactListType = { data: ImportContact[]; count?: number };

type UseImportContactListOptions = UseQueryOptions<ImportContactListType, Error, ImportContactListType>;

interface UseGetImportContactListProps extends UseImportContactListOptions {
    accountId: string;
    page?: number;
    getCount?: boolean;
    populatePaths?: string[];
    limit?: number;
    search?: string;
}

export const useGetImportContactList = (
    props: UseGetImportContactListProps
): UseQueryResult<ImportContactListType, Error> => {
    const { accountId, page, populatePaths, getCount, limit = 20, search, ...options } = props;

    const toast = useToast();

    const queryResult = useQuery<ImportContactListType, Error, ImportContactListType>(
        [QueryKey.ContactImportListPagination, { accountId, page, populatePaths, limit, getCount, search }],
        ({ queryKey }) => {
            const [, { accountId, page, populatePaths, limit, getCount, search }] = queryKey as [
                string,
                Record<string, any>
            ];
            const queryParams = mapQueryParams({
                limit,
                getCount,
                page: page ?? 1,
                populatePaths: populatePaths?.join(",") ?? "",
                search,
            });

            return fetcher(`/api/accounts/${accountId}/entityImports?${queryParams}`);
        },
        {
            ...options,
            refetchInterval: 120000,
            cacheTime: 1000 * 60 * 10,
            staleTime: 1000 * 60 * 10,
            enabled: Boolean(accountId),
            onError: (error) => {
                toast({
                    status: "error",
                    title: "Error",
                    description: error.message,
                });
            },
            keepPreviousData: true,
        }
    );
    return queryResult;
};
