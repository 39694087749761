import { HStack, Button, Text, Box, StackProps, ButtonGroup } from "@chakra-ui/react";
import { LabelValue } from "app/types";
import React from "react";
import ReactSelectStyled from "../../CustomizedReactSelect/ReactSelectStyled";
import LoadingState from "../../LoadingState";
import { pageSizeOption } from "../utils";

export interface PaginationFooterControlledCommonProps extends StackProps {
    pageSize: number;
    onPageSizeChange: (pageSize: number) => void;
    canPreviousPage: boolean;
    canNextPage: boolean;
    pageIndex: number;
    onPreviousPage: () => void;
    onNextPage: () => void;
    isDataFetching?: boolean;
    componentIdentifier?: string;
    noOfDataInCurrentPage?: number;
}

export interface PaginationFooterControlledWithTotalCountProps extends PaginationFooterControlledCommonProps {
    dataCountLoadType: "instant";
    totalDataCount: number;
}
export interface PaginationFooterControlledWithoutTotalCountProps extends PaginationFooterControlledCommonProps {
    dataCountLoadType: "lazy";
    totalDataCount?: number;
    isLoadingTotalCount: boolean;
    onTotalCountClick: () => void;
}

export type PaginationFooterControlledProps =
    | PaginationFooterControlledWithTotalCountProps
    | PaginationFooterControlledWithoutTotalCountProps;

const PaginationFooterControlled: React.FC<PaginationFooterControlledProps> = (props) => {
    const {
        canNextPage,
        canPreviousPage,
        pageIndex,
        pageSize,
        onPageSizeChange,
        onNextPage,
        onPreviousPage,
        isDataFetching,
        noOfDataInCurrentPage,
        ...paginationContainerProps
    } = props;

    const inlineLoading = React.useMemo(() => {
        return (
            <Box mx={1}>
                <LoadingState />
            </Box>
        );
    }, []);

    const { pageStartCount, pageEndCount } = React.useMemo(() => {
        const pageStartCount = pageIndex * pageSize + 1;
        let pageEndCount: string | number | JSX.Element = pageStartCount - 1 + pageSize;

        const { totalDataCount } = props;

        if (isDataFetching) {
            pageEndCount = inlineLoading;
        } else if ((!canNextPage && totalDataCount) || (totalDataCount && totalDataCount < pageEndCount)) {
            pageEndCount = totalDataCount;
        } else {
            pageEndCount = pageStartCount - 1 + (noOfDataInCurrentPage ?? pageEndCount);
        }

        return { pageStartCount, pageEndCount };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canNextPage, pageIndex, pageSize, props.totalDataCount, isDataFetching, noOfDataInCurrentPage]);

    const dataCountDisplayComp = React.useMemo(() => {
        if (props.dataCountLoadType === "lazy" && props.totalDataCount == null)
            return (
                <Button
                    display="inline-flex"
                    size="xs"
                    colorScheme="blue"
                    variant="link"
                    isLoading={props.isLoadingTotalCount}
                    onClick={props.onTotalCountClick}
                    lineHeight="inherit"
                    height="min-content"
                    mx={1}
                >
                    Total count
                </Button>
            );
        if (props.dataCountLoadType === "lazy" && props.isLoadingTotalCount)
            return (
                <Box as="text" display="inline-flex" fontSize="2xs" color="gray.700" mx={1}>
                    {inlineLoading} items
                </Box>
            );

        return (
            <Text display="inline" fontSize="2xs" color="gray.700" mx={1}>
                {props.totalDataCount ?? "-"} items
            </Text>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inlineLoading, props.dataCountLoadType, props.totalDataCount, props]);

    const disablePreviousBtn = !canPreviousPage || isDataFetching;
    const disableNextBtn = !canNextPage || isDataFetching;

    return (
        <HStack py={3} px={6} zIndex={1} w="full" bg="white" borderWidth="1px" {...paginationContainerProps}>
            <HStack flex={1}>
                <Text fontSize="2xs" color="gray.700">
                    Show rows per page
                </Text>
                <ReactSelectStyled<LabelValue<number, number>>
                    options={pageSizeOption}
                    value={pageSizeOption.find((option) => option.value == pageSize)}
                    onChange={(selected) => {
                        const selectedOption = selected as LabelValue<number, number>;
                        if (selectedOption?.value) {
                            onPageSizeChange(Number(selectedOption.value));
                        }
                    }}
                    width="70px"
                    size="xs"
                    menuPlacement="top"
                />
            </HStack>
            <Box as="p" display="inline-flex" fontSize="2xs" color="gray.700" mx={1}>
                Showing {pageStartCount} - {pageEndCount} of {dataCountDisplayComp}
            </Box>
            <ButtonGroup size="xs" variant="outline" bg="white">
                <Button onClick={onPreviousPage} isDisabled={disablePreviousBtn}>
                    Previous
                </Button>
                <Button onClick={onNextPage} isDisabled={disableNextBtn}>
                    Next
                </Button>
            </ButtonGroup>
        </HStack>
    );
};

export default React.memo(PaginationFooterControlled);
