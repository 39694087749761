import React from "react";
import {
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Popover,
    PopoverTrigger,
    PopoverBody,
    PopoverContent,
    VStack,
    PopoverArrow,
    HStack,
    Text,
    Divider,
    Box,
    BoxProps,
    ButtonProps,
    IconProps,
} from "@chakra-ui/react";
import NavigationButton from "./NavigationButton";
import { NavigationItem, SubNavigationItem } from "../types";
import { checkActiveRoute, appLayoutStyles } from "../utils";
import StatusLabel, { getStatusColor } from "app/components/StatusLabel";
import { useGetAccessibleNavigation } from "../hooks/useGetAccessibleNavigations";
import { useNavigationTrackEvent } from "../hooks/useNavigationTrackEvent";

export interface NavigationAccordionItemProps {
    mainNav: NavigationItem;
    subNav?: SubNavigationItem[];
    onToggle?: () => void;
    isCollapsed?: boolean;
}

const buttonStyle: ButtonProps = {
    size: "sm",
    variant: "ghost",
    w: "full",
    maxW: "full",
    fontSize: "2xs",
    _hover: { bg: "none" },
    _active: { bg: "none" },
    fontWeight: "normal",
    rounded: "none",
    color: "gray.600",
};

const NavigationAccordionItem: React.FC<NavigationAccordionItemProps> = (props) => {
    const { subNav, mainNav, isCollapsed = false, onToggle } = props;
    const isSubNav = subNav?.length ?? false;
    const { to: mainNavTo, href: mainNavHref, ...restMainNav } = mainNav;
    const mainNavigationProps = isSubNav ? restMainNav : mainNav;

    const { mainNav: accessibleMainNav, subNav: accessibleSubNav } = useGetAccessibleNavigation(props);
    const trackLeftNavEvent = useNavigationTrackEvent({ isCollapsed });

    const isActiveItem = isSubNav
        ? checkActiveRoute(
              (accessibleSubNav
                  ?.map((nav) => {
                      if (nav.type !== "divider") return nav.to;
                  })
                  ?.filter((to) => Boolean(to)) as string[]) ?? []
          )
        : checkActiveRoute(mainNav.to ? [mainNav.to] : []);

    const badge = React.useCallback(
        (badge: NavigationItem["badge"], isSubNav: boolean = false) => {
            if (!badge) return null;
            if (!isCollapsed || isSubNav) return <StatusLabel status={badge} px="1" />;
            return (
                <Box
                    {...(getStatusColor(badge) as BoxProps)}
                    rounded="full"
                    top="4px"
                    right="10px"
                    boxSize="2"
                    pos="absolute"
                />
            );
        },
        [isCollapsed]
    );
    const mainNavBadge = React.useMemo(() => {
        if (!isSubNav) return badge(mainNavigationProps.badge);
        if (!isCollapsed) return null;

        const subNavBadges = accessibleSubNav
            ?.map((subNavigation) => {
                if (subNavigation.type !== "divider") {
                    return subNavigation.badge;
                }
                return undefined;
            })
            .filter((value) => Boolean(value));

        if (!subNavBadges) return null;
        if (subNavBadges?.length < 1) return null;
        else if (subNavBadges?.length === 1) return badge(subNavBadges[0]);
        else return badge("new");
    }, [accessibleSubNav, badge, isCollapsed, isSubNav, mainNavigationProps.badge]);

    const itemStyle = isActiveItem ? appLayoutStyles.activeItem : appLayoutStyles.item;

    const accordionButton = React.useMemo(() => {
        const { buttonProps, ...navigationButtonProps } = mainNavigationProps;

        return (
            <AccordionButton
                py={0}
                px={isCollapsed ? 0 : 1.5}
                as="div"
                sx={itemStyle}
                _hover={isActiveItem ? {} : appLayoutStyles.hoverItem}
                onClick={() => {
                    if (isSubNav || !mainNavigationProps.eventName) return;

                    trackLeftNavEvent(mainNavigationProps.eventName);
                }}
                tabIndex={-1}
            >
                <HStack w="full" pos="relative">
                    <NavigationButton
                        {...navigationButtonProps}
                        {...buttonStyle}
                        {...(itemStyle as ButtonProps)}
                        {...buttonProps}
                        onToggle={onToggle}
                        iconProps={{ ...(itemStyle as IconProps), ...mainNavigationProps.iconProps }}
                        isCollapsed={isCollapsed}
                        justifyContent={isCollapsed ? "center" : "start"}
                        pl={0}
                        py={isSubNav ? 0 : undefined}
                    />
                    {mainNavBadge}
                </HStack>
                {isSubNav && !isCollapsed && <AccordionIcon color={isActiveItem ? "white" : "gray.500"} />}
            </AccordionButton>
        );
    }, [isActiveItem, isCollapsed, isSubNav, itemStyle, mainNavBadge, mainNavigationProps, trackLeftNavEvent]);

    const accordionButtonWithPopover = React.useMemo(() => {
        return (
            <Popover trigger="click" placement="right" isLazy>
                {({ onClose }) => {
                    return (
                        <>
                            <PopoverTrigger>{accordionButton}</PopoverTrigger>
                            <PopoverContent maxW="min-content">
                                <PopoverArrow />
                                <PopoverBody boxShadow="base" p="2">
                                    <VStack spacing={0} minW="28" w="full">
                                        <Text fontSize="0.625rem" color="gray.400" alignSelf="start" pl={2}>
                                            {accessibleMainNav?.title}
                                        </Text>
                                        {accessibleSubNav?.map((subNavigation, index) => {
                                            if (subNavigation.type === "divider") {
                                                return (
                                                    <Divider
                                                        alignSelf="center"
                                                        key={`divider-${index}`}
                                                        borderColor="gray.300"
                                                    />
                                                );
                                            }
                                            const { buttonProps, ...navigationButtonProps } = subNavigation;

                                            return (
                                                <HStack
                                                    key={index}
                                                    w="full"
                                                    _hover={appLayoutStyles.hoverItem}
                                                    pr="1.5"
                                                    onClick={() => {
                                                        onClose();
                                                        if (!subNavigation.eventName) return;
                                                        trackLeftNavEvent(subNavigation.eventName);
                                                    }}
                                                >
                                                    <NavigationButton
                                                        justifyContent="start"
                                                        py={0}
                                                        px={2}
                                                        {...navigationButtonProps}
                                                        {...buttonStyle}
                                                        {...buttonProps}
                                                        onToggle={onToggle}
                                                        color="gray.600"
                                                    />
                                                    {badge(subNavigation.badge, true)}
                                                </HStack>
                                            );
                                        })}
                                    </VStack>
                                </PopoverBody>
                            </PopoverContent>
                        </>
                    );
                }}
            </Popover>
        );
    }, [accordionButton, accessibleMainNav, accessibleSubNav, badge, trackLeftNavEvent]);

    if (!isSubNav && !accessibleMainNav) return null;
    if (isSubNav && (!accessibleSubNav || accessibleSubNav.length === 0)) {
        return null;
    }

    return (
        <AccordionItem border="none" w="full" px="0">
            {isCollapsed && isSubNav ? accordionButtonWithPopover : accordionButton}

            {isSubNav && !isCollapsed && (
                <AccordionPanel as={VStack} spacing={0} p={0} w="full">
                    {accessibleSubNav?.map((subNavigation, idx) => {
                        if (subNavigation.type === "divider")
                            return <Divider alignSelf="center" borderColor="gray.300" w="75%" key={idx} />;

                        const { buttonProps, ...navigationButtonProps } = subNavigation;

                        return (
                            <HStack
                                id={idx + subNavigation.title}
                                key={idx + subNavigation.title}
                                pr="1.5"
                                w="full"
                                _hover={appLayoutStyles.hoverItem}
                                onClick={() => {
                                    if (!subNavigation.eventName) return;
                                    trackLeftNavEvent(subNavigation.eventName);
                                }}
                            >
                                <NavigationButton
                                    {...navigationButtonProps}
                                    {...buttonStyle}
                                    {...buttonProps}
                                    onToggle={onToggle}
                                    justifyContent="start"
                                    py={0}
                                    pr={1}
                                    pl="30px"
                                    fontSize="xs"
                                />
                                {badge(subNavigation.badge)}
                            </HStack>
                        );
                    })}
                </AccordionPanel>
            )}
        </AccordionItem>
    );
};

export default React.memo(NavigationAccordionItem);
