import lazyWithRetry from "app/components/Lazy/LazyWithRetry";
import NavigationLayout from "app/layouts/NavigationLayout";
import { Authed } from "app/types";
import withSuspense from "app/WithSuspense";
import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

const BotTemplateAdmin = withSuspense(
    lazyWithRetry(() => import("app/screens/Account/Admin/BotTemplate/BotTemplateAdmin"))
);
const CreateAndUpdateBotTemplate = withSuspense(
    lazyWithRetry(() => import("app/screens/Account/Admin/BotTemplate/BotTemplateForm"))
);
const BotTemplateFlow = withSuspense(
    lazyWithRetry(() => import("app/screens/Account/Admin/BotTemplate/BotTemlateFlow"))
);

const AccountsExport = withSuspense(lazyWithRetry(() => import("app/screens/Account/Admin/AccountsExport")));

const AdminRoutes: React.FC<{ authed: Authed }> = ({ ...routeProps }) => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute
                {...routeProps}
                path={`${path}/bot-template`}
                exact
                component={NavigationLayout}
                componentProps={{ component: BotTemplateAdmin }}
            />
            <ProtectedRoute
                {...routeProps}
                path={[`${path}/bot-template/create`, `${path}/bot-template/:botTemplateId`]}
                exact
                component={NavigationLayout}
                componentProps={{ component: CreateAndUpdateBotTemplate }}
            />
            <ProtectedRoute
                {...routeProps}
                path={`${path}/bot-template/:botTemplateId/:botTemplateName`}
                exact
                component={NavigationLayout}
                componentProps={{ component: BotTemplateFlow }}
            />

            <ProtectedRoute
                {...routeProps}
                path={[`${path}/accounts-export`,`${path}/export/:backstageType`]}
                exact
                component={NavigationLayout}
                componentProps={{ component: AccountsExport }}
            />
        </Switch>
    );
};

export default AdminRoutes;
