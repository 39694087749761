import { Button, ButtonProps, Icon } from "@chakra-ui/react";
import React from "react";
import { CgImport } from "react-icons/cg";
import { Link } from "react-router-dom";

type NewImportButtonProps = ButtonProps;

const NewImportButton: React.FC<NewImportButtonProps> = (props) => {
    return (
        <Button
            borderRadius="base"
            colorScheme="brand"
            variant="solid"
            leftIcon={<Icon as={CgImport} color="white" fontSize="sm" />}
            size="sm"
            as={Link}
            to="/contacts/import/new"
            {...props}
        >
            Import
        </Button>
    );
};

export default NewImportButton;
