import { Tr, Box, StackProps, TableRowProps, Thead, TableColumnHeaderProps } from "@chakra-ui/react";
import { RowData, Table } from "@tanstack/react-table";
import React, { useContext, MouseEventHandler } from "react";
import { tableStyles } from "./TableStyles";
import TableContext from "./TableContext/context";
import { GBColumnDef, TableContextType } from "./TableContext/type";
import TableHeaderCell from "./TableHeaderCell";
import { headerStyles } from "./TableStyles";
import isEqual from "lodash/isEqual";

const resizingStyle = { ...tableStyles.resizer, ...tableStyles.isResizing };
const resizerStyle = tableStyles.resizer;
interface TableHeaderProps<T extends RowData> extends TableRowProps {
    table?: Table<T>;
    isSticky?: boolean;
    enableHeaderColumnDivider?: boolean;
    tableCellProps?: TableColumnHeaderProps;
}
const TableHeader = <T extends RowData = RowData>(props: TableHeaderProps<T>) => {
    const {
        isSticky = true,
        enableHeaderColumnDivider = false,
        tableCellProps = {},
        ...tableHeaderContainerProps
    } = props;
    const {
        table,
        frozenColumnPos,
        getFrozenColumnShadowStyle,
        frozenColumnConfig: { frozenColumnRef, setFrozenColumnRef },
    } = useContext<TableContextType<T>>(TableContext);
    const stickyProps = isSticky ? ({ pos: "sticky", top: 0, zIndex: 2 } as StackProps) : {};

    const onResize = React.useCallback((resizerHandler: (e: unknown) => void) => {
        return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            resizerHandler(e);
        };
    }, []);

    return (
        <Thead position="relative" zIndex="2" display="contents">
            {table.getHeaderGroups().map((headerGroup) => (
                <Tr
                    key={headerGroup.id}
                    role="group"
                    {...tableHeaderContainerProps}
                    {...stickyProps}
                    display="contents"
                >
                    {headerGroup.headers.map((header, index) => {
                        const { enableColumnFreezing, freezeTo } = header.column.columnDef as GBColumnDef<T>;
                        const isResizable = header.column.getCanResize();
                        const resizerHandler = header.getResizeHandler();
                        return (
                            <TableHeaderCell<T>
                                key={index}
                                header={header}
                                isSticky={isSticky}
                                freezeLeftPosition={frozenColumnPos?.left?.[index]}
                                freezeRightPosition={frozenColumnPos?.right?.[index]}
                                ref={(element) => {
                                    if (!enableColumnFreezing || !element) return;
                                    if (!frozenColumnRef.hasOwnProperty(`${index}_${!freezeTo ? "left" : freezeTo}`)) {
                                        setFrozenColumnRef((old) => ({
                                            ...old,
                                            [`${index}_${!freezeTo ? "left" : freezeTo}`]: element,
                                        }));
                                    }
                                }}
                                {...headerStyles.th}
                                {...(isResizable || enableHeaderColumnDivider ? headerStyles.th_divider : {})}
                                {...getFrozenColumnShadowStyle("right", index)}
                                {...getFrozenColumnShadowStyle("left", index)}
                                {...tableCellProps}
                            >
                                {isResizable && (
                                    <Box
                                        onMouseDown={onResize(resizerHandler)}
                                        onTouchStart={resizerHandler}
                                        _groupHover={header.column.getIsResizing() ? resizingStyle : resizerStyle}
                                    />
                                )}
                            </TableHeaderCell>
                        );
                    })}
                </Tr>
            ))}
        </Thead>
    );
};

export default React.memo(TableHeader, (previous, current) => {
    return isEqual(previous, current);
}) as <T extends RowData = RowData>(props: TableHeaderProps<T>) => JSX.Element;
