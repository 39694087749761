import { Accordion, VStack } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
interface NavigationAccordionProps {
    isCollapsed: boolean;
}
const NavigationAccordion: React.FC<PropsWithChildren<NavigationAccordionProps>> = (props) => {
    const { isCollapsed, children } = props;
    return (
        <Accordion
            as={VStack}
            allowToggle
            w="full"
            mb="10"
            overflowX="auto"
            align={isCollapsed ? undefined : "start"}
            px={isCollapsed ? "1" : "2"}
            spacing={1.5}
        >
            {children}
        </Accordion>
    );
};

export default NavigationAccordion;
